<meta name="viewport" content="width=device-width, initial-scale=1.0">

<app-menu></app-menu>

<body  [ngStyle]="{'width': receivedData ? '83%' : 'auto'}">
  <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.actualite == 1">
    <br><br>
    <div class="card">
      <div class="card-body">
   <br>

       
<br>

        <h2 id="h4" class="titre">قائمة الترند </h2>

        <div class="row" style="overflow-x:auto;direction: rtl;" id="part2">
          <table id="dtable" datatable [dtOptions]="dtOptions" class="row-border hover" style="padding-top: 22px;">
            <thead>
              <tr>
                <th style="border-bottom-right-radius: 10px; border-top-right-radius:10px">#</th>
                <th>التاريخ</th>
                <th style="width: 191px;">المستخدم</th>
                <th style="width: 178px;">رمز الإحالة</th>
                <th style="width: 111px;">عدد الإعجابات</th>
                <th >عدد التعليقات</th>
                <th style="border-bottom-left-radius: 10px; border-top-left-radius:10px"></th>
              </tr>
            </thead>
            <tbody *ngFor="let trend of trenddemands ; let index = index">
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;">
                <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{((currentPage * 10) -9)+index}}</td>
                <td  style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ trend.created_at | date: 'yyyy-MM-dd H:mm' }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" >{{ trend.name }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ trend.codeparrainage }}</td>

                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" >{{ trend.reactCount }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" >{{ trend.commentCount }}</td>
                <td style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"><span class="d-flex">
                    <button id="submitB" [routerLink]="['/publication-detail/', trend._id]" >
                        <i class="material-icons" style="color: #30287a;">info</i>
                    </button>

                    <button (click)="openSm(content)" #refuseButton id="submitB">
                      <i class="material-icons" style="color: rgb(206, 6, 6);">cancel</i>
                    </button> &nbsp; &nbsp;
                  </span>
                </td>



                <ng-template #content let-modal>
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                    </div>
                    <div class="modal-body" dir="rtl">
                      <h4 id="h3">  إزالة هذا المنشور من قائمة الترند </h4>
                      <div class="justify-content-center d-flex">
                        <button (click)="refusetrend(trend._id); submitButton2.disabled = true" #submitButton2
                          type="button" class="lab btn">إزالة  المنشور</button>
                      </div>
                    </div>
                  </div>
                </ng-template>

              </tr>

            </tbody>
            <tr *ngIf="trenddemands?.length == 0 ">
              <td colspan="7" class="no-data-available">لا توجد بيانات متوفرة</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="user.role != 'globale' && user.actualite == 0 " style="padding-top: 10% !important">
    <div class="container" style="width: 100%; height: 400px; max-height: 800px;background-size: contain;
      background-repeat: no-repeat;background-position: center center;display:block;margin-top:auto;
      margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
    </div>
  </div>
</body>


