<app-menu></app-menu>

<body dir="rtl" [ngStyle]="{'width': receivedData ? '82%' : 'auto'}" >
    <div class="container pt-5" *ngIf="user.role == 'globale' || user.theme == 1">
        <h2>الخلفيات</h2>
        <div class="row">

            <div class="col">
                <form [formGroup]="registerForm" enctype="multipart/form-data">
                    <div class="bord">

                        <br>

                          <div class="d-flex flex-row justify-content-center align-items-center">
                            <label  for="inputGroupFile01">
                                <img class="img-fluid img" *ngIf="link ==''" src="assets/picupload.png" alt="">
                                <img class="img-fluid img" *ngIf="link !=''" [src]="link" style="
                               height: 172px;width: 185px;
                            ">

                          </label> </div>

                        <div class="d-flex flex-row justify-content-center align-items-center">
                          
                            <input (change)="selectImage($event)" [(ngModel)]="imag" name="imag" accept="image/*"
                                formControlName="imag" style="display:none" type="file" class="custom-file-input"
                                id="inputGroupFile01">
                        </div>
                        <div class="invalid-feedback" *ngIf="submitted == true;" ><span>{{message}}</span></div>
                        <br>
                        <div class="d-flex" style="margin-right: 97%;width: 221px;margin-top: -97px;">
                          <div class="card" style="height: 120px;background-color: white;margin-right: -119px;margin-top: -1px;
                          box-shadow: 3px 3px 3px darkgrey;">
                            <h4><p class="prix" style="text-align: center;">المبلغ</p></h4>
                            <br>
                            <div style="display: flex; align-items: center;">
                          <input style="margin-right:0px;" type="text" onpaste="return false;" (keypress)="keyPressNumbers($event)"style="outline: none;margin-inline: auto;border: none;border-bottom: 1px solid #959595;padding-bottom: 0px;width: 100%;box-shadow: none;border-radius: 0px;margin-top: -15px;"
                              maxlength="10"  class="inp form-control" [(ngModel)]="prix" name="prix" formControlName="prix" placeholder="المبلغ">
                                <span style="margin-right: -25px;margin-top: -15px;">نقطة</span>
                              </div>
                        </div>
                        </div>
                        <br>
                        
                        <br>
                        <div class="d-flex flex-row justify-content-center align-items-center" style="margin-top:39px;margin-left:-203px" >
                            <button (click)="ajouter()" class="btn1" style="margin-top: -129px;margin-right: 156px;width: 107px;height: 38px;">تسجيل</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-lg-7" style=" border: 1px dashed #6c9aa6;  border-radius: 15px;">
              <div class="row" style="
              margin-top: 9px;
          ">
                  <div class="col-md-4" style="padding-bottom: 12px;"
                      *ngFor="let theme of themes| slice: pageSize | slice: 0:itemsPerPage; let index = index;">
                      <div style="border: 1px dashed blue; border-radius: 8px;height: 283px;">
                     
                          <img class="img-fuild image1" src="{{theme.image}}" alt="" height="134"  style="
                          margin-top: 5px; width: 90%;
                      ">
                          <p class="prix" style="text-align: center;font-size: 15px;margin: -10px;margin-top: 4px;">ثمن : {{theme.prix}} <img src="../assets/Coin.png" alt=""></p>
                          <br>
                          <div class="d-flex" style="justify-content:center" >

                            <img (click)="openSm2(content4)" class="delete" height="37px" width="37px" src="assets/deleteic.png"
                            alt="">
                            <a (click)="openSm2(content3)"> <img  src="../assets/edit.png" alt="" style="
                              height: 37px;
                              width:37px;
                              margin-top: 0px;
                              margin-right: 6px;
                              
                           "> </a>
                        </div>
                      </div>


                      <ng-template #content4 let-modal>
                          <div class="modal-body" dir="rtl">
                              <button type="button" class="close" aria-label="Close"
                                  (click)="modal.dismiss('Cross click')">
                                  <span aria-hidden="true">&times;</span>
                              </button>
                              <h5 id="h3"> هل تريد حذف هذه الخلفية ؟</h5>
                              <div class="d-flex justify-content-center">
                                  <button id="btnP" (click)="delete(theme.id)" type="button"
                                      class="btn btn-primary">&nbsp; &nbsp; تأكيد &nbsp; &nbsp;</button>
                              </div>
                          </div>
                      </ng-template>


                      <ng-template #content3 let-modal>
                           <div class="modal-content" >
                           <div class="modal-body" dir="rtl">
                              <button type="button" class="close" aria-label="Close"
                                  (click)="modal.dismiss('Cross click')" >
                                  <span aria-hidden="true">&times;</span>
                              </button>

                              <form [formGroup]="registerForm2" enctype="multipart/form-data">
                                  <div >
                                      <h5 style="text-align: center ;">تعديل الخلفيات</h5>
                                      <br>
                                      <div class="cardimg">
                                          <div
                                              class=" col-img d-flex flex-row justify-content-center align-items-center ">
                                              <img class="img-fluid img imgg" *ngIf="link2 ==''" src="{{theme.image}}"
                                                  alt="">
                                              <img class="img-fluid img imgg"  *ngIf="link2 !=''" [src]="link2">
                                          </div>
                                      </div>
                                      <div class="d-flex flex-row justify-content-center align-items-center">
                                          <label class="btn2" for="inputGroupFile02">تحميل</label>
                                          <input (change)="selectImage2($event)" accept="image/*" style="display:none"
                                              type="file" class="custom-file-input" id="inputGroupFile02">
                                      </div>

                                      <br>
                                      <div class="d-flex">
                                          <p class="prix">المبلغ</p>
                                          <input type="text" maxlength="10" onpaste="return false;"
                                              (keypress)="keyPressNumbers($event)" class="inp form-control"
                                              [(ngModel)]="theme.prix" name="prix2" formControlName="prix2">
                                      </div>
                                      <div *ngIf="submitted2 && g['prix2'].errors" class="invalid-feedback">
                                          <div *ngIf="g['prix2'].errors['required']" style="text-align: right;"> يجب
                                              إدخال الثمن</div>
                                      </div>
                                      <br>
                                      <div class="d-flex flex-row justify-content-center align-items-center">
                                          <button (click)="updateTheme(theme.id, theme.prix)"
                                              class="btn1">تعديل</button>
                                      </div>
                                  </div>
                              </form>
                          </div>
                        </div>
                      </ng-template>
                      <br>
                  </div>
                  <br>
                  <br>
              </div>

              <div class="d-flex justify-content-end">
              <ngb-pagination dir="rtl" [maxSize]="3" *ngIf="themes.length > itemsPerPage" style="width: 131%;"
                  [collectionSize]="themes.length" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage"
                  (pageChange)="onPageChange(currentPage); p = $event"><ng-template ngbPaginationPrevious>
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                  </ng-template>
                  <ng-template ngbPaginationNext >
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
      
                    
                  </ng-template></ngb-pagination>
                  
              </div>
          </div>
        </div>
    </div>

    <div class="container" *ngIf="user.role != 'globale' && user.theme == 0 " style="padding-top: 10% !important">
        <div class="container" style="
        width: 100%;
        height: 400px;
        max-height: 800px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        display:block;
        margin-top:auto;
        margin-bottom:auto;
        max-height: 800px;
        background-image: url('assets/droit.png')">
        </div>
    </div>
</body>


