import { Pipe, PipeTransform } from '@angular/core';
import { Admin } from 'src/app/modules/admin';



@Pipe({
  name: 'filtreadmin',
  pure:false
})
export class FiltreadminPipe implements PipeTransform {

  transform(utilisateur: Admin[], chercheTerme: string): Admin[] {
      if(!utilisateur || !chercheTerme){
          return utilisateur;
      }
    return utilisateur.filter(utilisateur =>
        utilisateur.email.toLowerCase().indexOf(chercheTerme.toLowerCase()) !== -1);
  }

}