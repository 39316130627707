import { Component, OnInit, Renderer2 } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Recharge } from 'src/app/modules/recharge';
import { NotificationService } from 'src/app/services/notification.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { RechargeService } from 'src/app/services/recharge.service';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-editproduct',
  templateUrl: './editproduct.component.html',
  styleUrls: ['./editproduct.component.css']
})
export class EditproductComponent implements OnInit {

  prix: string;
  name: string
  prix2: string;
  img: string;
  img2: string;
  submitted = false;
  submitted2 = false;
  images1: File;
  images2: File;
  registerForm: FormGroup;
  registerForm2: FormGroup;
  link: string = "";
  link2: string = "";
  closeResult = '';
  products: Recharge[] = [];
  currentPage = 1;
  itemsPerPage = 8;
  pageSize: number;
  p: number = 1;
  user: any = {};
  imagePreviews: string[] = [];
  imagePreviews1: string[] = [];
  currentIndex = 0;
  productId: number;
  product: any = {};
  receivedData: any;
  constructor(private servicerecharge: RechargeService,
    private formBuilder: FormBuilder,
    private notifyService: NotificationService,
    private modalService: NgbModal,private sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute, private serviceauth: AuthentificationService
  ) { }

  ngOnInit(): void {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;
    this.productId = this.route.snapshot.params['productId'];
    this.getProductbyId()


    this.registerForm = this.formBuilder.group({
      prix: ['', Validators.required],
      name: ['', Validators.required],
      description: ['', Validators.required],
      img: [''],
      images: this.formBuilder.array([])
    });

    this.user = this.serviceauth.getCurrentUser();

    this.registerForm2 = this.formBuilder.group({
      prix2: ['', Validators.required],
      new_images: this.formBuilder.array([])
    });


  }

  get f() { return this.registerForm.controls; }
  get g() { return this.registerForm2.controls; }



  getProductbyId() {
    this.servicerecharge.getbyid(this.productId)
      .pipe(first()).subscribe(data => {
        this.product = data
        this.product.images.forEach((image) => {
          const control = this.formBuilder.control(image);
          this.imageArray.push(control);
        });
      })
  }

  get imageArray() {
    return this.registerForm.get('images') as FormArray;
  }
  onFileSelected(event: any, index: number) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.imageArray.setControl(index, this.formBuilder.control(files[i]));
      this.previewImage(files[i], index);
    }
  }

  getSrc(index: number) {
    const control = this.imageArray.at(index);
    if (control.value instanceof File) {
      // Si c'est une nouvelle image non encore enregistrée
      return this.imagePreviews[index];
    } else if (typeof control.value === 'string') {
      // Si c'est une URL
      return control.value;
    } else {
      return null;
    }
  }
  addImage() {
    const control = this.formBuilder.control(null);
    this.imageArray.push(control);
    this.imagePreviews.push('');
  }
  previewImage(files: File, index: number) {
    if (files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreviews[index] = e.target.result;
      };
      reader.readAsDataURL(files);
    }
  }

  previousImage() {
    if (this.currentIndex > 0) {
      this.currentIndex -= 3;
    }
  }

  // Méthode pour passer à l'affichage suivant de trois images
  nextImage() {
    if (this.currentIndex < (this.imageArray.controls.length - 3)) {
      this.currentIndex += 3;
    }
  }






  ajouter(prix, name , description) {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('img', this.images1);
    formData.append('prix', prix);
    formData.append('name', name);
    formData.append('description', description);
    formData.append('type', "product");
    for (let i = 0; i < this.imageArray.length; i++) {
      const item = this.imageArray.at(i).value;
      if (item instanceof File) {
        formData.append('images[]', item);
      } else if (typeof item === 'string') {
        formData.append('imageUrls[]', item);
      }
    }

    this.servicerecharge.updateProduct(formData, this.productId).pipe(first()).subscribe(() => {
      this.prix = "";
      this.link = "";
      this.name = "";
      this.img = "";
      this.imagePreviews = [];
      this.showToasterSuccess();
      this.getProductbyId();
      this.images1 = null;
      this.submitted = false;
      this.imageArray.controls = []
      //this.router.navigate(['/product-list']);
    })
    
  }

  showToasterSuccess() {
    this.notifyService.showSuccess(" تم تعديل المنتج")
  }

  selectImage(event: any) {

    var reader = new FileReader();
    const file = event.target.files[0];
    this.images1 = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link = event.target.result
    }
  }






  isZeroAllowed = true;
  keyPressNumbers(event) {
    const key = event.key || String.fromCharCode(event.keyCode);

if (key === '0' && this.isZeroAllowed && event.target.selectionStart === 0) {
  event.preventDefault();
}

  var charCode = (event.which) ? event.which : event.keyCode;
  if ((charCode < 48 || charCode > 57)) {
    event.preventDefault();
    return false;
  } else {
    return true;
  }

}

  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }

}
