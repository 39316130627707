import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DelegateService {
  uri = environment.apiUrl;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  constructor(private http: HttpClient) { }

  delete(id: string) {
    return this.http.delete(`${this.uri}deleteDelegate/` + id, { headers: this.headers });
  }

  update(id: string) {
    const obj = {};
    return this.http.post(`${this.uri}acceptDelegate/${id}`, obj, { headers: this.headers });
  }

  updaterefuse(id: string, admin: string) {
    const obj = {admin : admin};
    return this.http.post(`${this.uri}refuseDelegate/${id}`, obj, { headers: this.headers });
  }

  updaterefuseC(cause: string, id: string, admin: string) {
    const obj = {
      cause: cause, admin : admin
    };
    return this.http.post(`${this.uri}refuseDelegateCause/${id}`, obj, { headers: this.headers });
  }

  updatedelegate(name: string, phoneNumber: string, address: string, country: string, city: string, remark: string, id: string) {
    const obj = {
      name: name,
      city: city,
      address: address,
      country: country,
      phoneNumber: phoneNumber,
      remark: remark
    };
    return this.http.put(`${this.uri}updateDelegate/${id}`, obj, { headers: this.headers });
  }

  addRemarkDelegate(remark: string, id: string) {
    const obj = {
      remark: remark
    };
    return this.http.put(`${this.uri}addRemarkDelegate/${id}`, obj, { headers: this.headers });
  }


}
