<meta name="viewport" content="width=device-width, initial-scale=1.0">

<body [ngStyle]="{'width': receivedData ? '92.5%' : 'auto'}">
  <div class="wrapper">
    <app-menu></app-menu>
    <div class="container" *ngIf="user.role == 'globale' || user.actualite == 1" dir="rtl">
      <br> <br>
      <div class="card">
        <div class="card-body" style="text-align: right;">
          <div class="row" id="row3">
            <div class="col-md-4">
              <h2>الأخبار </h2>
            </div>
            <div class="col-md-6"></div>
            <div class="col-md-2">
              <button id="add" (click)="open1(longContent); " type="button" class="form-control lab">إضافة</button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <h6> <span *ngIf="p >= 1 && p <= ( (actualite.length/ 10) - (( actualite.length % 10 ) /10)) "> {{(p * 10)
                  -9}}- {{ p * 10 }} من {{ actualite.length }}</span></h6>
              <h6> <span *ngIf=" p > ( (actualite.length/ 10) - (( actualite.length % 10 ) /10))"> {{ ((p - 1 )* 10 ) +
                  1
                  }}- {{ actualite.length}} من {{ actualite.length }} </span></h6>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-6"></div>
          </div>
          <div class="row" style="overflow-x:auto;direction: rtl;">
            <table class="table table-striped" dir="rtl" style="text-align: right;">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">الصورة</th>
                  <th scope="col" id="th">الخبر</th>
                  <th scope="col">التاريخ</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody *ngFor="let act of actualite| slice: pageSize | slice: 0:itemsPerPage; let index = index;">
                <tr>
                  <td scope="row" id="ind">{{((p * 10) -9)+index}}</td>
                  <td id="imge">
                    <a (click)="open(content)"><i class="material-icons" style="color: #30287a;">image</i></a>
                    <ng-template #content let-modal id="template">
                      <div class="modal-content">
                        <div class="modal-body" id="mbody" dir="rtl">
                          <div class="row" id="row5">
                            <div class="col-md-2">
                              <button id="close" type="button" class="close" aria-label="Close"
                                (click)="modal.close('Cross click'); ferme()">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                          </div><br>
                          <h4 *ngIf="link == '' &&  act.img==url1"> لا توجد صورة </h4>
                          <img *ngIf="link == '' &&  act.img!=url1" src='{{act.img}}' height="250px">
                          <img *ngIf="link !='' " [src]="link" height="250px"><br>
                          <div class="flex">
                            <label id="upload3" (change)="selectImage($event)" class="form-control lab"
                              for="inputGroupFile01"> <i class="fa fa-camera"></i> تحميل صورة
                              <input type="file" accept="image/*" style="display: none;" id="inputGroupFile01"> </label>
                            <button id="update" (click)="modifierimage(act.id)" type="button"
                              class="form-control lab ">تعديل</button>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </td>
                  <td id="cont">{{ act.contenu }}</td>
                  <td id="dte">{{ act.created_at | date: 'yyyy-MM-dd H:mm' }}</td>
                  <td id="dte1">
                    <a (click)="open1(longContent1)"><i class="material-icons" style="color: #30287a;">edit</i></a>
                    &nbsp;
                    <a (click)="openSm2(content4)"><i class="material-icons"
                        style="color: rgb(206, 6, 6);">delete</i></a>
                    <ng-template #longContent1 let-modal id="template2">
                      <div class="modal-content">
                        <div class="modal-body" id="mbody" dir="rtl">
                          <div class="row" id="row5">
                            <div class="col-md-2">
                              <button id="close1" type="button" class="close" aria-label="Close"
                                (click)="modal.close('Cross click')">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                          </div>
                          <form [formGroup]="registerForm1" enctype="multipart/form-data">
                            <h4> تعديل الخبر</h4>
                            <h5 style="text-align: center !important;">الخبر</h5>
                            <div class="row row1">
                              <textarea style="max-height: 150px !important;" rows="3" class="form-control" type="text"
                                formControlName="actu1" [(ngModel)]="act.contenu"></textarea>
                              <div *ngIf="submitted && h['actu1'].errors" class="invalid-feedback">
                                <div *ngIf="h['actu1'].errors['required']">يجب ادخال الخبر </div>
                              </div>
                            </div>
                            <div class="d-flex justify-content-center">
                              <button id="update1" (click)="modifier(act.id, act.contenu) " type="button"
                                class="btn lab">
                                &nbsp; &nbsp; تعديل &nbsp; &nbsp;</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #content4 let-modal id="template4">
                      <div class="modal-body" dir="rtl">
                        <button id="close2" type="button" class="close" aria-label="Close"
                          (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 id="h3"> هل تريد حذف هذا الخبر ؟</h4>
                        <div class="d-flex justify-content-center">
                          <button id="btnP" (click)="delete(act.id)" type="button" class="btn btn-primary">&nbsp; &nbsp;
                            تأكيد &nbsp; &nbsp;</button>
                        </div>
                      </div>
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
            <ngb-pagination dir="rtl" [maxSize]="5" *ngIf="actualite.length > itemsPerPage"
              [collectionSize]="actualite.length" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage"
              (pageChange)="onPageChange(currentPage); p = $event"></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
    <ng-template #longContent let-modal id="template3">
      <div class="modal-content">
        <div class="modal-body" dir="rtl">
          <button id="close3" type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click'); ferme()">
            <span aria-hidden="true">&times;</span>
          </button><br>
          <h4>إضافة خبر</h4>
          <form [formGroup]="registerForm" enctype="multipart/form-data">
            <div class="form-group">
              <div class="row row1">
                <p>الخبر </p>
                <textarea style="max-height: 150px !important;" rows="3" name="actu" formControlName="actu" id="text"
                  class="form-control" [(ngModel)]="actu"> </textarea>
              </div>
              <div class="row row1">
                <div class="col-md-6">
                  <div class="row"> <br> </div>
                  <div class="row row2" style="text-align: center;">
                    <label id="upload2" class="form-control lab" for="inputGroupFile01"><i class="fa fa-camera"></i>
                      تحميل
                      صورة</label>
                    <input style="display: none;" accept="image/*" (change)="selectImage2($event)" type="file"
                      class="custom-file-input" id="inputGroupFile01">
                  </div>
                  <br><br>
                  <div class="row row2">
                    <div class="d-flex justify-content-center">
                      <button id="add2" (click)="ajouter()" type="button" class="form-control lab"> &nbsp; &nbsp; إضافة
                        &nbsp; &nbsp;</button>
                    </div>
                  </div>
                  <div class="row"> <br> </div>
                </div>
                <div class="col-md-6 col-img d-flex flex-row justify-content-center align-items-center">
                  <i *ngIf="link2 ==''" style="color: gray;" class="fa fa-camera"></i>
                  <img class="img-fluid" *ngIf="link2 !=''" [src]="link2" height="200px" width="200px">
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ng-template>
    <div class="container" *ngIf="user.role != 'globale' && user.actualite == 0 " style="padding-top: 10% !important">
      <div class="container"
        style="width: 100%;height: 400px;max-height: 800px; background-size: contain;
  background-repeat: no-repeat;background-position: center center;display:block; margin-top:auto; margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
      </div>
    </div>
  </div>
</body>