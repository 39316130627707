<app-menu></app-menu>

<body dir="rtl" [ngStyle]="{'width': receivedData ? '82%' : 'auto'}" style="padding: 62px;">
    <div class="container pt-5" *ngIf="user.role == 'globale' || user.pret == 1" style="    margin-right: 22px;">
        <div class="d-flex" style="justify-content: space-between;margin-bottom: 100px;">
            <h2>القروض </h2>
            <button routerLink="/pret-list" class="lab"> قائمة طلبات القروض</button>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-6">
                <form [formGroup]="registerForm" enctype="multipart/form-data">
                    <div class="bord">
                        <img src="../../../assets/walletpaix.png" class="img-fluid image" alt=""> <br><br>
                        <h5 class="prix">إسم القرض</h5>
                        <input type="text" [(ngModel)]="name" name="name" formControlName="name" maxlength="50"
                            class="inp form-control">

                        <div *ngIf=" submitted==true &&f['name'].errors" class="invalid-feedback">
                            <div *ngIf="f['name'].errors['required']"> يجب إدخال الإسم</div>
                        </div>
                        <br>


                        <h5 class="prix">مبلغ القرض</h5>
                        <input type="text" onpaste="return false;" (keypress)="keyPressNumbers($event)" maxlength="10"
                            class="inp form-control" [(ngModel)]="prix" name="prix" formControlName="prix">

                        <div *ngIf="submitted && f['prix'].errors" class="invalid-feedback">
                            <div *ngIf="f['prix'].errors['required']"> يجب إدخال المبلغ</div>
                        </div>

                        <br>

                        <div class="d-flex flex-row justify-content-center align-items-center">
                            <button [disabled]="registerForm.invalid" type="submit" #submitButton1 (click)="ajouter(); submitButton1.disabled = true" class="lab">إضافة</button>
                        </div>
                        <br>
                    </div>
                </form>
            </div>

            <div class="col-lg-6">
                <div class="row" style="overflow-x:auto;direction: rtl;">
                    <table class="table ">
                        <thead>
                            <tr>
                                <th>التاريخ</th>
                                <th>إسم القرض</th>
                                <th>المبلغ</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let pret of prets| slice: pageSize | slice: 0:itemsPerPage; let index = index;">
                            <tr>
                                <td>{{ pret.created_at | date: 'yyyy-MM-dd H:mm' }}</td>
                                <td>{{ pret.name }}</td>
                                <td>{{ pret.amount }}</td>
                                <td>
                                    <div class="d-flex">
                                        <a (click)="open1(longContent1)"><img src="../../../assets/editp.png" height="27px"></a>
                                        &nbsp;&nbsp;
                                        <a (click)="openSm2(content4)"><img src="../../../assets/deletep.png" height="27px"></a>
                                    </div>
                                </td>

                                <ng-template #longContent1 let-modal id="template2">
                                    <div class="modal-content">
                                        <div class="modal-body" id="mbody" dir="rtl">
                                            <div class="row" id="row5">
                                                <div class="col-md-2">
                                                    <button id="close1" type="button" class="close" aria-label="Close"
                                                        (click)="modal.close('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <form [formGroup]="registerForm2" enctype="multipart/form-data">
                                                <div class="bord1">
                                                    <img src="../../../assets/walletpaix.png" class="img-fluid image" alt=""> <br><br>
                                                    <h5 class="prix">إسم القرض</h5>
                                                    <input type="text" [(ngModel)]="pret.name" formControlName="name2" maxlength="50"
                                                        class="inp form-control">
                            
                                                    <div *ngIf=" submitted==true &&g['name2'].errors" class="invalid-feedback">
                                                        <div *ngIf="g['name2'].errors['required']"> يجب إدخال الإسم</div>
                                                    </div>
                                                    <br>
                            
                            
                                                    <h5 class="prix">مبلغ القرض</h5>
                                                    <input type="text" onpaste="return false;" (keypress)="keyPressNumbers($event)" maxlength="10"
                                                        class="inp form-control" [(ngModel)]="pret.amount" formControlName="prix2">
                            
                                                    <div *ngIf="submitted && g['prix2'].errors" class="invalid-feedback">
                                                        <div *ngIf="g['prix2'].errors['required']"> يجب إدخال المبلغ</div>
                                                    </div>
                            
                                                    <br>
                            
                                                    <div class="d-flex flex-row justify-content-center align-items-center">
                                                        <button [disabled]="registerForm2.invalid" type="submit" #submitButton1 (click)="update(pret.id,pret.name,pret.amount); submitButton1.disabled = true" class="lab">تعديل</button>
                                                    </div>
                                                    <br>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template #content4 let-modal>
                                    <div class="modal-body" dir="rtl">
                                        <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <h5 id="h3"> هل تريد حذف هذا القرض ؟</h5>
                                        <br>
                                        <div class="d-flex justify-content-center">
                                            <button id="btnP" (click)="delete(pret.id)" type="button"
                                                class="btn btn-primary">&nbsp;
                                                &nbsp; تأكيد &nbsp; &nbsp;</button>
                                        </div>
                                    </div>
                                </ng-template>
                            </tr>
                        </tbody>
                    </table>
                    <ngb-pagination dir="rtl" [maxSize]="5" *ngIf="prets.length > itemsPerPage"
                        [collectionSize]="prets.length" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage"
                        (pageChange)="onPageChange(currentPage); p = $event"></ngb-pagination>
                </div>
            </div>
        </div>


    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.pret == 0 " style="padding-top: 10% !important">
        <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
          background-repeat: no-repeat;background-position: center center;display:block; margin-top:auto;
          margin-bottom:auto; max-height: 800px; background-image: url('assets/droit.png')">
        </div>
      </div>
</body>