<app-menu></app-menu>
<body [ngStyle]="{'width': receivedData ? '83%' : 'auto'}" style="padding: 62px;">
  <div class="container" *ngIf="user.role == 'globale' || user.event== 1" dir="rtl">
    <br><br>
    <h2 class="text-right" style="margin-left: auto;">إضافة إعلان</h2>
    <div class="container">
      <div class="row" id="row3">
        <div class="col-md-6">
          <div>
            <br>
            <form enctype="multipart/form-data">
              <div class="form-group">
                <div class="row row1">
                  <div class="col-md-12">
                    <div class="row"> <br> </div>
                    <div class="row row2 justify-content-center">
                      <label id="upload" class="form-control" for="inputGroupFile02" style="border:none">
                        <div class="col-img d-flex flex-column justify-content-center align-items-center" id="ajout" style="text-align: center;width: 100%;height: auto;border: 1px dashed #009bc4;">
                          <img class="img-fluid img" *ngIf="link2 ==''" src="../assets/Group 10.png" alt="" style="height: 122px;width: 128px;">
                          <img class="img-fluid img" *ngIf="link2 !=''" [src]="link2" style="height: 122px;width: 128px;">
                        </div>
                      </label>
                      <div class="invalid-feedback" style="height: auto;width: 100%;border: none;">
                        <span>{{image}}</span>
                      </div>
                      <input style="display: none;" accept="image/*" (change)="selectImage1($event)" type="file" class="custom-file-input" id="inputGroupFile02">
                      <div class="ajout-container">
                        <button (click)="ajouter()" type="button" class="form-control lab">إضافة</button>
                      </div>
                    </div>
                    <br><br>
                    <div class="row"> <br> </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <table class="table table-striped" dir="rtl" style="text-align: right;">
            <thead>
              <tr>
                <th style="text-align: center;width:10%;" scope="col">#</th>
                <th scope="col" style="text-align: right;">الصورة</th>
                <th scope="col" style="text-align: right;">التاريخ</th>
                <th scope="col" style="text-align: right;"></th>
              </tr>
            </thead>
            <tbody *ngFor="let act of events | slice: pageSize | slice: 0:itemsPerPage; let index = index;">
              <tr>
                <td style="text-align: center" scope="row">{{((p * 10) -9) + index}}</td>
                <td>
                  <a id="image" (click)="open(content)">
                    <i class="material-icons" style="color: #30287a;">image</i>
                  </a>
                  <ng-template #content let-modal>
                    <div class="modal-content">
                      <div class="modal-body" dir="rtl" *ngIf="act.image">
                        <div class="row">
                          <div class="col-md-2">
                            <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click'); ferme()">
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <br>
                          </div>
                        </div>
                        <br>
                        <img *ngIf="link == ''" src='{{act.image}}' height="250px">
                        <img *ngIf="link !=''" [src]="link" height="250px">
                        <br>
                        <div class="flex">
                          <label class="form-control lab" (change)="selectImage3($event)" for="inputGroupFile01">
                            <i class="fa fa-camera"></i> تحميل صورة
                            <input type="file" accept="image/*" style="display: none;" id="inputGroupFile01">
                          </label>
                          <button (click)="modifierimage(act.id)" type="button" class="form-control lab">تعديل</button>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </td>
                <td>{{ act.created_at | date: 'yyyy-MM-dd H:mm' }}</td>
                <td>
                  <a (click)="openSm2(content4)">
                    <img src="../assets/deltepublication.png" alt="" style="height: 28px;width: 22px;">
                  </a>
                  <ng-template #content4 let-modal>
                    <div class="modal-body" dir="rtl">
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h5 id="h3">هل تريد الحذف ؟</h5>
                      <div class="d-flex justify-content-center">
                        <button id="btnP" (click)="delete(act.id)" type="button" class="btn btn-success">تأكيد</button>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
          <ngb-pagination dir="ltr" [maxSize]="5" *ngIf="events.length > itemsPerPage" [collectionSize]="events.length" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage" (pageChange)="onPageChange(currentPage); p = $event"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="user.role != 'globale' && user.event == 0" style="padding-top: 10% !important">
    <div class="container" style="width: 100%;height: 400px;max-height: 800px;background-size: contain;background-repeat: no-repeat;background-position: center center;display: block;margin-top: auto;margin-bottom: auto;max-height: 800px;background-image: url('assets/droit.png')">
    </div>
  </div>
</body>
