import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { DemandproductService } from 'src/app/services/demandproduct.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-orderdetail',
  templateUrl: './orderdetail.component.html',
  styleUrls: ['./orderdetail.component.css']
})
export class OrderdetailComponent implements OnInit {
  user: any = {};
  order: any = {};
  carts: any = [];
  orderId: string;
  registerForm: FormGroup;
  closeResult = '';
  cause: string;
  submitted = false;
  constructor(private servicedemandproduct: DemandproductService, private serviceauth: AuthentificationService
    , private route: ActivatedRoute, private notifyService: NotificationService, private modalService: NgbModal,
    private formBuilder: FormBuilder) { }


  ngOnInit(): void {
    this.user = this.serviceauth.getCurrentUser();
    this.orderId = this.route.snapshot.params['orderId'];
    this.getOrderbyId(this.orderId)
    this.registerForm = this.formBuilder.group({
      cause: ['', Validators.required],
    });

  }

  get f() { return this.registerForm.controls; }


  getOrderbyId(orderId) {
    this.servicedemandproduct.getOrderbyId(orderId).pipe(first())
      .subscribe(data => {
        this.order = data['order'];
        this.carts = data['carts'];
      })
  }

  update(id: string) {
    this.servicedemandproduct.update(id).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.showToasterSuccess();
      this.modalService.dismissAll();
      this.cause = "";
      this.getOrderbyId(this.orderId)
    })
  }

  updaterefuse(id: string) {
    this.servicedemandproduct.updaterefuse(id, this.user.email).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.showToasterError();
      this.modalService.dismissAll();
      this.cause = "";
      this.getOrderbyId(this.orderId)
    })
  }

  updaterefuseC(id: string, cause: string) {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.servicedemandproduct.updaterefuseC(cause, id, this.user.email).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.showToasterError();
      this.modalService.dismissAll();
      this.submitted = false;
      this.cause = "";
      this.getOrderbyId(this.orderId)
    })
  }


  showToasterError() {
    this.notifyService.showSuccess("تم رفض طلب شراء المنتج ")
  }

  showToasterSuccess() {
    this.notifyService.showSuccess("تمت المافقة على طلب شراء المنتج")
  }

  open(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
