import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { Offer } from 'src/app/modules/offer';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { SharedService } from 'src/app/shared.service';
import { RechargeService } from 'src/app/services/recharge.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-codeparrainage',
  templateUrl: './codeparrainage.component.html',
  styleUrls: ['./codeparrainage.component.css']
})
export class CodeparrainageComponent implements OnInit {

  codeparrainage: string;
  codeparrainage1: string;
  price: string;
  price1: string;
  registerForm: FormGroup;
  submitted = false;
  registerForm1: FormGroup;
  submitted1 = false;
  dtOptions: DataTables.Settings = {};
  currentPage: number;
  offers: Offer[];
  user: any = {};
  uri = environment.urlMarket;
  itemsPerPage = 10; 
  pageSize: number;
  p: number = 1;
  closeResult = '';
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  receivedData: boolean;
  constructor(private servicerecharge: RechargeService, private modalService: NgbModal, private serviceauth: AuthentificationService
    , private formBuilder: FormBuilder, private notifyService: NotificationService, private http: HttpClient,private sharedService: SharedService) { }

  ngOnInit(): void {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
    });
    this.receivedData=false;
    this.dtOptions = {
      pagingType: 'simple_numbers',
      language: {
        processing: "يرجى الإنتظار...",
        searchPlaceholder: 'البحث',
        search: "",
        lengthMenu: " _MENU_ ",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        paginate: {
          first: "",
          previous: "<i class='material-icons'>navigate_next</i> ",
          next: "<i class='material-icons'>navigate_before</i>",
          last: ""
        },
        
      },
      drawCallback: function () {
        const paginationIcons = document.querySelectorAll('.material-icons');
    
        paginationIcons.forEach(icon => {
          // Use type assertion to access the style property
          (icon as HTMLElement).style.color = '#0d6efd';
        });
        
      },
      info: false,
      paging: true,
      ordering: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.currentPage = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        const rowData = {
          no_of_records: dataTablesParameters.length,
          page: this.currentPage,
          group_id: 1
        };
        this.http.post<DataTablesResponse>(this.uri + 'CDJSNECFJRNGJJTNG', dataTablesParameters, { headers: this.headers }).subscribe(resp => {
          const details = JSON.parse(JSON.stringify(resp.original));
          this.offers = details.data;
          
          callback({
            recordsTotal: details.recordsTotal,
            recordsFiltered: details.recordsFiltered,
            data: [],
          });
        });
        
      },
      
      columns: [
        { data: "codeparrainageSpecial" },
        { data: "price" },

      ],
      
    };


    this.registerForm = this.formBuilder.group({
      codeparrainage: ['', Validators.required],
      price: ['', Validators.required],
    });

    this.registerForm1 = this.formBuilder.group({
      price1: ['', Validators.required],
      codeparrainage1:['', Validators.required],
    });

    this.user = this.serviceauth.getCurrentUser();
  }
  get f() { return this.registerForm.controls; }
  get g() { return this.registerForm1.controls; }
  ajouter() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('codeparrainageSpecial', this.codeparrainage);
    formData.append('price', this.price);
    this.servicerecharge.addCodeparrainage(formData)
      .pipe(first()).subscribe((data) => {
        if(data['success'] == "false"){
          this.showToasterError();
          $('#dtable').DataTable().draw(true);
          this.codeparrainage = "";
          this.price = "";
          this.submitted = false;
        }else{
          $('#dtable').DataTable().draw(true);
          this.codeparrainage = "";
          this.price = "";
          this.submitted = false;
          this.showToasterSuccess();
        }

      })
  }

  update(id,price1 , codeparrainageSpecial) {
    this.submitted1 = true;
    if (this.registerForm1.invalid) {
      return;
    }

    this.servicerecharge.updateCodeparrainage(id, price1 , codeparrainageSpecial)
      .pipe(first()).subscribe((data) => {
        $('#dtable').DataTable().draw(true);
        if(data['success'] == "false"){
          this.price1 = "";
          this.codeparrainage1 = "";
          this.submitted1 = false;
          this.showToasterError();
          this.modalService.dismissAll();
        }else{
          this.price1 = "";
          this.codeparrainage1 = "";
          this.submitted1 = false;
          this.showToasterSuccess2();
          this.modalService.dismissAll();
        }

      })
  }

  showToasterSuccess() {
    this.notifyService.showSuccess(" تم اضافة رمز الإحالة  ")
  }

  showToasterError() {
    this.notifyService.showError("الرجاء إدخال رمز صحيح")
  }


  showToasterSuccess1() {
    this.notifyService.showSuccess(" تم حذف رمز الإحالة  ")
  }
  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }
  showToasterSuccess2() {
    this.notifyService.showSuccess(" تم تعديل رمز الإحالة  ")
  }

  open1(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  delete(id: string) {
    this.servicerecharge.deleteCodeparrainage(id).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(true);
      this.modalService.dismissAll();
      this.showToasterSuccess1();
    })
  }
}

