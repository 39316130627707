<meta name="viewport" content="width=device-width, initial-scale=1.0">

<app-menu id="page-menu"></app-menu>

<body [ngStyle]="{'width': receivedData ? '82.5%' : ''}">
  <div class="container">
    <div class="row">
      <!-- Première colonne -->
      <div class="col-md-6">
        <div *ngIf="user.role == 'globale' || user.publicite == 1" dir="rtl" id="cont" style="padding-right: 33px;">
          <table class="table table-striped"  dir="rtl" style="text-align: right;height: 236px;width: 526px;margin-top: 67px; margin-right: 12px;">
            <thead>
              <tr>
                <th scope="col" style="border-bottom-right-radius: 10px; border-top-right-radius:10px">#</th>
                <th scope="col">الصورة</th>

                <th scope="col">التاريخ</th>
                <th scope="col" style="border-bottom-left-radius: 10px; border-top-left-radius:10px"></th>
              </tr>
            </thead>
            <tbody *ngFor="let act of publicite| slice: pageSize | slice: 0:itemsPerPage; let index = index;">
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;">
                <td style="border-left: none; border-top:none ;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" scope="row" id="ind">{{((p * 10) -9)+index}}</td>
                <td id="imge" style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <a id="image" (click)="open(content0)">                      <i class="material-icons" style="color: #30287a;">image</i>
                  </a>

                  <ng-template #content0 let-modal>
                    <div class="modal-content">
                      <div class="modal-body" id="mbody" dir="rtl" *ngIf="act.img">
                        <div class="row" id="row5">
                          <div class="col-md-2">
                            <button id="close" type="button" class="close" aria-label="Close"
                              (click)="modal.close('Cross click'); ferme()">
                              <span aria-hidden="true">&times;</span>
                            </button> <br>
                          </div>
                          <div class="col-md-2">

                          </div>
                        </div>

                        <br>
                        <img *ngIf="link == ''" src='{{act.img}}' height="250px" style="
                        margin-right: 74px;
                    ">
                        <img *ngIf="link !=''" [src]="link" height="250px" style="
                        margin-right: 74px;
                    ">

                        <br>
                        <div class="flex">
                          <label id="change" (change)="selectImage($event)" class="form-control lab"
                            for="inputGroupFile04"> <i class="fa fa-camera"></i> تحميل صورة
                            <input type="file" accept="image/*" style="display: none;" id="inputGroupFile04"> </label>

                          <button id="update" (click)="modifierimage(act.id)" type="button"
                            class="form-control lab ">تعديل</button>
                        </div>

                      </div>
                    </div>

                  </ng-template>

                </td>


                <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" id="dte">{{ act.created_at | date: 'yyyy-MM-dd H:mm' }}</td>

                <td id="dte1" style="  border-right: none; border-top:none ; border-bottom: 1px solid rgba(24, 156, 189, 0.596);">


                  <a (click)="openSm2(content4)">
                    <i id="delete" class="material-icons" style="color: rgb(206, 6, 6);">delete</i>
                  </a>



                  <ng-template #content4 let-modal>

                    <div class="modal-body" dir="rtl">
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h5 id="h3" style="
                      margin-top: 10px;
                      margin-right: 39px;
                  "> هل تريد حذف هذا الإعلان ؟</h5>


                      <div class="d-flex justify-content-center">
                        <button id="btnP" (click)="delete(act.id)" type="button" class="btn btn-primary">&nbsp; &nbsp;
                          تأكيد &nbsp; &nbsp;</button>

                      </div>

                    </div>

                  </ng-template>
                </td>

              </tr>
            </tbody>
          </table>
          <ngb-pagination dir="rtl" [maxSize]="5" *ngIf="publicite.length > itemsPerPage"
            [collectionSize]="publicite.length" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage"
            (pageChange)="onPageChange(currentPage); p = $event"></ngb-pagination>
            


  <ng-template #longContent let-modal>
    <div class="modal-content">
      <div class="modal-body" dir="rtl">
        <button id="close" type="button" class="close" aria-label="Close"
          (click)="modal.dismiss('Cross click'); ferme()">
          <span aria-hidden="true">&times;</span>
        </button>

        <br>
        <h5>إضافة إعلان</h5>


        <form enctype="multipart/form-data">
          <div class="form-group">


            <div class="row row1">
              <div class="col-md-6">
                <div class="row"> <br> </div>
                <div class="row row2" style="text-align: center;">

                  <label id="upload" class="form-control lab" for="inputGroupFile02"><i class="fa fa-camera"></i> تحميل
                    صورة</label>

                  <div class="invalid-feedback">{{image}}</div>

                  <input style="display: none;" accept="image/*" (change)="selectImage2($event)" type="file"
                    class="custom-file-input" id="inputGroupFile02">

                </div>
                <br> <br>
                <div class="row row2">
                  <div class="d-flex justify-content-center">
                    <button id="add" (click)="ajouter()" type="button" class="form-control lab"> &nbsp; &nbsp; إضافة
                      &nbsp; &nbsp;</button>
                  </div>
                </div>
                <div class="row"> <br> </div>
              </div>
              <div class="col-md-6 col-img d-flex flex-row justify-content-center align-items-center">
                <i *ngIf="link2 ==''" style="color: gray;" class="fa fa-camera"></i>

                <img class="img-fluid" *ngIf="link2 !=''" [src]="link2" height="200px" width="200px">
              </div>
            </div>


          </div>


        </form>


      </div>
    </div>
  </ng-template>

        </div>
      </div>
      <!-- Deuxième colonne -->
      <div class="col-md-6">
        <div class="col" id="col2" style="margin-top: 7%; ">
          <div id="partdeux">
            <div class="card-body" style="text-align: right;">
              <div class="col-md-4">
            
              </div>
              <div >
              
                <table class="responsive row-border hover" style="margin-top: -6px; " id="tb" >
                  <div id="tit"  style="text-align: center;width: 100% ;background-color: rgba(98, 180, 218, 0.479);border-top-left-radius: 10px;border-bottom-right-radius: 10px;border-bottom-left-radius: 10px;border-top-right-radius: 10px;height: 31px;margin-top: 52px;"> <span style="font-size: 19px;"> إضافة صورة</span> </div>
                  <table  class="responsive row-border hover" style="margin-top: -6px; margin-left: -2px;"  id="tb" >
                    <thead>
                      <tr>
                       
                      </tr>
                    </thead>
                    <tbody>
          
                      <tr colspan="2">
                        <td style="border: 1px dashed rgba(212, 212, 212, 0.521);  box-shadow: 3px 3px 3px darkgrey;" >
                          <div class="d-flex flex-row justify-content-center align-items-center" id="in">
                            <label class="btn2" for="inputGroupFile03" id="labe">
                              <div id="imgshow" class="col-md-6 col-img d-flex flex-row justify-content-center align-items-center" style="
                              height: 203px;
                              width: 228px;
                              border:none;
                              margin-right: 179px;
                              background-color:transparent;
                          ">
                              <img *ngIf="link2 ==''" id="im"  src="../assets/imagepicdownload.png" style="margin-left: 48px;" >
          
                              <img class="img-fluid" *ngIf="link2 !=''" [src]="link2" height="200px" width="200px">
                            </div>
          
                            <input (change)="selectImage2($event)"  name="img" accept="image/*" required
                                formControlName="img" style="display:none" type="file" class="custom-file-input"
                                id="inputGroupFile03">
                      </label>  </div>
                      <br>
                      <button class="btn btn-primary btn-lg " id="update1" (click)="ajouter()" type="button" class="btn lab" style="margin-left: 43%;background-color: rgba(159, 212, 243, 0.719);color: black;height: 42px;width: 96px;border:#9b9b9b">اضافة</button>
                      <div *ngIf="submitted3 " class="invalid-feedback">
                        <div>  يجب ادخال  الصورة</div>
                      </div>
                    </td>
                      </tr>
          
          
                    </tbody>
                  </table>
          
          
          
          
          
          
          
          
            
                            <thead>
                    <tr></tr>
                  </thead>
                  
                </table>
                <br><br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="user.role != 'globale' && user.publicite == 0 " style="padding-top: 10% !important">
    <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
      background-repeat: no-repeat;background-position: center center;display:block; margin-top:auto;
      margin-bottom:auto; max-height: 800px; background-image: url('assets/droit.png')">
    </div>
  </div>
</body>




