import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Recharge } from '../modules/recharge';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class RechargeService {
  uri=environment.urlMarket;
  uri1 = environment.urlMarket;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  constructor(private http: HttpClient) { }

  getbyid(id: number) {
    return this
      .http
      .get<Recharge>(`${this.uri}rechargess/${id}`, { headers: this.headers });
  }

  getProducts() {
    const url_get = this.uri + 'products';
    return this.http.get<Recharge[]>(url_get, { headers: this.headers });
  }


  getRecharges() {
    const url_get = this.uri + 'recharges';
    return this.http.get<Recharge[]>(url_get, { headers: this.headers });
  }



  addProduct(data: FormData) {
    this
      .http
      .post(`${this.uri}addrecharge`, data, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'products';
    return this.http.get<Recharge[]>(url_get, { headers: this.headers });
  }

  addPret(name:string, amount:string) {
    const obj={
      name : name,
      amount:amount
    }
    return this
      .http
      .post(`${this.uri}IKUJHGCSX154X85X4ED`, obj, { headers: this.headers });
  }
  updatePret(id:string, name:string, amount:string) {
    const obj={
      name : name,
      amount:amount
    }
    return this
      .http
      .put(`${this.uri}IKUJHGCSX154X85X4ED/` + id, obj, { headers: this.headers });
  }

  getPrets() {
    const url_get = this.uri + 'IKUJHGCSX154X85X4ED';
    return this.http.get<Recharge[]>(url_get, { headers: this.headers });
  }

  deletepret(id: string) {
    return this.http.delete(`${this.uri}IKUJHGCSX154X85X4ED/` + id, { headers: this.headers });
  }

  addCategory(name:string) {
    const obj={
      name : name
    }
    return this
      .http
      .post(`${this.uri}UJTYHRTVRGRFSC8DCFD`, obj, { headers: this.headers });
  }

  updateCategory(id:string, name:string) {
    const obj={
      name : name
    }
    return this
      .http
      .put(`${this.uri}UJTYHRTVRGRFSC8DCFD/` + id, obj, { headers: this.headers });
  }

  getCategories() {
    const url_get = this.uri + 'UJTYHRTVRGRFSC8DCFD';
    return this.http.get<Recharge[]>(url_get, { headers: this.headers });
  }

  deleteCategory(id: string) {
    return this.http.delete(`${this.uri}UJTYHRTVRGRFSC8DCFD/` + id, { headers: this.headers });
  }

  updateProduct(data: FormData, id: number) {
    this
      .http
      .post(`${this.uri}updateproduct/${id}`, data, { headers: this.headers })
      .subscribe((data) => {
      });
    const url_get = this.uri + 'products';
    return this.http.get<Recharge[]>(url_get, { headers: this.headers });
  }



  updaterecharge(id: string, data: FormData) {
    this
      .http
      .post(`${this.uri}updaterecharge/${id}`, data, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'recharges';
    return this.http.get<Recharge[]>(url_get, { headers: this.headers });
  }

  deleterecharge(id: string) {
    const obj = {};
    return this.http.put(`${this.uri}deleterecharge/` + id, obj, { headers: this.headers });
  }

  addCodeparrainage(data: FormData) {
    return this
      .http
      .post(`${this.uri1}QJKNSKIEHFNCKDRR`, data, { headers: this.headers });
  }

  updateCodeparrainage(id, price , codeparrainageSpecial) {
    const obj = {
      price: price,
      codeparrainageSpecial : codeparrainageSpecial
    };
    return this
      .http
      .put(`${this.uri1}QJKNSKIEHFNCKDRR/` + id, obj, { headers: this.headers });
  }

  deleteCodeparrainage(id: string) {
    return this.http.delete(`${this.uri1}QJKNSKIEHFNCKDRR/` + id, { headers: this.headers });
  }
}
