import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class StoreServiceService {
    private uri = environment.urlStore;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
   }

    constructor(private http: HttpClient) {}




    getAllStores(){
      const url_get = this.uri + 'getAllStores';
      return this.http.get(url_get);
    }


    updateStore(id: string, prix: any): Observable<any> {

  const obj={prix:prix}

      return this.http.put(`${this.uri}update/${id}`, obj );
    }

   delete(id:any) {
      return this.http.get(`${this.uri}delete/${id}`);
    }



    addstore(data: FormData) {
      return this.http.post(`${this.uri}insert`, data);
    }




}

