import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { first } from 'rxjs/operators';
import { ActualiteService } from 'src/app/services/actualite.service';
import { Lightbox } from 'ngx-lightbox';
import { IAlbum } from 'ngx-lightbox';
import { NotificationService } from 'src/app/services/notification.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-publicationdetail',
  templateUrl: './publicationdetail.component.html',
  styleUrls: ['./publicationdetail.component.css']
})
export class PublicationdetailComponent implements OnInit {
  postId: string;
  user: any = {};
  post:any= {};
  showAll: boolean = false;
  images : [] = [];
  visibleCommentsCount = 4;
  closeResult = '';
  showReplies: { [commentId: string]: boolean } = {}; 
  constructor( private notifyService: NotificationService,private lightbox: Lightbox , private modalService: NgbModal
    , private serviceactualite: ActualiteService,private route: ActivatedRoute,private serviceauth: AuthentificationService) { }

  ngOnInit(): void {
    this.user = this.serviceauth.getCurrentUser();

    this.postId = this.route.snapshot.params['pubId'];

    this.getPostbyId(this.postId)
  }

  getPostbyId(postId) {
    this.serviceactualite.getPostbyId(postId).pipe(first())
    .subscribe(data => {
      this.post = data;
      if(this.post.content[0].images){
        this.images = JSON.parse(this.post.content[0].images)
      }
    })
  }
  toggleReplies(commentId: string) {
    this.showReplies[commentId] = !this.showReplies[commentId];
    if (this.showReplies[commentId] && !this.post.comments.find(c => c._id === commentId)?.replies) {
      this.loadReplies(commentId);
    }
  }

  loadReplies(commentId: string) {
    this.serviceactualite.getReplies(this.postId , commentId).pipe(first())
    .subscribe(data => {

      const comment = this.post.comments.find(c => c._id.$oid === commentId);
      if (comment) {
        comment.replies = data;
      }
    });
  }

  getReplies(commentId) {
    
    this.serviceactualite.getReplies(this.postId , commentId).pipe(first())
    .subscribe(data => {
      this.post = data;

    })
  }

  deleteComment(postId, commentId) {
    this.serviceactualite.deleteComment(postId, commentId)
      .pipe(first()).subscribe(() => {
        this.getPostbyId(this.postId)
        this.showToasterError2();
        this.modalService.dismissAll();
      })
  }
  deleteReply(postId, commentId, replyId) {
    this.serviceactualite.deleteReply(postId, commentId, replyId)
      .pipe(first()).subscribe(() => {
        this.getPostbyId(this.postId)
        this.showToasterError3();
        this.modalService.dismissAll();
      })
  }
  showToasterError2() {
    this.notifyService.showSuccess("تم  حذف التعليق  ")
  }

  showToasterError3() {
    this.notifyService.showSuccess("تم  حذف الإجابة  ")
  }
  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  accepttrend() {

    this.serviceactualite.accepttrend(this.postId).pipe(first()).subscribe(() => {
      this.showToasterSuccess2();
      $('#dtable').DataTable().draw(false);
      this.getPostbyId(this.postId)
    })
  }

  refusetrend() {
    this.serviceactualite.refusetrend(this.postId).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.showToasterError();
      this.modalService.dismissAll();
      this.getPostbyId(this.postId)
    })
  }

  delete() {
    this.serviceactualite.deletepublication(this.postId)
    .pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.showToasterError1();
      this.modalService.dismissAll();
      this.getPostbyId(this.postId)

    })
  }

  open(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openSm(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  showToasterSuccess2() {
    this.notifyService.showSuccess("تم قبول الطلب")
  }

  showToasterError() {
    this.notifyService.showError("تم رفض الطلب")
  }
  showToasterError1() {
    this.notifyService.showSuccess("تم حذف المنشور ")
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



  toggleView() {
    this.showAll = !this.showAll;
  if (this.showAll) {
    const imagesToDisplay: IAlbum[] = this.images
      .slice(5)
      .map(image => ({ src: image, caption: this.post.content[0].text, thumb: image }));
    this.lightbox.open(imagesToDisplay, 0);
  }
  }

  loadMoreComments() {
    this.visibleCommentsCount += 4;
  }

}
