import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contact } from '../modules/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  uri = environment.apiUrl;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });

  constructor(private http: HttpClient) { }
  getAllcontact(): Observable<Contact[]> {
    const url_get = this.uri + 'contacts';
    return this.http.get<Contact[]>(url_get, { headers: this.headers });
  }

  getContacts(page: number , pageSize: number , searchTerm:string): Observable<any> {
    const obj={
      searchTerm : searchTerm
    }
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.post(`${this.uri}contacts?page=${page}`, obj,{  headers: this.headers , params });
  }

  updatecontact(reponse: string, id: string) {
    const obj = {
      reponse: reponse,

    };
    return this.http.put(`${this.uri}updatecontact/${id}`, obj, { headers: this.headers });
  }

  allContact(id: string): Observable<Contact[]> {
    const url_get = this.uri + 'allContact/' + id;
    return this.http.get<Contact[]>(url_get, { headers: this.headers });
  }

}
