import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { UtilisateurService } from 'src/app/services/utilisateur.service';

@Component({
  selector: 'app-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.css']
})
export class HistoriqueComponent implements OnInit {

  user: any = {};
  userId: string;
  codeparrainage: string;
  historiques:any= [];
  currentPage = 1;
  pageSize = 10;
  totalItems: number;
  startDate : string;
  endDate :string;
  constructor(private route: ActivatedRoute,private serviceutilisateur: UtilisateurService,private serviceauth: AuthentificationService) { }

  ngOnInit(): void {
    this.codeparrainage = this.route.snapshot.params['codeparrainage'];
    this.userId = this.route.snapshot.params['userId'];
    console.log(this.userId)
    this.getHistorue(this.userId)
    this.user = this.serviceauth.getCurrentUser();
  }

  getHistorue(userId) {
    this.serviceutilisateur.getHistorique(this.currentPage, this.pageSize, this.startDate, this.endDate, userId)
    .subscribe(response => {
    this.startDate = new Date( response['startDate']).toISOString().split('T')[0]
    this.endDate = new Date(response['endDate']).toISOString().split('T')[0] 
      this.historiques = response['historiques']['data'].map(historique => ({
        ...historique,
        created_at: new Date(parseInt(historique.created_at.$date.$numberLong, 10))
      }));
      this.totalItems = response['historiques']['total'];
    })
  }

  pageChange(newPage: number) {
    this.currentPage = newPage;
    this.getHistorue(this.userId);
  }

  onDateChange() {
    console.log(this.startDate)
    this.currentPage = 1;
    this.getHistorue(this.userId);
  }
}
