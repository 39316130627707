<!DOCTYPE html>
<html>

<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="robots" content="noindex">
    <meta name="googlebot" content="noindex">
    <title>SNTAT</title>
    <!-- Fonts -->
    <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap" rel="stylesheet">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"
        integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
    <link rel="stylesheet" type="text/css"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900|Roboto+Slab:400,700" />
    <script src="https://kit.fontawesome.com/42d5adcbca.js" crossorigin="anonymous"></script>
</head>

<body>

    <div class="container">
        <div class="row">
            <div class="col-md-12 min-vh-100 d-flex flex-column justify-content-center">
                <div class="row">
                    <div class="col-lg-6 col-md-8 mx-auto">

                        <!-- form card login -->
                        <div class="card rounded ">

                            <div class="card-body">
                                <div class="cent">
                                    <img src="../../../assets/logosn.png" class="avatar">
                                </div>
                                <br> <br> <br>
                                <form class="form" role="form" [formGroup]="registerForm">
                                    <div class="form-group">
                                        <input type="text" formControlName="email" placeholder="البريد الإلكتروني"
                                            name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                            [(ngModel)]="email">
                                        <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                                            <div *ngIf="f['email'].errors['required']">يجب إدخال البريد الإلكتروني</div>
                                            <div *ngIf="f['email'].errors['email']">بريد الإلكتروني غير صحيح</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input formControlName="password" type="password" placeholder="كلمة المرور"
                                            name="password" [(ngModel)]="password">
                                        <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                                            <div *ngIf="f['password'].errors">يجب إدخال كلمة المرور</div>
                                        </div>
                                    </div>
                                    <div>

                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-3"></div>
                                        <div class="col-md-6">
                                            <button class="btn" (click)="login()">تسجيل الدخول</button>
                                        </div>
                                        <div class="col-md-3">
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                    <div class="col-md-3"></div>
                                        <div class="col-md-6">
                                   <a routerLink="/request-reset-password"> هل نسيت كلمة المرور؟ </a>
                                        </div>
                                        <div class="col-md-3"></div>
                                </div> -->
                                    <br> <br> <br> <br> <br>
                                </form>
                            </div>
                            <!--/card-block-->
                        </div>
                        <!-- /form card login -->
                    </div>
                </div>
                <!--/row-->

            </div>
            <!--/col-->
        </div>
        <!--/row-->
    </div>
    <!--/container-->

    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
        integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
        crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.12.9/dist/umd/popper.min.js"
        integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
        crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/js/bootstrap.min.js"
        integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
        crossorigin="anonymous"></script>

</body>

</html>