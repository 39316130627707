import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { ActualiteService } from 'src/app/services/actualite.service';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-list-trend',
  templateUrl: './list-trend.component.html',
  styleUrls: ['./list-trend.component.css']
})
export class ListTrendComponent implements OnInit {

  
  uri = environment.actualiteUrl;
  trenddemands: any;
  url1 = environment.urlStorage;
  MD = new Array();
  curr: any = {};
  registerForm: FormGroup;
  col: string;
  montant1: number;
  date = new Date;
  idc: string;
  searchText: string;
  currentPage: number;
  user: any = {};
  closeResult = '';
  offers: any;
  cause: string;
  nbrLike: number;
  submitted = false;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });

  dtOptions: DataTables.Settings = {};
  receivedData: any;
  constructor(private serviceactualite: ActualiteService, public datepipe: DatePipe,
    private notifyService: NotificationService, private serviceauth: AuthentificationService, private modalService: NgbModal,
    private formBuilder: FormBuilder, private http: HttpClient,private sharedService: SharedService,) { }

  ngOnInit() {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;
    this.dtOptions = {
      pagingType: 'simple_numbers',
      language: {
        processing: "يرجى الإنتظار...",
        search: "",
        searchPlaceholder: 'البحث',
        lengthMenu: " _MENU_ ",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        paginate: {
          first: "",
          previous: "<i class='material-icons'>navigate_next</i> ",
          next: "<i class='material-icons'>navigate_before</i>",
          last: ""
        },
      },
      info: false,
      paging: true,
      ordering: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.currentPage = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        const rowData = {
          no_of_records: dataTablesParameters.length,
          page: this.currentPage,
          group_id: 1
        };
        this.http.post<DataTablesResponse>(this.uri + 'GJDVCNEJKFRNJKENTGJNTGNGT', dataTablesParameters, { headers: this.headers }).subscribe(resp => {
          const details = JSON.parse(JSON.stringify(resp.original));
          this.trenddemands = details.data;
          callback({
            recordsTotal: details.recordsTotal,
            recordsFiltered: details.recordsFiltered,
            data: [],
          });
        });
      },
      columns: [
        { 
          data: "created_at",
          type: 'date', // Add this line for date filtering
          render: function (data, type, row) {
            // You can customize the date format here if needed
            return new Date(data).toLocaleDateString();
          }
        },
        { data: "name" },
        { data: "codeparrainage" },
        { data: "reactCount" },
        { data: "commentCount" },
      ],
    };
    this.registerForm = this.formBuilder.group({
      nbrLike: ['', Validators.required]
    });

    this.idc = "1";
    this.serviceactualite.getGlobalVar()
      .subscribe(data => {
        this.curr = data
        this.nbrLike = this.curr.nbrlike;
      })
    this.user = this.serviceauth.getCurrentUser();

  }

  get f() { return this.registerForm.controls; }


  refusetrend(id: string) {
    this.serviceactualite.refusetrend( id).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.showToasterError();
      this.modalService.dismissAll();
      this.cause = "";
    })
  }

  showToasterSuccess1() {
    this.notifyService.showSuccess("تم تعديل عدد الإعجابات ")
  }


  showToasterError() {
    this.notifyService.showError("تم  إزالة المنشور من قائمة الترند ")
  }

  open(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openSm(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openSm3(content) {
    this.modalService.open(content, { size: 'lg', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  isZeroAllowed = true;
  keyPressNumbers(event) {
    const key = event.key || String.fromCharCode(event.keyCode);

if (key === '0' && this.isZeroAllowed && event.target.selectionStart === 0) {
  event.preventDefault();
}

  var charCode = (event.which) ? event.which : event.keyCode;
  if ((charCode < 48 || charCode > 57)) {
    event.preventDefault();
    return false;
  } else {
    return true;
  }

}
inputEvent(event: any) {
const value = event.target.value;
this.isZeroAllowed = value === '';
}

}
