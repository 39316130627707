import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Admin } from 'src/app/modules/admin';
import { AdminService } from 'src/app/services/admin.service';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MustMatch } from '../must-match.validator';
import { ModalDismissReasons, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/shared.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  public collapse2 = false;
  public collapse3 = false; 
  user: any = {};
  user1: Admin;
  passwordac: string;
  passwordac1: string;
  password: string;
  repassword: string;
  registerForm: FormGroup;
  registerForm1: FormGroup;
  submitted = false;
  submitted1 = false;
  conversionDecryptOutput: string;
  decPassword: string;
  textToConvert: string = "";
  closeResult = '';
  nom: string;
  prenom: string;
  email: string;
  telephone: number;
  validpasssword = {};
  validpasssword1 = {};
  utilisateur: any = {};
  RIB: string;
  receivedData: any;
  constructor(private authentificationService: AuthentificationService,
    private servicesadmin: AdminService,
    private notifyService: NotificationService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,private sharedService: SharedService,
    config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;
    this.user1 = this.authentificationService.getCurrentUser();
    this.servicesadmin.getbyid(this.user1.id)
      .subscribe(data => {
        this.user = data
        this.nom = this.user.nom;
        this.prenom = this.user.prenom;
        this.email = this.user.email;
        this.telephone = this.user.telephone;
        // this.RIB= this.user.RIB;
      })

    this.registerForm = this.formBuilder.group({
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: [null, Validators.required],
      passwordac: ['', Validators.required],
      RIB: ['']
    }, {
    });
    this.registerForm1 = this.formBuilder.group({
      passwordac1: ['', Validators.required],
      password: ['', Validators.required],
      repassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'repassword')
    });
  }

  get f() { return this.registerForm.controls; }
  get f1() { return this.registerForm1.controls; }
  valide() {
    this.servicesadmin.validatepassword(this.passwordac, this.user.id)
      .subscribe(
        data => {
          this.validpasssword = data

        }
      )
  }
  update() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.servicesadmin.validatepassword(this.passwordac, this.user.id)
      .subscribe(
        data => {
          this.validpasssword = data
          if (this.validpasssword == true) {
            this.servicesadmin.updateadmin(this.nom, this.prenom, this.email, this.telephone, this.user.role, this.user.id)
              .pipe(first()).subscribe(data => {
                this.utilisateur = data
                this.showToasterSuccess();
                this.collapse2 = false;
                this.modalService.dismissAll();
                this.user = this.utilisateur;
                this.nom = this.utilisateur.nom;
                this.prenom = this.utilisateur.prenom;
                this.email = this.utilisateur.email;
                this.telephone = this.utilisateur.telephone;
                // this.RIB = this.utilisateur.RIB;
                this.passwordac = '';
                window.location.reload();
              })

          }
        }
      )



  }
  updatepassword() {
    this.submitted1 = true;
    // stop here if form is invalid
    if (this.registerForm1.invalid) {
      return;
    }

    this.servicesadmin.validatepassword(this.passwordac1, this.user.id)
      .subscribe(
        data => {
          this.validpasssword1 = data
          if (this.validpasssword1 == true) {

            this.servicesadmin.updatepassword(this.password, this.user.id)
              .pipe(first()).subscribe(data1 => {
                this.user1 = data1['user'];
                this.authentificationService.setUser(this.user1, data1['token']);
                this.showToasterSuccess1();
                this.modalService.dismissAll();
                this.passwordac1 = '';
                window.location.reload();
              })
          }
        }
      )



  }
  showToasterSuccess() {
    this.notifyService.showSuccess("تم تعديل المعلومات الشخصية")
  }
  showToasterSuccess1() {
    this.notifyService.showSuccess("تم تعديل  كلمة المرور")
  }
  openSm(content) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.servicesadmin.validatepassword(this.passwordac, this.user.id)
      .subscribe(
        data => {
          this.validpasssword = data
          if (this.validpasssword == true) {

            this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          }
        }
      )
  }
  openSm1(content) {
    this.submitted1 = true;
    // stop here if form is invalid
    if (this.registerForm1.invalid) {
      return;
    }
    this.servicesadmin.validatepassword(this.passwordac1, this.user.id)
      .subscribe(
        data => {
          this.validpasssword1 = data
          if (this.validpasssword1 == true) {

            this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          }
        }
      )
  }
  open(content) {
    this.submitted = false;
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  reset() {
    window.location.reload();
  }
}
