<meta name="viewport" content="width=device-width, initial-scale=1.0">

<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '92.5%' : 'auto'}">
    <div class="container p-5" *ngIf="user.role == 'globale' " dir="rtl" style="text-align: right;">
        <h2>إضافة مسؤول</h2>
        <br>
        <form [formGroup]="registerForm" class="pr-5">
            <div class="row">
                <div class="col-md-4">
                    <div class="d-flex"> الإسم <div style="color: red;">*</div>
                    </div>
                    <div class="row pl-4">
                        <input class="input form-control" formControlName="prenom" type="text" name="prenom" id="prenom"
                            value="" [(ngModel)]="prenom">
                        <div *ngIf="submitted && f['prenom'].errors" class="invalid-feedback">
                            <div *ngIf="f['prenom'].errors['required']">الإسم مطلوب</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="d-flex"> اللقب <div style="color: red;">*</div>
                    </div>
                    <div class="row pl-4">
                        <input class=" input form-control" formControlName="nom" type="text" name="nom" id="nom"
                            value="" [(ngModel)]="nom">
                        <div *ngIf="submitted && f['nom'].errors" class="invalid-feedback">
                            <div *ngIf="f['nom'].errors['required']">اللقب مطلوب</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="d-flex "> البريد الإلكتروني <div style="color: red;">*</div>
                    </div>
                    <div class="row pl-4">
                        <input class=" input form-control" formControlName="email" type="email" name="email" id="email"
                            [(ngModel)]="email">
                        <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                            <div *ngIf="f['email'].errors['required']">البريد الإلكتروني مطلوب</div>
                            <div *ngIf="f['email'].errors['email']">يجب أن يكون البريد الإلكتروني صالحًا</div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-4">
                    <div class="d-flex"> رقم الهاتف <div style="color: red;">*</div>
                    </div>
                    <div class="row pl-4">
                        <input class=" input form-control" formControlName="telephone" type="number"
                            onKeyPress="if(this.value.length==13) return false;" onkeydown="javascript: return event.keyCode === 8 ||
            event.keyCode === 46 ? true : !isNaN(Number(event.key))" name="telephone" id="telephone" value=""
                            [(ngModel)]="telephone">
                    </div>
                    <div *ngIf="submitted && f['telephone'].errors" class="invalid-feedback">
                        <div *ngIf="f['telephone'].errors['required']">رقم الهاتف مطلوب</div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class=" d-flex"> كلمة السر <div style="color: red;">*</div>
                    </div>
                    <div class="row pl-4">
                        <input class=" input form-control" formControlName="password" type="password" name="password"
                            id="password" value="" [(ngModel)]="password">
                        <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                            <div *ngIf="f['password'].errors['required']">كلمة السر مطلوبة</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="d-flex "> تأكيد كلمة السر <div style="color: red;">*</div>
                    </div>
                    <div class="row pl-4">
                        <input class="input form-control" formControlName="repassword" type="password" name="repassword"
                            id="repassword" value="" [(ngModel)]="repassword">
                        <div *ngIf="submitted && f['repassword'].errors" class="invalid-feedback">
                            <div *ngIf="f['repassword'].errors['required']">تأكيد كلمة السر مطلوب</div>
                            <div *ngIf="f['repassword'].errors['mustMatch']">كلمة السر غير مطابقة</div>
                        </div>
                    </div>
                </div>
            </div>
            <p>*يجب ان يكون رقم الهاتف على النحو التالي
                :964YYXXXXXX</p>
            <br>
            <div class="row"> <strong>الوظائف المتاحة </strong></div>
            <br>
            <div class="row">
                <div class="col-md-3">
                    <input #publicite id="publicite" type="checkbox" (change)="onpublicite(publicite.checked)"> &nbsp;
                    <label for="c3">الإعلانات</label>
                </div>
                <div class="col-md-3">
                    <input #actualite id="actualite" type="checkbox" (change)="onactualite(actualite.checked)"> &nbsp;
                    <label for="c2">الأخبار</label>
                </div>
                <div class="col-md-3">
                    <input #users id="users" type="checkbox" (change)="onusers(users.checked)"> &nbsp;
                    <label for="c1">المستخدم</label>
                </div>

                <div class="col-md-3">
                    <input #counter id="counter" type="checkbox" (change)="oncounter(counter.checked)"> &nbsp;
                    <label for="c1">عروض العداد</label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <input #conversion id="conversion" type="checkbox" (change)="onconversion(conversion.checked)">
                    &nbsp;
                    <label for="c4">طلبات التحويل</label>
                </div>
                <div class="col-md-3">
                    <input #tousconversion id="tousconversion" type="checkbox"
                        (change)="ontousconversion(tousconversion.checked)"> &nbsp;
                    <label for="c4">سجل التحويلات</label>
                </div>
                <div class="col-md-3">
                    <input #jeux id="jeux" type="checkbox" (change)="onjeux(jeux.checked)"> &nbsp;
                    <label for="c5">المسابقات</label>
                </div>
                <div class="col-md-3">
                    <input #mobile id="mobile" type="checkbox" (change)="onmobile(mobile.checked)"> &nbsp;
                    <label for="c6"> بطاقات الهاتف</label>
                </div>
            </div>
            <div class="row">
               <div class="col-md-3">
                    <input #product id="product" type="checkbox" (change)="onproduct(product.checked)"> &nbsp;
                    <label for="c6"> المنتجات </label>
                </div>
                <div class="col-md-3">
                    <input #event id="user_block" type="checkbox" (change)="onuserevent(event.checked)">
                    &nbsp;
                    <label for="c6"> فعاليات </label>
                </div>
                <div class="col-md-3">
                    <input #pret id="user_block" type="checkbox" (change)="onuserpret(pret.checked)">
                    &nbsp;
                    <label for="c6"> القروض</label>
                </div>
                <div class="col-md-3">
                    <input #store id="user_block" type="checkbox" (change)="onuserstore(store.checked)">
                    &nbsp;
                    <label for="c6"> متجر
                        الايقونات </label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <input #user_block id="user_block" type="checkbox" (change)="onuserblock(user_block.checked)">
                    &nbsp;
                    <label for="c6"> حظر (المستخدم) </label>
                </div>

                
                <div class="col-md-3">
                    <input #listdelegate id="listdelegate" type="checkbox"
                        (change)="onlistdelegate(listdelegate.checked)"> &nbsp;
                    <label for="c6"> قائمة الوكلاء </label>
                </div>
                <div class="col-md-3">
                    <input #demanddelegate id="demanddelegate" type="checkbox"
                        (change)="ondemanddelegate(demanddelegate.checked)"> &nbsp;
                    <label for="c6"> طلبات الوكالة </label>
                </div>
                <div class="col-md-3">
                    <input #block id="block" type="checkbox" (change)="onblock(block.checked)"> &nbsp;
                    <label for="c6"> الحظر </label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <input #signal id="signal" type="checkbox" (change)="onsignal(signal.checked)"> &nbsp;
                    <label for="c6"> الإبلاغات </label>
                </div>
                <div class="col-md-3">
                    <input #gift id="gift" type="checkbox" (change)="ongift(gift.checked)"> &nbsp;
                    <label for="c6"> الهدايا </label>
                </div>
                <div class="col-md-3">
                    <input #theme id="theme" type="checkbox" (change)="ontheme(theme.checked)"> &nbsp;
                    <label for="c6"> الخلفيات </label>
                </div>

                <div class="col-md-3">
                    <input #contact id="contact" type="checkbox" (change)="oncontact(contact.checked)"> &nbsp;
                    <label for="c6"> الدعم </label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <input #user_point id="user_point" type="checkbox" (change)="onuserpoint(user_point.checked)">
                    &nbsp;
                    <label for="c6"> نقاط (المستخدم) </label>
                </div>
                <div class="col-md-3">
                    <input #user_diamond id="user_diamond" type="checkbox"
                        (change)="onuserdiamond(user_diamond.checked)"> &nbsp;
                    <label for="c6"> مجوهرات (المستخدم) </label>
                </div>
                <div class="col-md-3">
                    <input #user_message id="user_message" type="checkbox"
                        (change)="onusermessage(user_message.checked)"> &nbsp;
                    <label for="c6"> رسالة (المستخدم) </label>
                </div>

                <div class="col-md-3">
                    <input #user_delete id="user_delete" type="checkbox" (change)="onuserdelete(user_delete.checked)">
                    &nbsp;
                    <label for="c6"> حذف(المستخدم) </label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <input #codeparrainage id="user_block" type="checkbox" (change)="oncodeparrainage(codeparrainage.checked)">
                    &nbsp;
                    <label for="c6">رموز الاحالة</label>
                </div>
            </div>
            <br>
            <div class="row ">
                <div class="col-md-5"></div>
                <div class="col-md-2"> <button (click)="ajouter();" type="button" class="button form-control"> <strong>
                            إضافة </strong> </button> </div>
                <div class="col-md-5"></div>

            </div>
        </form>
    </div>
    <div class="container" *ngIf="user.role != 'globale' " style="padding-top: 10% !important">
        <div class="container" style=" width: 100%;height: 400px; max-height: 800px;background-size: contain;
        background-repeat: no-repeat;background-position: center center;display:block;margin-top:auto;
        margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
        </div>
    </div>
</body>