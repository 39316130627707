import { Injectable } from '@angular/core';
import { PubliciteService } from 'src/app/services/publicite.service';
import { Publicite } from 'src/app/modules/publicite';

import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PubliciteResolver implements Resolve<Publicite[]> {
  constructor(private publicites: PubliciteService) {}
  resolve(): Observable<Publicite[]> {
    return this.publicites.getAllpublicite();
  }
}
