import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Recharge } from 'src/app/modules/recharge';
import { NotificationService } from 'src/app/services/notification.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { RechargeService } from 'src/app/services/recharge.service';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.css']
})
export class MobileComponent implements OnInit {


  prix: string;
  prix2: string;
  img: string;
  img2: string;
  submitted = false;
  submitted2 = false;
  images: File;
  images2: File;
  registerForm: FormGroup;
  registerForm2: FormGroup;
  link: string = "";
  link2: string = "";
  message: string='';
  closeResult = '';
  recharges: Recharge[] = [];
  currentPage = 1;
  itemsPerPage = 4;
  pageSize: number;
  p: number = 1;
  vide=false;
  user: any = {};
  dtOptions: DataTables.Settings = {};
  receivedData: any;
  constructor(private servicerecharge: RechargeService,
    private formBuilder: FormBuilder,
    private notifyService: NotificationService, private sharedService: SharedService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private serviceauth: AuthentificationService,
  ) { }

  ngOnInit(): void {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;
    this.dtOptions = {
      pagingType: 'simple_numbers',
      searching: false,
      language: {
        processing: "يرجى الإنتظار...",
        searchPlaceholder: 'البحث',search: "",
        lengthMenu: " _MENU_ ",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        paginate: {
          first: "",
          previous: "<i class='material-icons'>navigate_next</i> ",
          next: "<i class='material-icons'>navigate_before</i>",
          last: ""
        },
      },
      info: false,
      paging: true,
      ordering: false,
      serverSide: true,
      processing: true,}
    this.user = this.serviceauth.getCurrentUser();
    this.registerForm = this.formBuilder.group({
      prix: ['', Validators.required],
      img: ['', Validators.required],
    });

    this.registerForm2 = this.formBuilder.group({
      prix2: ['', Validators.required],
    });

    this.getRecharges();

  }

  get f() { return this.registerForm.controls; }
  get g() { return this.registerForm2.controls; }

  getRecharges() {
    this.servicerecharge.getRecharges()
      .pipe(first()).subscribe(data => {
        this.recharges = data

      })
  }


  onSubmit() {
    if (this.registerForm.invalid) {
      this.vide=true;
      return ;
    }else
    {
      this.vide=false;
      this.message=  "  ";
    }
  }

  ajouter() {
    if (this.registerForm.invalid&&(this.vide==false)) {
      
      
        this.message=  "يجب إدخال المعطيات";
        
      return ;
    }
     else if (this.registerForm.invalid&&(this.vide==true)) {
        this.vide=true; this.message=  "يجب إدخال المعطيات";
        
        return ;
      } else {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('img', this.images);
    formData.append('prix', this.prix);
    formData.append('type', "recharge");
    this.servicerecharge.addProduct(formData)
      .pipe(first()).subscribe(() => {
        this.prix = "";
        this.link = "";
        this.img = "";
        this.showToasterSuccess();
        setTimeout(() => {
          this.getRecharges();
        }, 3000)
        this.images = null;
        this.submitted = false;
        this.vide=true;
        this.message=  "  ";
   
      
      })}
  }


  updateRecharge(id: string, prix: string) {
    this.submitted2 = true;
    if (this.registerForm2.invalid) {
      return;
    }
    const formData2 = new FormData();
    formData2.append('img', this.images2);
    formData2.append('prix', prix);
    this.servicerecharge.updaterecharge(id, formData2)
      .pipe(first()).subscribe(() => {
        this.prix2 = "";
        this.link2 = "";
        this.showToasterSuccess2();
        setTimeout(() => {
          this.getRecharges();

        }, 2000)
        this.images2 = null;
        this.submitted2 = false;
        this.modalService.dismissAll();
      })
  }


  delete(id: string) {
    this.servicerecharge.deleterecharge(id)
      .pipe(first()).subscribe(() => {
        this.getRecharges();
        this.showToasterError();
        this.modalService.dismissAll();
      })
  }

  showToasterSuccess() {
    this.notifyService.showSuccess(" تم اضافة بطاقة")
  }

  showToasterSuccess2() {
    this.notifyService.showSuccess(" تم تعديل البطاقة")
  }

  showToasterError() {
    this.notifyService.showSuccess("تم محو البطاقة ")
  }

  selectImage(event: any) {

    var reader = new FileReader();
    const file = event.target.files[0];
    this.images = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link = event.target.result
    }
  }

  selectImage2(event: any) {

    var reader = new FileReader();
    const file = event.target.files[0];
    this.images2 = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link2 = event.target.result
    }

  }


  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }


  }


  open(content) {
    this.prix2 = "";
    this.link2 = "";
    this.modalService.open(content, { windowClass: "myCustomModalClass", centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;


    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });
  }


  isZeroAllowed = true;

  keyPressNumbers(event) {
      const key = event.key || String.fromCharCode(event.keyCode);

  if (key === '0' && this.isZeroAllowed && event.target.selectionStart === 0) {
    event.preventDefault();
  }

    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }

  }
inputEvent(event: any) {
  const value = event.target.value;
  this.isZeroAllowed = value === '';
}

  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }


}

// import { Component, OnInit } from '@angular/core';
// import { first } from 'rxjs/operators';
// import { NotificationService } from 'src/app/services/notification.service';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { DatePipe } from '@angular/common';
// import {  NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
// import { environment } from 'src/environments/environment.prod';
// import { Subscription  } from 'rxjs';
// import { AuthentificationService } from 'src/app/services/authentification.service';
// import { RechargeService } from 'src/app/services/recharge.service';

// @Component({
//   selector: 'app-mobile',
//   templateUrl: './mobile.component.html',
//   styleUrls: ['./mobile.component.css']
// })
// export class MobileComponent implements OnInit {


//   recharge1:any = {};
//   recharge2:any = {};
//   recharge3:any = {};
//   recharge4:any = {};
//   recharge5:any = {};
//   recharge6:any = {};
//   recharge7:any = {};
//   recharge8:any = {};


//   public collapse= false;
//   prix:string;
//   prix2:string;
//   prix3:string;
//   prix4:string;
//   prix5:string;
//   prix6:string;
//   prix7:string;
//   prix8:string;


//   img:string;
//   img2:string;
//   img3:string;
//   img4:string;
//   img5:string;
//   img6:string;
//   img7:string;
//   img8:string;



//   actu1:string;
//   date= new Date;
//   registerForm: FormGroup;
//   registerForm1: FormGroup;

//   registerForm2: FormGroup;
//   registerForm22: FormGroup;

//   registerForm3: FormGroup;
//   registerForm33: FormGroup;

//   registerForm4: FormGroup;
//   registerForm44: FormGroup;

//   registerForm5: FormGroup;
//   registerForm55: FormGroup;

//   registerForm6: FormGroup;
//   registerForm66: FormGroup;

//   registerForm7: FormGroup;
//   registerForm77: FormGroup;

//   registerForm8: FormGroup;
//   registerForm88: FormGroup;


//   submitted = false;
//   submitted2 = false;
//   submitted3 = false;
//   submitted4 = false;
//   submitted5 = false;
//   submitted6 = false;
//   submitted7 = false;
//   submitted8 = false;

//   currentPage = 1;
//   itemsPerPage = 10;
//   pageSize: number;
//   images: File;


//   images2: File;
//   images3: File;
//   images4: File;
//   images5: File;
//   images6: File;
//   images7: File;
//   images8: File;

//   closeResult = '';
//   url=environment.apiUrl;
//   url1 = environment.urlStorage ;
//   link1:string="";
//   link2:string="";
//   link3:string="";
//   link4:string="";
//   link5:string="";
//   link6:string="";
//   link7:string="";
//   link8:string="";

//   initialValues: any;
//   user:any = {};
//   p: number = 1;
//   private updateSubscription: Subscription;
//   constructor(
//     private servicerecharge: RechargeService,
//     private serviceauth: AuthentificationService,
//     private notifyService: NotificationService,
//     private formBuilder: FormBuilder,
//     public datepipe: DatePipe,
//     private modalService: NgbModal,
//     config: NgbModalConfig) {
//       config.backdrop = 'static';
//       config.keyboard = false;
//       this.controle();
//      }

//   ngOnInit() {
//     this.user = this.serviceauth.getCurrentUser();
//     this.servicerecharge.getbyid(1)
//     .subscribe(data =>{ this.recharge1 = data });
//     this.servicerecharge.getbyid(2)
//     .subscribe(data =>{ this.recharge2 = data })
//     this.servicerecharge.getbyid(3)
//     .subscribe(data =>{ this.recharge3 = data })
//     this.servicerecharge.getbyid(4)
//     .subscribe(data =>{ this.recharge4 = data })
//     this.servicerecharge.getbyid(5)
//     .subscribe(data =>{ this.recharge5 = data })
//     this.servicerecharge.getbyid(6)
//     .subscribe(data =>{ this.recharge6 = data })
//     this.servicerecharge.getbyid(7)
//     .subscribe(data =>{ this.recharge7 = data })
//     this.servicerecharge.getbyid(8)
//     .subscribe(data =>{ this.recharge8 = data })

//   }
//   controle(){
//     this.registerForm = this.formBuilder.group({
//       prix: ['',Validators.required],
//       img: '',
//   });
//   this.registerForm1 = this.formBuilder.group({
//     prix: ['',Validators.required],
//     img: ['',Validators.required]

// });

// this.registerForm2 = this.formBuilder.group({
//   prix2: ['',Validators.required],
//   img2: '',
// });
// this.registerForm22 = this.formBuilder.group({
// prix2: ['',Validators.required],
// img2: ['',Validators.required]

// });

// this.registerForm3 = this.formBuilder.group({
//   prix3: ['',Validators.required],
//   img3: '',
// });
// this.registerForm33 = this.formBuilder.group({
// prix3: ['',Validators.required],
// img3: ['',Validators.required]

// });

// this.registerForm4 = this.formBuilder.group({
//   prix4: ['',Validators.required],
//   img4: '',
// });
// this.registerForm44 = this.formBuilder.group({
// prix4: ['',Validators.required],
// img4: ['',Validators.required]

// });

// this.registerForm5 = this.formBuilder.group({
//   prix5: ['',Validators.required],
//   img5: '',
// });
// this.registerForm55 = this.formBuilder.group({
// prix5: ['',Validators.required],
// img5: ['',Validators.required]

// });

// this.registerForm6 = this.formBuilder.group({
//   prix6: ['',Validators.required],
//   img6: '',
// });
// this.registerForm66 = this.formBuilder.group({
// prix6: ['',Validators.required],
// img6: ['',Validators.required]

// });

// this.registerForm7 = this.formBuilder.group({
//   prix7: ['',Validators.required],
//   img7: '',
// });
// this.registerForm77 = this.formBuilder.group({
// prix7: ['',Validators.required],
// img7: ['',Validators.required]

// });

// this.registerForm8 = this.formBuilder.group({
//   prix8: ['',Validators.required],
//   img8: '',
// });
// this.registerForm88 = this.formBuilder.group({
// prix8: ['',Validators.required],
// img8: ['',Validators.required]

// });




//   }
//   get f() { return this.registerForm.controls; }
//   get h() { return this.registerForm1.controls; }

//   get a() { return this.registerForm2.controls; }
//   get b() { return this.registerForm22.controls; }


//   get c() { return this.registerForm3.controls; }
//   get d() { return this.registerForm33.controls; }

//   get e() { return this.registerForm4.controls; }
//   get g() { return this.registerForm44.controls; }

//   get i() { return this.registerForm5.controls; }
//   get j() { return this.registerForm55.controls; }

//   get k() { return this.registerForm6.controls; }
//   get l() { return this.registerForm66.controls; }

//   get m() { return this.registerForm7.controls; }
//   get n() { return this.registerForm77.controls; }

//   get o() { return this.registerForm8.controls; }
//   get r() { return this.registerForm88.controls; }




// delete1(){
//   this.submitted = false;
//   this.servicerecharge.deleterecharge("1")
//   .pipe(first()).subscribe( () => {
//     this.servicerecharge.getbyid(1)
//     .subscribe(data =>{ this.recharge1 = data });
//     this.link1 = "",
//     this.submitted = false;
//     this.images= null;
//     this.prix = "",
//     this.showToasterError();
//     this.modalService.dismissAll();
//     })
// }


// delete2(){
//   this.submitted2 = false;
//   this.servicerecharge.deleterecharge("2")
//   .pipe(first()).subscribe( () => {
//     this.servicerecharge.getbyid(2)
//     .subscribe(data =>{ this.recharge2 = data });
//     this.link2 = "",
//     this.images2= null;
//     this.submitted2 = false;
//     this.prix2 = "",
//     this.showToasterError();
//     this.modalService.dismissAll();
//     })
// }


// delete3(){
//   this.submitted3 = false;
//   this.servicerecharge.deleterecharge("3")
//   .pipe(first()).subscribe( () => {
//     this.servicerecharge.getbyid(3)
//     .subscribe(data =>{ this.recharge3 = data });
//     this.submitted3 = false;
//     this.link3 = "",
//     this.images3= null;
//     this.prix = "",
//     this.showToasterError();
//     this.modalService.dismissAll();
//     })
// }

// delete4(){
//   this.submitted4 = false;
//   this.servicerecharge.deleterecharge("4")
//   .pipe(first()).subscribe( () => {
//     this.servicerecharge.getbyid(4)
//     .subscribe(data =>{ this.recharge4 = data });
//     this.link4 = "",
//     this.images4= null;
//     this.submitted4 = false;
//     this.prix = "",
//     this.showToasterError();
//     this.modalService.dismissAll();
//     })
// }

// delete5(){
//   this.submitted5 = false;
//   this.servicerecharge.deleterecharge("5")
//   .pipe(first()).subscribe( () => {
//     this.servicerecharge.getbyid(5)
//     .subscribe(data =>{ this.recharge5 = data });
//     this.link5 = "",
//     this.images5= null;
//     this.submitted5 = false;
//     this.prix = "",
//     this.showToasterError();
//     this.modalService.dismissAll();
//     })
// }

// delete6(){
//   this.submitted6 = false;
//   this.servicerecharge.deleterecharge("6")
//   .pipe(first()).subscribe( () => {
//     this.servicerecharge.getbyid(6)
//     .subscribe(data =>{ this.recharge6 = data });
//     this.link6 = "",
//     this.submitted6 = false;
//     this.images6= null;
//     this.prix = "",
//     this.showToasterError();
//     this.modalService.dismissAll();
//     })
// }

// delete7(){
//   this.submitted7 = false;
//   this.servicerecharge.deleterecharge("7")
//   .pipe(first()).subscribe( () => {
//     this.servicerecharge.getbyid(7)
//     .subscribe(data =>{ this.recharge7 = data });
//     this.link7 = "",
//     this.images7= null;
//     this.submitted7 = false;
//     this.prix = "",
//     this.showToasterError();
//     this.modalService.dismissAll();
//     })
// }

// delete8(){
//   this.submitted8 = false;
//   this.servicerecharge.deleterecharge("8")
//   .pipe(first()).subscribe( () => {
//     this.servicerecharge.getbyid(8)
//     .subscribe(data =>{ this.recharge8 = data });
//     this.link8 = "",
//     this.images8= null;
//     this.submitted8 = false;
//     this.prix = "",
//     this.showToasterError();
//     this.modalService.dismissAll();
//     })
// }









//  modifier(id:string, prix:string){
//   this.submitted = true;

//   // stop here if form is invalid
//   if (this.registerForm.invalid) {
//       return;
//   }

//   const formData = new FormData();
//   formData.append('img', this.images);
//   formData.append('prix', prix);

// this.servicerecharge.updaterecharge(id, formData)
// .pipe(first()).subscribe( () => {

//   this.prix ="";
//   setTimeout(() => {
//     this.showToasterSuccess1();
// }, 2000)
//   this.modalService.dismissAll();
//   this.submitted = false;

// })
// }

// modifier1(id:string, prix:string){
//   this.submitted = true;

//   // stop here if form is invalid
//   if (this.registerForm1.invalid) {
//       return;
//   }

//   const formData = new FormData();
//   formData.append('img', this.images);
//   formData.append('prix', prix);

// this.servicerecharge.updaterecharge(id, formData)
// .pipe(first()).subscribe( () => {

//   this.prix ="";
//   setTimeout(() => {
//     this.showToasterSuccess1();
// }, 2000)
//   this.modalService.dismissAll();
//   this.submitted = false;

// })
// }




// modifier2(id:string, prix:string){
//   this.submitted2 = true;

//   // stop here if form is invalid
//   if (this.registerForm2.invalid) {
//       return;
//   }

//   const formData = new FormData();
//   formData.append('img', this.images2);
//   formData.append('prix', prix);

// this.servicerecharge.updaterecharge(id, formData)
// .pipe(first()).subscribe( () => {

//   this.prix2 ="";
//   setTimeout(() => {
//     this.showToasterSuccess1();
// }, 2000)
//   this.modalService.dismissAll();
//   this.submitted2 = false;

// })
// }

// modifier22(id:string, prix:string){
//   this.submitted2 = true;

//   // stop here if form is invalid
//   if (this.registerForm22.invalid) {
//       return;
//   }

//   const formData = new FormData();
//   formData.append('img', this.images2);
//   formData.append('prix', prix);

// this.servicerecharge.updaterecharge(id, formData)
// .pipe(first()).subscribe( () => {

//   this.prix2 ="";
//   setTimeout(() => {
//     this.showToasterSuccess1();
// }, 2000)
//   this.modalService.dismissAll();
//   this.submitted2 = false;

// })
// }





// modifier3(id:string, prix:string){
//   this.submitted3 = true;

//   // stop here if form is invalid
//   if (this.registerForm3.invalid) {
//       return;
//   }

//   const formData = new FormData();
//   formData.append('img', this.images3);
//   formData.append('prix', prix);

// this.servicerecharge.updaterecharge(id, formData)
// .pipe(first()).subscribe( () => {

//   this.prix3 ="";
//   setTimeout(() => {
//     this.showToasterSuccess1();
// }, 2000)
//   this.modalService.dismissAll();
//   this.submitted3 = false;

// })
// }

// modifier33(id:string, prix:string){
//   this.submitted3 = true;

//   // stop here if form is invalid
//   if (this.registerForm33.invalid) {
//       return;
//   }

//   const formData = new FormData();
//   formData.append('img', this.images3);
//   formData.append('prix', prix);

// this.servicerecharge.updaterecharge(id, formData)
// .pipe(first()).subscribe( () => {

//   this.prix3 ="";
//   setTimeout(() => {
//     this.showToasterSuccess1();
// }, 2000)
//   this.modalService.dismissAll();
//   this.submitted3 = false;

// })
// }





// modifier4(id:string, prix:string){
//   this.submitted4 = true;

//   // stop here if form is invalid
//   if (this.registerForm4.invalid) {
//       return;
//   }

//   const formData = new FormData();
//   formData.append('img', this.images4);
//   formData.append('prix', prix);

// this.servicerecharge.updaterecharge(id, formData)
// .pipe(first()).subscribe( () => {

//   this.prix4 ="";
//   setTimeout(() => {
//     this.showToasterSuccess1();
// }, 2000)
//   this.modalService.dismissAll();
//   this.submitted2 = false;

// })
// }

// modifier44(id:string, prix:string){
//   this.submitted4 = true;

//   // stop here if form is invalid
//   if (this.registerForm44.invalid) {
//       return;
//   }

//   const formData = new FormData();
//   formData.append('img', this.images4);
//   formData.append('prix', prix);

// this.servicerecharge.updaterecharge(id, formData)
// .pipe(first()).subscribe( () => {

//   this.prix4 ="";

//   setTimeout(() => {
//     this.showToasterSuccess1();
// }, 2000)
//   this.modalService.dismissAll();
//   this.submitted4 = false;

// })
// }




// modifier5(id:string, prix:string){
//   this.submitted5 = true;

//   // stop here if form is invalid
//   if (this.registerForm5.invalid) {
//       return;
//   }

//   const formData = new FormData();
//   formData.append('img', this.images5);
//   formData.append('prix', prix);

// this.servicerecharge.updaterecharge(id, formData)
// .pipe(first()).subscribe( () => {

//   this.prix5 ="";

//   setTimeout(() => {
//     this.showToasterSuccess1();
// }, 2000)


//   this.modalService.dismissAll();
//   this.submitted5 = false;


// })
// }

// modifier55(id:string, prix:string){
//   this.submitted5 = true;

//   // stop here if form is invalid
//   if (this.registerForm55.invalid) {
//       return;
//   }

//   const formData = new FormData();
//   formData.append('img', this.images5);
//   formData.append('prix', prix);

// this.servicerecharge.updaterecharge(id, formData)
// .pipe(first()).subscribe( () => {


//   setTimeout(() => {
//     this.showToasterSuccess1();
// }, 2000)

//   this.prix5 ="";
//   this.submitted5 = false;

// })
// }



// modifier6(id:string, prix:string){
//   this.submitted6 = true;

//   // stop here if form is invalid
//   if (this.registerForm6.invalid) {
//       return;
//   }

//   const formData = new FormData();
//   formData.append('img', this.images6);
//   formData.append('prix', prix);

// this.servicerecharge.updaterecharge(id, formData)
// .pipe(first()).subscribe( () => {

//   this.prix6 ="";
//   setTimeout(() => {
//     this.showToasterSuccess1();
// }, 2000)
//   this.modalService.dismissAll();
//   this.submitted6 = false;

// })
// }

// modifier66(id:string, prix:string){
//   this.submitted6 = true;

//   // stop here if form is invalid
//   if (this.registerForm66.invalid) {
//       return;
//   }

//   const formData = new FormData();
//   formData.append('img', this.images6);
//   formData.append('prix', prix);

// this.servicerecharge.updaterecharge(id, formData)
// .pipe(first()).subscribe( () => {

//   this.prix6 ="";
//   setTimeout(() => {
//     this.showToasterSuccess1();
// }, 2000)
//   this.modalService.dismissAll();
//   this.submitted6 = false;

// })
// }


// modifier7(id:string, prix:string){
//   this.submitted7 = true;

//   // stop here if form is invalid
//   if (this.registerForm7.invalid) {
//       return;
//   }

//   const formData = new FormData();
//   formData.append('img', this.images7);
//   formData.append('prix', prix);

// this.servicerecharge.updaterecharge(id, formData)
// .pipe(first()).subscribe( () => {

//   this.prix7 ="";
//   setTimeout(() => {
//     this.showToasterSuccess1();
// }, 2000)
//   this.modalService.dismissAll();
//   this.submitted7 = false;

// })
// }

// modifier77(id:string, prix:string){
//   this.submitted7 = true;

//   // stop here if form is invalid
//   if (this.registerForm77.invalid) {
//       return;
//   }

//   const formData = new FormData();
//   formData.append('img', this.images7);
//   formData.append('prix', prix);

// this.servicerecharge.updaterecharge(id, formData)
// .pipe(first()).subscribe( () => {

//   this.prix7 ="";
//   setTimeout(() => {
//     this.showToasterSuccess1();
// }, 2000)
//   this.modalService.dismissAll();
//   this.submitted7 = false;

// })
// }


// modifier8(id:string, prix:string){
//   this.submitted8 = true;

//   // stop here if form is invalid
//   if (this.registerForm8.invalid) {
//       return;
//   }

//   const formData = new FormData();
//   formData.append('img', this.images8);
//   formData.append('prix', prix);

// this.servicerecharge.updaterecharge(id, formData)
// .pipe(first()).subscribe( () => {

//   this.prix8 ="";
//   setTimeout(() => {
//     this.showToasterSuccess1();
// }, 2000)
//   this.modalService.dismissAll();
//   this.submitted8 = false;

// })
// }

// modifier88(id:string, prix:string){
//   this.submitted8 = true;

//   // stop here if form is invalid
//   if (this.registerForm88.invalid) {
//       return;
//   }

//   const formData = new FormData();
//   formData.append('img', this.images8);
//   formData.append('prix', prix);

// this.servicerecharge.updaterecharge(id, formData)
// .pipe(first()).subscribe( () => {

//   this.prix8 ="";
//   setTimeout(() => {
//     this.showToasterSuccess1();
// }, 2000)
//   this.modalService.dismissAll();
//   this.submitted8 = false;

// })
// }










// showToasterSuccess(){
//   this.notifyService.showSuccess(" تم اضافة بطاقة الشحن")
// }
// showToasterSuccess1(){
//   this.notifyService.showSuccess("تم تعديل بطاقة الشحن ")
// }
// showToasterError(){
//   this.notifyService.showSuccess("تم محو بطاقة الشحن ")
// }
// public onPageChange(pageNum: number): void {
//   this.pageSize = this.itemsPerPage*(pageNum - 1);
// }
// selectImage1(event: any) {

//     var reader = new FileReader();
//     const file = event.target.files[0];
//     this.images = file;
//     reader.readAsDataURL(file)
//     reader.onload = (event: any) =>{
//       this.link1 = event.target.result
//     }

// }

// selectImage2(event: any) {

//   var reader = new FileReader();
//   const file = event.target.files[0];
//   this.images2 = file;
//   reader.readAsDataURL(file)
//   reader.onload = (event: any) =>{
//     this.link2 = event.target.result
//   }

// }

// selectImage3(event: any) {

//   var reader = new FileReader();
//   const file = event.target.files[0];
//   this.images3 = file;
//   reader.readAsDataURL(file)
//   reader.onload = (event: any) =>{
//     this.link3 = event.target.result
//   }

// }

// selectImage4(event: any) {

//   var reader = new FileReader();
//   const file = event.target.files[0];
//   this.images4 = file;
//   reader.readAsDataURL(file)
//   reader.onload = (event: any) =>{
//     this.link4 = event.target.result
//   }

// }
// selectImage5(event: any) {

//   var reader = new FileReader();
//   const file = event.target.files[0];
//   this.images5 = file;
//   reader.readAsDataURL(file)
//   reader.onload = (event: any) =>{
//     this.link5 = event.target.result
//   }

// }

// selectImage6(event: any) {

//   var reader = new FileReader();
//   const file = event.target.files[0];
//   this.images6 = file;
//   reader.readAsDataURL(file)
//   reader.onload = (event: any) =>{
//     this.link6 = event.target.result
//   }

// }

// selectImage7(event: any) {

//   var reader = new FileReader();
//   const file = event.target.files[0];
//   this.images7 = file;
//   reader.readAsDataURL(file)
//   reader.onload = (event: any) =>{
//     this.link7 = event.target.result
//   }

// }

// selectImage8(event: any) {

//   var reader = new FileReader();
//   const file = event.target.files[0];
//   this.images8 = file;
//   reader.readAsDataURL(file)
//   reader.onload = (event: any) =>{
//     this.link8 = event.target.result
//   }

// }




// isZeroAllowed = true;

// keyPressNumbers(event) {
//     const key = event.key || String.fromCharCode(event.keyCode);

// if (key === '0' && this.isZeroAllowed && event.target.selectionStart === 0) {
//   event.preventDefault();
// }

//   var charCode = (event.which) ? event.which : event.keyCode;
//   if ((charCode < 48 || charCode > 57)) {
//     event.preventDefault();
//     return false;
//   } else {
//     return true;
//   }

// }
// inputEvent(event: any) {
// const value = event.target.value;
// this.isZeroAllowed = value === '';
// }


// }

