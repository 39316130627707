import { Component, OnInit } from '@angular/core';
import { ActualiteService } from 'src/app/services/actualite.service';
import { first } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment.prod';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { ActivatedRoute } from '@angular/router';
import { PubliciteService } from 'src/app/services/publicite.service';
import { TextesDeroulante } from 'src/app/modules/textDeroulante';
import { UserPublicity } from 'src/app/modules/userPublicity';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-user-publicity',
  templateUrl: './user-publicity.component.html',
  styleUrls: ['./user-publicity.component.css']
})
export class UserPublicityComponent implements OnInit {

  userPublicities: UserPublicity[] = [];
  text: string;
  text1: string;
  date = new Date;
  registerForm: FormGroup;
  registerForm1: FormGroup;
  submitted = false;
  vide=false;
  currentPage = 1;
  itemsPerPage = 3;
  pageSize: number;
  images: File;
  closeResult = '';
  url = environment.apiUrl;
  user: any = {};
  p: number = 1;
  uri = environment.apiUrl;
  reason: string;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  dtOptions: DataTables.Settings = {};
  receivedData: boolean;
  constructor(private servicepublicite: PubliciteService, private http: HttpClient,
    private serviceauth: AuthentificationService,
    private notifyService: NotificationService,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private route: ActivatedRoute,private sharedService: SharedService,
    private modalService: NgbModal) { }

  ngOnInit() {

    this.user = this.serviceauth.getCurrentUser();
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;
    var that = this;
    this.dtOptions = {
      pagingType: 'simple_numbers',
      searching: false,
      language: {
        processing: "يرجى الإنتظار...",
        searchPlaceholder: 'البحث',
        search: "",
        lengthMenu: " _MENU_ ",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        
        paginate: {
          first: "",
          previous: "<i class='material-icons'>navigate_next</i> ",
          next: "<i class='material-icons'>navigate_before</i>",
          last: ""
        },
      },
      info: false,
      paging: true,
      ordering: false,
      serverSide: true,
      processing: true,
      pageLength: 5, // Set the number of records per page
      lengthMenu: [5, 10, 25, 50], // Set the dropdown options for records per page
      lengthChange: false, // Disable the option to change the number of records per page
      ajax: (dataTablesParameters: any, callback) => {
        this.currentPage = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        const rowData = {
          no_of_records: dataTablesParameters.length,
          page: this.currentPage,
          group_id: 1
        };
    
        that.http.post<DataTablesResponse>(this.uri + 'indexUserPublicities', dataTablesParameters, { headers: this.headers }).subscribe(resp => {
          const details = JSON.parse(JSON.stringify(resp.original));
          that.userPublicities = details.data;
          callback({
            recordsTotal: details.recordsTotal,
            recordsFiltered: details.recordsFiltered,
            data: [],
          });
        });
      },
    };
    
      this.registerForm = this.formBuilder.group({
      
      text: ['', Validators.required]
    });
    this.registerForm1 = this.formBuilder.group({
      text1: ['', Validators.required]
    });

  }
  get f() { return this.registerForm.controls; }
  get h() { return this.registerForm1.controls; }




  delete(id: string) {
    this.servicepublicite.deletetextderoulante(id).pipe(first()).subscribe(() => {
      this.showToasterError();
      this.modalService.dismissAll();
      $('#dtable').DataTable().draw(true);
     
    })
  }

  ajouter() {
if(this.registerForm.invalid){
  this.vide=true;
  return
}else{
    this.submitted = true;
    this.servicepublicite.addtextderoulante(this.text).pipe(first()).subscribe(() => {
      this.vide=false;
      this.modalService.dismissAll();
      this.text = '';
      this.submitted = false;
      $('#dtable').DataTable().draw(true);
      this.showToasterSuccess();
    })
  }}

  modifier(id: string, text: string) {
    this.submitted = true;
    if (this.registerForm1.invalid) {
      return;
    }
    this.servicepublicite.updatetextderoulante(text, id).pipe(first()).subscribe(() => {
      this.text1 = "";
      this.showToasterSuccess1();
      this.modalService.dismissAll();
      this.submitted = false;
      $('#dtable').DataTable().draw(false);
      window.location.reload();
    })
  }

  showToasterSuccess() {
    this.notifyService.showSuccess("تم اضافة إشهار  ")
  }
  showToasterSuccess1() {
    this.notifyService.showSuccess("تم تعديل الإشهار   ")
  }
  showToasterError() {
    this.notifyService.showSuccess("تم محو الإشهار   ")
  }
  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }

  open(content) {
    this.modalService.open(content, { centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.text = '';
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.text = '';
    });
  }

  open1(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.text = '';
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.text = '';
    });
  }

  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}


