<div id="menu"><app-menu></app-menu></div>

<div id="contenu" [ngStyle]="{'width': receivedData ? '82%' : 'auto'}">

    <body dir="rtl">
        <div class="container pt-5" *ngIf="user.role == 'globale' || user.codeparrainage == 1">

            <h2>إضافة رموز إحالة</h2>
            <br>
            <form [formGroup]="registerForm">
                <div class="row">

                    <div class="col-md-4 d-flex">
                        <input type="text" class="inpu form-control" formControlName="codeparrainage" oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"
                            name="codeparrainage" [(ngModel)]="codeparrainage" placeholder=" رمز الإحالة   ">
                        <img class="parrainage" src="../../../assets/parinaige.png" alt="">

                    </div>
             
                    <div class="col-md-4 d-flex">
                        <input type="number" min="1" class="inpu form-control" oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" formControlName="price" onkeydown="javascript: return event.keyCode === 8 ||
                event.keyCode === 46 ? true : !isNaN(Number(event.key))" name="price" [(ngModel)]="price"
                            placeholder="ثمن الرمز">
                        <img class="parrainage" src="../../../assets/wallet.png" alt="">

                    </div>
                

                    <div class="col-md-3" style="text-align: center;">
                        <button (click)="ajouter()" class="btn lab">تسجيل</button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4 d-flex">
                        
                        <div *ngIf="submitted && f['codeparrainage'].errors" class="invalid-feedback">
                            <div class="error" *ngIf="f['codeparrainage'].errors['required']">رمز الإحالة مطلوب</div>
                        </div>
                    </div>
                    
                    <div class="col-md-4 d-flex">
                        <div *ngIf="submitted && f['price'].errors" class="invalid-feedback">
                            <div class="error" *ngIf="f['price'].errors['required']"> ثمن الرمز مطلوب</div>
                        </div>

                    </div>
                 
                </div>
            </form>
            <br>
            <h3> قائمة رموز الإحالة</h3>
            <br>
            <div class="row" style="overflow-x:auto;direction: rtl;">
                <table id="dtable" datatable [dtOptions]="dtOptions" class="row-border hover">
                    <thead>
                        <br>
                        <tr>
                            <th style="border-bottom-right-radius: 10px; border-top-right-radius:10px">#</th>
                            <th>رمز الإحالة </th>
                            <th>ثمن الرمز</th>
                            <th style="border-bottom-left-radius: 10px; border-top-left-radius:10px"></th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let offer of offers | slice: pageSize | slice: 0:itemsPerPage; let index = index;">
                        <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;">
                            <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                                {{((currentPage * 10) -9)+index}}</td>
                            <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                                {{offer.codeparrainageSpecial}} </td>
                            <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                                {{offer.price}} </td>
                           
                            <td  style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                                <a *ngIf="offer.state == 'inProgress'" (click)="open1(longContent1)"><img id="edit" src="../../../assets/editpublication.png" alt=""></a> &nbsp; &nbsp;
                                <a *ngIf="offer.state == 'inProgress'" (click)="openSm2(content4)"><img src="../../../assets/deltepublication.png" alt=""></a>
                            </td>
                        </tr>
                        <ng-template #longContent1 let-modal>
                            <div class="modal-content">
                                <div class="modal-body" id="mbody" dir="rtl">

                                    <button type="button" class="close" aria-label="Close"
                                        (click)="modal.close('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h5 id="h3"> تعديل رمز الإحالة  </h5>
                                    <form [formGroup]="registerForm1" enctype="multipart/form-data">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <p>رمز الإحالة </p>
                                                <input type="text" class="inp form-control" formControlName="codeparrainage1"oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"
                                                    [(ngModel)]="offer.codeparrainageSpecial">
                                                <div *ngIf="submitted1 && g['codeparrainage1'].errors" class="invalid-feedback">
                                                    <div class="error" *ngIf="g['codeparrainage1'].errors['required']"> رمز الإحالة  
                                                        مطلوب</div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <p>ثمن الرمز</p>
                                                <input type="text" class="inp form-control" formControlName="price1"oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"
                                                    onkeydown="javascript: return event.keyCode === 8 ||
                              event.keyCode === 46 ? true : !isNaN(Number(event.key))" [(ngModel)]="offer.price">
                                                <div *ngIf="submitted1 && g['price1'].errors" class="invalid-feedback">
                                                    <div class="error" *ngIf="g['price1'].errors['required']"> الثمن
                                                        مطلوب</div>
                                                </div>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="d-flex justify-content-center">
                                            <button type="button" (click)="update(offer.id , offer.price, offer.codeparrainageSpecial)"
                                                class="btn lab">&nbsp;
                                                &nbsp; تعديل &nbsp; &nbsp;</button>
                                        </div>
                                    </form>


                                </div>
                            </div>
                        </ng-template>
                        <ng-template #content4 let-modal>
                            <div class="modal-body" dir="rtl">
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h5 id="h3"> هل تريد حذف هذا الرمز ؟</h5>
                                <div class="d-flex justify-content-center">
                                    <button id="btnP" (click)="delete(offer.id)" type="button"
                                        class="btn btn-primary">&nbsp; &nbsp; تأكيد
                                        &nbsp; &nbsp;</button>
                                </div>
                            </div>
                        </ng-template>

                    </tbody>
                    <div style="margin-left: -196px;">

                    </div>
                </table>
            </div>
        </div>

        <div class="container" *ngIf="user.role != 'globale' && user.codeparrainage == 0 " style="padding-top: 10% !important">
            <div class="container" style="width: 100%; height: 400px; max-height: 800px;background-size: contain;
      background-repeat: no-repeat;background-position: center center;display:block;margin-top:auto;
      margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
            </div>
        </div>

    </body>
</div>