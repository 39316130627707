import { Component, HostListener, OnInit } from '@angular/core';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { PubliciteService } from 'src/app/services/publicite.service';
import { first } from 'rxjs/operators';
import { OfferPublicity } from 'src/app/modules/offerPublicity';
import { UserPublicity } from 'src/app/modules/userPublicity';
import { environment } from 'src/environments/environment.prod';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-offer-publicity',
  templateUrl: './offer-publicity.component.html',
  styleUrls: ['./offer-publicity.component.css']
})
export class OfferPublicityComponent implements OnInit {
  user: any = {};
  numberViews:string;
  price:string;
  offerPublicities:OfferPublicity[] = [];
  userPublicitiesDemand:UserPublicity[] = [];
  dtOptions: DataTables.Settings = {};
  uri = environment.apiUrl;
  currentPage: number;
  closeResult = '';
  reason: string;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  registerForm: FormGroup;
  registerForm1: FormGroup;
  submitted:boolean = false;
  isScreenBelow768px: boolean;
  submitted1:boolean = false;
  currentPage1 = 1;
  itemsPerPage = 6;
  pageSize: number;
  p: number = 1;
  formControls: FormControl[] = [];
  message: string;
  receivedData: any;
  constructor(private serviceauth: AuthentificationService,private servicepublicite: PubliciteService,  private route: ActivatedRoute, private notifyService: NotificationService,
    private http: HttpClient, private modalService: NgbModal,private sharedService: SharedService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.isScreenBelow768px = window.innerWidth < 768;
    this.user = this.serviceauth.getCurrentUser();
    this.getofferPublicities();
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;
    var that = this;
    this.dtOptions = {
      pagingType: 'simple_numbers',
      searching: true,
      language: {
        processing: "يرجى الإنتظار...",
        searchPlaceholder: 'البحث',
        search: "",
        lengthMenu: " _MENU_ ",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        paginate: {
          first: "",
          previous: "<i class='material-icons'>navigate_next</i> ",
          next: "<i class='material-icons'>navigate_before</i>",
          last: ""
        },
      },
      info: false,
      paging: true,
      ordering: false,
      serverSide: true,
      processing: true,
      pageLength: 10,  // Définir le nombre d'éléments par page, ajustez-le selon vos besoins

      ajax: (dataTablesParameters: any, callback) => {
        this.currentPage = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        const rowData = {
          no_of_records: dataTablesParameters.length,
          page: this.currentPage,
          group_id: 1
        };
    
        that.http.post<DataTablesResponse>(this.uri + 'indexUserPublicitiesInProgress', dataTablesParameters, { headers: this.headers }).subscribe(resp => {
          const details = JSON.parse(JSON.stringify(resp.original));
          that.userPublicitiesDemand = details.data;
          callback({
            recordsTotal: details.recordsTotal,
            recordsFiltered: details.recordsFiltered,
            data: [],
          });
        });
      },
      columns: [
        { data: "created_at" },
        { data: "codeparrainage" },
        { data: "numberViews" },
        { data: "text" }
      ],
    };
    
    this.registerForm = this.formBuilder.group({
      numberViews0: ['', Validators.required],
      price0: ['', Validators.required],
      numberViews1: ['', Validators.required],
      price1: ['', Validators.required],
      numberViews2: ['', Validators.required],
      price2: ['', Validators.required],
      numberViews3: ['', Validators.required],
      price3: ['', Validators.required],
    });
    this.registerForm1 = this.formBuilder.group({
      reason: ['', Validators.required],
    });
  }

  get f() { return this.registerForm1.controls; }

  getofferPublicities() {
    this.servicepublicite.getofferPublicities()
      .pipe(first()).subscribe(data => { this.offerPublicities = data ;})
  }

  modifier(id: string, numberViews: string, price:string) {
    if((numberViews=='0'||price=='0'||price==''||numberViews=='')){
      this.message=' معطيات خاطئة';
      this.submitted=true;
return
    }else{

    this.servicepublicite.updateofferPublicities(numberViews,price, id).pipe(first()).subscribe(() => {
      this.showToasterSuccess();
      this.modalService.dismissAll();
      this.getofferPublicities();
      this.message= '';
    })}
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isScreenBelow768px = window.innerWidth < 769;
  }
  delete(id: string) {
    this.servicepublicite.deleteofferPublicities(id).pipe(first()).subscribe(() => {
      this.getofferPublicities();
      this.showToasterSuccess2();
      this.modalService.dismissAll();
    })
  }


  update(id: string) {
    this.servicepublicite.update(id).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.showToasterError3();
      this.modalService.dismissAll();
      this.reason = "";
    })
  }

  updaterefuse(id: string) {
    this.servicepublicite.updaterefuse(id, this.user.email).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.showToasterError();
      this.modalService.dismissAll();
      this.reason = "";
    })
  }

  updaterefuseC(id: string, reason: string) {
    this.submitted1 = true;
    if (this.registerForm1.invalid) {
      return;
    }
    this.servicepublicite.updaterefuseC(reason, id, this.user.email).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.showToasterError();
      this.modalService.dismissAll();
      this.submitted = false;
      this.reason = "";
    })
  }
  onKeyPress(event: any, controlName: string) {
    const inputValue = event.target.value;
    const maxLength = controlName === 'numberViews' ? 10 : 10; // Adjust the max length as needed

    if (inputValue.length >= maxLength) {
      event.preventDefault(); // Prevent input if the limit is reached
    }
    if (inputValue.startsWith('0')) {
      const modifiedValue = ' ' + inputValue.slice(1);}
  }
  showToasterError() {
    this.notifyService.showSuccess("تم رفض الطلب  ") 
  }

  showToasterSuccess() {
    this.notifyService.showSuccess(" تم تعديل العرض ")
  }
  showToasterSuccess2() {
    this.notifyService.showSuccess(" تم حذف العرض ")
  }

  showToasterError3() {
    this.notifyService.showSuccess("تم قبول الطلب  ")
  }
  openSm3(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }

  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  open(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
