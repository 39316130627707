<app-menu></app-menu>
<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">

<body>
  <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.actualite == 1">
    <br><br>
    <div class="card">
      <div class="card-body">
        <br>
        <h2 id="h4" class="d-flex align-items-center justify-content-between"> تفاصيل المنشور  </h2>
          <span class="d-flex " *ngIf="post.trend == 'inProgress'" id="btns">
              <button (click)="open(content)" #refuseButton id="submitB">
                  <i class="material-icons" style="color: rgb(206, 6, 6);">cancel</i>
              </button>
              <button (click)="accepttrend();submitButton.disabled = true" #submitButton id="submitB">
                  <i class="material-icons" style="color: green;">check_circle</i>
              </button>
              <button (click)="openSm(content4)" #refuseButton id="submitB">
                  <i class="material-icons" style="color: red;">delete</i>
              </button>
              <ng-template #content4 let-modal>
                  <div class="modal-body" dir="rtl">
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                      </button>
                      <br>
                      <h5 id="h3"> هل تريد حذف هذا المنشور ؟</h5>
                      <div class="d-flex justify-content-center">
                          <button id="btnP" (click)="delete()" type="button" class="btn btn-primary">&nbsp;
                              &nbsp; تأكيد &nbsp; &nbsp;</button>
                      </div>
                  </div>
              </ng-template>
              <ng-template #content let-modal>
                  <div class="modal-content">
                      <div class="modal-header">
                          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                      </div>
                      <div class="modal-body" dir="rtl">
                          <h4 id="h3"> سيتم رفض طلب الترند </h4><br>
                          <div class="justify-content-center d-flex">
                              <button (click)="refusetrend(); submitButton2.disabled = true" #submitButton2 type="button" class="lab btn">رفض الطلب</button>
                          </div><br>
                      </div>
                  </div>
              </ng-template>
          </span>
    
      


        <div class="container posts-content">
          <div class="row">
            <div class="card mb-4" id="pubb">
              <div class="card-body">
                <div class="d-flex mb-3">
                  <img [src]="post.image" class="media-object" alt="">
                  <div class="media-body" style="margin-right: 15px;">
                    <h5>{{post.name}} {{post.codeparrainage}} </h5>
                    <div class="text-muted small">{{ post.created_at | date: 'yyyy/MM/d H:mm' }}</div>
                  </div>
                </div>

                <p *ngIf="post.type == 'text' || post.type == 'imageText' || post.type == 'videoText'">
                  {{post.content[0].text}}
                </p>


                <div class="row" *ngIf="post.type == 'image' || post.type == 'imageText'">
                  <div *ngFor="let image of images?.slice(0, showAll ? images.length : 5); let i = index"
                    class="col-lg-4">
                    <div class="cardImage">
                      <img class="img-fluid imagePost" [src]="image" alt="Image {{ i + 1 }}" />
                    </div>
                  </div>
                  <div *ngIf="images?.length > 5" class="col-lg-4 d-flex align-items-center">
                    <div class="cardImage">
                      <button (click)="toggleView()" class="btn btn1">
                        {{ showAll ? 'اخفاء' : 'اعرضهم جميعا' }}
                      </button>
                    </div>
                  </div>
                </div>

                <div class="row video" *ngIf="post.type == 'video' || post.type == 'videoText'">
                  <video height="400" controls>
                    <source [src]="post.content[0].video" type="video/mp4">
                  </video>
                </div>

              </div>
              <div class="card-footer">
                <a href="javascript:void(0)" class="d-inline-block text-muted" style="margin-left: 10px;">
                  <small class="align-middle">
                    الإعجابات <strong>{{post.reactCount}}</strong> </small>
                </a>
                <a href="javascript:void(0)" class="d-inline-block text-muted">
                  <small class="align-middle">
                    تعليقات <strong>{{post.commentCount}}</strong> </small>
                </a>
              </div>
            </div>
          </div>
        </div>
        <section class="content-item" id="comments" *ngIf="post.comments && post.comments.length > 0">
          <div class="container">

            <div class="media" *ngFor="let comment of post.comments.slice(0, visibleCommentsCount); let i = index">
              <a class="pull-right"><img class="media-object1" [src]="comment.image" alt=""></a>
              <div class="media-body">
                <h5 class="media-heading"> {{comment.name}} {{comment.codeparrainage}} </h5>

                <div class="text-muted small"> {{ comment.created_at.$date.$numberLong | date: 'yyyy/MM/d H:mm' }}</div>
                <p *ngIf="comment.type.text"> {{comment.type.text}} </p>
                <img class="img-fluid imageComment" [src]="comment.type.image" alt="">
                <p *ngIf="comment.type.gift">
                  {{comment.type.gift.text}}
                  <img [src]="comment.type.gift.image" width="50" height="50">
                  <img src="../../../assets/smallmultiplicate.png" width="50" height="50">
                  {{comment.type.gift.giftCount}}
                </p>
                <br>
                <ul class="list-unstyled list-inline media-detail pull-right ">
                  <a href="javascript:void(0)" class="d-inline-block text-muted" style="margin-left: 10px;">
                    <small class="align-middle">
                      الإعجابات <strong>{{comment.reactCount}}</strong> </small>
                  </a>
                  <a href="javascript:void(0)" (click)="toggleReplies(comment._id.$oid)"
                    class="d-inline-block text-muted ">
                    <small class="align-middle">
                      تعليقات <strong>{{comment.commentCount}}</strong> </small>
                  </a>  &nbsp;
                  <a href="javascript:void(0)" class="d-inline-block text-muted">
                  <small class="align-middle response">
                    <a (click)="openSm2(content5)">حذف</a>
                  </small>
                </a>
                  <ng-template #content5 let-modal>
                    <div class="modal-body" dir="rtl">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h5 id="h3"> هل تريد حذف هذا التعليق ؟</h5>
                        <br>
                        <div class="d-flex justify-content-center">
                          <button (click)="deleteComment(post._id, comment._id.$oid)" class="custom-button custom-button-with-image">حذف</button>
                        </div>
                    </div>
                  </ng-template>
                </ul>
              </div>

              <div class="reply " *ngIf="comment.replies && comment.replies.length > 0">
                <br>
                <div *ngFor="let reply of comment.replies" class="media">
                  <a class="pull-right"><img class="media-object1" [src]="reply.image" alt=""></a>
                  <div class="media-body">
                    <h5 class="media-heading"> {{reply.name}} {{reply.codeparrainage}} </h5>
                    <div class="text-muted small"> {{ reply.created_at | date: 'yyyy/MM/d H:mm' }}</div>
                    <br>
                    <strong class="userReply" *ngIf="reply.userReply && !reply.type.text">{{reply.userReply}}</strong>
                    <p *ngIf="reply.type.text"><strong class="userReply"
                        *ngIf="reply.userReply">{{reply.userReply}}</strong> {{reply.type.text}} </p>
                    <img *ngIf="reply.type.image" class="img-fluid imageComment" [src]="reply.type.image" alt="">
                    <p *ngIf="reply.type.gift">
                      {{reply.type.gift.text}}
                      <img [src]="reply.type.gift.image" width="50" height="50">
                      <img src="../../../assets/smallmultiplicate.png" width="50" height="50">
                      {{reply.type.gift.giftCount}}
                    </p>
                    <br>
                    <ul class="list-unstyled list-inline media-detail pull-right ">
                      <a href="javascript:void(0)" class="d-inline-block text-muted" style="margin-left: 10px;">
                        <small class="align-middle">
                          الإعجابات <strong>{{reply.reactCount}}</strong> </small>
                      </a>
                      <a href="javascript:void(0)" (click)="toggleReplies(comment._id.$oid)"
                        class="d-inline-block text-muted ">
                        <small class="align-middle">
                          تعليقات <strong>{{reply.commentCount}}</strong> </small>
                      </a> &nbsp;
                      <a href="javascript:void(0)" class="d-inline-block text-muted" style="margin-left: 10px;">
                      <small class="align-middle response">
                        <a (click)="openSm2(content6)">حذف</a>
                      </small>
                      </a>
                      <ng-template #content6 let-modal>
                        <div class="modal-body" dir="rtl">
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h5 id="h3"> هل تريد حذف هذه الإجابة ؟</h5>
                            <br>
                            <div class="d-flex justify-content-center">
                              <button (click)="deleteReply(post._id, comment._id.$oid, reply._id)" class="custom-button custom-button-with-image">حذف</button>
                            </div>
                        </div>
                    </ng-template>
                    </ul>
                  </div>
                </div>
              </div>

              <br>

            </div>
            <button class="btn btn1" *ngIf="post.comments.length > visibleCommentsCount" (click)="loadMoreComments()">
              اقرأ المزيد
            </button>
          </div>
        </section>

      </div>
    </div>
  </div>

  <div class="container" *ngIf="user.role != 'globale' && user.actualite == 0 " style="padding-top: 10% !important">
    <div class="container" style="width: 100%; height: 400px; max-height: 800px;background-size: contain;
      background-repeat: no-repeat;background-position: center center;display:block;margin-top:auto;
      margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
    </div>
  </div>
</body>