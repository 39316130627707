import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtTokenModule } from '../modules/jwt-token-module';
import { AuthentificationService } from '../services/authentification.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate  {
  constructor(private authService: AuthentificationService,
    private router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.jwtToken.pipe(
      map( (jwtToken: JwtTokenModule) => {
        if (jwtToken.isAuthenticated) {
          return true;
        } else {
          this.router.navigate(['']);
          return false;
        }
      })
    );
  }
  
}