<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '82.5%' : 'auto'}" style="overflow-y: auto">
    <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.users == 1"
        style="padding-right: 72px;">
        <br><br>
        <div class="card">
            <div class="card-body">
                <h2 style="margin-right:12px">قائمة طلبات حذف الحساب للمستخدمين الذين لم يوافقوا على شروط الاستخدام </h2>
                <br>
                <div class="d-flex col-md-2">
                    <input type="search" (search)="search()" class="inputSearch form-control" [(ngModel)]="searchTerm" name="searchTerm">
                    <i (click)=" search()" class="searchIcon material-icons">search</i>
                </div>
                <br>
                <div class="row" style="overflow-x:auto;direction: rtl; ">
                    <table id="dtable" datatable [dtOptions]="dtOptions" class="row-border hover"
                        style="padding: 11px;">
                        <thead>
                            <tr>
                                <th scope="col" style="border-bottom-right-radius: 10px; border-top-right-radius:10px">#
                                </th>
                                <th scope="col">التاريخ</th>
                                <th scope="col">المستخدم</th>
                                <th scope="col">رمز الإحالة</th>
                                <th scope="col" style="border-bottom-left-radius: 10px; border-top-left-radius:10px">
                                    الإجراء
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let usertodelete of userstodelete ; let index = index">
                            <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;">
                                <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                                    {{((currentPage * 10)
                                    -9)+index}}</td>
                                <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                                    {{ usertodelete.created_at | date: 'yyyy-MM-dd H:mm' }}</td>

                                <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                                    {{ usertodelete.name }}</td>
                                <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                                    {{ usertodelete.codeparrainage }}</td>
                                <td style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                                    <div class="d-flex">
                                        <a *ngIf="(user.role == 'globale' || user.user_delete == 1) && isOlderThanFiveDays(usertodelete.created_at)" class="action" (click)="openSm(content)">
                                            <i class="material-icons" style="color: rgb(206, 6, 6);">delete</i>
                                          </a> &nbsp; &nbsp;
    
                                          <a *ngIf="user.role == 'globale' || user.users == 1" class="action" (click)="open(content1)">
                                            <i class="material-icons" style="color: green;">cancel</i>
                                          </a>
                                    </div>
                                </td>
                                <ng-template #content let-modal>
                                    <div class="modal-body" dir="rtl">
                                      <div class="modal-header">
                                        <button type="button" class="btn-close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')"></button>
                                    </div>
                                    <br>
                                      <h5 id="h3"> هل تريد حذف هذا المستخدم؟</h5>
                                      <br>
                                      <div class="d-flex justify-content-center">
                                        <button id="btnP" (click)="delete(usertodelete.codeparrainage.slice(4) , usertodelete.codeparrainage)" type="button" class="btn btn-primary"> &nbsp; &nbsp;
                                          تأكيد &nbsp; &nbsp;</button>
                                      </div>
                                    </div>
                                  </ng-template>

                                  <ng-template #content1 let-modal>
                                    <div class="modal-body" dir="rtl">
                                      <div class="modal-header">
                                        <button type="button" class="btn-close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')"></button>
                                    </div>
                                    <br>
                                      <h5 id="h3"> هل تريد إلغاء طلب حذف الحساب ؟</h5>
                                      <br>
                                      <div class="d-flex justify-content-center">
                                        <button id="btnP" (click)="cancel(usertodelete.codeparrainage)" type="button" class="btn btn-primary"> &nbsp; &nbsp;
                                          تأكيد &nbsp; &nbsp;</button>
                                      </div>
                                    </div>
                                  </ng-template>

                            </tr>


                        </tbody>
                        <tr *ngIf="userstodelete?.length == 0 ">
                            <td colspan="8" class="no-data-available" style="background-color: transparent;">لا توجد
                                بيانات متوفرة
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

    </div>


    <div class="container" *ngIf="user.role != 'globale' && user.users == 0 "
        style="padding-top: 10% !important">

        <div class="container" style="
      width: 100%;
      height: 400px;
      max-height: 800px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      display:block;
      margin-top:auto;
      margin-bottom:auto;
      max-height: 800px;

      background-image: url('assets/droit.png')">



        </div>
    </div>
</body>