import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CadeauService } from 'src/app/services/cadeau.service';
import { first } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { Cadeau } from 'src/app/modules/cadeau';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyService } from 'src/app/services/currency.service';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { StoreServiceService } from 'src/app/services/store-service.service';
import { Store } from 'src/app/modules/store';
import { data } from 'jquery';

import { SharedService } from 'src/app/shared.service';
@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit {

  link1: string = "";
  link2: string = "";
  link3: string = "";
  prix1: string = "";
  prix2: string = "";
  prix3: string = "";
  prix4: string = "";
  img1: string;
  img2: string;
  img3: File;
  images: File;
  images2: File;
  img: string;
  registerForm1: FormGroup;
  registerForm2: FormGroup;
  registerForm3: FormGroup;
  registerForm4: FormGroup;
  submitted1 = false;
  submitted2 = false;
  submitted3 = false;
  submitted4 = false;
  cadeaux: Cadeau[] = [];
  cadeauxCher: Cadeau[] = [];
  cadeauxMoyen: Cadeau[] = [];
  cadeauxNonCher: Cadeau[] = [];
  currentPage = 1;
  itemsPerPage = 4;
  pageSize: number;
  p: number = 1;

  showT = true;
  closeResult = '';
  curr: any = {};
  vide=false;
  user: any = {};
  store: Store[];
  prix: string;
  image: File;
  error:string;
  formData = {
    image: null,
    prix: null
  };
  icons: any;
  message: string='';
  receivedData: any;
  constructor(private fb: FormBuilder, private modalService: NgbModal, private serviceauth: AuthentificationService,
    private servicecadeau: CadeauService,
    private storeService: StoreServiceService,private sharedService: SharedService,
     private servicecurrency: CurrencyService,
    private notifyService: NotificationService) { }

  ngOnInit(): void {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;

    this.registerForm3 = this.fb.group({
      prix3: ['', Validators.required],
      img: ['', Validators.required],
    });

    this.registerForm4 = this.fb.group({
      prix1: ['', Validators.required],
    });
    this.user = this.serviceauth.getCurrentUser();
    this.servicecurrency.getbyid("1")
      .subscribe(data => {
        this.curr = data


      })


    this.geticon();
  }
  get imgControl(): AbstractControl {
    return this.registerForm3.get('img');
  }
  get f() { return this.registerForm1.controls; }
  get g() { return this.registerForm2.controls; }
  get h() { return this.registerForm3.controls; }
  get k() { return this.registerForm4.controls; }





  isFieldEmpty(): boolean {
    return !this.prix3 || this.prix3.trim() === ''||!this.image;
  }


  geticon() {
    this.storeService.getAllStores()
      .pipe(first())
      .subscribe((data:any) => {
        this.store = data.stores;
        this.icons=data.store;
      });
  }




  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }


  supprimer(id:any){
    this.storeService.delete(id).subscribe(res => {
      this.showToasterSuccess2()
      this.modalService.dismissAll();
      setTimeout(() => {
        this.geticon();
      }, 3000)
    })
  }
  modifyStorePrice(id: string, prix: any) {
    this.storeService.updateStore(id, prix)
      .subscribe(res =>{
        this.store = res;
        this.showToasterSuccess3()
        this.modalService.dismissAll();
        setTimeout(() => {
          this.geticon();
        }, 3000)
        this.prix1='';
  });


  }


  showToasterSuccess() {
    this.notifyService.showSuccess(" تم اضافة ايقونة")
  }
  showToasterSuccess2() {
    this.notifyService.showSuccess(" تم حذف ايقونة")
  }

  showToasterSuccess3() {
    this.notifyService.showSuccess(" تم تعديل ايقونة ")
  }


isZeroAllowed = true;

  keyPressNumbers(event) {
      const key = event.key || String.fromCharCode(event.keyCode);

  if (key === '0' && this.isZeroAllowed && event.target.selectionStart === 0) {
    event.preventDefault();
  }

    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }

  }
inputEvent(event: any) {
  const value = event.target.value;
  this.isZeroAllowed = value === '';
}



  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  selectImage(event: any) {
    this.imgControl.updateValueAndValidity();
    var reader = new FileReader();
    const file = event.target.files[0];
    this.images = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link3 = event.target.result
    }
  }

  ajouter() {
     if (this.registerForm3.invalid&&(this.vide==false)) {
    this.vide=true;
    
      this.message=  "يجب إدخال المعطيات";
      
    return ;
  }
   else if (this.registerForm3.invalid&&(this.vide==true)) {
      this.vide=true; this.message=  "يجب إدخال المعطيات";
      
      return ;
    } else {
    const formData = new FormData();
    formData.append('image', this.images);
    formData.append('prix', this.prix3);
    this.storeService.addstore(formData)
      .pipe(first()).subscribe(() => {
        this.prix3 = "";
        this.link3 = "";
        this.img = "";
        this.vide=false;
        this.showToasterSuccess();
        setTimeout(() => {
          this.geticon();
        }, 3000)
        this.images = null;
        this.submitted3 = false;
        this.message='';
      })
  }}


  onSubmit() {
    if (this.registerForm3.invalid) {
      this.vide=true;
      return ;
    }
  }
}
