import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { Notificationadmin } from '../modules/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationadminService {

  uri=environment.apiUrl;
  tok = localStorage.getItem('token');
  token= JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization' : `Bearer ${this.token}`,
    'Accept' : 'application/json'
  });
  constructor(private http: HttpClient) { }
  getAll(): Observable<Notificationadmin[]> {
    const url_get = this.uri+'notificationAdmin';
    return this.http.get<Notificationadmin[]>(url_get, {headers : this.headers});
  }

  addupdate(id:string) {
    const obj = {
     
      };
    this.http.put(`${this.uri}notificationAdmin/update/${id}`,obj, {headers : this.headers})
        .subscribe();
        const url_get = this.uri+'notificationAdmin';
    return this.http.get<Notificationadmin[]>(url_get, {headers : this.headers});
  }

  changetout(): Observable<Notificationadmin[]> {
    const url_get = this.uri+'updateLuNotification';
    return this.http.get<Notificationadmin[]>(url_get, {headers : this.headers});
  }
}
