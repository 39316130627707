<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '82%' : 'auto'}">
  <div class="container" *ngIf="user.role == 'globale' ">
 
    <br> <br>

    <div class="card">
      <div class="card-body">
        <div class="row" style="text-align: right;">


          <h2>قائمة المسؤولين</h2>
        </div>

        <div class="row" dir="rtl">
          <!--div class="col-md-4" id="col4">
                    <button (click)="cherche(searchText)" type="button" class="btn btn-primary"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg></button>
                    <button (click)="reset()" type="button" class="btn btn-danger"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                    </svg></button>
                  </div-->
          <div class="col-md-4">
            <div class="form-group">
              <input type="text" id="search" [(ngModel)]="searchText" placeholder=" البريد الإلكتروني"
                class="form-control" />
            </div>
          </div>
        </div>
        <div class="row" style="overflow-x:auto;direction: rtl;">
          <table class="table table-striped" dir="rtl" class="table row-border hover" style="margin-top: 30px;">
            <thead>
              <tr>
                <th style="border-bottom-right-radius: 10px; border-top-right-radius:10px" scope="col">#</th>
                <th style="text-align:right" scope="col">الإسم</th>
                <th style="text-align:right" scope="col">اللقب</th>
                <th style="text-align:right" scope="col"> الوظيفة</th>
                <th style="text-align:right" scope="col"> البريد الإلكتروني</th>
                <th style="text-align:right" scope="col">رقم الهاتف</th>
                <th style="text-align:right" style="border-bottom-left-radius: 10px; border-top-left-radius:10px" scope="col"></th>
              </tr>
            </thead>
            <tbody *ngFor="let ad of filteredItems | filtreadmin: searchText | slice: pageSize | slice: 0:itemsPerPage; let index = index;">
              <tr  style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;" >
                <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  {{((p * 10) -9)+index}}
                </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  {{ ad.prenom }}

                </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  {{ ad.nom }}
                </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <div *ngIf="ad.role =='globale' ">المسؤول</div>
                  <div *ngIf="ad.role =='particulier' ">إداري</div>
                </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  {{ ad.email }}
                </td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  {{ ad.telephone }}
                </td>

                <td style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <a [routerLink]="['/editAdmin/', ad.id]" ><i class="material-icons" style="color: #30287a;">edit</i> </a> &nbsp; &nbsp;


                  <a (click)="openSm2(content)" *ngIf="user.role =='globale' ">
                    <i class="material-icons" style="color: rgb(206, 6, 6);">delete</i>
                  </a>
                  <ng-template #content let-modal>

                    <div class="modal-body" dir="rtl">
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h5 id="h3"> هل تريد حذف هذا المسؤول؟</h5>

                      <div class="d-flex justify-content-center">
                        <button id="btnP" (click)="delete(ad.id)" type="button" class="btn btn-primary"> &nbsp; &nbsp;
                          تأكيد &nbsp; &nbsp;</button>

                      </div>

                    </div>

                  </ng-template>
                </td>
              </tr>

        
          </tbody>

        </table>
        <ngb-pagination dir="rtl" [maxSize]="5" *ngIf="filteredItems.length > itemsPerPage"
          [collectionSize]="filteredItems.length" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage"
          (pageChange)="onPageChange(currentPage) ; p = $event "></ngb-pagination>
          </div>
      </div>
    </div>

  </div>


  <div class="container" *ngIf="user.role != 'globale' " style="padding-top: 10% !important">

    <div class="container" style="
      width: 100%;
      height: 400px;
      max-height: 800px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      display:block;
      margin-top:auto;
      margin-bottom:auto;
      max-height: 800px;

      background-image: url('assets/droit.png')">
    </div>
  </div>

</body>


