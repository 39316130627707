import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DemandeRecharge } from '../modules/demanderecharge';
import { Recharge } from '../modules/recharge';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DemanderechargeService {

  uri = environment.urlMarket;
  uri1 = environment.apiUrl;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });

  constructor(private http: HttpClient) { }



  getAlldemande(): Observable<DemandeRecharge[]> {

    const url_get = this.uri1 + 'demandesRecharge';
    return this.http.get<DemandeRecharge[]>(url_get, { headers: this.headers });
  }


  getAllrecharge(): Observable<Recharge[]> {

    const url_get = this.uri1 + 'recharges';
    return this.http.get<Recharge[]>(url_get, { headers: this.headers });
  }


  updatedemande(id: string) {
    const obj = {};
    return this.http.put(`${this.uri1}updateDemandeRecharge/` + id, obj, { headers: this.headers });
  }

  updatecodeparrainage(id: string) {
    const obj = {};
    return this.http.post(`${this.uri}SDJHFKJFVRNHJZZDS/` + id, obj, { headers: this.headers });
  }

  updatecodeparrainage2(id: string, cause: string) {
    const obj = {
      cause: cause
    };
    return this.http.post(`${this.uri}SDJHFKJFVRNHJZZDS/` + id, obj, { headers: this.headers });
  }

  updatedemande2(id: string, cause: string) {
    const obj = {
      cause: cause
    };
    return this.http.put(`${this.uri1}updateDemandeRecharge/` + id, obj, { headers: this.headers });
  }

  updatedemanderefuse(reponse: string, id: string, admin : string) {
    const obj = {
      reponse: reponse,
      admin : admin
    };
    return this.http.put(`${this.uri1}updateDemandeRechargeRefuse/${id}`, obj, { headers: this.headers })
  }

  updatedemanderefuseC(id: string, admin : string) {
    const obj = {
      admin : admin};
    return this.http.put(`${this.uri1}updateDemandeRechargeRefuseC/${id}`, obj, { headers: this.headers })
  }


  codeparrainagerefuseC(cause: string, id: string, admin:string) {
    const obj = {
      cause: cause,
      admin : admin
    };
    return this.http.post(`${this.uri}DHTYTUGHFGVFIHRDS/${id}`, obj, { headers: this.headers })
  }

  codeparrainagerefuse(id: string, admin:string) {
    const obj = { admin : admin};
    return this.http.post(`${this.uri}DSGTTFGLODSZXFTWZ/${id}`, obj, { headers: this.headers })
  }
}
