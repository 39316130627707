import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Actualite } from '../modules/actualite';

@Injectable({
  providedIn: 'root'
})
export class ActualiteService {
  uri = environment.apiUrl;
  urlActualite = environment.actualiteUrl;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });

  constructor(private http: HttpClient) {

  }
  getAllactualite(): Observable<Actualite[]> {

    // tslint:disable-next-line:variable-name
    const url_get = this.uri + 'actualitess';
    return this.http.get<Actualite[]>(url_get, { headers: this.headers });
  }
  addactualite(data: FormData) {

    this.http.post(`${this.uri}addactualites`, data, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'actualitess';
    return this.http.get<Actualite[]>(url_get, { headers: this.headers });
  }
  getbyid(id: string) {

    return this
      .http
      .get(`${this.uri}actualites/actualite/${id}`, { headers: this.headers });
  }
  updateactualite(contenu: string, id: string) {
    const obj = {
      contenu: contenu,

    };
    this
      .http
      .put(`${this.uri}updateactualite/${id}`, obj, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'actualitess';
    return this.http.get<Actualite[]>(url_get, { headers: this.headers });
  }
  updateimage(data: FormData, id: string) {

    this
      .http
      .post(`${this.uri}updateimageactualite/${id}`, data, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'actualitess';
    return this.http.get<Actualite[]>(url_get, { headers: this.headers });
  }
  deleteactualite(id: string) {
    return this.http.delete(`${this.uri}deleteactualite/` + id, { headers: this.headers });
  }

  deletepublication(id: string) {
    return this.http.post(`${this.urlActualite}QDKHEJNQZKEFH858UT5KJTNJT/` + id, { headers: this.headers });
  }

  accepttrend(id: string) {
    return this.http.put(`${this.urlActualite}DILJEI854UIRFHUIHFKREJKU4I/` + id, { headers: this.headers });
  }

  refusetrend(id: string) {
    return this.http.put(`${this.urlActualite}ALSJLO3IO494FE3OR4KOIFKRO5/` + id, { headers: this.headers });
  }

  updateNbrLike(nbrLike: number) {
    const obj = {
      nbrlike: nbrLike
    };
    return this
      .http
      .put(`${this.urlActualite}PTIJQSJNCJHNNVXHSXHJZZDEDE`, obj, { headers: this.headers });
  }

  getGlobalVar() {
    return this.http.get(`${this.urlActualite}ZPDEJLOZDJLIQFHEKJHKJERHGK`, { headers: this.headers });
  }

  getPostbyId(id: string) {

    return this
      .http
      .get(`${this.urlActualite}AOZKAAZIJE478YRUIKNDEH2J2V/${id}`, { headers: this.headers });
  }

  getReplies(post_id: string , comment_id) {
    const obj = {
      jqd0945gzdjqbzge: post_id,
      qdxezdszqecf: comment_id
    };
    return this
      .http
      .post(`${this.urlActualite}QCFEQ4SRE54GDR4H7F47YJU4U`,obj,  { headers: this.headers });
  }

  addPost(data: FormData) {
    return this
      .http
      .post(`${this.urlActualite}HKBRDT24R2GVFE5T4ZGV52847`, data, { headers: this.headers });
     
  }

  addComment(comment, post_id) {
    const obj = {
      comment : comment,
      post_id : post_id 
    }
    return this
      .http
      .post(`${this.urlActualite}SFVE5RG58TY7H5YG5IKF54QXD`, obj, { headers: this.headers });
     
  }

  addReply(comment, post_id, refComment) {
    const obj = {
      comment : comment,
      post_id : post_id ,
      refComment : refComment
    }
    return this
      .http
      .post(`${this.urlActualite}8F7E8GVR4E5R4V5eq4cf54erz`, obj, { headers: this.headers });
     
  }

  addReply1(comment, post_id, refComment, refReply) {
    const obj = {
      comment : comment,
      post_id : post_id ,
      refComment : refComment,
      refReply : refReply
    }
    return this
      .http
      .post(`${this.urlActualite}8F7E8GVR4E5R4V5eq4cf54erz`, obj, { headers: this.headers });
     
  }

  addVideo(data: FormData) {
    return this
      .http
      .post(`${this.urlActualite}85EF45ZER4F5ER47G8THJUKIO`, data, { headers: this.headers });
     
  }

  getPosts(page: number , pageSize: number): Observable<any> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());
    return this.http.post(`${this.urlActualite}PI545F45ED4C5456Z4SZDA54E?page=${page}`, { params });
  }

  deletePost(id) {
    return this.http.delete(`${this.urlActualite}KJRCFERNHJF528GHYJ785KU7E/` + id, { headers: this.headers });
  }

  deleteComment(post_id , comment_id) {
    const obj = {
      post_id : post_id,
      comment_id : comment_id
    }
    return this.http.post(`${this.urlActualite}YG45V3QR4GRD4FG5ER45V4R58`, obj , { headers: this.headers });
  }
  updateComment(post_id , comment_id, comment) {
    const obj = {
      post_id : post_id,
      comment_id : comment_id,
      hqdbxjqegdhjzecfj : comment
    }
    return this.http.post(`${this.urlActualite}8ZQD4SX786ZD47C8Q47F87G8G`, obj , { headers: this.headers });
  }

  deleteReply(post_id , comment_id , reply_id) {
    const obj = {
      post_id : post_id,
      comment_id : comment_id,
      reply_id : reply_id
    }
    return this.http.post(`${this.urlActualite}5RFVE524GH5DT5YHJ548JUJ4Z`, obj , { headers: this.headers });
  }

  updateReply(reply_id , comment) {
    const obj = {
      reply_id : reply_id,
      hqdbxjqegdhjzecfj : comment
    }
    return this.http.post(`${this.urlActualite}C486RG478H478TYBH458Y4H78`, obj , { headers: this.headers });
  }

  updatePost( data: FormData, id: string, ) {
    return this
      .http
      .post(`${this.urlActualite}5F48ERG74EJ4TY7JUK475KKI8IK/${id}`, data, { headers: this.headers });
  }

}
