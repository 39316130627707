<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '82%' : 'auto'}">
  <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.signal == 1">
    <br><br>
    <div class="card">
      <div class="card-body" >

        <div class="d-flex" style="justify-content: space-between">
          <h2 id="h4">قائمة الإبلاغات :</h2>
        </div>


        <br>
     
        <div class="row" style="overflow-x:auto;direction: rtl;">
          <table id="dtable" datatable [dtOptions]="dtOptions" class="row-border hover" style="   padding-top: 32px;          ">
            <thead>
              <tr>
                <th  style="border-bottom-right-radius: 10px; border-top-right-radius:10px">#</th>
                <th>التاريخ</th>
                <th>المستخدم</th>
                <th>رقم الهاتف</th>
                <th>رمز الإحالة</th>
                <th> الفريق</th>
                <th> النقاط</th>
                <th> المجوهرات</th>
                <th>عدد الإبلاغات</th>
                <th style="border-bottom-left-radius: 10px; border-top-left-radius:10px"></th>
              </tr>
            </thead>
            <tbody>
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;" *ngFor="let person of persons; let index = index;">
                <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{((currentPage * 10) -9)+index}}</td>
                <td  style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ person.created_at | date: 'yyyy-MM-dd H:mm' }}</td>
                <td  style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">    <div class="d-flex">{{  person.nomprenom }}
                  &nbsp;&nbsp; <img *ngIf="person.badge" height="30" width="30" [src]="person.badge" alt="">
                 </div>
                </td>
                <td  style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ person.numerotele }}</td>
                <td  style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ person.codeparrainage }}</td>
                <td  style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> <a class="action" (click)="openSm(contentGroup); getEquipeUser(person.id)">
                    <i class="material-icons" style="color: green;">supervised_user_circle</i>
                  </a>
                </td>
                <td  style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <div>
                    {{ person.pointAmount }}
                  </div>
                </td>
                <td  style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <div>
                    {{ person.diamondAmount }}
                  </div>
                </td>
                <td  style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <div class="d-flex">
                    <a class="action" (click)="openSm(contentSignal)">
                      <i class="material-icons" style="color: orange;">edit</i>
                    </a>
                    {{ person.nbrSignal }}
                  </div>
                </td>
                <td style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                  <div class="d-flex">

                    <a class="action" (click)="openSm(content)">
                      <i class="material-icons" style="color: rgb(206, 6, 6);">delete</i>
                    </a>
                    <a class="action" (click)="openSm3(content2); diffTime(person.token)">
                      <i class="material-icons">block</i>
                    </a>
                  </div>
                </td>


                <ng-template #contentGroup let-modal>
                  <div class="modal-content" dir="rtl">
                    <button type="button"  aria-label="Close" style="width: 32px;"
                      (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">

                      <div class="card-container">
                        <div class="float-layout">
                          <div class="card-image">

                            <div class="card card1">
                              <h4>عدد الفريق </h4>
                              <h3>{{equipe}} </h3>


                            </div>
                            <img src="assets/equipe.png">
                          </div>
                        </div>
                      </div>

                      <div class="card-container">
                        <div class="float-layout">
                          <div class="card-image">

                            <div class="card card2">
                              <h4>عدد المتصلين</h4>
                              <h3>{{equipeOnline}} </h3>


                            </div>
                            <img src="assets/equipeOnline.png">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>



                <ng-template #content let-modal>
                  <div class="modal-body" dir="rtl">
                    <button type="button"  aria-label="Close"
                                  (click)="modal.dismiss('Cross click')">
                                  <span aria-hidden="true">&times;</span>
                              </button>
                    <h5 id="h3"> هل تريد حذف هذا المستخدم؟</h5>
                    <br>
                    <div class="d-flex justify-content-center">
                      <button id="btnP" (click)="delete(person.id , person.codeparrainage)" type="button" class="btn btn-primary"> &nbsp; &nbsp;
                        تأكيد &nbsp; &nbsp;</button>
                    </div>
                  </div>
                </ng-template>

                <ng-template #contentSignal let-modal>
                  <div class="modal-body" dir="rtl">
                    <button type="button"  aria-label="Close" style="width: 32px;"
                    (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                    <h5 id="h3"> هل تريد حذف الإبلاغات ؟ </h5>
                    <br>
                    <div class="d-flex justify-content-center">
                      <button id="btnP" (click)="deleteSignal(person.id)" type="button" class="btn btn-primary"> &nbsp;
                        &nbsp; تأكيد &nbsp; &nbsp;</button>
                    </div>
                  </div>
                </ng-template>


                <ng-template #content2 let-modal class="testt">
                  <div class="modal-content">
                    <div class="modal-body" dir="rtl">
                      <h4> هل تريد حظر هذا المستخدم؟</h4>
                      <br>
                      <div class="d-flex" style="justify-content: space-between;">
                        <button class="btn bl1" (click)="showBlock()" *ngIf="diffJour <= 0 || person.token == ''">حظر
                        </button>
                        <button class="btn bl4" (click)="deblockUser(person.id)" *ngIf="diffJour >= 0">إلغاء الحظر
                        </button>
                        <button class="btn bl2" aria-label="Close" (click)="modal.dismiss('Cross click')">إلغاء</button>
                      </div>
                      <br>
                      <div style="font-size : 18px" *ngIf="diffJour > 0"> <strong> مدة الحظر المتبقية : {{diffJour}} يوم
                        </strong> </div>

                      <div *ngIf="periode == true">
                        <form [formGroup]="registerForm">
                          <div class="d-flex" style="justify-content: space-between;">
                            <div style="font-size : 18px"> <strong>يرجى تحديد المدة الزمنية للحظر </strong> </div>
                            <input max="99999" min="1" name="periodeBlock" formControlName="periodeBlock" [(ngModel)]="periodeBlock"
                              class="inp form-control" type="number">
                          </div>
                          <div *ngIf="submitted && g['periodeBlock'].errors" class="invalid-feedback">
                            <div style="text-align:left ;" class="err" *ngIf="g['periodeBlock'].errors['required']">يجب
                              ادخال المدة الزمنية للحظر </div>
                          </div>
                          <br>
                          <div class="d-flex justify-content-center">
                            <button (click)="blockUser(person.id)" class="btn bl3">تسجيل</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </tr>

            </tbody>
          </table>
      
        </div>
      </div>
    </div>
  </div>



  <div class="container" *ngIf="user.role != 'globale' && user.signal == 0 " style="padding-top: 10% !important">
    <div class="container" style="
  width: 100%;
  height: 400px;
  max-height: 800px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display:block;
  margin-top:auto;
  margin-bottom:auto;
  max-height: 800px;
  background-image: url('assets/droit.png')">
    </div>
  </div>

</body>

