<meta name="viewport" content="width=device-width, initial-scale=1.0">

<app-menu></app-menu>

<body dir="rtl" [ngStyle]="{'width': receivedData ? '83%' : 'auto'}">
    <div class="container pt-5" *ngIf="user.role == 'globale' || user.actualite == 1" style="
    margin-right: 62px;
    width: 79%;">
        <h2>المنشورات </h2>
        <br>
        <div class="row">
            <div class="col-lg-6">
                <form [formGroup]="registerForm" enctype="multipart/form-data">
                    <div class="bord">
                        <h5 style="text-align: center ;"> إضافة منشور</h5>
                        <br>
                        <textarea maxlength="500" formControlName="texte" id="" [(ngModel)]="texte"
                            style="max-height: 100px" name="" id="" class="form-control" cols="30" rows="3"></textarea>
                        <label for="inputGroupFile01"
                            class=" labelImg col-img d-flex flex-row justify-content-center align-items-center ">
                            <img class="img-fluid img" *ngIf="link !=''" [src]="link" height="150px">
                        </label>
                        <input (change)="selectImage($event)" [(ngModel)]="img" accept="image/*" formControlName="img"
                            style="display:none" type="file" class="custom-file-input" id="inputGroupFile01">
                        <div *ngIf="imageArray.controls.length < 7"
                            class="d-flex flex-row justify-content-center align-items-center">
                            <img src="../../../assets/downloadpic.png" height="50" width="50" style="cursor: pointer;"
                                (click)="addImage()" alt="">
                        </div>
                        <br>
                        <div formArrayName="images">
                            <div class="images-container align-items-center justify-content-center"
                                style="width: 105%;">
                                <button class="button btn-pagination" *ngIf="imageArray.controls.length > 3"
                                    (click)="previousImage()" [disabled]="currentIndex === 0"> <span
                                        class="material-icons">arrow_right</span> </button>
                                <div *ngFor="let imageControl of imageArray.controls; let i = index">
                                    <div class="form-group" *ngIf="i >= currentIndex && i < currentIndex + 3">
                                        <label class="labelImg1  col-img d-flex flex-row  align-items-center"
                                            [for]="'image' + i">
                                            <img class="img-fluid" [src]="imagePreviews[i]" *ngIf="imagePreviews[i]">
                                        </label>
                                        <input type="file" [id]="'image' + i" (change)="onFileSelected($event, i)"
                                            accept="image/*" style="display:none">
                                    </div>
                                </div>
                                <button class="button btn-pagination" *ngIf="imageArray.controls.length > 3"
                                    (click)="nextImage()"
                                    [disabled]="currentIndex === (imageArray.controls.length - 1)"><span
                                        class="material-icons">arrow_left</span></button>
                            </div>
                        </div>
                        <div *ngIf="imageArray.controls.length == 0">
                            <br><br><br><br>
                        </div>
                        <br><br>
                        <div *ngIf="submitted && registerForm.invalid" class="invalid-feedback">
                            <div> يجب إدخال إحدى البياناب</div>
                        </div>
                        <div class="d-flex flex-row justify-content-center align-items-center">
                            <button [disabled]="registerForm.invalid" type="submit" #submitButton1
                                (click)="ajouter(); submitButton1.disabled = true" class="btn1">إضافة</button>
                        </div>
                    </div>
                </form>
            </div>
            <br>
            <div class="col-lg-6">
                <form [formGroup]="registerForm2" enctype="multipart/form-data">
                    <div class="bord">
                        <h5 style="text-align: center ;"> إضافة فيديو</h5>
                        <br>
                        <textarea maxlength="500" formControlName="texte2" id="" [(ngModel)]="texte2"
                            style="max-height: 100px" name="" id="" class="form-control" cols="30" rows="3"></textarea>
                        <label for="inputGroupFile02"
                            class=" labelImg col-img d-flex flex-row justify-content-center align-items-center ">
                            <video class="form-control" *ngIf="video" [src]="link2" controls height="120px"></video>
                        </label>
                        <input (change)="selectImage2($event)" [(ngModel)]="video"
                            accept="video/mp4,video/x-m4v,video/*" formControlName="video" style="display:none"
                            type="file" class="custom-file-input" id="inputGroupFile02">
                        <br><br><br><br><br> <br><br>
                        <div *ngIf="submitted2 && g['video'].errors" class="invalid-feedback">
                            <div *ngIf="g['video'].errors['required']"> يجب إدخال الفيديو </div>
                        </div>
                        <div class="d-flex flex-row justify-content-center align-items-center" id="bt">
                            <button [disabled]="registerForm2.invalid" type="submit" #submitButton2
                                (click)="ajouterVideo(); submitButton2.disabled = true" class="btn1">إضافة</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <br><br>
    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.actualite == 0 " style="padding-top: 10% !important">
        <div class="container" style="width: 100%; height: 400px; max-height: 800px;background-size: contain;
        background-repeat: no-repeat;background-position: center center;display:block;margin-top:auto;
        margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
        </div>
    </div>
</body>