import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  utilisateur: any;
  registerForm: FormGroup;
  submitted = false;
  constructor(private authentificationService: AuthentificationService, private router: Router, private notifyService: NotificationService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  get f() { return this.registerForm.controls; }

  login() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.authentificationService.signin(this.email, this.password).pipe(first()).subscribe(
      data => {
        this.router.navigateByUrl('/conversion');
        // this.router.navigate(['publicite']);
        this.utilisateur = data['user'];
        this.authentificationService.setUser(this.utilisateur, data['token']);

      },
      error => {
        this.showToasterError();
      });
  }

  showToasterError() {
    this.notifyService.showError("الرجاء التحقق من البينات")
  }

}