import { Component, OnInit } from '@angular/core';
import { Publicite } from 'src/app/modules/publicite';
import { PubliciteService } from 'src/app/services/publicite.service';
import { first } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ModalDismissReasons, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment.prod';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-publicite',
  templateUrl: './publicite.component.html',
  styleUrls: ['./publicite.component.css']
})
export class PubliciteComponent implements OnInit {


  publicite: Publicite[] = [];
  nextButtonContent: string = '<img src="../assets/icons8-left-64.png" alt="Next">';
  prevButtonContent: string = '<img src="../assets/right.png" alt="Previous">';
  public collapse = false;
  actu: string;
  image: string = "";
  actu1: string;
  date = new Date;
  submitted3=false;
  registerForm: FormGroup;
  registerForm1: FormGroup;
  submitted = false;
  currentPage = 1;
  itemsPerPage = 7;
  pageSize: number;
  images: File;
  images2: File;
  closeResult = '';
  url = environment.apiUrl;
  link: string = "";
  link2: string = "";
  initialValues: any;
  user: any = {};
  p: number = 1;
  uri = environment.apiUrl;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  dtOptions: DataTables.Settings = {};
  receivedData: any;
  constructor(private servicepublicite: PubliciteService, private route: ActivatedRoute, private serviceauth: AuthentificationService,
    private notifyService: NotificationService, private formBuilder: FormBuilder, public datepipe: DatePipe,private sharedService: SharedService,
    private modalService: NgbModal, private http: HttpClient) { }
    ngOnInit() {
      this.sharedService.data$.subscribe(data => {
        this.receivedData = data;
       
      });
      this.receivedData=false;
      this.dtOptions = {
  
        searching: false,
        language: {
          processing: "يرجى الإنتظار...",
          search: "البحث في القائمة ",
          lengthMenu: " _MENU_ ",
          infoPostFix: "",
          loadingRecords: "Chargement en cours...",
          paginate: {
            first: "",
            previous: "<i class='material-icons'>navigate_next</i> ",
            next: "<i class='material-icons'>navigate_before</i>",
            last: ""
          },
        },
        info: false,
        paging: false,
        ordering: false
      }
    this.user = this.serviceauth.getCurrentUser();
    this.getpublicite()
    this.route.data.subscribe((data: { publicite: Publicite[] }) => this.publicite = data.publicite);
  }

  controle() {

    this.registerForm1 = this.formBuilder.group({
      img1: ['', Validators.required]
    });
  }
  get f() { return this.registerForm.controls; }
  get h() { return this.registerForm1.controls; }

  getpublicite() {
    this.servicepublicite.getAllpublicite().pipe(first()).subscribe(data => { this.publicite = data })
  }

  delete(id: string) {
    this.servicepublicite.deletepublicite(id).pipe(first()).subscribe(() => {
      this.getpublicite();
      this.showToasterError();
      this.modalService.dismissAll();
    })
  }


  ajouter() {
    this.submitted3=true;
    if (!this.images2&&this.submitted3==true) {
      this.image = "يجب تحميل الصورة ";
      return;
    }

    const formData = new FormData();
    formData.append('img', this.images2);
    this.servicepublicite.addpublicite(formData)
      .pipe(first()).subscribe(() => {
        this.getpublicite();
        this.showToasterSuccess();
        this.modalService.dismissAll();
        this.images2 = null;
        this.link2 = "";
        this.submitted = false;
        this.submitted3=false
        this.image = "";

      })
  }

  modifierimage(id) {
    const formData = new FormData();
    formData.append('img', this.images);
    this.servicepublicite.updatepublicite(formData, id)
      .pipe(first()).subscribe(() => {
        this.getpublicite();
        this.showToasterSuccess1();
        this.modalService.dismissAll();
        this.link = "",
          this.images = null;
      })
  }

  showToasterSuccess() {
    this.notifyService.showSuccess("تم اضافة إعلان ")
  }
  showToasterSuccess1() {
    this.notifyService.showSuccess("تم تعديل الإعلان ")
  }
  showToasterError() {
    this.notifyService.showSuccess("تم محو الإعلان ")
  }
  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }
  selectImage(event: any) {

    var reader = new FileReader();
    const file = event.target.files[0];
    this.images = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link = event.target.result
    }

  }

  selectImage2(event: any) {
    this.image = "";
    var reader = new FileReader();
    const file = event.target.files[0];
    this.images2 = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link2 = event.target.result
    }

  }


  open(content) {
    this.modalService.open(content, { centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.actu = '';

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.actu = '';
    });
  }
  open1(content) {
    this.actu = '';
    this.link2 = "";
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.actu = '';
      this.link2 = "";
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.actu = '';
      this.link2 = "";
    });
  }



  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  refresh(): void {
    this.modalService.dismissAll();
    this.getpublicite()
  }
  ferme() {
    this.link = "";
    this.link2 = "";
    this.images = null;
    this.images2 = null;
  }

}
