<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>

<header>
  <div class="container">
    <div class="row justify-content-start">
      <div class="d-flex col-md-2 shadow-border" id="user">

        <img src="../assets/useradmin.png" height="42" width="42" alt="">
        <span style="margin-left:30px"> {{user1.nom}} {{user1.prenom}} </span>

      </div>

    </div>
  </div>


</header>



<div *ngIf="isChecked==false" class="hidden-div">
  <li (click)="sendDataToPage()">
    <label for="sideMenuHandler" class="menu-icon" style="z-index: 999">
      <img src="../assets/arrowright.png" alt="" height="35" width="46">
    </label>
    <input type="checkbox" class="side-menu-handler" id="sideMenuHandler2" checked>
  </li>
</div>





<div *ngIf="isChecked">
  <li (click)="sendDataToPage2()"><label for="sideMenuHandler" class="menu-icon1" style="z-index: 999">

      <img src="../assets/arrowleft.png" alt="" height="35" width="47" >
    </label>
    <input type="checkbox" class="side-menu-handler" id="sideMenuHandler2" checked (click)="close()">
  </li>
</div>

<input type="checkbox" class="side-menu-handler" id="sideMenuHandler" checked [(ngModel)]="isChecked">

<nav class="main-menu" id="sidebar" style="z-index: 1000;">
  <div *ngIf="isChecked" class="list">

    <a id="home" routerLink="/conversion" style="margin-top:16px"><img src="../assets/logosntatdashboard.png"
        alt="Avatar" style="margin-left: 11%; height: 75px; width: 195px; margin-top:00px" class="avatar"></a>
  </div>
  <div *ngIf="isChecked==false">
    <a id="home" routerLink="/conversion" style="margin-right:0px"> <img class="home2" src="../assets/logosntatmini.png"
        style="margin-left: -43%; margin-top: -8px;" alt="Avatar" height="75px" width="49px" class="avatar"></a>
  </div>

  <ul>
    <div *ngIf="isChecked==false">
      <li class="pub" style="width: 38px;">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub" name="dropdown-sub" [(ngModel)]="Cheked5"
          (click)="cheked11()" />
        <label id="change" class="for-dropdown-sub" for="dropdown-sub"
          style="margin-left: -3px;height: 31px;margin-top: 14px;width: 70px;">
          <i class="fa fa-usd" style="color: rgb(0, 110, 255);margin-left: 22px;" aria-hidden="true"></i>

        </label>

      </li>
    </div>
    <div *ngIf="isChecked" class="list">
      <li class="pub" style="margin:-30 px" (click)="cheked01()">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub" name="dropdown-sub" [(ngModel)]="Cheked5" />
        <label id="change" class="for-dropdown-sub" for="dropdown-sub"
          style="margin-left: 38px;margin: 6px;margin-left: 10px;">
          <i class="fa fa-caret-down" id="faicon" aria-hidden="true" id="faicon"></i> <span
            style="margin-left:  94px; ;margin-top: 1px;">التحويل</span> <i class="fa fa-usd" aria-hidden="true"
            style="color: rgb(0, 110, 255);"></i>

        </label>
        <div class="section-dropdown-sub" style="margin-right:13px">
          <a routerLinkActive="active" routerLink="/conversion" style="margin-right: 13;"><span
              style="vertical-align: middle; margin-left:47px; margin-top: 0px;">طلبات التحويل </span><i
              class="fa fa-money" aria-hidden="true" style="color: #0E9F0E;"></i>

          </a>
          <a routerLinkActive="active" routerLink="/allconversions"><span
              style="margin-left:38px ;margin-top: -10px;">سجل التحويلات </span> <i class="fa fa-list-alt"
              aria-hidden="true" style="color: #BFCC07;"></i> </a>
        </div>
      </li>
    </div>

    <div *ngIf="isChecked" class="list" style="margin:-30 px">
      <li (click)="cheked02()">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub-1" name="dropdown-sub-1" [(ngModel)]="Cheked1" />
        <label id="pub" class="for-dropdown-sub" for="dropdown-sub-1"
          style="margin-left: 38px;margin: 6px;margin-left: 10px;">

          <i class="fa fa-caret-down" aria-hidden="true" id="faicon"></i> <span
            style="margin-left:94px; margin-top:3px;">الوكلاء</span>
          <i class="fa fa-users" aria-hidden="true" style="color: #099292;"></i>
        </label>
        <div class="section-dropdown-sub">
          <a routerLinkActive="active" routerLink="/delegates-list"> <span style="margin-left:59px ;margin-top: 0px;">
              قائمة الوكلاء</span> <i class="fa fa-list-ol" aria-hidden="true" style="color: #5526D8;"></i>
          </a>
          <a routerLinkActive="active" routerLink="/delegates-demands"> <span
              style="margin-left:52px ;margin-top: -10px;">طلبات الوكلاء</span><i class="fa fa-bars" aria-hidden="true"
              style="color: #0D89DC;"></i>


          </a>
        </div>

      </li>
    </div>
    <div *ngIf="isChecked==false">
      <li style="height:18px;width: 38px;">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub-1" name="dropdown-sub-1" [(ngModel)]="Cheked1"
          (click)="cheked22()" />
        <label id="change" class="for-dropdown-sub" for="dropdown-sub-1"
          style="margin-left: -8px;height: 33px;margin-top: 15px;width: 78px;">
          <i class="fa fa-users" style="color: #099292;margin-top: -3px;margin-left: 24px;" aria-hidden="true"></i>


        </label>

      </li>
    </div>

    <div *ngIf="isChecked==false">
      <li style="width: 38px;">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub3"  name="dropdown-sub3" [(ngModel)]="Cheked3"
          (click)="cheked44()">
        <label id="change" class="for-dropdown-sub" for="dropdown-sub3"
          style="margin-left: 0px;height: 34px;margin-top: 14px;width: 68px;"> <i class="fa fa-comments"
            aria-hidden="true" style="color: #51A174;margin-left:16px"></i>
          <span style="margin-left:80px ;   "></span>
        </label>

      </li>
    </div>



    <div *ngIf="isChecked" class="list" style="margin:-30 px">
      <li (click)="cheked03()">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub3" name="dropdown-sub4" [(ngModel)]="Cheked3" />
        <label id="pab" class="for-dropdown-sub" for="dropdown-sub3"
          style="margin-left: 38px;margin: 6px;margin-left: 10px;">
          <i class="fa fa-caret-down" aria-hidden="true" id="faicon"></i> <span
            style="margin-left:94px ;margin-top: 0px;">الدردشة</span>
          <i class="fa fa-comments" aria-hidden="true" style="color: #51A174;"></i>
        </label>
        <div class="section-dropdown-sub">

          <a routerLinkActive="active" routerLink="/gift-actualite"><span style="margin-left:47px ;margin-top: -1px;">
              الهدايا الاخبار</span> <i class="fa fa-gift" aria-hidden="true" style="color: #AC1FE6;"></i>
          </a>
          <a routerLinkActive="active" routerLink="/gifts"><span style="margin-left:47px ;margin-top: -1px;"> الهدايا
              الغرف</span><img src="../assets/giftroom.png" style="margin-right: 38px;">
          </a>
          <a routerLinkActive="active" routerLink="/themes"> <span
              style="margin-left:70px ;margin-top: -2px;">الخلفيات</span> <i class="fa fa-picture-o" aria-hidden="true"
              style="color: #E88181;"></i>
          </a>
        </div>
      </li>
    </div>



    <div *ngIf="isChecked" class="list" style="margin:-30 px">
      <li (click)="cheked04()">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub4" name="dropdown-sub4" [(ngModel)]="Cheked4" />
        <label id="pab" class="for-dropdown-sub" for="dropdown-sub4"
          style="margin-left: 38px;margin: 6px;margin-left: 10px;">
          <i class="fa fa-caret-down" aria-hidden="true" id="faicon"></i> <span
            style="margin-left:80px ;margin-top: 0px;">الإعلانات</span>
          <i class="fa fa-film" aria-hidden="true" style="color: #69BC21;"></i>
        </label>
        <div class="section-dropdown-sub">
          <a routerLinkActive="active" routerLink="/publicite" style=" height: 50px;"> <span
              style="margin-left:90px ;margin-top: -3px; padding: 6px;">صور</span><i class="fa fa-camera-retro"
              aria-hidden="true" style="color: #BEBE1E;"></i>
          </a>
          <a routerLinkActive="active" routerLink="/offerPublicities"> <span
              style="margin-left:30px ;margin-top: 2px;">طلبات الإشهارات </span><i class="fa fa-bullhorn"
              aria-hidden="true" style="color: rgb(0, 140, 255);"></i>
          </a>
          <a routerLinkActive="active" routerLink="/userPublicities"> <span
              style="margin-left:30px ;margin-top: 1px;">قائمة الإشهارات </span><i class="fa fa-eye" aria-hidden="true"
              style="color: #6FC1C4;"></i>
          </a>
        </div>
      </li>
    </div>

    <div *ngIf="isChecked==false">
      <li style="width: 38px;">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub4" name="dropdown-sub4" [(ngModel)]="Cheked4"
          (click)="cheked55()" />
        <label id="change" class="for-dropdown-sub" for="dropdown-sub4"
          style="margin-left: 0px;height: 34px;margin-top: 14px;width: 68px;"> <i class="fa fa-film" aria-hidden="true"
            style="color: #69BC21; margin-left: 16px;"></i>
          <span style="margin-left:40px ;"></span> </label>
      </li>

    </div>


    <div *ngIf="isChecked" class="list" style="margin:-30 px">
      <li (click)="cheked05()">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub2" name="dropdown-sub2" [(ngModel)]="Cheked2" />
        <label id="pub" class="for-dropdown-sub" for="dropdown-sub2"
          style="margin-left: 38px;margin: 6px;margin-left:10px;">

          <i class="fa fa-caret-down" aria-hidden="true" id="faicon"></i><span
            style="margin-left:94px ;margin-top: -3px;">الحظر
          </span><i class="fa fa-ban" aria-hidden="true" style="color: #AC1EDE;"></i>
        </label>
        <div class="section-dropdown-sub">
          <a routerLinkActive="active" routerLink="/list-blocks"><span
              style="margin-left:95px ;margin-top: -5px; ">الحظر</span> <i class="fa fa-lock" aria-hidden="true"
              style="color: #868ACB; margin-right:45pxx"></i>
          </a>
          <a routerLinkActive="active" routerLink="/signal-list"><span
              style="margin-left:75px ;margin-top: -10px;">الإبلاغات</span> <i class="fa fa-exclamation-triangle"
              aria-hidden="true" style="color: #AC1EDE;"></i> </a>
        </div>
      </li>
    </div>
    <div *ngIf="isChecked==false">
      <li style="width: 38px;">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub2" name="dropdown-sub2" [(ngModel)]="Cheked2"
          (click)="cheked33()" />
        <label id="change" class="for-dropdown-sub" for="dropdown-sub2"
          style="margin-left: 0px;height: 34px;margin-top: 14px;width: 68px;"> <i class="fa fa-ban" aria-hidden="true"
            style="color: #AC1EDE;margin-left: 17px;"></i>
          <span style="margin-left:80px ;margin-top: -10px;"></span>

        </label>

      </li>
    </div>


    <div *ngIf="isChecked" class="list" style="margin:-30 px">
      <li (click)="cheked06()">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub7" name="dropdown-sub4" [(ngModel)]="Cheked7" />
        <label id="pab" class="for-dropdown-sub" for="dropdown-sub7"
          style="margin-left: 38px;margin: 6px;margin-left: 10px;">
          <i class="fa fa-caret-down" aria-hidden="true" id="faicon"></i> <span
            style="margin-left:94px ;margin-top: 0px;">المتجر</span>
          <i class="fa fa-shopping-cart"></i>
        </label>
        <div class="section-dropdown-sub">
          <a routerLinkActive="active" routerLink="/events" style="height:45px"> <span
              style="margin-left:44px ;margin-top: 0px; padding: 6px;">فعاليات</span><img src="../assets/events.png"
              alt="" style="margin-right: 33px;">
          </a>
          <a routerLinkActive="active" routerLink="/carte-mobile"> <span
              style="margin-left:30px ;margin-top: -3px;">بطاقات الهاتف </span><img src="../assets/rechargemobile.png"
              style="margin-right: 33px;">
          </a>
         <a routerLinkActive="active" routerLink="/product-list"> <span
              style="margin-left:30px ;margin-top: -10px;">المنتجات </span><img src="../assets/product.png"
              style="margin-right: 33px;">
          </a>
           <a routerLinkActive="active" routerLink="/pret"> <span
            style="margin-left:30px ;margin-top: -10px;">القروض </span>
            <i class="fa fa-money" aria-hidden="true" style="margin-right: 30px;"></i>

        </a> 
          <a routerLinkActive="active" routerLink="/store"> <span style="margin-left:30px ;margin-top: -10px;"> متجر
              الايقونات</span><img src="../assets/store.png" style="margin-right: 33px;">
          </a>
        </div>
      </li>
    </div>

    <div *ngIf="isChecked==false">
      <li (click)="cheked77()">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub4" name="dropdown-sub7" [(ngModel)]="Cheked7" />
        <label id="change" class="for-dropdown-sub" for="dropdown-sub4"
          style="margin-left: 0px;height: 32px;margin-top: 14px;"> <i class="fa fa-shopping-cart"
            style="margin-left:15px"></i>
          <span style="margin-left:40px ;"></span> </label>
      </li>

    </div>












    <div *ngIf="isChecked==false">
      <li (click)="cheked88()">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub3" name="dropdown-sub3" [(ngModel)]="Cheked3">
        <label id="change" class="for-dropdown-sub" for="dropdown-sub3"
          style="margin-left: 0px; height:33px ;margin-top:14px"> <i class="fa fa-info-circle" aria-hidden="true"
            style="color: #51A174;margin-left:18px"></i>
          <span style="margin-left:80px ;   "></span>
        </label>

      </li>
    </div>


    <div *ngIf="isChecked" class="list" style="margin:-30 px">
      <li (click)="cheked07()">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub8" name="dropdown-sub8" [(ngModel)]="Cheked8" />
        <label id="pab" class="for-dropdown-sub" for="dropdown-sub8"
          style="margin-left: 38px;margin: 6px;margin-left: 10px;">
          <i class="fa fa-caret-down" aria-hidden="true" id="faicon"></i> <span
            style="margin-left:94px ;margin-top: 0px;">الأخبار</span>
          <i class="fa fa-info-circle" aria-hidden="true" style="color: #51A174;"></i>
        </label>
        <div class="section-dropdown-sub">

          <a routerLinkActive="active" routerLink="/posts-list"><span style="margin-left:47px ;margin-top: -1px;">
              المنشورات</span> <i class="fa fa-comment" aria-hidden="true" style="color: #AC1FE6;"></i>
          </a>
          <a routerLinkActive="active" routerLink="/add-post"><span style="margin-left:47px ;margin-top: -1px;"> إضافة
              منشور</span> <i class="fa fa-comment" aria-hidden="true" style="color: #1f33e6;"></i>
          </a>
          <a routerLinkActive="active" routerLink="/trend-demands"> <span
              style="margin-left:50px ;margin-top: -2px;">طلبات الترند</span> <i class="fa fa-picture-o"
              aria-hidden="true" style="color: #E88181;"></i>
          </a>
          <a routerLinkActive="active" routerLink="/trend-list"> <span
            style="margin-left:50px ;margin-top: -2px;">قائمة الترند</span> <i class="fa fa-picture-o"
            aria-hidden="true" style="color: #E88181;"></i>
        </a>
        </div>
      </li>
    </div>



    <div *ngIf="isChecked==false">
      <li (click)="cheked99()">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub3" name="dropdown-sub3" [(ngModel)]="Cheked9">
        <label id="change" class="for-dropdown-sub" for="dropdown-sub3"
          style="margin-left: 0px; height:33px ;margin-top:14px">  
                   <i class="fa fa-key" aria-hidden="true" style="color: #AC1FE6;; margin-left: 14px;" ></i>

          <span style="margin-left:80px ;   "></span>
        </label>

      </li>
    </div>



    <div *ngIf="isChecked" class="list" style="margin:-30 px">
      <li  (click)="cheked08()">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub9" name="dropdown-sub8" [(ngModel)]="Cheked9" />
        <label id="pab" class="for-dropdown-sub" for="dropdown-sub9"
          style="margin-left: 38px;margin: 6px;margin-left: 10px;">
          <i class="fa fa-caret-down" aria-hidden="true" id="faicon"></i> <span
            style="margin-left:94px ;margin-top: 0px;">رموز الاحالة</span>
          <i class="fa fa-key" aria-hidden="true" style="color:  #AC1FE6;"></i>
        </label>
        <div class="section-dropdown-sub">

          <a routerLinkActive="active" routerLink="/codeparrainage-demands"><span style="margin-left:47px ;margin-top: -1px;">
              قائمة الطلبات </span> <i class="fa fa-sort-numeric-asc" aria-hidden="true" style="color: #bdff71;"></i>
          </a>
          <a routerLinkActive="active" routerLink="/codeparrainage"><span style="margin-left:47px ;margin-top: -1px;"> إضافة
              رمز احالة</span> <i class="fa fa-plus" aria-hidden="true" style="color: #f55e72;"></i>
          </a>
        
        </div>
      </li>
    </div>

    <div *ngIf="isChecked==false">
      <li (click)="cheked10()">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub3" name="dropdown-sub3" [(ngModel)]="Cheked10">
        <label id="change" class="for-dropdown-sub" for="dropdown-sub3"
          style="margin-left: 0px; height:33px ;margin-top:14px">  
                   <i class="fa fa-gamepad" aria-hidden="true" style="color: #7a7a7a;; margin-left: 14px;" ></i>

          <span style="margin-left:80px ;   "></span>
        </label>

      </li>
    </div>



    <div *ngIf="isChecked" class="list" style="margin:-30 px">
      <li (click)="cheked09()" >
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub10" name="dropdown-sub8" [(ngModel)]="Cheked10" />
        <label id="pab" class="for-dropdown-sub" for="dropdown-sub10"
          style="margin-left: 38px;margin: 6px;margin-left: 10px;">
          <i class="fa fa-caret-down" aria-hidden="true" id="faicon"></i> <span
            style="margin-left:94px ;margin-top: 0px;">الالعاب</span>
          <i class="fa fa-gamepad" aria-hidden="true" style="color:  #7a7a7a;"></i>
        </label>
        <div class="section-dropdown-sub">

          <a routerLinkActive="active" routerLink="/questions"><span style="margin-left:47px ;margin-top: -1px;">
            المسابقات </span> <i class="fa fa-question-circle" aria-hidden="true" style="color: #f55e72;"></i>
          </a>
          <a routerLinkActive="active" routerLink="/fortune-wheel"><span style="margin-left:47px ;margin-top: -1px;">
            عجلة الحظ </span> <i class="fa fa-repeat" aria-hidden="true" style="color: #5ef5f5;"></i>
          </a>
        
        </div>
      </li>
    </div>

    <div *ngIf="isChecked" class="list" style="margin:-30 px">
      <li  (click)="cheked100()">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub11" name="dropdown-sub8" [(ngModel)]="cheked11" />
        <label id="pab" class="for-dropdown-sub" for="dropdown-sub11"
          style="margin-left: 38px;margin: 6px;margin-left: 10px;">
          <i class="fa fa-caret-down" aria-hidden="true" id="faicon"></i> <span
            style="margin-left:94px ;margin-top: 0px;"> المستخدم</span>
            <i class="fa fa-user" aria-hidden="true" style="color: #CFA310;"></i>
        </label>
        <div class="section-dropdown-sub">

          <a routerLinkActive="active" routerLink="/users"><span style="margin-left:47px ;margin-top: -1px;">
            قائمة المستخدمين </span> <i class="fa fa-sort-numeric-asc" aria-hidden="true" style="color: #bdff71;"></i>
          </a>
          <a routerLinkActive="active" routerLink="/usersToDelete"><span style="margin-left:47px ;margin-top: -1px;">
            طلبات حذف الحساب</span> <i class="fa fa-plus" aria-hidden="true" style="color: #f55e72;"></i>
          </a>
        
        </div>
      </li>
    </div>

    <div *ngIf="isChecked==false">
      <li (click)="cheked100()">
        <input class="dropdown-sub" type="checkbox" id="dropdown-sub11" name="dropdown-sub11" [(ngModel)]="Cheked11">
        <label id="change" class="for-dropdown-sub" for="dropdown-sub11"
          style="margin-left: 5px; height:33px ;margin-top:14px">  
                   <i class="fa fa-user" aria-hidden="true" style="color: #CFA310;; margin-left: 14px;" ></i>

          <span style="margin-left:80px ;   "></span>
        </label>

      </li>
    </div>

<!-- 
    <div *ngIf="isChecked" class="list">
      <li>
        <a id="users" class="nav-link" routerLinkActive="active" style="color: #b3b3b3;" routerLink="/users">

          <span class="nav-text">
            المستخدم&nbsp;&nbsp;
          </span>&nbsp;
          <i class="fa fa-user" aria-hidden="true" style="color: #CFA310;"></i>
        </a>
      </li>
    </div> -->



    <!-- <div *ngIf="isChecked==false">
      <li style="margin-left: 8px;">
        <a id="users" class="nav-link" routerLinkActive="active" style="color: #b3b3b3;margin-left:-9px;width: 324px;"
          routerLink="/users">
          <i class="fa fa-user" aria-hidden="true" style="color: #CFA310;"></i>

          <span class="nav-text">
            المستخدم
          </span>
        </a>
      </li>
    </div> -->

 


    <div *ngIf="isChecked==false">

      <li style="margin-left: 10px;">
        <a id="counters" class="nav-link " routerLinkActive="active"
          style="color: #b3b3b3; margin-left:-9px;width: 324px;" routerLink="/counters">
          <i class="fa fa-tachometer" style="color: #25c5ec;" aria-hidden="true"></i>

          <span class="nav-text">
            عروض العداد
          </span>

        </a>
      </li>
    </div>

    <div *ngIf="isChecked" class="list">

      <li>
        <a id="counters" class="nav-link " routerLinkActive="active" style="color: #b3b3b3;" routerLink="/counters">


          <span class="nav-text">
            عروض العداد &nbsp;&nbsp;
          </span> &nbsp;
          <i class="fa fa-tachometer" style="color: #25c5ec;" aria-hidden="true"></i>
        </a>
      </li>
    </div>


    <div *ngIf="isChecked" class="list">

      <li>
        <a id="counters" class="nav-link" routerLinkActive="active" style="color: #b3b3b3;" routerLink="/support">
          <span class="nav-text">
            الدعم &nbsp; &nbsp;
          </span>
          <img src="../assets/message.png" class="active-image" style="margin-left: 10px;">
        </a>
      </li>
    </div>

    <div *ngIf="isChecked==false" class="list">

      <li>
        <a id="counters" class="nav-link " routerLinkActive="active" style="color: #b3b3b3;margin-left:0px"
          routerLink="/support">
          <span class="nav-text">
          </span>
          <img src="../assets/message.png" style="margin-right: 33px; margin-right: 207px;">
        </a>
      </li>
    </div>



    <div *ngIf="isChecked==false">

      <li style="margin-left: 16px;" (click)="cheked66()">
        <a id="counters" class="nav-link " routerLinkActive="active"
          style="color: #b3b3b3;width: 78px;margin-left: -23px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" class="bi bi-person"
            style="margin-right:28px" viewBox="0 0 16 16">
            <path
              d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
          </svg>


        </a>
      </li>
    </div>
    <div *ngIf="isChecked==false">

      <li style="margin-left: 0px;">
        <a id="counters" class="nav-link " routerLinkActive="active"
          style="color: #b3b3b3;width: 92px;margin-right: -44px;" (click)="onlogout()">
          <div style="
    margin-left: 9px;
"> <i class="fa fa-power-off fa-2x" style="padding: 40%; margin-left: 20px;"></i></div>


        </a>
      </li>
    </div>


    <!--

            </ul>


            <ul class="logout" style="list-style: none; padding: 0; display: flex; align-items: center; margin-left: -353px; ">

              <div *ngIf="isChecked==false">
                 <li class="inline-li" style="width: 150%; margin-right: 3px;">
                  <input class="dropdown-sub" type="checkbox" id="dropdown-sub6" name="dropdown-sub6"[(ngModel)]="Cheked6" >
                  <label class="for-dropdown-sub" for="dropdown-sub6" style="margin-left: 17px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" class="bi bi-person" viewBox="0 0 16 16">
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                    </svg>
                    <br>

                   </label>



                  <i class="uil uil-plus"></i>
                </li>
<li></li>
                <li  style="width: 140%; margin-left: 21px; "> <label for="" (click)="onlogout()">
                  <i class="fa fa-power-off fa-2x" style="margin-left: 10px; padding:40px;" ></i></label></li>
              </div>
                <div *ngIf="isChecked" class="list" style="margin-top: -49px;">
                  <li class="inline-li" style="width: 150%; margin-top: -7px; ">
                    <input class="dropdown-sub" type="checkbox" id="dropdown-sub6" name="dropdown-sub6"[(ngModel)]="Cheked6"/>

                    <div class="section-dropdown-sub" style="margin-left: 343px; margin-top: 80px; ">
                      <a id="contact"  routerLinkActive="active" routerLink="/support"> الدعم </a>
                      <a id="admin"  routerLinkActive="active" routerLink="/admin">قائمة المسؤولين </a>
                      <a id="add-admin"  routerLinkActive="active" routerLink="/add-admin">إضافة مسؤول </a>
                      <a id="profile"  routerLinkActive="active" routerLink="/profile">الملف الشخصي</a>
                    </div>

                  </li>
                </div>



                <div *ngIf="((isChecked)&&(Cheked6==false))" class="list" style="margin-top: -49px;" >
                  <li class="inline-li" style="width: 90%; margin-top: 15px; margin-left: -35px;" id="log" >
                    <input class="dropdown-sub" type="checkbox" id="dropdown-sub6" name="dropdown-sub6" [(ngModel)]="Cheked6"/>
                        <label class="for-dropdown-sub" for="dropdown-sub6" id="log"style="
                        margin-top: 20px;
                    " >
                          <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="#000000" class="bi bi-person" viewBox="0 0 16 16" style="margin-top: -3px;">
                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                          </svg> <a id="logout" class="dropdown-item" style="cursor: pointer;  z-index: 1;   "(click)="onlogout()">
                            <i class="fa fa-power-off fa-2x"></i>
                            </a></label>

                  </li>
                  </div>

                   <div *ngIf="((isChecked)&&(Cheked6))" class="list" style="margin-top: -79px;">
                    <li class="inline-li" style="width: 90%; margin-top: 60px ;" id="log" >
                      <input class="dropdown-sub" type="checkbox" id="dropdown-sub6" name="dropdown-sub6" [(ngModel)]="Cheked6"/>
                          <label class="for-dropdown-sub" for="dropdown-sub6" id="log" style="
                          margin-top: 16px;
                      ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#000000" class="bi bi-person" viewBox="0 0 16 16" style="margin-top: -212px;margin-right:50px">
                              <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                            </svg></label>
                            <a id="logout" class="dropdown-item" style="cursor: pointer; align-items: left; position: relative; z-index: 1;margin-top: -206px;  margin-right: 200px;" (click)="onlogout()">
                              <i class="fa fa-power-off fa-2x"id="logot" style="margin-top: 20%;" ></i>
                              </a>
                    </li>
                    </div> -->


    <div *ngIf="isChecked" class="special-dropdown">
      <li style="width: 12%;margin-left: 142px;"> <label for="" (click)="onlogout()">
          <i class="fa fa-power-off fa-2x" style="margin-left: 10px; padding:40px;"></i></label></li>
      <div class="dropdown" style=" margin-left: 122px;top: -89px;">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#000000" class="bi bi-person"
          viewBox="0 0 16 16">
          <path
            d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
        </svg>
        <div class="dropdown-content" style="height:148px; box-shadow: 8px 8px 16px 0px rgba(0,0,0,0.2); ">

          <a id="admin" routerLinkActive="active" routerLink="/admin" style="height:30px"> <span>قائمة المسؤولين
            </span></a>
          <a id="add-admin" routerLinkActive="active" routerLink="/add-admin" style="height:30px"><span>إضافة مسؤول
            </span></a>
          <a id="profile" routerLinkActive="active" routerLink="/profile" style="height:30px"> <span>الملف
              الشخصي</span></a>
        </div>
      </div>



    </div>
  </ul>
  <ul class="content">
    <li>


  </ul>
</nav>


</html>