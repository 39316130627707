import { Component, OnInit } from '@angular/core';
import { CurrencyService } from 'src/app/services/currency.service';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/shared.service';
import { ActivatedRoute } from '@angular/router';
import { UtilisateurService } from 'src/app/services/utilisateur.service';
import { first } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-usertodelete',
  templateUrl: './usertodelete.component.html',
  styleUrls: ['./usertodelete.component.css']
})
export class UsertodeleteComponent implements OnInit {
  uri = environment.apiUrl;
  userstodelete: any[];
  MD = new Array();
  curr: any = {};
  max: number;
  montant1: number;
  id: string = "";
  searchTerm: string;
  searchText: string;
  currentPage: number;
  user: any = {};
  receivedData:boolean;
  closeResult = '';
  col: string;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  }); 
  url1 = environment.urlStorage;
  dtOptions: DataTables.Settings = {};
  constructor(private serviceutilisateur: UtilisateurService,private modalService: NgbModal, private notifyService: NotificationService,
    private serviceauth: AuthentificationService, private route: ActivatedRoute,private sharedService: SharedService,private servicecurrency: CurrencyService, private http: HttpClient) { }

  ngOnInit() {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
    });
    this.receivedData=false;
    this.dtOptions = {
      pagingType: 'simple_numbers',
      language: {
        processing: "يرجى الإنتظار...",
        searchPlaceholder: 'البحث',search: "",
        lengthMenu: " _MENU_ ",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        paginate: {
          first: "",
          previous: "<i class='material-icons'>navigate_next</i> ",
          next: "<i class='material-icons'>navigate_before</i>",
          last: ""
        },
      },
      info: false,
      paging: true,
      ordering: false,
      serverSide: true,
      processing: true,
      searching:false,
      ajax: (dataTablesParameters: any, callback) => {
        this.currentPage = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        const rowData = {
          no_of_records: dataTablesParameters.length,
          page: this.currentPage,
          group_id: 1
        };
        const obj = { searchTerm: this.searchTerm }
        this.http.post<DataTablesResponse>(this.uri + 'usersToDelete', Object.assign(dataTablesParameters, obj), { headers: this.headers })
          .subscribe(resp => {
            const details = JSON.parse(JSON.stringify(resp.original));
            this.userstodelete = details.data;
            callback({
              recordsTotal: details.recordsTotal,
              recordsFiltered: details.recordsFiltered,
              data: [],
            });
          });
      },
      columns: [
        { data: "created_at" },
        { data: "name" }
      ],
    };
    this.user = this.serviceauth.getCurrentUser();
    this.servicecurrency.getbyid("1")
      .subscribe(data => {
        this.curr = data
        this.montant1 = this.curr.montantparpoint;
        this.max = this.curr.maxmontant;
      })
  }

  open(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openSm(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  delete(id: string , codeparrainage:string) {
    this.serviceutilisateur.deleteusers(id , codeparrainage).pipe(first()).subscribe(() => {
      this.modalService.dismissAll();
      $('#dtable').DataTable().draw(false);
      this.showToasterError();
    })
  }

  cancel( codeparrainage:string) {
    console.log(codeparrainage)
    this.serviceutilisateur.canceldeleteusers(codeparrainage).pipe(first()).subscribe(() => {
      this.modalService.dismissAll();
      $('#dtable').DataTable().draw(false);
      this.showToasterError();
    })
  }


  showToasterError() {
    this.notifyService.showSuccess("تم محو المستخدم")
  }
  search() {
    $('#dtable').DataTable().ajax.reload();
  }

  isOlderThanFiveDays(date: string): boolean {
    const createdAt = new Date(date);
    const now = new Date();
    const diffInDays = Math.floor((now.getTime() - createdAt.getTime()) / (1000 * 3600 * 24));
    return diffInDays > 2;
  }
}
