import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { Question } from 'src/app/modules/question';
import { NotificationService } from 'src/app/services/notification.service';
import { QuestionService } from 'src/app/services/question.service';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { SharedService } from 'src/app/shared.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {
  question: Question[] = [];
  closeResult = '';
  currentPage = 1;
  itemsPerPage = 10;
  pageSize: number;
  show = true;
  show1 = false;
  question1: string;
  reponseChoix: string = "";
  reponseVF: string = "Vrai";
  choixA: string;
  choixB: string;
  choixC: string;
  choixD: string;
  type: string = "VF";
  registerForm: FormGroup;
  submitted = false;
  registerForm1: FormGroup;
  submitted1 = false;
  registerForm2: FormGroup;
  submitted2 = false;
  registerForm3: FormGroup;
  submitted3 = false;
  questionA: any = {};
  rep: string;
  question2: string;
  reponseChoix2: string = "";
  reponseVF2: string = "Vrai";
  choixA2: string;
  choixB2: string;
  choixC2: string;
  choixD2: string;
  showF = false;
  showM = false;
  showD = false;
  showC = false;
  showT = true;
  questionFacile: Question[] = [];
  questionMoyen: Question[] = [];
  questionDifficile: Question[] = [];
  questionC: Question[] = [];
  categorie: string = "";
  categorie2: string;
  niveau: string = "";
  p: number = 1;
  currentPage1 = 1;
  itemsPerPage1 = 10;
  pageSize1: number;
  currentPage2 = 1;
  itemsPerPage2 = 10;
  pageSize2: number;
  currentPage3 = 1;
  itemsPerPage3 = 10;
  pageSize3: number;
  currentPage4 = 1;
  itemsPerPage4 = 10;
  pageSize4: number;
  maxPages = 10;
  niveau2: string;
  reponseChoix21: string = "";
  user: any = {};
  dtOptions: DataTables.Settings = {};
  dtOptions1: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  dtOptions3: DataTables.Settings = {};
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
 
  uri = environment.urlGame;
  receivedData:boolean;
  dt:boolean=false;
  constructor(private servicequestion: QuestionService,
    private modalService: NgbModal,private route: ActivatedRoute,
    private serviceauth: AuthentificationService,private sharedService: SharedService,
    private notifyService: NotificationService, private http: HttpClient) { }

  ngOnInit() {

    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
    });
    this.receivedData=false;

    this.user = this.serviceauth.getCurrentUser();

    this.dtOptions = {
      pagingType: 'simple_numbers',
      language: {
        processing: "يرجى الإنتظار...",
        searchPlaceholder: 'البحث',search: "",
        lengthMenu: " _MENU_ ",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        paginate: {
          first: "",
          previous: "<i class='material-icons'>navigate_next</i> ",
          next: "<i class='material-icons'>navigate_before</i>",
          last: ""
        },
      },
      searching: true, 
      info: false,
      paging: true,
      ordering: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.currentPage = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        const rowData = {
          no_of_records: dataTablesParameters.length,
          page: this.currentPage,
          group_id: 1
        };
        this.http.post<DataTablesResponse>(this.uri + 'questions', dataTablesParameters, { headers: this.headers }).subscribe(resp => {
          const details = JSON.parse(JSON.stringify(resp.original).replace(/&quot;/g, '“'));
          this.question = details.data;
          callback({
            recordsTotal: details.recordsTotal,
            recordsFiltered: details.recordsFiltered,
            data: [],
          });
        });
      },
      columns: [
        { data: "text" },
      ],
    };

    this.registerForm = new FormGroup({
      question1: new FormControl('', Validators.required),
      niveau: new FormControl('', Validators.required)
    });
    this.registerForm1 = new FormGroup({
      choixA: new FormControl('', Validators.required),
      choixB: new FormControl('', Validators.required),
      choixC: new FormControl('', Validators.required),
      choixD: new FormControl('', Validators.required),
    });
    this.registerForm2 = new FormGroup({
      question2: new FormControl('', Validators.required),
      niveau2: new FormControl('', Validators.required)
    });
    this.registerForm3 = new FormGroup({
      choixA2: new FormControl('', Validators.required),
      choixB2: new FormControl('', Validators.required),
      choixC2: new FormControl('', Validators.required),
      choixD2: new FormControl('', Validators.required)
    });
  }

  get f() { return this.registerForm.controls; }
  get f1() { return this.registerForm1.controls; }
  get f2() { return this.registerForm2.controls; }
  get f3() { return this.registerForm3.controls; }


  getquestionFacile() {
    this.dtOptions1 = {
      pagingType: 'simple_numbers',
      language: {
        processing: "يرجى الإنتظار...",
        searchPlaceholder: 'البحث',search: "",
        lengthMenu: " _MENU_ ",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        paginate: {
          first: "",
          previous: "<i class='material-icons'>navigate_next</i> ",
          next: "<i class='material-icons'>navigate_before</i>",
          last: ""
        },
      },
      searching: true, 
      info: false,
      paging: true,
      ordering: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.currentPage = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        const rowData = {
          no_of_records: dataTablesParameters.length,
          page: this.currentPage,
          group_id: 1
        };
        this.http.post<DataTablesResponse>(this.uri + 'questionsFacile', dataTablesParameters, { headers: this.headers }).subscribe(resp => {
          const details = JSON.parse(JSON.stringify(resp.original).replace(/&quot;/g, '“'));
          this.questionFacile = details.data;
          callback({
            recordsTotal: details.recordsTotal,
            recordsFiltered: details.recordsFiltered,
            data: [],
          });
        });
      },
      columns: [
        { data: "text" },

      ],
    };

  }

  getquestionMoyen() {

    this.dtOptions2 = {
      pagingType: 'simple_numbers',
      language: {
        processing: "يرجى الإنتظار...",
        searchPlaceholder: 'البحث',search: "",
        lengthMenu: " _MENU_ ",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        paginate: {
          first: "",
          previous: "<i class='material-icons'>navigate_next</i> ",
          next: "<i class='material-icons'>navigate_before</i>",
          last: ""
        },
      },
      searching: true, 
      info: false,
      paging: true,
      ordering: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.currentPage = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        const rowData = {
          no_of_records: dataTablesParameters.length,
          page: this.currentPage,
          group_id: 1
        };
        this.http.post<DataTablesResponse>(this.uri + 'questionsMoyen', dataTablesParameters, { headers: this.headers }).subscribe(resp => {
          const details = JSON.parse(JSON.stringify(resp.original).replace(/&quot;/g, '“'));
          this.questionMoyen = details.data;
          callback({
            recordsTotal: details.recordsTotal,
            recordsFiltered: details.recordsFiltered,
            data: [],
          });
        });
      },
      columns: [
        { data: "text" },

      ],
    };

  }

  getquestionDifficile() {


    this.dtOptions3 = {
      pagingType: 'simple_numbers',
      language: {
        processing: "يرجى الإنتظار...",
        searchPlaceholder: 'البحث',search: "",
        lengthMenu: " _MENU_ ",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        paginate: {
          first: "",
          previous: "<i class='material-icons'>navigate_next</i> ",
          next: "<i class='material-icons'>navigate_before</i>",
          last: ""
        },
      },
      searching: true, 
      info: false,
      paging: true,
      ordering: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.currentPage = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        const rowData = {
          no_of_records: dataTablesParameters.length,
          page: this.currentPage,
          group_id: 1
        };
        this.http.post<DataTablesResponse>(this.uri + 'questionsDifficile', dataTablesParameters, { headers: this.headers }).subscribe(resp => {
          const details = JSON.parse(JSON.stringify(resp.original).replace(/&quot;/g, '“'));
          this.questionDifficile = details.data;
          callback({
            recordsTotal: details.recordsTotal,
            recordsFiltered: details.recordsFiltered,
            data: [],
          });
        });
      },
      columns: [
        { data: "text" },

      ],
    };

  }

  open1(content) {
    this.modalService.open(content, { size: 'lg', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.show = true;
      this.show1 = false;
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.show = true;
      this.show1 = false;
      return 'by clicking on a backdrop';
    } else {
      this.show = true;
      this.show1 = false;
      return `with: ${reason}`;
    }
  }
  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });
  }

  delete(id) {
    this.servicequestion.delete(id)
      .pipe(first())
      .subscribe(data => {
        this.modalService.dismissAll();
        $('#dtable').DataTable().draw(false);
        $('#dtableF').DataTable().draw(false);
        $('#dtableM').DataTable().draw(false);
        $('#dtableD').DataTable().draw(false);
        this.showToasterError();
      })
  }

  showToasterSuccess() {
    this.notifyService.showSuccess("تم اضافة سؤال")
  }
  showToasterSuccess1() {
    this.notifyService.showSuccess("تم تعديل السؤال")
  }
  showToasterError() {
    this.notifyService.showSuccess("تم محو السؤال")
  }
  qts(i) {
    if (i == 1) {
      this.type = "VF";
      this.show = true;
      this.show1 = false;
    } else {
      this.type = "Choix";
      this.show1 = true;
      this.show = false;
    }
  }
  qts1() {
    this.show = true;
    this.show1 = false;
    this.registerForm.reset();
    this.registerForm1.reset();
    this.submitted = false;
    this.submitted1 = false;
    this.question1 = "";
    this.reponseChoix = "";
    this.choixA = "";
    this.choixB = "";
    this.choixC = "";
    this.choixD = "";
    this.niveau = "";
  }
  reponse(j) {
    if (j == 1) {
      this.reponseChoix = this.choixA;
    } else if (j == 2) {
      this.reponseChoix = this.choixB;
    } else if (j == 3) {
      this.reponseChoix = this.choixC;
    } else if (j == 4) {
      this.reponseChoix = this.choixD;
    }
  }
  reponseVF1(j) {
    if (j == 1) {
      this.reponseVF = "Vrai";
    } else {
      this.reponseVF = "Faux";
    }
  }
  ajouter() {
    if (this.type == "Choix") {
      this.submitted1 = true;
      this.submitted = true;
      if (this.registerForm.invalid || this.registerForm1.invalid || this.reponseChoix == "") {
        return;
      }
      this.servicequestion.addchoix(this.type, this.question1, this.reponseChoix, this.choixA, this.choixB, this.choixC, this.choixD, this.niveau)
        .pipe(first())
        .subscribe(data => {
          $('#dtable').DataTable().ajax.reload();
          $('#dtableF').DataTable().ajax.reload();
          $('#dtableM').DataTable().ajax.reload();
          $('#dtableD').DataTable().ajax.reload();
          this.modalService.dismissAll();
          this.showToasterSuccess();
          this.registerForm.reset();
          this.registerForm1.reset();
          this.submitted = false;
          this.submitted1 = false;
          this.question1 = "";
          this.reponseChoix = "";
          this.choixA = "";
          this.choixB = "";
          this.choixC = "";
          this.choixD = "";

          this.niveau = "";
          this.type = "VF";
        })
    } else {
      this.submitted = true;
      // stop here if form is invalid
      if (this.registerForm.invalid) {
        return;
      }
      this.servicequestion.addVF(this.type, this.question1, this.reponseVF, this.niveau)
        .pipe(first())
        .subscribe(data => {
          $('#dtable').DataTable().ajax.reload();
          $('#dtableF').DataTable().ajax.reload();
          $('#dtableM').DataTable().ajax.reload();
          $('#dtableD').DataTable().ajax.reload();
          this.modalService.dismissAll();
          this.showToasterSuccess();
          this.registerForm.reset();
          this.registerForm1.reset();
          this.submitted = false;
          this.submitted1 = false;
          this.question1 = "";
          this.reponseVF = "Vrai"
          this.niveau = "";
          this.type = "VF";
        })
    }
  }
  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }
  getquestionbyid(id) {
    this.servicequestion.getbyid(id)
      .pipe(first())
      .subscribe(data => {
        this.questionA = data;
        this.question2 = this.questionA.text;
        this.reponseChoix2 = this.questionA.reponseChoix;
        this.reponseVF2 = this.questionA.reponseVF;
        this.choixA2 = this.questionA.choixA;
        this.choixB2 = this.questionA.choixB;
        this.choixC2 = this.questionA.choixC;
        this.choixD2 = this.questionA.choixD;
        this.niveau2 = this.questionA.niveau;
        if (this.questionA.type == 'Choix') {
          if (this.questionA.choixA == this.questionA.reponseChoix) {
            this.rep = "choixA";
          } else if (this.questionA.choixB == this.questionA.reponseChoix) {
            this.rep = "choixB";
          } else if (this.questionA.choixC == this.questionA.reponseChoix) {
            this.rep = "choixC";
          } else {
            this.rep = "choixD";
          }
        }
      })
  }
  modifierChoix(id, text, choixA, choixB, choixC, choixD, type) {
    this.choixA2 = choixA;
    this.choixB2 = choixB;
    this.choixC2 = choixC;
    this.choixD2 = choixD;
    this.submitted3 = true;
    // stop here if form is invalid
    if (this.registerForm3.invalid || this.registerForm2.invalid) {
      return;
    }
    if (this.reponseChoix21 == "") {
      if (this.rep == "choixA") {
        this.reponseChoix2 = choixA;
      } else if (this.rep == "choixB") {
        this.reponseChoix2 = choixB;
      } else if (this.rep == "choixC") {
        this.reponseChoix2 = choixC;
      } else if (this.rep == "choixD") {
        this.reponseChoix2 = choixD;
      }
    } else {

      if (this.reponseChoix21 == "choixA") {
        this.reponseChoix2 = choixA;
      } else if (this.reponseChoix21 == "choixB") {
        this.reponseChoix2 = choixB;
      } else if (this.reponseChoix21 == "choixC") {
        this.reponseChoix2 = choixC;
      } else if (this.reponseChoix21 == "choixD") {
        this.reponseChoix2 = choixD;
      }
    }
    this.servicequestion.updatechoix(text, this.reponseChoix2, choixA, choixB, choixC, choixD, type, this.categorie2, this.niveau2, id)
      .pipe(first())
      .subscribe(data => {
        $('#dtable').DataTable().draw(false);
        $('#dtableF').DataTable().draw(false);
        $('#dtableM').DataTable().draw(false);
        $('#dtableD').DataTable().draw(false);
        this.modalService.dismissAll();
        this.showToasterSuccess1();
        this.registerForm3.reset();
        this.submitted3 = false;
        this.reponseChoix21 = "";

      })
  }
  modifierVF(id, text, type) {
    this.submitted2 = true;
    // stop here if form is invalid
    if (this.registerForm2.invalid) {
      return;
    }
    this.servicequestion.updateVF(text, this.reponseVF2, type, this.categorie2, this.niveau2, id)
      .pipe(first())
      .subscribe(data => {
        $('#dtable').DataTable().draw(false);
        $('#dtableF').DataTable().draw(false);
        $('#dtableM').DataTable().draw(false);
        $('#dtableD').DataTable().draw(false);
        this.modalService.dismissAll();
        this.showToasterSuccess1();
        this.registerForm2.reset();
        this.submitted2 = false;
      })
  }
  reponse2(j) {
    if (j == 1) {
      this.reponseChoix21 = "choixA";
    } else if (j == 2) {
      this.reponseChoix21 = "choixB";
    } else if (j == 3) {
      this.reponseChoix21 = "choixC";
    } else if (j == 4) {
      this.reponseChoix21 = "choixD";
    }
  }
  reponseVF3(j) {
    if (j == 1) {
      this.reponseVF2 = "Vrai";
    } else {
      this.reponseVF2 = "Faux";
    }
  }
}
