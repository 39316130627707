<app-menu></app-menu>
<body [ngStyle]="{'width': receivedData ? '92.5%' : 'auto'}">

  <div class="container" id="main">
    <h2>   قائمة الإشهارات </h2>
    <div class="row" id="par1">
     
      
       <div class="container" *ngIf="user.role == 'globale' || user.publicite == 1" dir="rtl">
        <br> <br>
         <div >
          <div class="card-body" style="text-align: right;">
        
        <br>
        <div class="col">
          <div class="part">
            <div class="card-body" style="text-align: right; text-align: -webkit-center;" id="blog2" >
      
                    <div id="titl" style="border-top-left-radius: 10px; border-bottom-right-radius: 10px; border-bottom-left-radius: 10px; border-top-right-radius: 10px; background-color:#008be957;margin-top:23px;height:33px;width: 530px;"> <span > إضافة إشهار</span> </div>
      
      
      
                <tbody>
      
                  <tr>
                    <td style="
                    width: 527px;
                    height: 327px;
                ">
                      <form [formGroup]="registerForm"  enctype="multipart/form-data">
                      <textarea placeholder="كتابة اعلانات" maxlength="255" style="max-height: 150px !important; " rows="3" name="text" rows="3" name="text" formControlName="text" id="text"
                      class="form-control" [(ngModel)]="text" style="width: 100%;height:242px;background-color:rgb(211 232 247 / 23%);border-radius:9px"></textarea>
                  <br>
      
                  <button class="btn btn-primary btn-lg " id="update1" (click)="ajouter()" type="button" class="btn lab" style="margin-left: 0%;background-color: rgba(159, 212, 243, 0.719);color: black;width: 21%;border:none;border-radius:9px; width: 36%;" >اضافة</button>
                  <div *ngIf="vide" class="invalid-feedback">
                    <div style="
                    margin-left: 77%;
                    margin-top: -28px;
                ">  يجب ادخال  الاعلان</div>
                  </div>
                </form>
                </td>
                  </tr>
      
      
                </tbody>
      
      
      
      
      
      
      
            </div></div>
        </div>
        <br>
         <br>
        <div class="col">
         <div class="row" style="direction: rtl;margin-top: -20px; width: 107%;"  id="row4">
          <table id="dtable" datatable [dtOptions]="dtOptions" class="responsive row-border hover" style="overflow: auto; ">
            <thead>
              <tr>

                <th  style="border-bottom-right-radius: 10px; border-top-right-radius:10px">التاريخ</th>
                <th>المستخدم </th>
                <th style="width:189px;"><span style="
                  margin-right: 54px;
              ">الإشهار</span>   </th>
                <th> عدد المشاهدات </th>
                <th>عدد المشاهدات الحالية</th>
                <th style="border-bottom-left-radius: 10px; border-top-left-radius:10px"></th>
              </tr>
            </thead>
            <tbody>
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;" *ngFor="let userPublicity of userPublicities;let index = index">

                <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ userPublicity.created_at | date: 'yyyy-MM-dd H:mm' }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" *ngIf="userPublicity.type == 'user'">{{ userPublicity.codeparrainage }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"  *ngIf="userPublicity.type == 'admin'">الأدمن</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" >{{ userPublicity.text }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"  *ngIf="userPublicity.type == 'user'">{{ userPublicity.numberViews }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" *ngIf="userPublicity.type == 'admin'">_</td>
                <td  style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"*ngIf="userPublicity.type == 'user'">{{ userPublicity.currentNumberViews }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" *ngIf="userPublicity.type == 'admin'">_</td>
                <td style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                 <div class="d-flex">
                  <a *ngIf="userPublicity.type == 'admin'" (click)="open1(longContent1)"><i class="material-icons" style="color: #30287a;">edit</i></a> &nbsp;
                  <a (click)="openSm2(content4)"><i class="material-icons" style="color: rgb(206, 6, 6);">delete</i></a>
                 </div>
                </td>

                <ng-template #longContent1 let-modal id="template2" style="z-index: 1050">
                  <div class="modal-content">
                    <div class="modal-body" id="mbody" dir="rtl">
                      <div class="row" id="row5">
                        <div class="col-md-2"> 
                          <button id="close1" type="button" class="close" aria-label="Close"
                            (click)="modal.close('Cross click')">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      </div>
                      <form [formGroup]="registerForm1" enctype="multipart/form-data">
                        <h4> تعديل الإشهار  </h4>

                        <div class="row row1">
                          <textarea maxlength="255" style="max-height: 150px !important; background-color:#ebebeb" rows="3" class="form-control" type="text"
                            formControlName="text1" [(ngModel)]="userPublicity.text"></textarea>
                          <div *ngIf="submitted && h['text1'].errors" class="invalid-feedback">
                            <div *ngIf="h['text1'].errors['required']">يجب ادخال الإشهار   </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center">
                          <button id="update1" (click)="modifier(userPublicity.id, userPublicity.text) " type="button" class="btn lab">
                            &nbsp; &nbsp; تعديل &nbsp; &nbsp;</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </ng-template>
                <ng-template #content4 let-modal id="template4" style="z-index: 1050">
                  <div class="modal-body" dir="rtl">
                    <button id="close2" type="button" class="close" aria-label="Close"
                      (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 id="h3" style="
                    margin-top: 10px;
                    margin-right: 39px;
                "> هل تريد حذف هذا الإشهار   ؟</h4>
                    <div class="d-flex justify-content-center">
                      <button id="btnP" (click)="delete(userPublicity.id)" type="button" class="btn btn-primary">&nbsp; &nbsp;
                        تأكيد &nbsp; &nbsp;</button>
                    </div>
                  </div>
                </ng-template>

              </tr>

            </tbody>
          </table>
          
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="user.role != 'globale' && user.publicite == 0 " style="padding-top: 10% !important">
    <div class="container"
      style="width: 100%;height: 400px;max-height: 800px; background-size: contain;
      background-repeat: no-repeat;background-position: center center;display:block; margin-top:auto; margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
    </div>
  </div>
</div>
<div class="col" >
  <div class="container" *ngIf="user.role == 'globale' || user.publicite == 1" dir="rtl" style="
  margin-top: 20px;" [ngStyle]="{'margin-top': receivedData ? '20px' : 'auto'}"
>
    <br> <br>
    
    </div>
</div>
</div>
</div>
<br>
<br>
</body>
