import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Message } from '../modules/message';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  uri = environment.apiUrl;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });

  constructor(private http: HttpClient) { }

  addmessage(data: FormData) {
    this.http.post(`${this.uri}message`, data, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'messages';
    return this.http.get<Message[]>(url_get, { headers: this.headers });
  }


  addmessageTotal(message) {
    const obj = {
      message: message
    }

    // const url_get = this.uri+'messages';
    return this.http.post(`${this.uri}messageTotal`, obj, { headers: this.headers });
  }
  addPointTotal(message, montant) {
    const obj = {
      message: message,
      montant: montant
    }

    this.http.post(`${this.uri}messagePointTotal`, obj, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'messages';
    return this.http.get<Message[]>(url_get, { headers: this.headers });
  }




  getMsgbyid(id: string): Observable<Message[]> {
    const url_get = this.uri + 'messages/' + id;
    return this.http.get<Message[]>(url_get, { headers: this.headers });
  }

  getProgressPoint() {
    const url_get = this.uri + 'getProgressPoint';
    return this.http.get(url_get, { headers: this.headers });
  }

  getProgress() {
    const url_get = this.uri + 'getProgress';
    return this.http.get(url_get, { headers: this.headers });
  }


}
