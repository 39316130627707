<meta name="viewport" content="width=device-width, initial-scale=1.0">

<app-menu></app-menu>

<body dir="rtl" [ngStyle]="{'width': receivedData ? '82%' : 'auto'}" style="overflow-x:hidden">
    <div class="container pt-5" *ngIf="user.role == 'globale' || user.publicite == 1" id="container2">
      <div class="container"id="container2">
      <div class="row">
        <div class="col" id="par1">
          <h2> طلبات الإشهار</h2>
          <br>
          <div class="row" style="overflow-y:auto;direction: rtl;">
              <table id="dtable" datatable [dtOptions]="dtOptions" class=" row-border hover" style="margin-top: 12px; padding-top: 22px;">
                <thead>
                  <tr>

                    <th  style="border-bottom-right-radius: 10px; border-top-right-radius:10px">التاريخ</th>
                    <th>المستخدم </th>
                    <th>الإعلان </th>
                    <th> عدد المشاهدات </th>
                    <th style="border-bottom-left-radius: 10px; border-top-left-radius:10px"></th>
                  </tr>
                </thead>
                <tbody>
                
                 
                  <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;"  *ngFor="let userPublicityDemand of userPublicitiesDemand; let index = index;">
                   
                    <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ userPublicityDemand.created_at | date: 'yyyy-MM-dd H:mm' }}</td>
                    <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ userPublicityDemand.codeparrainage }}</td>
                    <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ userPublicityDemand.text }}</td>
                    <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ userPublicityDemand.numberViews }}</td>

                    <td style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                      <span class="d-flex">
                        <button #refuseButton (click)="open(content)"  id="submitB">
                          <i class="material-icons" style="color: rgb(206, 6, 6);">cancel</i>
                        </button> &nbsp; &nbsp;
                        <button (click)="update(userPublicityDemand.id) ; submitButton.disabled = true" #submitButton id="submitB">
                          <i class="material-icons" style="color: green;">check_circle</i>
                        </button>
                      </span>

                    </td>
 


                    <ng-template #content let-modal>
                      <div class="modal-content" id="con">
                        <div class="modal-header">
                          <button type="button" class="btn-close" aria-label="Close"
                            (click)="modal.dismiss('Cross click')"></button>
                        </div>
                        <div class="modal-body" dir="rtl">
                          <h4 id="h3"> سيتم رفض طلب الإشهار  </h4><br>
                          <div class="justify-content-center d-flex">
                            <button (click)="updaterefuse(userPublicityDemand.id);submitButton2.disabled = true" #submitButton2
                              type="button" class="lab btn">رفض الطلب</button>
                          </div><br>
                          <form [formGroup]="registerForm1">
                            <div class="flex" dir="ltr">
                              <textarea placeholder="السبب" pattern=".*\S+.*" maxlength="250"
                                style="max-height: 100px !important;" name="reason" formControlName="reason" id=""
                                [(ngModel)]="reason" cols="30" rows="1" class="form-control"></textarea>
                              <button [disabled]="registerForm1.invalid"
                                (click)="updaterefuseC(userPublicityDemand.id, reason); submitButton1.disabled = true" #submitButton1
                                type="button" class="lab btn"> <i style="color: #fff;"
                                  class="material-icons glyphicon">send</i> &nbsp;</button>
                            </div>
                            <div *ngIf="submitted1 && f['reason'].errors" class="invalid-feedback">
                              <div class="err" *ngIf="f['reason'].errors['required']">يجب ادخال السبب </div>
                            </div><br>
                          </form>
                        </div>
                      </div>
                    </ng-template>

                  </tr>
                  <tr>
                  
                  </tr>
                  <tr *ngIf="userPublicitiesDemand?.length == 0 " >
                    <td colspan="6"  style="background-color: transparent;">لا توجد بيانات متوفرة</td>
                  </tr>
                </tbody>
              </table>
              
            </div>
          <br>
        </div>
        <div class="col" style="margin-top: 6%;">

        


        <div class="d-flex" style="border-top-left-radius: 10px; border-bottom-right-radius: 10px; border-bottom-left-radius: 10px; border-top-right-radius: 8px;position: relative;        z-index: 2;margin-top:43px">
          <div class="p-2" id="p2" style="background-color: #91e1f9;border-radius: 10px;width: 74%;margin-right:35px  ;  box-shadow:  3px 3px 3px darkgrey;"><span >عروض الإعلانات</span></div>

          <div class="p-2" style="background-color: #a4e2ff;border-radius: 10px;margin-right: 13px;    box-shadow:  3px 3px 3px darkgrey;
      "><img src="../assets/deltepublication.png" (click)="openSm2(content4)"></div>
      <div class="p-2" style="margin-right: 23px;background-color: #91ebff;border-radius: 11px;    box-shadow:  3px 3px 3px darkgrey; "><img src="../assets/editpublication.png" (click)="openSm3(contentP)"></div>
      </div>

      <div id="car" style="border:1px solid rgb(138, 131, 131);border-radius:10px; background-color:rgba(106, 203, 255, 0.212);position: relative;
      z-index: 1; width:112% ;margin-top:-10px;">

      <div id="call" style="
      margin-top:17px;
  ">
            <div class="container">
              <div class="row" style="padding: 12px;">
            <ng-container *ngFor="let offerPublicity of offerPublicities;let i = index">
              
              <br>
                <div class="col" style="border:none;border-radius:10px;background-color:#cadfdebf; width: 277px!important;margin-bottom: 20px;">
                    <div class="row" id="row" style="width:279px;height: 133px;">
                      
                   <div class="col" id="txt" style="
                     margin-top: 25px;
                   ">
                      <span> المشاهدات: {{offerPublicity.numberViews}}</span>
                      <h6>الثمن: {{offerPublicity.price}} نقطة</h6>
                    </div>
                    <div class="col" id="co">
                      <img id="picr" src="../assets/rightpic.png" alt="" style="
                     margin-right:200px;margin-top: -72px;
                       ">
                   
                   </div></div>
                </div>
          
                  &nbsp;
                  &nbsp;
              
            </ng-container>
              </div>
            </div>
</div>

        <br>
      </div>
</div>
      </div>
    </div>
    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.publicite == 0 " style="padding-top: 10% !important">
        <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
        background-repeat: no-repeat; background-position: center center;display:block;margin-top:auto;
        margin-bottom:auto; max-height: 800px;background-image: url('assets/droit.png')">
        </div>
    </div>

    <ng-template #contentP let-modal class="testt" style=" overflow-x: auto;">
      <div class="modal-content" style="width:613px;  " id="conten">
        <div class="modal-body" dir="rtl" id="mod" style="
        margin-right: -22%;width:102%;
      
    ">   <div class="modal-header">
                          <button type="button" class="btn-close" aria-label="Close"
                            (click)="modal.dismiss('Cross click')"></button>
                        </div>
          <br>
          <div style="color: red; margin-right: 44%;">{{message}}</div>
          <form [formGroup]="registerForm" style="background-color:rgb(255, 255, 255);">
            <ng-container *ngFor="let offerPublicit of offerPublicities; let i = index">
              <ng-container *ngIf="i % 2 === 0; else oddRow">
                
                <td style="background-color:rgb(255, 255, 255);" [ngClass]="{'align-horizontal': isScreenBelow768px}">
                  <div class="container">
                    <div class="row">
                  <div class="col">
                    <div class="card"style="width:100%;height:104px;background-color: #ededed;margin-right:17px" >
                      <div class="row">
                        <div class="col">
                        <div class="col-12 col-sm-6 col-md-8" id="pp">
                        <div class="col-12 col-sm-6 col-md-8"  id="pp"style="display: flex; align-items: center;">
  <span style="margin-right: 10px;"> المشاهدات:</span>  <input type="number"  class="inputPoint form-control" [formControlName]="'numberViews' + i"
  [(ngModel)]="offerPublicit.numberViews" id="numberViews"  (keypress)="onKeyPress($event, 'numberViews')"pattern="[0-9]+"
  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
  style="width: 107px;background-color: transparent;">
                        </div><div class="col-12 col-sm-6 col-md-8" style="display: flex; align-items: center;">
  <span style="margin-right: 10px;">الثمن:</span> <input type="number" class="mb-2 inputPoint form-control"  (keypress)="onKeyPress($event, 'price')"  [formControlName]="'price' + i" [(ngModel)]="offerPublicit.price" id="price" style="width: 107px;background-color: transparent;margin-right:33px" max="9999999"pattern="[0-9]+"
  oninput="this.value = this.value.replace(/[^0-9]/g, '');"></div>
                      </div> </div>
                      <div>
                        <img style=" margin-right: 102%;
                        margin-top: -45%;" class="done" src="../assets/done_svgrepo.com.png"  height="30" width="30" (click)="modifier(offerPublicit.id, offerPublicit.numberViews, offerPublicit.price)">
                      </div> </div>


                  </div> </div></div></div>
                </td>
              </ng-container>
              <ng-template #oddRow>
                <td style="background-color:rgb(255, 255, 255);" [ngClass]="{'align-horizontal': isScreenBelow768px}">
                  <div class="container">
                    <div class="row">
                  <div class="col">
                    <div class="card" style="width:98%;height:104px;background-color: #ededed; margin-right:23px" >
                      <div class="row">
                      <div class="col">
                      <div class="col-12 col-sm-6 col-md-8"  id="pp" style="display: flex; align-items: center;">
  <span style="margin-right: 10px;"> المشاهدات:</span>
 <input type="number" class="inputPoint form-control" (keypress)="onKeyPress($event, 'numberViews')"pattern="[0-9]+"
 oninput="this.value = this.value.replace(/[^0-9]/g, '');"  [formControlName]="'numberViews' + i"  [(ngModel)]="offerPublicit.numberViews" id="numberViews" style=" width:107px; background-color: transparent; ">
 
</div>

   <div class="col-12 col-sm-6 col-md-8" style="display: flex; align-items: center;">
  <span style="margin-right: 10px;">الثمن:</span>  <input type="number" pattern="[0-9]+"
  oninput="this.value = this.value.replace(/[^0-9]/g, '');" (keypress)="onKeyPress($event, 'price')"  class="mb-2 inputPoint form-control" [formControlName]="'price' + i" [(ngModel)]="offerPublicit.price" id="price" style="width: 107px;background-color: transparent;margin-right:34px">
                </div></div>

                   <div> <img  src="../assets/done_svgrepo.com.png"style=" margin-right: 102%;margin-top: -45%;" class="done" (click)="modifier(offerPublicit.id, offerPublicit.numberViews, offerPublicit.price)" height="30" width="30"></div> </div>

            </div>
                </div>
               </div>
               </div>
                </td>
               
              </ng-template>
              <ng-container *ngIf="(i + 1) % 2 === 0 || i === offerPublicities.length - 1">
                <tr *ngIf="i !== offerPublicities.length - 1">
              </ng-container>
            </ng-container>

          </form>

        </div>
      </div>
    </ng-template>
    <ng-template #content4 let-modal id="template4" >
      <div class="modal-content" id="modal2" style="margin-left:-85%">
        <div class="modal-header">
          <button type="button" class="btn-close" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
        </div>
      <div class="row" style="  width: 736px;margin-left: 0%;">
        <ng-container *ngFor="let offerPublicity of offerPublicities; let i = index">
          <div class="col-md-6">
            
            <div class="card" style="margin-bottom: 15px;" id="cardd">
              <div class="row" >
                <div style="
                margin-left: -10%;
                height: 0px;
            ">
                 <div style="
                 margin-right: 100%;
             "> <img src="../assets/deltepublication.png"(click)="openSm2(content4)"style="
             margin-top: 13px;
         "> </div>
                </div>
                <div class="col" >
                  <h6>عدد المشاهدات: {{offerPublicity.numberViews}}</h6>
                  <h6>الثمن: {{offerPublicity.price}} نقطة</h6>
                </div>
              </div>
            </div>
          </div>
          <ng-template #content4 let-modal id="template4" style="z-index: 1050">
            <div class="modal-body" dir="rtl">
              <div class="modal-header">
                <button type="button" class="btn-close" aria-label="Close"
                  (click)="modal.dismiss('Cross click')"></button>
              </div>
              <h4 id="h3"> هل تريد حذف هذا الإشهار   ؟</h4>
              <div class="d-flex justify-content-center">
                <button id="btnP" (click)="delete(offerPublicity.id)" type="button" class="btn btn-primary">&nbsp; &nbsp;
                  تأكيد &nbsp; &nbsp;</button>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </div>
    </ng-template>


<br> <br>
</body>


