import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { JwtTokenModule } from "../modules/jwt-token-module";
import { AuthentificationService } from "../services/authentification.service";

export interface IsDirty {
    isDirty(): boolean | Observable<boolean>;
}

@Injectable({
    providedIn: 'root'
})
export class IsNotSignedInGuard implements CanActivate {

    constructor(private authService: AuthentificationService,
        private router: Router){}
        canActivate(
          next: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<boolean> {
          return this.authService.jwtToken.pipe(
            map( (jwtToken: JwtTokenModule) => {
              if (jwtToken.isAuthenticated) {
                this.router.navigate(['/conversion']);
                return false;
                
              } else {
                return true;
              }
            })
          );
        }
}