import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthentificationService } from 'src/app/services/authentification.service';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router , private authentificationService: AuthentificationService,) { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Intercepter la requête HTTP
    // Ajoutez votre logique ici
    
    // Passez la requête modifiée au prochain intercepteur ou au gestionnaire HTTP final
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          // Intercepter la réponse HTTP
          // Ajoutez votre logique ici
        },
        (error: any) => {
          // Intercepter les erreurs HTTP
          if (error.status === 401) {
            this.authentificationService.logout();
            this.router.navigate(['/']);
          }
        }
      )
    );
  }
}