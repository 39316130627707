<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '82.5%' : 'auto'}">

  <div class="container" *ngIf="user.role != 'globale' && user.jeux == 0 " style="padding-top: 10% !important">

    <div class="container" 
      style="width: 100%;height: 400px;max-height: 800px;background-size: contain;background-repeat: no-repeat;
   background-position: center center;display:block;margin-top:auto;margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
    </div>
   </div>
   <div class="container" *ngIf="user.role == 'globale' || user.jeux == 1">
    <br> <br>
    <div class="card">
      <div class="card-body">
        <div class="row" id="row3" dir="rtl">

          <div id="back" style="background-color:#b3daffd9; border-radius: 15px; height: 44px;">
            <div class="row" id="rw" style="margin-top: -1px;">
              <div class="col-md-3 col-12 mb-2">
                <div class="dropdown">
                  <button (click)="showT = false; showF = true; showM = false; showD = false; getquestionFacile()" style="border-radius: 9px; margin-top: -6px;width: 93px;margin-right: 82px;" type="button" id="button3" class="btn btn-primary btn-block">أسئلة سهلة</button>
                </div>
              </div>
              <div class="col-md-2 col-6 mb-2">
                <div class="dropdown">
                  <button (click)="showT = false; showM = true; showF = false; showD = false;getquestionMoyen()" style="border-radius: 9px;width: 101px" type="button" id="button1" class="btn btn-primary btn-block">أسئلة متوسطة</button>
                </div>
              </div>
              <div class="col-md-2 col-6 mb-2">
                <div class="dropdown">
                  <button (click)="showT = false; showD = true;showF = false; showM = false;getquestionDifficile()" style="border-radius: 9px; margin-right: 49px;width: 93px" type="button" id="button4" class="btn btn-primary btn-block">أسئلة صعبة</button>
                </div>
              </div>
              <div class="col-md-3 col-12 text-center" id="add">
                <button (click)="open1(longContent);" id="button2" type="button" class="btn btn-primary btn-block" style="border-radius: 9px;">إضافة سؤال</button>
              </div>
            </div>
          </div>
          
<br>      
<br>      

<br>      
<br>
            <div class="row" id="row3">
               <div class="col-md-4"></div>
          </div> 
        </div>
        <div class="row" style="overflow-x:auto; direction: rtl;" *ngIf="showT">
          <table id="dtable" datatable [dtOptions]="dtOptions" class="table table-striped" style="margin-top: 63px;">
            <thead>
              <tr>
                <th  style="border-bottom-right-radius: 10px; border-top-right-radius:10px"  >#</th>
                <th scope="col">السؤال</th>
                <th scope="col"  style="border-bottom-left-radius: 10px; border-top-left-radius:10px" ></th>
              </tr>
            </thead>
            <tbody *ngFor="let qt of question; let index = index;">
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;">
                <td style="  border-left: none;border-top:none; border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{((currentPage * 10) -9)+index}}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" >{{ qt.text }}</td>
                <td style="  border-right: none; border-top:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" >
                  <a id="a1" (click)="open1(longContent1); getquestionbyid(qt.id)">
                    <i class="material-icons" style="color: #30287a;">edit</i>
                  </a>
                  <a (click)="openSm2(content4)">
                    <i class="material-icons" style="color: rgb(206, 6, 6);">delete</i>
                  </a>
                  <ng-template #longContent1 let-modal>
                    <div class="modal-body" id="mbody" dir="rtl">
                      <div class="row" id="row5">
                        <div class="col-md-2">
                          <button type="button" class="close" aria-label="Close"
                            (click)="modal.close('Cross click'); qts1();">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      </div>
                      <form>
                        <div class="row">
                          <div class="col-md-4"></div>
                          <div class="col-md-4">
                            <div class="form-group" [formGroup]="registerForm2">
                              <select class="form-select" aria-label="Default select example" formControlName="niveau2"
                                [(ngModel)]="niveau2">
                                <option selected disabled value="">--إختر مستوى السؤال--</option>
                                <option value="Facile">سهل</option>
                                <option value="Moyen">متوسط</option>
                                <option value="Difficile">صعب</option>
                              </select>
                              <div *ngIf="submitted && f2['niveau2'].errors" class="invalid-feedback">
                                <div *ngIf="f2['niveau2'].errors['required']"> مستوى السؤال مطلوب </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4"></div>
                        </div>
                        <div class="row">
                          <div class="col-md-2"></div>
                          <div class="col-md-8">
                            <div class="form-group" [formGroup]="registerForm2">
                              <input placeholder="السؤال" type="text" formControlName="question2" name="question1"
                                id="question1" value="" class="form-control" [(ngModel)]="question2">
                              <div *ngIf="submitted2 && f2['question2'].errors" class="invalid-feedback">
                                <div *ngIf="f2['question2'].errors['required']">السؤال مطلوب</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2"></div>
                        </div>
                        <div class="row">
                          <h3>الإجابة</h3>
                        </div>
                        <div class="row" *ngIf="questionA.type == 'VF'">
                          <div class="col-md-3"></div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp"
                                  [checked]="questionA.reponseVF=='Vrai'" name="rp" (click)="reponseVF3(1)">
                              </div>
                              <div class="form-group col-md-10">
                                <input placeholder="صواب" disabled type="text" name="prenom" id="prenom" value=""
                                  class="form-control">
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="questionA.reponseVF=='Faux'" (click)="reponseVF3(2)">
                              </div>
                              <div class="form-group col-md-10">
                                <input placeholder="خطأ" disabled type="text" name="prenom" id="prenom" value=""
                                  class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3"></div>
                        </div>
                        <div class="row" *ngIf="questionA.type == 'Choix'">
                          <div class="col-md-3"></div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="rep=='choixA'" (click)="reponse2(1)">
                              </div>
                              <div class="form-group col-md-10" [formGroup]="registerForm3">
                                <input placeholder="الجواب الأول" type="text" formControlName="choixA2" name="choixA"
                                  id="choixA" value="" class="form-control" [(ngModel)]="choixA2">
                                <div *ngIf="submitted3 && f3['choixA2'].errors" class="invalid-feedback">
                                  <div *ngIf="f3['choixA2'].errors['required']">الجواب الأول مطلوب</div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="rep=='choixB'" (click)="reponse2(2)">
                              </div>
                              <div class="form-group col-md-10" [formGroup]="registerForm3">
                                <input placeholder="الجواب الثاني" type="text" formControlName="choixB2" name="choixB"
                                  id="choixB" value="" class="form-control" [(ngModel)]="choixB2">
                                <div *ngIf="submitted3 && f3['choixB2'].errors" class="invalid-feedback">
                                  <div *ngIf="f3['choixB2'].errors['required']">الجواب الثاني مطلوب</div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="rep=='choixC'" (click)="reponse2(3)">
                              </div>
                              <div class="form-group col-md-10" [formGroup]="registerForm3">
                                <input placeholder="الجواب الثالث" type="text" formControlName="choixC2" name="choixC"
                                  id="choixC" value="" class="form-control" [(ngModel)]="choixC2">
                                <div *ngIf="submitted3 && f3['choixC2'].errors" class="invalid-feedback">
                                  <div *ngIf="f3['choixC2'].errors['required']">الجواب الثالث مطلوب</div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="rep=='choixD'" (click)="reponse2(4)">
                              </div>
                              <div class="form-group col-md-10" [formGroup]="registerForm3">
                                <input placeholder="الجواب الرابع" type="text" formControlName="choixD2" name="choixD"
                                  id="choixD" value="" class="form-control" [(ngModel)]="choixD2">
                                <div *ngIf="submitted3 && f3['choixD2'].errors" class="invalid-feedback">
                                  <div *ngIf="f3['choixD2'].errors['required']">الجواب الرابع مطلوب</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3"></div>
                        </div>
                        <div class="row">
                          <div class="col-md-5"></div>
                          <div class="col-md-2" id="row4">
                            <button *ngIf="questionA.type == 'VF'"
                              (click)="modifierVF(questionA.id,question2,questionA.type)" type="button" id="btr"
                              class="btn lab">تعديل</button>
                            <button *ngIf="questionA.type == 'Choix'"
                              (click)="modifierChoix(questionA.id,question2,choixA2,choixB2,choixC2,choixD2,questionA.type)"
                              type="button" class="btn lab">تعديل</button>
                          </div>
                          <div class="col-md-5"></div>
                        </div>
                      </form>
                    </div>
                  </ng-template>

                  <ng-template #content4 let-modal>
                    <div class="modal-body" dir="rtl">
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <br>
                      <h5 id="h3"> هل تريد حذف هذا السؤال ؟</h5>
                      <div class="d-flex justify-content-center">
                        <button id="btnP" (click)="delete(qt.id)" type="button" class="btn btn-primary"> &nbsp; &nbsp;
                          تأكيد &nbsp; &nbsp;</button>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div class="row" style="overflow-x:auto; direction: rtl;" *ngIf="showF">
          <table id="dtableF" datatable [dtOptions]="dtOptions1" class="table table-striped" dir="rtl" style="margin-top: 112px;">
            <thead>
              <tr>
                <th scope="col" style="border-bottom-right-radius: 10px; border-top-right-radius:10px" >#</th>
                <th scope="col">السؤال</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody *ngFor="let qt of questionFacile; let index = index;">
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;">
                <td style="  border-left: none;border-top:none; border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{((currentPage * 10) -9)+index}}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ qt.text }}</td>
                <td  style="  border-right: none; border-top:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" >
                  <a id="a1" (click)="open1(longContent1); getquestionbyid(qt.id)">
                    <i class="material-icons" style="color: #30287a;">edit</i>
                  </a>
                  <a (click)="openSm2(content4)">
                    <i class="material-icons" style="color: rgb(206, 6, 6);">delete</i>
                  </a>
                  <ng-template #longContent1 let-modal>
                    <div class="modal-body" id="mbody" dir="rtl">
                      <div class="row" id="row5">
                        <div class="col-md-2">
                          <button type="button" class="close" aria-label="Close"
                            (click)="modal.close('Cross click'); qts1();">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      </div>
                      <form>
                        <div class="row">
                          <div class="col-md-4"></div>
                          <div class="col-md-4">
                            <div class="form-group" [formGroup]="registerForm2">
                              <select class="form-select" aria-label="Default select example" formControlName="niveau2"
                                [(ngModel)]="niveau2">
                                <option selected disabled value="">--إختر مستوى السؤال--</option>
                                <option value="Facile">سهل</option>
                                <option value="Moyen">متوسط</option>
                                <option value="Difficile">صعب</option>
                              </select>
                              <div *ngIf="submitted && f2['niveau2'].errors" class="invalid-feedback">
                                <div *ngIf="f2['niveau2'].errors['required']"> مستوى السؤال مطلوب </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4"></div>
                        </div>
                        <div class="row">
                          <div class="col-md-2"></div>
                          <div class="col-md-8">
                            <div class="form-group" [formGroup]="registerForm2">
                              <input placeholder="السؤال" type="text" formControlName="question2" name="question1"
                                id="question1" value="" class="form-control" [(ngModel)]="question2">
                              <div *ngIf="submitted2 && f2['question2'].errors" class="invalid-feedback">
                                <div *ngIf="f2['question2'].errors['required']">السؤال مطلوب</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2"></div>
                        </div>
                        <div class="row">
                          <h3>الإجابة</h3>
                        </div>
                        <div class="row" *ngIf="questionA.type == 'VF'">
                          <div class="col-md-3"></div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp"
                                  [checked]="questionA.reponseVF=='Vrai'" name="rp" (click)="reponseVF3(1)">
                              </div>
                              <div class="form-group col-md-10">
                                <input placeholder="صواب" disabled type="text" name="prenom" id="prenom" value=""
                                  class="form-control">
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="questionA.reponseVF=='Faux'" (click)="reponseVF3(2)">
                              </div>
                              <div class="form-group col-md-10">
                                <input placeholder="خطأ" disabled type="text" name="prenom" id="prenom" value=""
                                  class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3"></div>
                        </div>
                        <div class="row" *ngIf="questionA.type == 'Choix'">
                          <div class="col-md-3"></div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="rep=='choixA'" (click)="reponse2(1)">
                              </div>
                              <div class="form-group col-md-10" [formGroup]="registerForm3">
                                <input placeholder="الجواب الأول" type="text" formControlName="choixA2" name="choixA"
                                  id="choixA" value="" class="form-control" [(ngModel)]="choixA2">
                                <div *ngIf="submitted3 && f3['choixA2'].errors" class="invalid-feedback">
                                  <div *ngIf="f3['choixA2'].errors['required']">الجواب الأول مطلوب</div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="rep=='choixB'" (click)="reponse2(2)">
                              </div>
                              <div class="form-group col-md-10" [formGroup]="registerForm3">
                                <input placeholder="الجواب الثاني" type="text" formControlName="choixB2" name="choixB"
                                  id="choixB" value="" class="form-control" [(ngModel)]="choixB2">
                                <div *ngIf="submitted3 && f3['choixB2'].errors" class="invalid-feedback">
                                  <div *ngIf="f3['choixB2'].errors['required']">الجواب الثاني مطلوب</div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="rep=='choixC'" (click)="reponse2(3)">
                              </div>
                              <div class="form-group col-md-10" [formGroup]="registerForm3">
                                <input placeholder="الجواب الثالث" type="text" formControlName="choixC2" name="choixC"
                                  id="choixC" value="" class="form-control" [(ngModel)]="choixC2">
                                <div *ngIf="submitted3 && f3['choixC2'].errors" class="invalid-feedback">
                                  <div *ngIf="f3['choixC2'].errors['required']">الجواب الثالث مطلوب</div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="rep=='choixD'" (click)="reponse2(4)">
                              </div>
                              <div class="form-group col-md-10" [formGroup]="registerForm3">
                                <input placeholder="الجواب الرابع" type="text" formControlName="choixD2" name="choixD"
                                  id="choixD" value="" class="form-control" [(ngModel)]="choixD2">
                                <div *ngIf="submitted3 && f3['choixD2'].errors" class="invalid-feedback">
                                  <div *ngIf="f3['choixD2'].errors['required']">الجواب الرابع مطلوب</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3"></div>
                        </div>
                        <div class="row">
                          <div class="col-md-5"></div>
                          <div class="col-md-2" id="row4">
                            <button *ngIf="questionA.type == 'VF'"
                              (click)="modifierVF(questionA.id,question2,questionA.type)" type="button"  id="btr"
                              class="btn lab">تعديل</button>
                            <button *ngIf="questionA.type == 'Choix'"
                              (click)="modifierChoix(questionA.id,question2,choixA2,choixB2,choixC2,choixD2,questionA.type)"
                              type="button" class="btn lab"  id="btr">تعديل</button>
                          </div>
                          <div class="col-md-5"></div>
                        </div>
                      </form>
                    </div>
                  </ng-template>

                  <ng-template #content4 let-modal>
                    <div class="modal-body" dir="rtl">
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <br>
                      <h5 id="h3"> هل تريد حذف هذا السؤال ؟</h5>

                      <div class="d-flex justify-content-center">
                        <button id="btnP" (click)="delete(qt.id)" type="button" class="btn btn-primary"> &nbsp; &nbsp;
                          تأكيد &nbsp; &nbsp;</button>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
   
        </div>
        <div class="row" style="overflow-x:auto; direction: rtl;" *ngIf="showM">
          <table id="dtableM" datatable [dtOptions]="dtOptions2" class="table table-striped" dir="rtl" style="margin-top: 112px;">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">السؤال</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody *ngFor="let qt of questionMoyen; let index = index;">
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;">
                <td style="  border-left: none;border-top:none; border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{((currentPage * 10) -9)+index}}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ qt.text }}</td>
                <td  style="  border-right: none; border-top:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" >
                  <a id="a1" (click)="open1(longContent1); getquestionbyid(qt.id)">
                    <i class="material-icons" style="color: #30287a;">edit</i>
                  </a>
                  <a (click)="openSm2(content4)">
                    <i class="material-icons" style="color: rgb(206, 6, 6);">delete</i>
                  </a>
                  <ng-template #longContent1 let-modal>
                    <div class="modal-body" id="mbody" dir="rtl">
                      <div class="row" id="row5">
                        <div class="col-md-2">
                          <button type="button" class="close" aria-label="Close"
                            (click)="modal.close('Cross click'); qts1();">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      </div>
                      <form>
                        <div class="row">
                          <div class="col-md-4"></div>
                          <div class="col-md-4">
                            <div class="form-group" [formGroup]="registerForm2">
                              <select class="form-select" aria-label="Default select example" formControlName="niveau2"
                                [(ngModel)]="niveau2">
                                <option selected disabled value="">--إختر مستوى السؤال--</option>
                                <option value="Facile">سهل</option>
                                <option value="Moyen">متوسط</option>
                                <option value="Difficile">صعب</option>
                              </select>
                              <div *ngIf="submitted && f2['niveau2'].errors" class="invalid-feedback">
                                <div *ngIf="f2['niveau2'].errors['required']"> مستوى السؤال مطلوب </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4"></div>
                        </div>
                        <div class="row">
                          <div class="col-md-2"></div>
                          <div class="col-md-8">
                            <div class="form-group" [formGroup]="registerForm2">
                              <input placeholder="السؤال" type="text" formControlName="question2" name="question1"
                                id="question1" value="" class="form-control" [(ngModel)]="question2">
                              <div *ngIf="submitted2 && f2['question2'].errors" class="invalid-feedback">
                                <div *ngIf="f2['question2'].errors['required']">السؤال مطلوب</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2"></div>
                        </div>
                        <div class="row">
                          <h3>الإجابة</h3>
                        </div>
                        <div class="row" *ngIf="questionA.type == 'VF'">
                          <div class="col-md-3"></div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp"
                                  [checked]="questionA.reponseVF=='Vrai'" name="rp" (click)="reponseVF3(1)">
                              </div>
                              <div class="form-group col-md-10">
                                <input placeholder="صواب" disabled type="text" name="prenom" id="prenom" value=""
                                  class="form-control">
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="questionA.reponseVF=='Faux'" (click)="reponseVF3(2)">
                              </div>
                              <div class="form-group col-md-10">
                                <input placeholder="خطأ" disabled type="text" name="prenom" id="prenom" value=""
                                  class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3"></div>
                        </div>
                        <div class="row" *ngIf="questionA.type == 'Choix'">
                          <div class="col-md-3"></div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="rep=='choixA'" (click)="reponse2(1)">
                              </div>
                              <div class="form-group col-md-10" [formGroup]="registerForm3">
                                <input placeholder="الجواب الأول" type="text" formControlName="choixA2" name="choixA"
                                  id="choixA" value="" class="form-control" [(ngModel)]="choixA2">
                                <div *ngIf="submitted3 && f3['choixA2'].errors" class="invalid-feedback">
                                  <div *ngIf="f3['choixA2'].errors['required']">الجواب الأول مطلوب</div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="rep=='choixB'" (click)="reponse2(2)">
                              </div>
                              <div class="form-group col-md-10" [formGroup]="registerForm3">
                                <input placeholder="الجواب الثاني" type="text" formControlName="choixB2" name="choixB"
                                  id="choixB" value="" class="form-control" [(ngModel)]="choixB2">
                                <div *ngIf="submitted3 && f3['choixB2'].errors" class="invalid-feedback">
                                  <div *ngIf="f3['choixB2'].errors['required']">الجواب الثاني مطلوب</div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="rep=='choixC'" (click)="reponse2(3)">
                              </div>
                              <div class="form-group col-md-10" [formGroup]="registerForm3">
                                <input placeholder="الجواب الثالث" type="text" formControlName="choixC2" name="choixC"
                                  id="choixC" value="" class="form-control" [(ngModel)]="choixC2">
                                <div *ngIf="submitted3 && f3['choixC2'].errors" class="invalid-feedback">
                                  <div *ngIf="f3['choixC2'].errors['required']">الجواب الثالث مطلوب</div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="rep=='choixD'" (click)="reponse2(4)">
                              </div>
                              <div class="form-group col-md-10" [formGroup]="registerForm3">
                                <input placeholder="الجواب الرابع" type="text" formControlName="choixD2" name="choixD"
                                  id="choixD" value="" class="form-control" [(ngModel)]="choixD2">
                                <div *ngIf="submitted3 && f3['choixD2'].errors" class="invalid-feedback">
                                  <div *ngIf="f3['choixD2'].errors['required']">الجواب الرابع مطلوب</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3"></div>
                        </div>
                        <div class="row">
                          <div class="col-md-5"></div>
                          <div class="col-md-2" id="row4">
                            <button *ngIf="questionA.type == 'VF'"
                              (click)="modifierVF(questionA.id,question2,questionA.type)" type="button" 
                              class="btn lab" id="btr">تعديل</button>
                            <button *ngIf="questionA.type == 'Choix'"
                              (click)="modifierChoix(questionA.id,question2,choixA2,choixB2,choixC2,choixD2,questionA.type)"
                              type="button" class="btn lab"  id="btr">تعديل</button>
                          </div>
                          <div class="col-md-5"></div>
                        </div>
                      </form>
                    </div>
                  </ng-template>

                  <ng-template #content4 let-modal>
                    <div class="modal-body" dir="rtl">
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <br>
                      <h5 id="h3"> هل تريد حذف هذا السؤال ؟</h5>
                      <div class="d-flex justify-content-center">
                        <button id="btnP" (click)="delete(qt.id)" type="button" class="btn btn-primary"> &nbsp; &nbsp;
                          تأكيد &nbsp; &nbsp;</button>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
 
        </div>

        <div class="row" style="overflow-x:auto; direction: rtl;" *ngIf="showD">
          <table id="dtableD" datatable [dtOptions]="dtOptions3" class="table table-striped" dir="rtl" style="margin-top: 112px;">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">السؤال</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody *ngFor="let qt of questionDifficile; let index = index;">
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;">
                <td style="  border-left: none;border-top:none; border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{((currentPage * 10) -9)+index}}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ qt.text }}</td>
                <td  style="  border-right: none; border-top:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" >
                  <a id="a1" (click)="open1(longContent1); getquestionbyid(qt.id)">
                    <i class="material-icons" style="color: #30287a;">edit</i>
                  </a>
                  <a (click)="openSm2(content4)">
                    <i class="material-icons" style="color: rgb(206, 6, 6);">delete</i>
                  </a>
                  <ng-template #longContent1 let-modal>
                    <div class="modal-body" id="mbody" dir="rtl">
                      <div class="row" id="row5">
                        <div class="col-md-2">
                          <button type="button" class="close" aria-label="Close"
                            (click)="modal.close('Cross click'); qts1();">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      </div>
                      <form>
                        <div class="row">
                          <div class="col-md-4"></div>
                          <div class="col-md-4">
                            <div class="form-group" [formGroup]="registerForm2">
                              <select class="form-select" aria-label="Default select example" formControlName="niveau2"
                                [(ngModel)]="niveau2">
                                <option selected disabled value="">--إختر مستوى السؤال--</option>
                                <option value="Facile">سهل</option>
                                <option value="Moyen">متوسط</option>
                                <option value="Difficile">صعب</option>
                              </select>
                              <div *ngIf="submitted && f2['niveau2'].errors" class="invalid-feedback">
                                <div *ngIf="f2['niveau2'].errors['required']"> مستوى السؤال مطلوب </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4"></div>
                        </div>
                        <div class="row">
                          <div class="col-md-2"></div>
                          <div class="col-md-8">
                            <div class="form-group" [formGroup]="registerForm2">
                              <input placeholder="السؤال" type="text" formControlName="question2" name="question1"
                                id="question1" value="" class="form-control" [(ngModel)]="question2">
                              <div *ngIf="submitted2 && f2['question2'].errors" class="invalid-feedback">
                                <div *ngIf="f2['question2'].errors['required']">السؤال مطلوب</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2"></div>
                        </div>
                        <div class="row">
                          <h3>الإجابة</h3>
                        </div>
                        <div class="row" *ngIf="questionA.type == 'VF'">
                          <div class="col-md-3"></div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp"
                                  [checked]="questionA.reponseVF=='Vrai'" name="rp" (click)="reponseVF3(1)">
                              </div>
                              <div class="form-group col-md-10">
                                <input placeholder="صواب" disabled type="text" name="prenom" id="prenom" value=""
                                  class="form-control">
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="questionA.reponseVF=='Faux'" (click)="reponseVF3(2)">
                              </div>
                              <div class="form-group col-md-10">
                                <input placeholder="خطأ" disabled type="text" name="prenom" id="prenom" value=""
                                  class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3"></div>
                        </div>
                        <div class="row" *ngIf="questionA.type == 'Choix'">
                          <div class="col-md-3"></div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="rep=='choixA'" (click)="reponse2(1)">
                              </div>
                              <div class="form-group col-md-10" [formGroup]="registerForm3">
                                <input placeholder="الجواب الأول" type="text" formControlName="choixA2" name="choixA"
                                  id="choixA" value="" class="form-control" [(ngModel)]="choixA2">
                                <div *ngIf="submitted3 && f3['choixA2'].errors" class="invalid-feedback">
                                  <div *ngIf="f3['choixA2'].errors['required']">الجواب الأول مطلوب</div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="rep=='choixB'" (click)="reponse2(2)">
                              </div>
                              <div class="form-group col-md-10" [formGroup]="registerForm3">
                                <input placeholder="الجواب الثاني" type="text" formControlName="choixB2" name="choixB"
                                  id="choixB" value="" class="form-control" [(ngModel)]="choixB2">
                                <div *ngIf="submitted3 && f3['choixB2'].errors" class="invalid-feedback">
                                  <div *ngIf="f3['choixB2'].errors['required']">الجواب الثاني مطلوب</div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="rep=='choixC'" (click)="reponse2(3)">
                              </div>
                              <div class="form-group col-md-10" [formGroup]="registerForm3">
                                <input placeholder="الجواب الثالث" type="text" formControlName="choixC2" name="choixC"
                                  id="choixC" value="" class="form-control" [(ngModel)]="choixC2">
                                <div *ngIf="submitted3 && f3['choixC2'].errors" class="invalid-feedback">
                                  <div *ngIf="f3['choixC2'].errors['required']">الجواب الثالث مطلوب</div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-2">
                                <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                                  [checked]="rep=='choixD'" (click)="reponse2(4)">
                              </div>
                              <div class="form-group col-md-10" [formGroup]="registerForm3">
                                <input placeholder="الجواب الرابع" type="text" formControlName="choixD2" name="choixD"
                                  id="choixD" value="" class="form-control" [(ngModel)]="choixD2">
                                <div *ngIf="submitted3 && f3['choixD2'].errors" class="invalid-feedback">
                                  <div *ngIf="f3['choixD2'].errors['required']">الجواب الرابع مطلوب</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3"></div>
                        </div>
                        <div class="row">
                          <div class="col-md-5"></div>
                          <div class="col-md-2" id="row4">
                            <button *ngIf="questionA.type == 'VF'"
                              (click)="modifierVF(questionA.id,question2,questionA.type)" type="button" id="btr"
                              class="btn lab">تعديل</button>
                            <button *ngIf="questionA.type == 'Choix'"
                              (click)="modifierChoix(questionA.id,question2,choixA2,choixB2,choixC2,choixD2,questionA.type)"
                              type="button" class="btn lab"  id="btr">تعديل</button>
                          </div>
                          <div class="col-md-5"></div>
                        </div>
                      </form>
                    </div>
                  </ng-template>
                  <ng-template #content4 let-modal>
                    <div class="modal-body" dir="rtl">
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <br>
                      <h5 id="h3"> هل تريد حذف هذا السؤال ؟</h5>
                      <div class="d-flex justify-content-center">
                        <button id="btnP" (click)="delete(qt.id)" type="button" class="btn btn-primary"> &nbsp; &nbsp;
                          تأكيد &nbsp; &nbsp;</button>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
            </tbody>
    
          </table>
          
        </div>
      </div>
    </div>

    <ng-template #longContent let-modal>
      <div class="modal-body" id="mbody" dir="rtl">
        <div class="row" id="row5">
          <div class="col-md-2">
            <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click'); qts1();">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <form>
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-6">
              <div class="form-group" [formGroup]="registerForm">
                <select class="form-select" aria-label="Default select example" formControlName="niveau"
                  [(ngModel)]="niveau">
                  <option selected disabled value="">--إختر مستوى السؤال--</option>
                  <option value="Facile">سهل</option>
                  <option value="Moyen">متوسط</option>
                  <option value="Difficile">صعب</option>
                </select>
                <div *ngIf="submitted && f['niveau'].errors" class="invalid-feedback">
                  <div *ngIf="f['niveau'].errors['required']"> مستوى السؤال مطلوب </div>
                </div>
              </div>
            </div>
            <div class="col-md-2"></div>
          </div>
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-4">
              <div class="row">
                <div class="form-group col-md-2" style="padding-right: 1px !important; ">
                  <input style="vertical-align: middle !important;" type="radio" id="qts" checked name="qts"
                    (click)="qts(1)">
                </div>
                <div class="form-group col-md-10" style="padding-right: 1px !important; ">
                  <input placeholder="صواب أو خطأ" disabled type="text" name="vf" id="vf" value="" class="form-control">
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="form-group col-md-2" style="padding-right: 1px !important; ">
                  <input style="vertical-align: middle !important;" type="radio" id="qts" name="qts" value="2"
                    (click)="qts(2)">
                </div>
                <div class="form-group col-md-10" style="padding-right: 1px !important; ">
                  <input placeholder="إحتمالات" disabled type="text" name="choix" id="choix" value=""
                    class="form-control">
                </div>
              </div>
            </div>
            <div class="col-md-2"></div>
          </div>
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <div class="form-group" [formGroup]="registerForm">
                <input placeholder="السؤال" type="text" formControlName="question1" name="question1" id="question1"
                  value="" class="form-control" [(ngModel)]="question1">
                <div *ngIf="submitted && f['question1'].errors" class="invalid-feedback">
                  <div *ngIf="f['question1'].errors['required']">السؤال مطلوب</div>
                </div>
              </div>
            </div>
            <div class="col-md-2"></div>
          </div>
          <div class="row">
            <h3>الإجابة</h3>
          </div>
          <div class="row" *ngIf="show">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <div class="row">
                <div class="form-group col-md-2">
                  <input style="vertical-align: middle !important;" type="radio" id="rp" checked name="rp"
                    (click)="reponseVF1(1)">
                </div>
                <div class="form-group col-md-10">
                  <input placeholder="صواب" disabled type="text" name="prenom" id="prenom" value=""
                    class="form-control">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-2">
                  <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp"
                    (click)="reponseVF1(2)">
                </div>
                <div class="form-group col-md-10">
                  <input placeholder="خطأ" disabled type="text" name="prenom" id="prenom" value="" class="form-control">
                </div>
              </div>
            </div>
            <div class="col-md-3"></div>
          </div>
          <div class="row" *ngIf="show1">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <div class="row">
                <div class="form-group col-md-2">
                  <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp" (click)="reponse(1)">
                </div>
                <div class="form-group col-md-10" [formGroup]="registerForm1">
                  <input placeholder="الجواب الأول" type="text" formControlName="choixA" name="choixA" id="choixA"
                    value="" class="form-control" [(ngModel)]="choixA">
                  <div *ngIf="submitted1 && f1['choixA'].errors" class="invalid-feedback">
                    <div *ngIf="f1['choixA'].errors['required']">الجواب الأول مطلوب</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-2">
                  <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp" (click)="reponse(2)">
                </div>
                <div class="form-group col-md-10" [formGroup]="registerForm1">
                  <input placeholder="الجواب الثاني" type="text" formControlName="choixB" name="choixB" id="choixB"
                    value="" class="form-control" [(ngModel)]="choixB">
                  <div *ngIf="submitted1 && f1['choixB'].errors" class="invalid-feedback">
                    <div *ngIf="f1['choixB'].errors['required']">الجواب الثاني مطلوب</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-2">
                  <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp" (click)="reponse(3)">
                </div>
                <div class="form-group col-md-10" [formGroup]="registerForm1">
                  <input placeholder="الجواب الثالث" type="text" formControlName="choixC" name="choixC" id="choixC"
                    value="" class="form-control" [(ngModel)]="choixC">
                  <div *ngIf="submitted1 && f1['choixC'].errors" class="invalid-feedback">
                    <div *ngIf="f1['choixC'].errors['required']">الجواب الثالث مطلوب</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-2">
                  <input style="vertical-align: middle !important;" type="radio" id="rp" name="rp" (click)="reponse(4)">
                </div>
                <div class="form-group col-md-10" [formGroup]="registerForm1">
                  <input placeholder="الجواب الرابع" type="text" formControlName="choixD" name="choixD" id="choixD"
                    value="" class="form-control" [(ngModel)]="choixD">
                  <div *ngIf="submitted1 && f1['choixD'].errors" class="invalid-feedback">
                    <div *ngIf="f1['choixD'].errors['required']">الجواب الرابع مطلوب</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3"></div>
          </div>
          <div class="row">
            <div class="d-flex justify-content-center">
              <button (click)="ajouter()" type="button" class="btn lab">إضافة</button>
            </div>
          </div>
        </form>
      </div>
    </ng-template>
  </div>
</body>



