<app-menu></app-menu>
<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">

<body dir="rtl" [ngStyle]="{'width': receivedData ? '82%' : 'auto'}">
    <div class="container p-5" dir="rtl"  *ngIf="user.role == 'globale'">
        <br><br>
        <div class="card">
            <div class="card-body">
                <br>
                <h2 id="h4"> عجلة الحظ </h2>
                <br><br>

                <form (ngSubmit)="submitForm()" #form="ngForm">
                    <div class="row" style="overflow-x:auto;direction: rtl;">
                        <table>
                            <th></th>
                            <th class="title"><label><strong>من </strong></label></th>
                            <th class="title"><label><strong> إلى</strong></label></th>
                            <th></th>
                            <tr *ngFor="let interval of intervals; let i = index">
                                <td>
                                    <label class="title"> <strong>نطاق {{ i + 1 }}</strong></label> &nbsp; &nbsp;
                                </td>
                                <td>
                                    <input class="input " type="number" [(ngModel)]="interval.min" name="min{{ i }}"
                                        required>
                                </td>
                                <td>
                                    <input class="input " type="number" [(ngModel)]="interval.max" name="max{{ i }}"
                                        required>
                                </td>
                                <td>
                                    <div class="d-flex">
                                        <input class="input " type="number" [(ngModel)]="interval.percentage"
                                            name="percentage{{ i }}" (ngModelChange)="calculateSum()" required>
                                        <strong class="percentage-icon">%</strong>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <div [ngClass]="{'green-text': sum === 100, 'red-text': sum !== 100}">
                                        <strong class="title">مجموع النسبة : {{ sum }} %</strong>
                                    </div>
                                </td>
                            </tr>
                        </table>

                        <div class="d-flex flex-row justify-content-center align-items-center">
                            <button class="button" type="submit">تسجيل</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>

    <div class="container" *ngIf="user.role != 'globale'" style="padding-top: 10% !important">
        <div class="container" style="width: 100%; height: 400px; max-height: 800px;background-size: contain;
      background-repeat: no-repeat;background-position: center center;display:block;margin-top:auto;
      margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
    </div>
    </div>
</body>