import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Publicite } from '../modules/publicite';
import { Event } from 'src/app/modules/event';
import { TextesDeroulante } from '../modules/textDeroulante';
import { OfferPublicity } from '../modules/offerPublicity';
import { UserPublicity } from '../modules/userPublicity';

@Injectable({
  providedIn: 'root'
})
export class PubliciteService {

  uri = environment.apiUrl;
  uriEvent = environment.urlMarket;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  constructor(private http: HttpClient) { }

  getAllpublicite(): Observable<Publicite[]> {
    const url_get = this.uri + 'publicites';
    return this.http.get<Publicite[]>(url_get, { headers: this.headers });
  }

  getEvent(): Observable<Event[]> {
    const url_get = this.uriEvent + 'events';
    return this.http.get<Event[]>(url_get, { headers: this.headers });
  }



  addpublicite(data: FormData) {
    this.http.post(`${this.uri}addpublicite`, data, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'publicites';
    return this.http.get<Publicite[]>(url_get, { headers: this.headers });
  }

  addtextderoulante(text:string){
    const obj = {
      text: text,
      type: "admin",
      numberViews : "0",
      state:'accept'
    }
    return this.http.post(`${this.uri}userPublicities`, obj, { headers: this.headers });
  }

  gettextderoulante() {
    const url_get = this.uri + 'userPublicities';
    return this.http.get<UserPublicity[]>(url_get, { headers: this.headers });
  }
  getofferPublicities(){
    const url_get = this.uri + 'offerPublicities';
    return this.http.get<OfferPublicity[]>(url_get, { headers: this.headers });
  }
  getuserPublicitiesDemand(){
    const url_get = this.uri + 'indexInProgress';
    return this.http.get<UserPublicity[]>(url_get, { headers: this.headers });
  }

  addevent(data: FormData) {
    this.http.post(`${this.uriEvent}events`, data, { headers: this.headers })
      .subscribe();
    const url_get = this.uriEvent + 'events';
    return this.http.get<Event[]>(url_get, { headers: this.headers });
  }

  updatetextderoulante(text: string, id: string) {
    const obj = {
      text: text,
    };
    return  this.http.put(`${this.uri}userPublicities/${id}`, obj, { headers: this.headers });
  }
  updateofferPublicities( numberViews: string, price:string, id: string) {
    const obj = {
      numberViews: numberViews,
      price: price
    };
    return  this.http.put(`${this.uri}offerPublicities/${id}`, obj, { headers: this.headers });
  }

  updatepublicite(data: FormData, id: string) {
    this
      .http
      .post(`${this.uri}updatepublicite/${id}`, data, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'publicites';
    return this.http.get<Publicite[]>(url_get, { headers: this.headers });
  }

  updateevent(data: FormData, id: string) {
    this
      .http
      .post(`${this.uriEvent}updateevent/${id}`, data, { headers: this.headers })
      .subscribe();
    const url_get = this.uriEvent + 'events';
    return this.http.get<Event[]>(url_get, { headers: this.headers });
  }


  deletepublicite(id: string) {
    return this.http.delete(`${this.uri}deletepublicite/` + id, { headers: this.headers });
  }

  deletetextderoulante(id: string){
    return this.http.delete(`${this.uri}userPublicities/` + id, { headers: this.headers });
  }
  deleteofferPublicities(id: string){
    return this.http.delete(`${this.uri}offerPublicities/` + id, { headers: this.headers });
  }
  deleteevent(id: string) {
    return this.http.delete(`${this.uriEvent}events/` + id, { headers: this.headers });
  }

  update(id: string) {
    const obj = {};
    return this.http.put(`${this.uri}acceptUserPublicity/${id}`, obj, { headers: this.headers });
  }

  updaterefuse(id: string, admin : string) {
    const obj = {admin : admin};
    return this.http.put(`${this.uri}refuseUserPublicity/${id}`, obj, { headers: this.headers });
  }

  updaterefuseC(reason: string, id: string, admin : string) {
    const obj = {
      reason: reason,
      admin : admin
    };
    return this.http.put(`${this.uri}refuseUserPublicityWithReason/${id}`, obj, { headers: this.headers });
  }


}
