<app-menu></app-menu>

<body dir="rtl">
    <div class="container pt-5" *ngIf="user.role == 'globale' || user.actualite == 1">
        <h2>تعديل المنشور</h2>
        <br>
        <div class="row">
            <div class="bord row">
                <form [formGroup]="registerForm" enctype="multipart/form-data" *ngIf="post &&
                     post.type=='imageText'">

                    <div class="textarea">
                        <textarea maxlength="500" formControlName="texte" id="" [(ngModel)]="texte" name="texte"
                            style="max-height: 100px" name="" id="" class="form-control " cols="30"
                            rows="3">{{texte}}</textarea>
                            <div *ngIf="submitted && f['texte'].errors" class="invalid-feedback">
                                <div *ngIf="f['texte'].errors['required']"> يجب إدخال المنشور</div>
                            </div>
                    </div>

                    <div formArrayName="images">
                        <div class="images-container align-items-center justify-content-center">
                            <button class="button btn-pagination" *ngIf="imageArray.controls.length > 3"
                                (click)="previousImage()" [disabled]="currentIndex === 0"> <span
                                    class="material-icons">arrow_right</span> </button>

                            <div *ngFor="let imageControl of imageArray.controls; let i = index">
                                <div class="form-group images col-md-3"
                                    *ngIf="i >= currentIndex && i < currentIndex + 3">

                                    <label class="labelImg1 col-img d-flex flex-row align-items-center"
                                        [for]="'image' + i">

                                        <img class="img-fluid" *ngIf="getSrc(i)"  [src]="getSrc(i)">

                                    </label>
                                    <input type="file" [id]="'image' + i" (change)="onFileSelected($event, i)"
                                        accept="image/*" style="display:none">
                                </div>
                            </div>

                            <button class="button btn-pagination" *ngIf="imageArray.controls.length > 3"
                                (click)="nextImage()"
                                [disabled]="currentIndex === (imageArray.controls.length - 1)"><span
                                    class="material-icons">arrow_left</span></button>
                        </div>
                    </div>
                    <br><br>
                    <div *ngIf="imageArray.controls.length < 7"
                        class="d-flex flex-row justify-content-center align-items-center">
                        <button (click)="addImage()" class="mb-5 btn1">إضافة صورة</button>
                    </div>

                    <br>
                    <div class="d-flex flex-row justify-content-center align-items-center">
                        <button [disabled]="registerForm.invalid" type="submit" #submitButton1
                            (click)="updateImageText()" class="btn1">تعديل</button>
                    </div>
                </form>

                <form [formGroup]="registerForm2" enctype="multipart/form-data" *ngIf="post &&
                  post.type=='image' ">

                    <div formArrayName="images">
                        <div class="images-container align-items-center justify-content-center">
                            <button class="button btn-pagination" *ngIf="imageArray.controls.length > 3"
                                (click)="previousImage()" [disabled]="currentIndex === 0"> <span
                                    class="material-icons">arrow_right</span> </button>

                            <div *ngFor="let imageControl of imageArray.controls; let i = index">
                                <div class="form-group images col-md-3"
                                    *ngIf="i >= currentIndex && i < currentIndex + 3">

                                    <label class="labelImg1 col-img d-flex flex-row align-items-center"
                                        [for]="'image' + i">
                                        <img class="img-fluid" *ngIf="getSrc(i)"
                                            [src]="getSrc(i)">

                                    </label>
                                    <input type="file" [id]="'image' + i" (change)="onFileSelected($event, i)"
                                        accept="image/*" style="display:none">
                                </div>
                            </div>

                            <button class="button btn-pagination" *ngIf="imageArray.controls.length > 3"
                                (click)="nextImage()"
                                [disabled]="currentIndex === (imageArray.controls.length - 1)"><span
                                    class="material-icons">arrow_left</span></button>
                        </div>
                    </div>
                    <br><br>
                    <div *ngIf="imageArray.controls.length < 7"
                        class="d-flex flex-row justify-content-center align-items-center">
                        <button (click)="addImage()" class="mb-5 btn1">إضافة صورة</button>
                    </div>

                    <br>
                    <div class="d-flex flex-row justify-content-center align-items-center">
                        <button [disabled]="registerForm2.invalid" type="submit" #submitButton1
                            (click)="updateImage();" class="btn1">تعديل</button>
                    </div>
                </form>

                <form [formGroup]="registerForm" enctype="multipart/form-data" *ngIf="post &&
                (post.type=='text')">

                    <div class="textarea">
                        <textarea maxlength="500" formControlName="texte" id="" [(ngModel)]="texte" name="texte"
                            style="max-height: 100px" name="" id="" class="form-control " cols="30"
                            rows="3">{{texte}}</textarea>
                            <div *ngIf="submitted && f['texte'].errors" class="invalid-feedback">
                                <div *ngIf="f['texte'].errors['required']"> يجب إدخال المنشور</div>
                            </div>
                    </div>
                    <br><br>

                    <br>
                    <div class="d-flex flex-row justify-content-center align-items-center">
                        <button [disabled]="registerForm.invalid" type="submit" #submitButton1
                            (click)="updateText();" class="btn1">تعديل</button>
                    </div>
                </form>

                <form [formGroup]="registerForm" enctype="multipart/form-data" *ngIf="post &&
                post.type=='videoText'">

                    <div class="textarea">
                        <textarea maxlength="500" formControlName="texte" id="" [(ngModel)]="texte" name="texte"
                            style="max-height: 100px" name="" id="" class="form-control " cols="30"
                            rows="3">{{texte}}</textarea>
                            <div *ngIf="submitted && f['texte'].errors" class="invalid-feedback">
                                <div *ngIf="f['texte'].errors['required']"> يجب إدخال المنشور</div>
                            </div>
                    </div>
                    <br>
                    <div class="row video">
                        <video height="400" controls>
                            <source [src]=" post.content[0].video" type="video/mp4">
                        </video>
                    </div>

                    <br>
                    <div class="d-flex flex-row justify-content-center align-items-center">
                        <button [disabled]="registerForm.invalid" type="submit" #submitButton1
                            (click)="updateText();" class="btn1">تعديل</button>
                    </div>
                </form>
            </div>
        </div>

        <br><br>
    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.actualite == 0 " style="padding-top: 10% !important">
        <div class="container" style="width: 100%; height: 400px; max-height: 800px;background-size: contain;
        background-repeat: no-repeat;background-position: center center;display:block;margin-top:auto;
        margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
        </div>
    </div>
</body>