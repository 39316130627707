import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Theme } from '../modules/theme';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  uri = environment.urlChatroom;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });

  constructor(private http: HttpClient) { }


  getTheme(): Observable<Theme[]> {
    const url_get = this.uri + 'themes';
    return this.http.get<Theme[]>(url_get, { headers: this.headers });
  }



  addTheme(data: FormData) {
    this
      .http
      .post(`${this.uri}themes`, data, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'themes';
    return this.http.get<Theme[]>(url_get, { headers: this.headers });
  }



  updateTheme(id: string, data: FormData) {

    this
      .http
      .post(`${this.uri}updatetheme/${id}`, data, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'themes';
    return this.http.get<Theme[]>(url_get, { headers: this.headers });
  }




  deleteTheme(id: string) {
    return this.http.delete(`${this.uri}themes/` + id, { headers: this.headers });
  }


}
