<app-menu></app-menu>

<body dir="rtl" [ngStyle]="{'width': receivedData ? '82%' : 'auto'}" >
    <div class="container pt-5" *ngIf="user.role == 'globale' || user.gift == 1">

        <h2 style="margin-right: 57px;">هدايا الأخبار</h2>
        <br>
        <div class="row row1">
            <div class="col-md-4">
                <form [formGroup]="registerForm1" enctype="multipart/form-data">
                    <div id="first1"class="card" style="background-color: transparent; background-color: transparent;border: 1px dashed #00ddf3;height:243px;width: 281px;">
                        <h4 class="center" style="background-color: #CBF1FF;border-radius: 8px;width: 165px; margin-right: 67px;margin-top: -18px;">هدايا قيمة</h4>
                      
                        <div class="cardimg">
                            <div class=" col-img d-flex flex-row justify-content-center align-items-center ">
                              <label   class="btn2" for="inputGroupFile01">
                              <img class="img-fluid img" *ngIf="link1 ==''" src="../assets/present gift.png" alt="">
                                <img class="img-fluid img" *ngIf="link1 !=''" [src]="link1" height="200px"
                                    width="200px">
                                  </label>
                             </div>
                        </div>
                        <div class="d-flex flex-row justify-content-center align-items-center">
                            <!-- <label  class="disable btn2"  for="inputGroupFile01">تحميل</label> -->

                            <input (change)="selectImage1($event)" accept="image/*" [(ngModel)]="img1" name="img1"
                                accept="image/*" formControlName="img1" style="display:none" type="file"
                                class="custom-file-input" id="inputGroupFile01">
                        </div>

                        <div class="d-flex" id="first" style="margin-right: 86%;width: 221px;margin-top: -121px;margin-right: 360px;">
                          <div  id="car" class="card" style="height: 91px;width:126px;background-color: white;margin-right: -162px;margin-top: -1px;
                          box-shadow: 3px 3px 3px darkgrey; border:none;">
                            <h4><p class="prix" style="text-align: center;">المبلغ</p></h4> <br>
                            <div style="display: flex; align-items: center;">
                            <input   style="margin-right:0px;" type="number" oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" min="1" (keypress)="keyPressNumbers($event)" style="outline: none;margin-inline: auto;border: none;border-bottom: 1px solid #959595;padding-bottom: 0px;width: 100%;box-shadow: none;border-radius: 0px;margin-top: -25px; "
                                class="inp form-control" [(ngModel)]="price1" name="price1"  formControlName="price1">
                                <span style="margin-right: -25px;margin-top: -15px;">نقطة</span>
                          </div>
                      </div>
                        </div>
                        <div *ngIf="(submitted1 && registerForm1.invalid)|| (price1=='0')" class="invalid-feedback">
                            <div *ngIf="registerForm1.invalid || (price1=='0')"style="
                            margin-top: -20px;
                            margin-right: 9px;"> <span>يجب إدخال المعطيات</span> </div>
                        </div>
                     
                         
                        <br>
                        <div class="d-flex flex-row justify-content-center align-items-center">
                              <button  (click)="ajouter1()" class=" btn1" id="btt" style="
                             margin-right: 203px;
                             margin-top: -14px;
                             color: black;
                             background-color: #CBF1FF;
                         ">تسجيل</button>
                            <!-- <button class="  btn1">تسجيل</button> -->
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-4">
                <form [formGroup]="registerForm2" enctype="multipart/form-data">
                    <div class="card"  id="first1" style="background-color: transparent; background-color: transparent;border: 1px dashed #00ddf3;height:243px;width: 280px;">

                      <h4 class="center" style="background-color: #CBF1FF;border-radius: 8px;width: 165px; margin-right: 67px;margin-top: -18px;">هدايا متوسطة</h4>
                    
                      <div class="cardimg" >
                            <div class=" col-img d-flex flex-row justify-content-center align-items-center ">
                              <label   class="btn2" for="inputGroupFile02">
                                <img class="img-fluid img" *ngIf="link2 ==''" src="../assets/present gift.png" alt="">
                                <img class="img-fluid img" *ngIf="link2 !=''" [src]="link2" height="200px"
                                    width="200px">
                                  </label>
                            </div>
                        </div>
                        <div class="d-flex flex-row justify-content-center align-items-center">

                            <input (change)="selectImage2($event)" [(ngModel)]="img2" name="img2" formControlName="img2"
                                accept="image/*" style="display:none" type="file" class="custom-file-input"
                                id="inputGroupFile02">
                        </div>


                        <div class="d-flex" style="margin-top:-109pxpx; margin-right:55%">

                          <div class="d-flex" style="margin-right: 86%;width: 221px;margin-top: -121px;margin-right: 360px;">
                            <div id="sas"  class="card" style="height: 91px;width:126px;background-color: white;margin-right: -330px;margin-top: -1px;
                            box-shadow: 3px 3px 3px darkgrey; border:none;">
                              <h4><p class="prix" style="text-align: center;">المبلغ</p></h4>

                            <input type="number" min="1" onpaste="return false;" (keypress)="keyPressNumbers($event)"
                                class="inp form-control" [(ngModel)]="price2" name="price2" oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" formControlName="price2" style="margin-top:-4px;outline: none; margin-inline: auto;border:none;border-bottom: 1px solid #959595;padding-bottom: 0px; width:90%;box-shadow: none;border-radius:0px ">
                                <span style="margin-right: 97px;margin-top: -25px;" id="pt">نقطة</span>
                                </div>  </div>

                          </div>
                  
                          <div *ngIf="(submitted2 && registerForm2.invalid)|| (price2=='0') " class="invalid-feedback">
                            <div *ngIf="registerForm2.invalid || (price2=='0')" style="
                              margin-top: -26px;
                                  margin-right: 16px;
                                  padding-bottom: 12px;
                        "> يجب إدخال المعطيات </div>
                        </div>
                        <br>
                        <div class="d-flex flex-row justify-content-center align-items-center">
                          <button  (click)="ajouter2()" id="btt" class=" btn1" style="
                          margin-right: 199px;
                          margin-top: -33px;
                          color: black;
                          background-color: #CBF1FF;
                      ">تسجيل</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-4">
                <form [formGroup]="registerForm3" enctype="multipart/form-data">
                    <div class="card"  id="first1"  style="background-color: transparent; background-color: transparent;border: 1px dashed #00ddf3;height:243px;width: 280px;">
                      <h4 class="center" style="background-color: #CBF1FF;border-radius: 8px;width: 165px; margin-right: 67px;margin-top: -18px;">هدايا بسيطة</h4>
                     
                      <div class="cardimg">
                            <div class=" col-img d-flex flex-row justify-content-center align-items-center ">
                              <label   class="btn2" for="inputGroupFile03">
                                <img class="img-fluid img" *ngIf="link3 ==''" src="../assets/present gift.png" alt="">
                                <img class="img-fluid img" *ngIf="link3 !=''" [src]="link3" height="200px"
                                    width="200px">
                                    </label>
                            </div>
                        </div>
                        <div class="d-flex flex-row justify-content-center align-items-center">

                            <input (change)="selectImage3($event)" [(ngModel)]="img3" name="img3" accept="image/*"
                                formControlName="img3" style="display:none" type="file" class="custom-file-input"
                                id="inputGroupFile03">
                        </div>



                        <div class="d-flex" style="margin-right: 86%;width: 221px;margin-top: -121px;margin-right: 360px;">
                          <div id=aj3 class="card" style="height: 91px;width:126px;background-color: white;margin-right: -162px;margin-top: -1px;
                          box-shadow: 3px 3px 3px darkgrey; border:none;">
                            <h4><p class="prix" style="text-align: center;">المبلغ</p></h4>

                            <input type="number" min="1" onpaste="return false;" (keypress)="keyPressNumbers($event)" style="margin-top:-4px;outline: none; margin-inline: auto;border:none;border-bottom: 1px solid #959595;padding-bottom: 0px; width:90%;box-shadow: none;border-radius:0px "
                                class="inp form-control" [(ngModel)]="price3" name="price3" oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" formControlName="price3">
                                <span style="margin-right: 90px;margin-top: -24px;" id="pt">نقطة</span>
                                </div>  </div>
                                <div *ngIf="(submitted3 && registerForm3.invalid) || (price3=='0')" class="invalid-feedback">
                                  <div *ngIf="registerForm3.invalid || (price3=='0')"style="
                                  margin-top: -10px;
                                  margin-right: 16px;
                                  
                              "> يجب إدخال المعطيات</div>
                              </div>
                            
                              
                        <br>
                        <div class="d-flex flex-row justify-content-center align-items-center">
                            <button (click)="ajouter3()"id='btt' class="btn1"style="
                            margin-right: 199px;
                            margin-top: -12px;
                            color: black;
                            background-color: #CBF1FF;
                        ">تسجيل</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="dropdown">
            <button class="selec dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img src="assets/filter.png" alt=""> &nbsp; تصنيف الهدايا
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item"
                        (click)="showCher = true ; showT = false; currentPage = 1;currentPage1 = 1;currentPage2 = 1;currentPage3 = 1; showMoyen = false; showNon = false; getCadeauxCher();onPageChange1(1)">هدايا
                        قيمة</a> </li>
                <li><a class="dropdown-item"
                        (click)="showCher = false ; showT = false;currentPage = 1;currentPage1 = 1;currentPage2 = 1;currentPage3 = 1; showMoyen = true; showNon = false; getCadeauxMoyen();onPageChange2(1)">هدايا
                        متوسطة</a></li>
                <li><a class="dropdown-item"
                        (click)="showCher = false ; showT = false;currentPage = 1;currentPage1 = 1;currentPage2 = 1;currentPage3 = 1; showMoyen = false; showNon = true; getCadeauxNonCher();onPageChange3(1)">هدايا
                        بسيطة </a></li>
            </ul>
        </div>
        <div class="row row2" id="all"  id="all"style="        background-color: transparent;        border: 1px dashed #6c9aa6;   border-radius: 15px;
    " *ngIf="showT">
            <div class="col-md-3"
                *ngFor="let cadeau of cadeaux| slice: pageSize | slice: 0:itemsPerPage; let index = index;">
                <div id="care" class="card1   justify-content-center "style="border: 1px dashed #28d2ff;border-radius: 15px;height: 207px;width: 183px;">

                    <img class="img-fuild img1" src="{{cadeau.image}}" alt="" width="158px" > <br>
                    <div style="white-space: nowrap; "> {{cadeau.price}} <img src="../assets/Coin.png" alt=""></div>
                    <div class="d-flex" style="justify-content: center;" >
                                                <img (click)="openSm2(content4)" class="delete" height="37px" width="37px" src="assets/deleteic.png"
                        alt="">
                        <a (click)="openSm2(content3)" class="updatebtn"> <img  src="../assets/edit.png" alt="" style="
                          height: 37px;
                          width:37px;
                          margin-top: -5px;
                       "> </a>
                    </div>

                </div>
                <br><br>
                <ng-template #content4 let-modal>
                    <div class="modal-body" dir="rtl">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h5 id="h3"> هل تريد حذف هذه الهدية ؟</h5>
                        <div class="d-flex justify-content-center">
                            <button id="btnP" (click)="delete(cadeau.id,'showT')" type="button" class="btn btn-primary">&nbsp;
                                &nbsp; تأكيد &nbsp; &nbsp;</button>
                        </div>
                    </div>
                </ng-template>

                <ng-template #content3 let-modal>
                    <div class="modal-body" dir="rtl">
                        <form [formGroup]="registerForm4" enctype="multipart/form-data">
                            <div class="bord">
                                <div class="col-md-2">
                                    <button id="close" type="button" class="close" aria-label="Close"
                                      (click)="modal.close('Cross click'); ">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                <h5 style="text-align: center ;">تعديل المبلغ</h5>
                                <br>
                                <div class="d-flex">
                                  <p class="price" style=" margin-top: 3px;">المبلغ</p>
                                    <input type="number" min="1" onpaste="return false;" formControlName="price4" oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"
                                        [(ngModel)]="cadeau.price" (keypress)="keyPressNumbers($event)"
                                        class="inp form-control">
                                        
                                </div>
                                <div *ngIf="submitted4 && k['price4'].errors" class="invalid-feedback">
                                    <div *ngIf="k['price4'].errors['required']" style="text-align: right;"> يجب إدخال
                                        الثمن</div>
                                </div>
                                <br>
                                <div class="d-flex flex-row justify-content-center align-items-center">
                                    <button (click)="updateCadeau(cadeau.id, cadeau.price)" class="btn1"id="btt1">تعديل</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>

            </div>
            <br>
         <div id="pagina">
            <ngb-pagination dir="rtl" [maxSize]="5" *ngIf="cadeaux.length > itemsPerPage" 
                [collectionSize]="cadeaux.length" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage"
                (pageChange)="onPageChange(currentPage); p = $event">
                <ng-template ngbPaginationPrevious>
                    <button class="btn btn-link"style="padding: 0px;" >
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
      
                    </button>
                  </ng-template>
                  <ng-template ngbPaginationNext >
                    <button class="btn btn-link"style="padding: 0px;" >
                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                    
                  </button>
                  </ng-template></ngb-pagination>
                </div>
        </div>
        <div class="row row2" id="all" *ngIf="showCher"style="background-color: transparent; border: 1px dashed #6c9aa6; border-radius: 15px;">
            <div class="col-md-3"
                *ngFor="let cadeau of cadeauxCher| slice: pageSize1 | slice: 0:itemsPerPage; let index = index;">
                <div  id="care" class="card1   justify-content-center "style="border: 1px dashed #28d2ff;border-radius: 15px;height: 207px;width: 183px;">

                    <img class="img-fuild img1" src="{{cadeau.image}}" alt="" width="158px" > <br>
                    <div style="white-space: nowrap; "> {{cadeau.price}} <img src="../assets/Coin.png" alt=""></div>
                    <div class="d-flex" style="justify-content: center;" >
                                                <img (click)="openSm2(content4)" class="delete" height="37px" width="37px" src="assets/deleteic.png"
                        alt="">
                        <a (click)="openSm2(content3)" class="updatebtn"> <img  src="../assets/edit.png" alt="" style="
                          height: 37px;
                          width:37px;
                          margin-top: -5px;
                       "> </a>
                    </div>
 
                </div>
                <br><br>
                <ng-template #content4 let-modal>
                    <div class="modal-body" dir="rtl">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h5 id="h3"> هل تريد حذف هذه الهدية ؟</h5>
                        <div class="d-flex justify-content-center">
                            <button id="btnP" (click)="delete(cadeau.id, 'showCher')" type="button" class="btn btn-primary">&nbsp;
                                &nbsp; تأكيد &nbsp; &nbsp;</button>
                        </div>
                    </div>
                </ng-template>
                <ng-template #content3 let-modal>
                    <div class="modal-body" dir="rtl">
                        <form [formGroup]="registerForm4" enctype="multipart/form-data">
                            <div class="bord">
                                <div class="col-md-2">
                                    <button id="close" type="button" class="close" aria-label="Close"
                                      (click)="modal.close('Cross click'); ">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                <h5 style="text-align: center ;">تعديل المبلغ</h5>
                                <br>
                                <div class="d-flex">
                                    <p class="price">المبلغ</p>
                                    <input type="number" min="1" onpaste="return false;" formControlName="price4" oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"
                                        [(ngModel)]="cadeau.price" (keypress)="keyPressNumbers($event)"
                                        class="inp form-control">
                                </div>
                                <div *ngIf="submitted4 && k['price4'].errors" class="invalid-feedback">
                                    <div *ngIf="k['price4'].errors['required']" style="text-align: right;"> يجب إدخال
                                        الثمن</div>
                                </div>
                                <br>
                                <div class="d-flex flex-row justify-content-center align-items-center">
                                    <button (click)="updateCadeau(cadeau.id, cadeau.price)" class="btn1" id="btt1">تعديل</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </div>
            <br>
            <div id="pagina">
            <ngb-pagination dir="rtl" [maxSize]="5" *ngIf="cadeauxCher.length > itemsPerPage"
                [collectionSize]="cadeauxCher.length" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage1"
                (pageChange)="onPageChange1(currentPage1); p = $event"><ng-template ngbPaginationPrevious>
                    <button class="btn btn-link"style="padding: 0px;">
                     <i class="fa fa-chevron-right" aria-hidden="true"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPaginationNext >
                    <button class="btn btn-link"style="padding: 0px;">
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                  </button>
                  </ng-template></ngb-pagination>
            </div>
        </div>
        <div class="row row2" id="all" *ngIf="showMoyen" style="background-color: transparent; border: 1px dashed #6c9aa6; border-radius: 15px;">
            <div class="col-md-3"
                *ngFor="let cadeau of cadeauxMoyen| slice: pageSize2 | slice: 0:itemsPerPage; let index = index;">
                <div id="care" class="card1   justify-content-center "style="border: 1px dashed #28d2ff;border-radius: 15px;height: 207px;width: 183px;">

                    <img class="img-fuild img1" src="{{cadeau.image}}" alt="" width="158px" > <br>
                    <div style="white-space: nowrap;"> {{cadeau.price}} <img src="../assets/Coin.png" alt=""></div>
                    <div class="d-flex" style="justify-content: center;" >
                                                <img (click)="openSm2(content4)" class="delete" height="37px" width="37px" src="assets/deleteic.png"
                        alt="">
                        <a (click)="openSm2(content3)" class="updatebtn"> <img  src="../assets/edit.png" alt="" style="
                          height: 37px;
                          width:37px;
                          margin-top: -5px;
                       "> </a>
                    </div>

                </div>
                <br><br>
                <ng-template #content4 let-modal>
                    <div class="modal-body" dir="rtl">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h5 id="h3"> هل تريد حذف هذه الهدية ؟</h5>
                        <div class="d-flex justify-content-center">
                            <button id="btnP" (click)="delete(cadeau.id,'showMoyen')" type="button" class="btn btn-primary">&nbsp;
                                &nbsp; تأكيد &nbsp; &nbsp;</button>
                        </div>
                    </div>
                </ng-template>
                <ng-template #content3 let-modal>
                    <div class="modal-body" dir="rtl">
                        <form [formGroup]="registerForm4" enctype="multipart/form-data">
                            <div class="bord">
                                <div class="col-md-2">
                                    <button id="close" type="button" class="close" aria-label="Close"
                                      (click)="modal.close('Cross click'); ">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                <h5 style="text-align: center ;">تعديل المبلغ</h5>
                                <br>
                                <div class="d-flex">
                                    <p class="price">المبلغ</p>
                                    <input type="number" min="1" onpaste="return false;" formControlName="price4" oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"
                                        [(ngModel)]="cadeau.price" (keypress)="keyPressNumbers($event)"
                                        class="inp form-control">
                                </div>
                                <div *ngIf="submitted4 && k['price4'].errors" class="invalid-feedback">
                                    <div *ngIf="k['price4'].errors['required']" style="text-align: right;"> يجب إدخال
                                        الثمن</div>
                                </div>
                                <br>
                                <div class="d-flex flex-row justify-content-center align-items-center">
                                    <button (click)="updateCadeau(cadeau.id, cadeau.price)" class="btn1" id="btt1">تعديل</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </div>
            <br>
            <div id="pagina">
            <ngb-pagination dir="rtl" [maxSize]="5" *ngIf="cadeauxMoyen.length > itemsPerPage" 
                [collectionSize]="cadeauxMoyen.length" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage2"
                (pageChange)="onPageChange2(currentPage2); p = $event"><ng-template ngbPaginationPrevious>
                    <button class="btn btn-link"style="padding: 0px;">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                    </button>
                  </ng-template>
                  <ng-template ngbPaginationNext >
                    <button class="btn btn-link"style="padding: 0px;">
                  <i class="fa fa-chevron-left" aria-hidden="true"></i>
                  </button>
                  </ng-template></ngb-pagination>
            </div>
        </div>
        <div class="row row2" id="all" *ngIf="showNon"style="background-color: transparent; border: 1px dashed #6c9aa6; border-radius: 15px;">
            <div class="col-md-3"
                *ngFor="let cadeau of cadeauxNonCher| slice: pageSize3 | slice: 0:itemsPerPage; let index = index;">
                <div id="care" class="card1   justify-content-center "style="border: 1px dashed #28d2ff;border-radius: 15px;height: 207px;width: 183px;">

                    <img class="img-fuild img1" src="{{cadeau.image}}" alt="" width="158px" > <br>
                    <div style="white-space: nowrap; ">{{cadeau.price}} <img src="../assets/Coin.png" alt=""></div>
                    <div class="d-flex" style="justify-content: center;" >
                                                <img (click)="openSm2(content4)" class="delete" height="37px" width="37px" src="assets/deleteic.png"
                        alt="">
                        <a (click)="openSm2(content3)" class="updatebtn"> <img  src="../assets/edit.png" alt="" style="
                          height: 37px;
                          width:37px;
                          margin-top: -5px;
                       "> </a>
                    </div>

                </div>
                <br><br>
                <ng-template #content4 let-modal>
                    <div class="modal-body" dir="rtl">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <br>
                        <h5 id="h3"> هل تريد حذف هذه الهدية ؟</h5>
                        <div class="d-flex justify-content-center">
                            <button id="btnP" (click)="delete(cadeau.id,'showNon')" type="button" class="btn btn-primary">&nbsp;
                                &nbsp; تأكيد &nbsp; &nbsp;</button>
                        </div>
                    </div>
                </ng-template>
                <ng-template #content3 let-modal>
                    <div class="modal-body" dir="rtl">
                        <form [formGroup]="registerForm4" enctype="multipart/form-data">
                            <div class="bord">
                                <div class="col-md-2">
                                    <button id="close" type="button" class="close" aria-label="Close"
                                      (click)="modal.close('Cross click'); ">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                <h5 style="text-align: center ;">تعديل المبلغ</h5>
                                <br>
                                <div class="d-flex">
                                    <p class="price">المبلغ</p>
                                    <input type="number" min="1" onpaste="return false;" formControlName="price4"  oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"
                                        [(ngModel)]="cadeau.price" (keypress)="keyPressNumbers($event)"
                                        class="inp form-control">
                                </div>
                                <div *ngIf="submitted4 && k['price4'].errors" class="invalid-feedback">
                                    <div *ngIf="k['price4'].errors['required']" style="text-align: right;"> يجب إدخال
                                        الثمن</div>
                                </div>
                                <br>
                                <div class="d-flex flex-row justify-content-center align-items-center">
                                    <button (click)="updateCadeau(cadeau.id, cadeau.price)" class="btn1" id="btt1">تعديل</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </div>
            <br>
            <div id="pagina">
                <ngb-pagination dir="rtl" [maxSize]="5" *ngIf="cadeauxNonCher.length > itemsPerPage"
                    [collectionSize]="cadeauxNonCher.length" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage3"
                    (pageChange)="onPageChange3(currentPage3); p = $event">
                    <ng-template ngbPaginationNext>
                        <button class="btn btn-link pagination-btn">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </button>
                    </ng-template>
                    <ng-template ngbPaginationPrevious>
                        <button class="btn btn-link pagination-btn">
                            <i class="fa fa-chevron-left" aria-hidden="true"></i>
                        </button>
                    </ng-template>
                </ngb-pagination>


                

            </div>
            </div>
        <br>
    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.gift == 0 " style="padding-top: 10% !important">
        <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
        background-repeat: no-repeat; background-position: center center;display:block;margin-top:auto;
        margin-bottom:auto; max-height: 800px;background-image: url('assets/droit.png')">
        </div>
    </div>
</body>


