import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { Admin } from 'src/app/modules/admin';
import { AdminService } from 'src/app/services/admin.service';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MustMatch } from '../must-match.validator';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-ajout-admin',
  templateUrl: './ajout-admin.component.html',
  styleUrls: ['./ajout-admin.component.css']
})
export class AjoutAdminComponent implements OnInit {
  utilisateur: Admin[];
  filteredItems: Admin[] = [];
  user: any = {};
  id: string;
  searchText: string;
  closeResult = '';
  nom: string;
  prenom: string;
  email: string;
  telephone: number;
  passwordac: string;
  password: string;
  repassword: string;
  registerForm: FormGroup;
  submitted = false;
  users = false;
  actualite = false;
  publicite = false;
  conversion = false;
  tousconversion = false;
  jeux = false;
  mobile = false;
  contact = false;
  counter = false;
  product = false;
  listdelegate = false;
  demanddelegate = false;
  block = false;
  signal = false;
  gift = false;
  theme = false;
  user_point = false;
  user_diamond = false;
  user_delete = false;
  user_block = false;
  user_message = false;
  event = false;
  store = false;
  pret = false;
  codeparrainage =false;
  receivedData: any;
  constructor(private serviceadmin: AdminService,
    private serviceauth: AuthentificationService,
    private modalService: NgbModal,
    private notifyService: NotificationService,
    private formBuilder: FormBuilder, private sharedService: SharedService,
    config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  ngOnInit() {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
    });
    this.receivedData = false;
    this.user = this.serviceauth.getCurrentUser();
    this.registerForm = this.formBuilder.group({
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: [null, Validators.required],
      password: ['', Validators.required],
      repassword: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'repassword')
    });
  }

  get f() { return this.registerForm.controls; }

  public onusers(value: boolean) {
    this.users = value;
  }

  public oncounter(value: boolean) {
    this.counter = value;
  }

  public ontheme(value: boolean) {
    this.theme = value;
  }

  public onsignal(value: boolean) {
    this.signal = value;
  }

  public ongift(value: boolean) {
    this.gift = value;
  }

  public onlistdelegate(value: boolean) {
    this.listdelegate = value;
  }

  public ondemanddelegate(value: boolean) {
    this.demanddelegate = value;
  }

  public onblock(value: boolean) {
    this.block = value;
  }

  public onproduct(value: boolean) {
    this.product = value;
  }

  public onactualite(value: boolean) {
    this.actualite = value;
  }

  public onpublicite(value: boolean) {
    this.publicite = value;
  }

  public onconversion(value: boolean) {
    this.conversion = value
  }

  public ontousconversion(value: boolean) {
    this.tousconversion = value;
  }

  public onjeux(value: boolean) {
    this.jeux = value;
  }

  public onmobile(value: boolean) {
    this.mobile = value;
  }

  public oncontact(value: boolean) {
    this.contact = value;
  }

  public onuserpoint(value: boolean) {
    this.user_point = value;
  }

  public onuserdiamond(value: boolean) {
    this.user_diamond = value;
  }

  public onusermessage(value: boolean) {
    this.user_message = value;
  }

  public onuserdelete(value: boolean) {
    this.user_delete = value;
  }

  public onuserblock(value: boolean) {
    this.user_block = value;
  }

  public onuserevent(value: boolean) {
    this.event = value;
  }

  public onuserstore(value: boolean) {
    this.store = value;
  }

  public onuserpret(value: boolean) {
    this.pret = value;
  }

  public oncodeparrainage(value: boolean) {
    this.codeparrainage = value;
  }
  ajouter() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.serviceadmin.addadmin(this.nom, this.prenom, this.email, this.password, this.telephone, "particulier",
      this.users, this.actualite, this.publicite, this.conversion, this.tousconversion, this.jeux, this.mobile,
      this.contact, this.counter, this.product, this.listdelegate, this.demanddelegate, this.block, this.signal, this.gift, this.theme
      , this.user_point, this.user_diamond, this.user_message, this.user_delete, this.user_block,  this.event,
       this.pret, this.store, this.codeparrainage)
      .pipe(first()).subscribe(() => {
        this.showToasterSuccess();
        this.modalService.dismissAll();
        this.nom = "";
        this.prenom = "";
        this.email = "";
        this.telephone = null;
        this.password = "";
        this.repassword = "";
        window.location.reload();
        this.submitted = false;
      });
  }
  showToasterSuccess() {
    this.notifyService.showSuccess("تمت اضافة مسؤول")
  }
}