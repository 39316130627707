import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Admin } from 'src/app/modules/admin';
import { Notificationadmin } from 'src/app/modules/notification';
import { AdminService } from 'src/app/services/admin.service';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { NotificationadminService } from 'src/app/services/notificationadmin.service';
import { SharedService } from 'src/app/shared.service';
import { MatToolbarModule } from '@angular/material/toolbar';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  notification: Notificationadmin[] = [];
  taille: number = 0;
  ChekedLast:boolean=false;
  user: boolean = true;
  actualite: boolean = false;
  publicite: boolean = false;
  conversion: boolean = false;
  tousconversion: boolean = false;
  question: boolean = false;
  paiement: boolean;
  mobile: boolean;
  isChecked: boolean =false;
  Cheked1: boolean=false;
  Cheked2: boolean=false; Cheked3: boolean=false; Cheked4: boolean=false; Cheked5: boolean=false;Cheked6: boolean=false;Cheked7: boolean=false;Cheked8: boolean=false;  Cheked9:boolean=false;Cheked10:boolean=false;
  Cheked11: boolean=false;
  nom: string;
  prenom: string;
  email: string;
  telephone: number;
  user1: Admin;
  user2: any = {};
  constructor(private authentificationService: AuthentificationService, private router: Router,private sharedService: SharedService,
    private servicenotificationadmin: NotificationadminService, private servicesadmin:AdminService) { }

  ngOnInit() {
    // this.getnotification();
  this.close2();
  this.user1 = this.authentificationService.getCurrentUser();
 

  }
  isDropdownOpen: boolean = false;

  // Function to toggle the dropdown
  sendDataToPage() {
   
    const dataToSend = true;
    this.sharedService.sendData(dataToSend);
  }
  sendDataToPage2() {
    const dataToSend = false;
    this.sharedService.sendData(dataToSend);
  }
  onlogout() {
    this.authentificationService.logout();
    this.router.navigate(['/']);
  }

  getnotification() {
    this.servicenotificationadmin.getAll().subscribe(data => {
      this.notification = data.filter(noti => noti.lu == "non")
      this.taille = this.notification.length;
    })
  }

  change(url: string, id: string) {
    this.servicenotificationadmin.addupdate(id).pipe(first()).subscribe(() => {
      this.router.navigate([url]);
      this.getnotification();
    })
  }

  cheked01() {
      this.Cheked2=false;
      this.Cheked3=false;
      this.Cheked4=false;
      this.Cheked1=false;
      this.Cheked6=false;
      this.Cheked7=false;
      this.Cheked8=false;
      this.Cheked9=false;
      this.Cheked10=false;
      this.Cheked11=false;


  }

  cheked02() {
    this.Cheked5=false;
    this.Cheked2=false;
    this.Cheked3=false;
    this.Cheked4=false;
    this.Cheked6=false;
    this.Cheked7=false;
    this.Cheked8=false;
    this.Cheked9=false;
    this.Cheked10=false;
    this.Cheked11=false;

}
cheked03() {
  this.Cheked1=false;
  this.Cheked5=false;
  this.Cheked2=false;
  this.Cheked4=false;
  this.Cheked6=false;
  this.Cheked7=false;
  this.Cheked8=false;
  this.Cheked9=false;
  this.Cheked10=false;
  this.Cheked11=false;

}

cheked04() {
  this.Cheked1=false;
  this.Cheked5=false;
  this.Cheked2=false;
  this.Cheked3=false;
  this.Cheked6=false;
  this.Cheked7=false;
  this.Cheked8=false;
  this.Cheked9=false;
  this.Cheked10=false;
  this.Cheked11=false;

}
cheked05() {
  this.Cheked1=false;
  this.Cheked5=false;
  this.Cheked4=false;
  this.Cheked3=false;
  this.Cheked6=false;
  this.Cheked7=false;
  this.Cheked8=false;
  this.Cheked9=false;
  this.Cheked10=false;
  this.Cheked11=false;

}
cheked06() {
  this.Cheked1=false;
  this.Cheked5=false;
  this.Cheked4=false;
  this.Cheked3=false;
  this.Cheked2=false;
  this.Cheked6=false;
  this.Cheked8=false;
  this.Cheked9=false;
  this.Cheked10=false;
  this.Cheked11=false;

}
cheked07() {
  this.Cheked1=false;
  this.Cheked5=false;
  this.Cheked4=false;
  this.Cheked3=false;
  this.Cheked2=false;
  this.Cheked6=false;
  this.Cheked7=false;
  this.Cheked9=false;
  this.Cheked10=false;
  this.Cheked11=false;

}
cheked08() {
  this.Cheked1=false;
  this.Cheked5=false;
  this.Cheked4=false;
  this.Cheked3=false;
  this.Cheked2=false;
  this.Cheked6=false;
  this.Cheked7=false;
  this.Cheked8=false;
  this.Cheked10=false;
  this.Cheked11=false;

}
cheked09() {
  this.Cheked1=false;
  this.Cheked5=false;
  this.Cheked4=false;
  this.Cheked3=false;
  this.Cheked2=false;
  this.Cheked6=false;
  this.Cheked7=false;
  this.Cheked9=false;
  this.Cheked8=false;
  this.Cheked11=false;

}

  cheked11() {

    this.Cheked5=true
      this.isChecked = true;
      console.log(this.Cheked5);
      this.Cheked2=false;
      this.Cheked3=false;
      this.Cheked4=false;
      this.Cheked1=false;
      this.Cheked6=false;
      this.Cheked8=false;
      this.Cheked9=false;
      this.Cheked10=false;
      this.Cheked11=false;

      this.sendDataToPage() 
  }

  cheked22(){
    this.Cheked1=true
      this.isChecked = true;
      this.Cheked2=false;
      this.Cheked3=false;
      this.Cheked4=false;
      this.Cheked5=false;
      this.Cheked6=false;
      this.Cheked8=false;
      this.Cheked9=false;
      this.Cheked10=false;
      this.Cheked11=false;

      this.sendDataToPage() 
}
cheked33(){
   this.Cheked2=true
    this.isChecked = true;
    this.Cheked1=false;
    this.Cheked3=false;
    this.Cheked4=false;
    this.Cheked5=false;
    this.Cheked6=false;
    this.Cheked8=false;
    this.Cheked9=false;
    this.Cheked10=false;
    this.Cheked11=false;

    this.sendDataToPage() 
}
cheked44(){
  this.Cheked3=true
    this.isChecked = true;
    this.Cheked1=false;
    this.Cheked2=false;
    this.Cheked4=false;
    this.Cheked5=false;
    this.Cheked6=false;
    this.Cheked8=false;
    this.Cheked9=false;
    this.Cheked10=false;
    this.Cheked11=false;

    this.sendDataToPage() 
}
cheked55(){
    this.Cheked4=true
    this.isChecked = true;
    this.Cheked1=false;
    this.Cheked3=false;
    this.Cheked2=false;
    this.Cheked5=false;
    this.Cheked6=false;
    this.Cheked8=false;
    this.Cheked9=false;
    this.Cheked10=false;
    this.Cheked11=false;

    this.sendDataToPage() 
}
cheked66(){
    this.Cheked6=true
    this.isChecked = true;
    this.Cheked1=false;
    this.Cheked3=false;
    this.Cheked4=false;
    this.Cheked5=false;
    this.Cheked2=false;
    this.Cheked8=false;
    this.Cheked9=false;
    this.Cheked10=false;
    this.Cheked11=false;

    this.sendDataToPage() 
}
cheked77(){
  this.Cheked7=true
  this.isChecked=true;
  this.Cheked1=false;
  this.Cheked3=false;
  this.Cheked4=false;
  this.Cheked5=false;
  this.Cheked2=false;
  this.Cheked6=false
  this.Cheked8=false;
  this.Cheked9=false;
  this.Cheked10=false;
  this.Cheked11=false;

  this.sendDataToPage() 
}
cheked88(){
  this.Cheked8=true
  this.isChecked=true;
  this.isChecked=true;
  this.Cheked1=false;
  this.Cheked3=false;
  this.Cheked4=false;
  this.Cheked5=false;
  this.Cheked2=false;
  this.Cheked6=false;
  this.Cheked7=false;
  this.Cheked9=false;
  this.Cheked10=false;
  this.Cheked11=false;

  this.sendDataToPage() 
}
cheked99(){
  this.Cheked9=true
  this.isChecked=true;
  this.isChecked=true;
  this.Cheked1=false;
  this.Cheked3=false;
  this.Cheked4=false;
  this.Cheked5=false;
  this.Cheked2=false;
  this.Cheked6=false;
  this.Cheked7=false;
  this.Cheked8=false;
  this.Cheked10=false;
  this.Cheked11=false;

  this.sendDataToPage() 
}
cheked10(){
  this.Cheked9=false;
  this.isChecked=true;
  this.isChecked=true;
  this.Cheked1=false;
  this.Cheked3=false;
  this.Cheked4=false;
  this.Cheked5=false;
  this.Cheked2=false;
  this.Cheked6=false;
  this.Cheked7=false;
  this.Cheked8=false;
  this.Cheked10=true;
  this.Cheked11=false;

  this.sendDataToPage() 
}
cheked100(){
  this.Cheked11=true;
  this.Cheked9=false;
  this.isChecked=true;
  this.Cheked1=false;
  this.Cheked3=false;
  this.Cheked4=false;
  this.Cheked5=false;
  this.Cheked2=false;
  this.Cheked6=false;
  this.Cheked7=false;
  this.Cheked8=false;
  this.Cheked10=false;
  console.log(this.Cheked11);

  this.sendDataToPage() 
}

close(){
  if(this.isChecked==false){
    this.Cheked1=false;
    this.Cheked2=false;
    this.Cheked3=false;
    this.Cheked4=false;
    this.Cheked5=false;
    this.Cheked6=false;
    this.Cheked8=false;
    this.Cheked9=false;
    this.Cheked10=false;
    this.Cheked11=false;

  }
}
close2(){
  if(this.Cheked1==true){
    this.Cheked2=false;
    this.Cheked3=false;
    this.Cheked4=false;
    this.Cheked5=false;
    this.Cheked6=false;
    this.Cheked8=false;
    this.Cheked9=false;
    this.Cheked10=false;
    this.Cheked11=false;

  }
  if(this.Cheked2==true){
    this.Cheked1=false;
    this.Cheked3=false;
    this.Cheked4=false;
    this.Cheked5=false;
    this.Cheked6=false;
    this.Cheked8=false;
    this.Cheked9=false;
    this.Cheked10=false;
    this.Cheked11=false;

  }
  if(this.Cheked3==true){
    this.Cheked1=false;
    this.Cheked2=false;
    this.Cheked4=false;
    this.Cheked5=false;
    this.Cheked6=false;
    this.Cheked8=false;
    this.Cheked9=false;
    this.Cheked10=false;
    this.Cheked11=false;


  }
  if(this.Cheked4==true){
    this.Cheked1=false;
    this.Cheked3=false;
    this.Cheked2=false;
    this.Cheked5=false;
    this.Cheked6=false;
    this.Cheked8=false;
    this.Cheked9=false;
    this.Cheked10=false;
    this.Cheked11=false;

  }
  if(this.Cheked5==true){
    this.Cheked1=false;
    this.Cheked3=false;
    this.Cheked4=false;
    this.Cheked2=false;
    this.Cheked6=false;
    this.Cheked8=false;
    this.Cheked9=false;
    this.Cheked10=false;
    this.Cheked11=false;

  }
  if(this.Cheked6==true){
    this.Cheked1=false;
    this.Cheked3=false;
    this.Cheked4=false;
    this.Cheked5=false;
    this.Cheked2=false;
    this.Cheked8=false;
    this.Cheked9=false;
    this.Cheked10=false;
    this.Cheked11=false;

  }
  if(this.Cheked7==true){
    this.Cheked1=false;
    this.Cheked2=false;
    this.Cheked3=false;
    this.Cheked4=false;
    this.Cheked5=false;
    this.Cheked6=false;
    this.Cheked8=false;
    this.Cheked9=false;
    this.Cheked10=false;
    this.Cheked11=false;

  }
  if(this.Cheked9==true){
    this.Cheked1=false;
    this.Cheked2=false;
    this.Cheked3=false;
    this.Cheked4=false;
    this.Cheked5=false;
    this.Cheked6=false;
    this.Cheked8=false;
    this.Cheked7=false;
    this.Cheked10=false;
    this.Cheked11=false;


  }
  if(this.Cheked10==true){
    this.Cheked1=false;
    this.Cheked2=false;
    this.Cheked3=false;
    this.Cheked4=false;
    this.Cheked5=false;
    this.Cheked6=false;
    this.Cheked8=false;
    this.Cheked7=false;
    this.Cheked9=false;
    this.Cheked11=false;

  }
  if(this.Cheked11==true){
    this.Cheked1=false;
    this.Cheked2=false;
    this.Cheked3=false;
    this.Cheked4=false;
    this.Cheked5=false;
    this.Cheked6=false;
    this.Cheked8=false;
    this.Cheked7=false;
    this.Cheked9=false;
    this.Cheked10=false;

  }
}


  changetout() {
    this.servicenotificationadmin.changetout().pipe(first()).subscribe(() => {
      this.getnotification();
    })
  }


}
