<app-menu class="menu"></app-menu>

<body dir="rtl" [ngStyle]="{'width': receivedData ? '82%' : '100%'}">
    <div class="container pt-5" *ngIf="user.role == 'globale' || user.gift == 1">
        <div class="row">
            <div class="col-md-3">
                <h2 class="pt-4">نسبة الحصول على النقاط</h2>
            </div>
            <div class="col-md-4">
                <div class="cardimg1 -progress " style="background-color:white">
                    <div class="d-flex justify-content-center">
                        <div class="inner-addon left-addon">
                            <p class="glyphicon">% </p>
                            <input type="text" onKeyPress="if(this.value.length==3) return false;" onkeydown="javascript: return event.keyCode === 8 ||
                        event.keyCode === 46 ? true : !isNaN(Number(event.key))" class="inpu form-control"
                                name="percentage" [(ngModel)]="percentage">
                        </div>
                        <button (click)="updatepourcentage()" class="btn lab1" style="
                        border-color: rgb(248, 248, 248);
                    ">تسجيل</button>
                    </div>
                    <span style="color: red;margin-right: 85px;">{{message}}</span>
                </div>
            </div>
            <div class="col-md-2">
                <img class="img-fluid imgProgress" src="assets/senddiamond.png" alt="">
            </div>
        </div>
        <h2 style="margin-right: 73px;">هدايا الغرف</h2>
        <br>
        <div class="row row1">
            <div class="col-md-4">
                <form [formGroup]="registerForm1" enctype="multipart/form-data">
                    <div class="card" id="first1"
                        style="background-color: transparent; background-color: transparent;border: 1px dashed #00ddf3;height:247px;width: 307px;">

                        <h4 class="center"
                            style="background-color: #CBF1FF;border-radius: 8px;width: 165px; margin-right: 60px;margin-top: -18px;">
                            هدايا قيمة</h4>
                        <div class="cardimg">
                            <div class=" col-img d-flex flex-row justify-content-center align-items-center ">
                                <label class="disable btn2" for="inputGroupFile01">
                                    <img class="img-fluid img" *ngIf="link1 ==''" src="../assets/present gift.png"
                                        alt="">
                                    <img class="img-fluid img" *ngIf="link1 !=''" [src]="link1" height="200px"
                                        width="200px">
                                </label>
                            </div>
                        </div>
                        <div class="d-flex flex-row justify-content-center align-items-center">
                            <input (change)="selectImage1($event)" accept="image/*" [(ngModel)]="img1" name="img1"
                                accept="image/*" formControlName="img1" style="display:none" type="file"
                                class="custom-file-input" id="inputGroupFile01">
                        </div>
                        <br>
                        <div class="d-flex"
                            style="margin-right: 86%;width: 221px;margin-top: -121px;margin-right: 360px;">
                            <div id="car" class="card" style="height: 91px;width:136px;background-color: white;margin-right: -162px;margin-top: -12px;
                          box-shadow: 3px 3px 3px darkgrey; border:none;">
                                <h4>
                                    <p class="prix" style="text-align: center;font-size: 1.2rem;">المبلغ</p>
                                </h4> <br>
                                <div style="display: flex; align-items: center; margin-top: -19px;">
                                    <input [attr.disabled]="true" type="text" (keypress)="keyPressNumbers($event)"
                                        style="margin-top:-3px;border-radius:2px;outline: none; margin-inline: auto;border:none;border-bottom: 1px solid #959595;padding-bottom: 0px; width:97%;box-shadow: none; "
                                        class="inp form-control" [(ngModel)]="prix1" name="prix1"
                                        formControlName="prix1" maxlength="10">
                                    <span style="margin-right: -30px;margin-top: 11px;">نقطة</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="submitted1 && registerForm1.invalid" class="invalid-feedback">
                            <div *ngIf="registerForm1.invalid" style="
                            margin-top: -20px;
                            margin-right: 109px;">يجب إدخال المعطيات</div>
                        </div>
                        <br>
                        <div class="d-flex flex-row justify-content-center align-items-center">
                            <button class=" disable btn1" style="
                            margin-right: 264px;
                            margin-top: -22px;
                            color: black;
                            background-color: #858d90;
                        ">تسجيل</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-4">
                <form [formGroup]="registerForm2" enctype="multipart/form-data">
                    <div class="card" id="first1"
                        style="background-color: transparent; background-color: transparent;border: 1px dashed #00ddf3;height:247px;width: 307px;">
                        <h4 class="center"
                            style="background-color: #CBF1FF;border-radius: 8px;width: 165px; margin-right: 60px;margin-top: -18px;">
                            هدايا متوسطة</h4>
                        <div class="cardimg">
                            <div class=" col-img d-flex flex-row justify-content-center align-items-center ">
                                <label class="btn2" for="inputGroupFile02">
                                    <img class="img-fluid img" *ngIf="link2 ==''" src="../assets/present gift.png"
                                        alt="">
                                    <img class="img-fluid img" *ngIf="link2 !=''" [src]="link2" height="200px"
                                        width="200px">
                                </label>
                            </div>
                        </div>
                        <div class="d-flex flex-row justify-content-center align-items-center">

                            <input (change)="selectImage2($event)" [(ngModel)]="img2" name="img2" formControlName="img2"
                                accept="image/*" style="display:none" type="file" class="custom-file-input"
                                id="inputGroupFile02">
                        </div>
                        <br>
                        <div class="d-flex"
                            style="margin-right: 86%;width: 221px;margin-top: -121px;margin-right: 360px;">
                            <div id="car" class="card" style="height: 91px;width: 136px;background-color: white;margin-right: -163px;margin-top: -11px;
                        box-shadow: 3px 3px 3px darkgrey;border: none;">
                                <h4>
                                    <p class="prix" style="text-align: center;font-size: 1.2rem;">المبلغ</p>
                                </h4>
                                <br>
                                <input
                                    style="outline: none;border-radius:2px; margin-inline: auto;border:none;border-bottom: 1px solid #959595;margin-top:-23px;padding-bottom: 0px; width:97%;box-shadow: none; "
                                    type="text" onpaste="return false;" (keypress)="keyPressNumbers($event)"
                                    class="inp form-control" [(ngModel)]="prix2" name="prix2" formControlName="prix2"
                                    maxlength="10">
                                <span class="pnt" style="margin-right: 101px;margin-top: -26px;">نقطة</span>
                            </div>
                        </div>
                        <div *ngIf="submitted2 && registerForm2.invalid" class="invalid-feedback">
                            <div *ngIf="g['prix2'].errors['required']" style="
                            margin-top: -10px;margin-right: 11px;
                        "> يجب إدخال المعطيات</div>
                        </div>
                        <br>
                        <div class="d-flex flex-row justify-content-center align-items-center">
                            <button (click)="ajouter2()" class="btn1" style="
                            margin-right: 270px;
                            margin-top: -21px;
                            color: black;
                            background-color: #CBF1FF;">تسجيل</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-4">
                <form [formGroup]="registerForm3" enctype="multipart/form-data">
                    <div class="card" id="first1"
                        style="background-color: transparent; background-color: transparent;border: 1px dashed #00ddf3;height:247px;width: 307px;">
                        <h4 class="center"
                            style="background-color: #CBF1FF;border-radius: 8px;width: 165px; margin-right: 60px;margin-top: -18px;">
                            هدايا بسيطة</h4>
                        <div class="cardimg">
                            <div class=" col-img d-flex flex-row justify-content-center align-items-center ">
                                <label class="btn2" for="inputGroupFile03">
                                    <img class="img-fluid img" *ngIf="link3 ==''" src="../assets/present gift.png"
                                        alt="">
                                    <img class="img-fluid img" *ngIf="link3 !=''" [src]="link3" height="200px"
                                        width="200px">
                                </label>
                            </div>
                        </div>
                        <div class="d-flex flex-row justify-content-center align-items-center">
                            <input (change)="selectImage3($event)" [(ngModel)]="img3" name="img3" accept="image/*"
                                formControlName="img3" style="display:none" type="file" class="custom-file-input"
                                id="inputGroupFile03">
                        </div>
                        <br>
                        <div class="d-flex"
                            style="margin-right: 86%;width: 221px;margin-top: -121px;margin-right: 360px;">
                            <div id="car" class="card" style="height: 91px;width:136px;background-color: white;margin-right: -163px;margin-top: -13px;
                          box-shadow: 3px 3px 3px darkgrey; border:none;">
                                <h4>
                                    <p class="prix" style="text-align: center;font-size: 1.2rem;">المبلغ</p>
                                </h4>
                                <br>
                                <div style="display: flex; align-items: center; margin-top: -19px;">
                                    <input style="margin-right:0px;" type="text" onpaste="return false;"
                                        (keypress)="keyPressNumbers($event)"
                                        style="margin-top:-3px;border-radius:2px;outline: none; margin-inline: auto;border:none;border-bottom: 1px solid #959595;padding-bottom: 0px; width:97%;box-shadow: none; "
                                        class="inp form-control" [(ngModel)]="prix3" name="prix3"
                                        formControlName="prix3" maxlength="10">
                                    <span style="margin-right: -33px;margin-top: 11px;">نقطة</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="submitted3 && registerForm3.invalid" class="invalid-feedback">
                            <div *ngIf="registerForm3.invalid" style=" margin-top: -10px;margin-right: 11px;
                        "> يجب إدخال المعطيات</div>
                        </div>
                        <br>
                        <div class="d-flex flex-row justify-content-center align-items-center">
                            <button (click)="ajouter3()" class="btn1" style="
                            margin-right: 291px;
                            margin-top: -18px;
                            color: black;
                            background-color: #CBF1FF;
                        ">تسجيل</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="dropdown">
            <button class="selec dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img src="assets/filter.png" alt=""> &nbsp; تصنيف الهدايا
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item"
                        (click)="showCher = true ; showT = false; showMoyen = false; showNon = false;currentPage = 1;currentPage1 = 1;currentPage2 = 1;currentPage3 = 1; getCadeauxCher();onPageChange1(1)">هدايا
                        قيمة</a> </li>
                <li><a class="dropdown-item"
                        (click)="showCher = false ; showT = false; showMoyen = true; showNon = false; currentPage = 1;currentPage1 = 1;currentPage2 = 1;currentPage3 = 1;getCadeauxMoyen();onPageChange2(1)">هدايا
                        متوسطة</a></li>
                <li><a class="dropdown-item"
                        (click)="showCher = false ; showT = false; showMoyen = false; showNon = true; currentPage = 1;currentPage1 = 1;currentPage2 = 1;currentPage3 = 1;getCadeauxNonCher();onPageChange3(1)">هدايا
                        بسيطة </a></li>
            </ul>
        </div>
        <div class="row row2"
            style=" background-color: white;        border: 1px dashed #28d2ff;   border-radius: 15px;" *ngIf="showT"
            id="show2">
            <div class="col-md-2"
                *ngFor="let cadeau of cadeaux| slice: pageSize | slice: 0:itemsPerPage; let index = index;">
                <div class="card1   justify-content-center "
                    style=" border: 1px dashed #28d2ff;  border-radius: 15px;height:177px">

                    <img class="img-fuild img1" src="{{cadeau.image}}" alt="" width="158px"> <br>
                    <div style="white-space: nowrap; "> {{cadeau.prix}} <img src="../assets/Coin.png" alt=""></div>
                    <div class="d-flex" id="manp">
                        <img (click)="openSm2(content4)" class="delete" height="37px" width="37px"
                            src="assets/deleteic.png" alt="">
                        <a (click)="openSm2(content3)" class="updatebtn"> <img src="../assets/edit.png" alt="" style="
                          height: 37px;
                          width:37px;
                          margin-top: -5px;
                       "> </a>
                    </div>
                </div>
                <br><br>
                <ng-template #content4 let-modal>
                    <div class="modal-body" dir="rtl">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h5 id="h3"> هل تريد حذف هذه الهدية ؟</h5>
                        <div class="d-flex justify-content-center">
                            <button id="btnP" (click)="delete(cadeau.id,'default')" type="button"
                                class="btn btn-primary">&nbsp;
                                &nbsp; تأكيد &nbsp; &nbsp;</button>
                        </div>
                    </div>
                </ng-template>

                <ng-template #content3 let-modal>
                    <div class="modal-body" dir="rtl">
                        <form [formGroup]="registerForm4" enctype="multipart/form-data">
                            <div class="bord">
                                <button id="close3" type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click'); ">
                                    <span aria-hidden="true">&times;</span>
                                </button><br>
                                <h5 style="text-align: center ;">تعديل المبلغ</h5>
                                <br>
                                <div class="d-flex">
                                    <p class="prix">المبلغ</p>
                                    <input type="text" onpaste="return false;" formControlName="prix4"
                                        oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"
                                        [(ngModel)]="cadeau.prix" (keypress)="keyPressNumbers($event)"
                                        class="inp form-control">
                                </div>
                                <div *ngIf="submitted4 && k['prix4'].errors" class="invalid-feedback">
                                    <div *ngIf="k['prix4'].errors['required']" style="text-align: right;"> يجب إدخال
                                        الثمن</div>
                                </div>
                                <br>
                                <div class="d-flex flex-row justify-content-center align-items-center">
                                    <button (click)="updateCadeau(cadeau.id, cadeau.prix)" id="btt">تعديل</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </div>
            <br>
            <div class="pagina">
                <ngb-pagination dir="rtl" [maxSize]="5" *ngIf="cadeaux.length > itemsPerPage"
                    [collectionSize]="cadeaux.length" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage"
                    (pageChange)="onPageChange(currentPage); p = $event"> <ng-template ngbPaginationPrevious>
                        <button class="btn btn-link" style="padding: 0px;">
                            <i class="fa fa-chevron-right" aria-hidden="true"></i>
                        </button>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <button class="btn btn-link" style="padding: 0px;">
                            <i class="fa fa-chevron-left" aria-hidden="true"></i>

                        </button>
                    </ng-template></ngb-pagination>
            </div>
        </div>
        <div class="row row2"
            style=" background-color: white;        border: 1px dashed #28d2ff;   border-radius: 15px;" *ngIf="showCher"
            id="show2">
            <div class="col-md-2"
                *ngFor="let cadeau of cadeauxCher| slice: pageSize1 | slice: 0:itemsPerPage; let index = index;">
                <div class="card1   justify-content-center "
                    style=" border: 1px dashed #28d2ff;  border-radius: 15px;height:177px">
                    <img class="img-fuild img1" src="{{cadeau.image}}" alt="" width="158px"> <br>
                    <div style="white-space: nowrap; ">{{cadeau.prix}} <img src="../assets/Coin.png" alt=""></div>
                    <div class="d-flex" id="manp" style="margin-top: 0px;">
                        <img (click)="openSm2(content4)" class="delete" height="37px" width="37px"
                            src="assets/deleteic.png" alt="">
                        <a (click)="openSm2(content3)" class="updatebtn"> <img src="../assets/edit.png" alt="" style="
                          height: 37px;
                          width:37px;
                          margin-top: -5px;
                       "> </a>
                    </div>
                </div>
                <br> <br>
                <ng-template #content4 let-modal>
                    <div class="modal-body" dir="rtl">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h5 id="h3"> هل تريد حذف هذه الهدية ؟</h5>
                        <div class="d-flex justify-content-center">
                            <button id="btnP" (click)="delete(cadeau.id,'showCher')" type="button"
                                class="btn btn-primary">&nbsp;
                                &nbsp; تأكيد &nbsp; &nbsp;</button>
                        </div>
                    </div>
                </ng-template>
                <ng-template #content3 let-modal>
                    <div class="modal-body" dir="rtl">
                        <form [formGroup]="registerForm4" enctype="multipart/form-data">
                            <div class="bord">
                                <button id="close3" type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click'); ">
                                    <span aria-hidden="true">&times;</span>
                                </button><br>
                                <h5 style="text-align: center ;">تعديل المبلغ</h5>
                                <br>
                                <div class="d-flex">
                                    <p class="prix">المبلغ</p>
                                    <input type="text" onpaste="return false;" formControlName="prix4"
                                        oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"
                                        [(ngModel)]="cadeau.prix" (keypress)="keyPressNumbers($event)"
                                        class="inp form-control">
                                </div>
                                <div *ngIf="submitted4 && g['prix4'].errors" class="invalid-feedback">
                                    <div *ngIf="k['prix4'].errors['required']" style="text-align: right;"> يجب إدخال
                                        الثمن</div>
                                </div>
                                <br>
                                <div class="d-flex flex-row justify-content-center align-items-center">
                                    <button (click)="updateCadeau(cadeau.id, cadeau.prix)" id="btt">تعديل</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </div>
            <br>
            <ngb-pagination dir="rrl" [maxSize]="5" *ngIf="cadeauxCher.length > itemsPerPage"
                [collectionSize]="cadeauxCher.length" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage1"
                (pageChange)="onPageChange1(currentPage1); p = $event"> <ng-template ngbPaginationPrevious>
                    <button class="btn btn-link" style="padding: 0px;">
                        <i class="fa fa-chevron-right" aria-hidden="true"></i>

                    </button>
                </ng-template>
                <ng-template ngbPaginationNext>
                    <button class="btn btn-link" style="padding: 0px;">
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>

                    </button>
                </ng-template></ngb-pagination>
        </div>
        <div class="row row2"
            style=" background-color: white;        border: 1px dashed #28d2ff;   border-radius: 15px;"
            *ngIf="showMoyen" id="show2">
            <div class="col-md-2"
                *ngFor="let cadeau of cadeauxMoyen| slice: pageSize2 | slice: 0:itemsPerPage; let index = index;">
                <div class="card1   justify-content-center "
                    style=" border: 1px dashed #28d2ff;  border-radius: 15px;height:177px">

                    <img class="img-fuild img1" src="{{cadeau.image}}" alt="" width="158px"> <br>
                    <div style="white-space: nowrap; "> {{cadeau.prix}} <img src="../assets/Coin.png" alt=""></div>
                    <div class="d-flex" id="manp" style="margin-top: 0px;">
                        <img (click)="openSm2(content4)" class="delete" height="37px" width="37px"
                            src="assets/deleteic.png" alt="">
                        <a (click)="openSm2(content3)" class="updatebtn"> <img src="../assets/edit.png" alt="" style="
                          height: 37px;
                          width:37px;
                          margin-top: -5px;
                       "> </a>
                    </div>

                </div>
                <br><br>
                <ng-template #content4 let-modal>
                    <div class="modal-body" dir="rtl">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h5 id="h3"> هل تريد حذف هذه الهدية ؟</h5>
                        <div class="d-flex justify-content-center">
                            <button id="btnP" (click)="delete(cadeau.id,'showMoyen')" type="button"
                                class="btn btn-primary">&nbsp;
                                &nbsp; تأكيد &nbsp; &nbsp;</button>
                        </div>
                    </div>
                </ng-template>
                <ng-template #content3 let-modal>
                    <div class="modal-body" dir="rtl">
                        <form [formGroup]="registerForm4" enctype="multipart/form-data">
                            <div class="bord">
                                <button id="close3" type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click'); ">
                                    <span aria-hidden="true">&times;</span>
                                </button><br>
                                <h5 style="text-align: center ;">تعديل المبلغ</h5>
                                <br>

                                <br>
                                <div style="display: flex; align-items: center;">
                                    <input type="text" onpaste="return false;" formControlName="prix4"
                                        oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"
                                        [(ngModel)]="cadeau.prix" (keypress)="keyPressNumbers($event)"
                                        class="inp form-control">
                                </div>
                                <div *ngIf="submitted4 && g['prix4'].errors" class="invalid-feedback">
                                    <div *ngIf="k['prix4'].errors['required']" style="text-align: right;"> يجب إدخال
                                        الثمن</div>
                                </div>




                                <br>
                                <div class="d-flex flex-row justify-content-center align-items-center">
                                    <button (click)="updateCadeau(cadeau.id, cadeau.prix)" id="btt">تعديل</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </div>
            <br>
            <div class="pagina">
                <ngb-pagination dir="rtl" [maxSize]="5" *ngIf="cadeauxMoyen.length > itemsPerPage"
                    [collectionSize]="cadeauxMoyen.length" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage2"
                    (pageChange)="onPageChange2(currentPage2); p = $event"> <ng-template ngbPaginationPrevious>
                        <button class="btn btn-link" style="padding: 0px;">
                            <i class="fa fa-chevron-right" aria-hidden="true"></i>

                        </button>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <button class="btn btn-link" style="padding: 0px;">
                            <i class="fa fa-chevron-left" aria-hidden="true"></i>

                        </button>
                    </ng-template></ngb-pagination>
            </div>
        </div>
        <div class="row row2"
            style=" background-color: white;        border: 1px dashed #28d2ff;   border-radius: 15px;" *ngIf="showNon"
            id="show2">
            <div class="col-md-2"
                *ngFor="let cadeau of cadeauxNonCher| slice: pageSize3 | slice: 0:itemsPerPage; let index = index;">
                <div class="card1   justify-content-center "
                    style=" border: 1px dashed #28d2ff;  border-radius: 15px;height:177px">

                    <img class="img-fuild img1" src="{{cadeau.image}}" alt="" width="158px"> <br>
                    <div style="white-space: nowrap; ">{{cadeau.prix}} <img src="../assets/Coin.png" alt=""></div>
                    <div class="d-flex" id="manp" style="margin-top: 0px;">
                        <img (click)="openSm2(content4)" class="delete" height="37px" width="37px"
                            src="assets/deleteic.png" alt="">
                        <a (click)="openSm2(content3)" class="updatebtn"> <img src="../assets/edit.png" alt="" style="
                          height: 37px;
                          width:37px;
                          margin-top: -5px;
                       "> </a>
                    </div>

                </div>
                <br><br>
                <ng-template #content4 let-modal>
                    <div class="modal-body" dir="rtl">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h5 id="h3"> هل تريد حذف هذه الهدية ؟</h5>
                        <div class="d-flex justify-content-center">
                            <button id="btnP" (click)="delete(cadeau.id,'showNon')" type="button"
                                class="btn btn-primary">&nbsp;
                                &nbsp; تأكيد &nbsp; &nbsp;</button>
                        </div>
                    </div>
                </ng-template>
                <ng-template #content3 let-modal>
                    <div class="modal-body" dir="rtl">
                        <form [formGroup]="registerForm4" enctype="multipart/form-data">
                            <div class="bord">
                                <button id="close3" type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click'); ">
                                    <span aria-hidden="true">&times;</span>
                                </button><br>
                                <h5 style="text-align: center ;">تعديل المبلغ</h5>
                                <br>
                                <div class="d-flex">
                                    <p class="prix">المبلغ</p>
                                    <input type="text" onpaste="return false;" formControlName="prix4"
                                        oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"
                                        [(ngModel)]="cadeau.prix" (keypress)="keyPressNumbers($event)"
                                        class="inp form-control">
                                </div>
                                <div *ngIf="submitted4 && g['prix4'].errors" class="invalid-feedback">
                                    <div *ngIf="k['prix4'].errors['required']" style="text-align: right;"> يجب إدخال
                                        الثمن</div>
                                </div>
                                <br>
                                <div class="d-flex flex-row justify-content-center align-items-center">
                                    <button (click)="updateCadeau(cadeau.id, cadeau.prix)" id="btt">تعديل</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>
            </div>
            <br>

            <div class="pagina">
                <ngb-pagination dir="rtl" [maxSize]="5" *ngIf="cadeauxNonCher.length > itemsPerPage"
                    [collectionSize]="cadeauxNonCher.length" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage3"
                    (pageChange)="onPageChange3(currentPage3); p = $event"> <ng-template ngbPaginationPrevious>
                        <button class="btn btn-link" style="padding: 0px;">
                            <i class="fa fa-chevron-right" aria-hidden="true"></i>
                        </button>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <button class="btn btn-link" style="padding: 0px;">
                            <i class="fa fa-chevron-left" aria-hidden="true"></i>


                        </button>
                    </ng-template></ngb-pagination>
            </div>
        </div>
        <br>
    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.gift == 0 " style="padding-top: 10% !important">
        <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
        background-repeat: no-repeat; background-position: center center;display:block;margin-top:auto;
        margin-bottom:auto; max-height: 800px;background-image: url('assets/droit.png')">
        </div>
    </div>
</body>