import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Theme } from 'src/app/modules/theme';
import { NotificationService } from 'src/app/services/notification.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.css']
})
export class ThemeComponent implements OnInit {
  prix: string;
  prix2: string;
  imag: string;
  img2: string;
  submitted = false;
  submitted2 = false;
  images: File;
  images2: File;
  registerForm: FormGroup;
  registerForm2: FormGroup;
  link: string = "";
  link2: string = "";
  closeResult = '';
  themes: Theme[] = [];
  currentPage = 1;
  itemsPerPage = 6;
  message='';
  pageSize: number;
  p: number = 1;
  user: any = {};
  receivedData: any;
  constructor(private servicetheme: ThemeService, private serviceauth: AuthentificationService,
    private formBuilder: FormBuilder,
    private notifyService: NotificationService,private sharedService: SharedService, 
    private modalService: NgbModal,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;
    this.route.data.subscribe((data: { themes: Theme[] }) => this.themes = data.themes);
    this.user = this.serviceauth.getCurrentUser();

    this.registerForm = this.formBuilder.group({
      prix: ['', Validators.required],
      imag: ['', Validators.required],
    });

    this.registerForm2 = this.formBuilder.group({
      prix2: ['', Validators.required],

    });

    this.getTheme();
  }

  get f() { return this.registerForm.controls; }
  get g() { return this.registerForm2.controls; }

  getTheme() {
    this.servicetheme.getTheme()
      .pipe(first()).subscribe(data => {
        this.themes = data

      })
  }



  ajouter() {
    if (this.registerForm.invalid) {
      this.submitted = true;
      this.message=' يجب إدخال المعطيات';
      return;
    }
    const formData = new FormData();
    formData.append('image', this.images);
    formData.append('prix', this.prix);
    this.servicetheme.addTheme(formData)
      .pipe(first()).subscribe(() => {
        this.prix = "";
        this.link = "";
        this.imag = "";
        this.showToasterSuccess();
        setTimeout(() => {
          this.getTheme();
        }, 3000)
        this.images = null;
        this.submitted = false;
      })
  }


  updateTheme(id: string, prix: number) {
    this.submitted2 = true;
    if (this.registerForm2.invalid) {
      return;
    }
    var prixa = prix.toString();
    const formData2 = new FormData();
    formData2.append('image', this.images2);
    formData2.append('prix', prixa);
    this.servicetheme.updateTheme(id, formData2)
      .pipe(first()).subscribe(() => {
        this.prix2 = "";
        this.link2 = "";
        this.showToasterSuccess2();
        setTimeout(() => {
          this.getTheme();

        }, 2000)


        this.images2 = null;
        this.submitted2 = false;
        this.modalService.dismissAll();
      })
  }


  delete(id: string) {
    this.servicetheme.deleteTheme(id)
      .pipe(first()).subscribe(() => {
        this.getTheme();
        this.showToasterError();
        this.modalService.dismissAll();
      })
  }

  showToasterSuccess() {
    this.notifyService.showSuccess(" تم اضافة خلفية")
  }

  showToasterSuccess2() {
    this.notifyService.showSuccess(" تم تعديل الخلفية")
  }

  showToasterError() {
    this.notifyService.showSuccess("تم محو الخلفية ")
  }

  selectImage(event: any) {

    var reader = new FileReader();
    const file = event.target.files[0];
    this.images = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link = event.target.result
    }
  }

  selectImage2(event: any) {

    var reader = new FileReader();
    const file = event.target.files[0];
    this.images2 = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link2 = event.target.result
    }

  }


  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }


  }


  open(content) {
    this.prix2 = "";
    this.link2 = "";
    this.modalService.open(content, { windowClass: "myCustomModalClass", centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;


    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });
  }


  isZeroAllowed = true;

  keyPressNumbers(event) {
      const key = event.key || String.fromCharCode(event.keyCode);

  if (key === '0' && this.isZeroAllowed && event.target.selectionStart === 0) {
    event.preventDefault();
  }

    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }

  }
inputEvent(event: any) {
  const value = event.target.value;
  this.isZeroAllowed = value === '';
}

  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }

}
