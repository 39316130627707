<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '82%' : 'auto'}">
    <div class="container p-5" *ngIf="user.role == 'globale' " dir="rtl" style="text-align: right;">
        <h2>تعديل مسؤول</h2>
        <br>
        <form [formGroup]="registerForm" class="pr-5">
            <div class="row">
                <div class="col-md-4">
                    <div class="d-flex"> الإسم <div style="color: red;">*</div>
                    </div>

                    <div class="row pl-4">
                        <input class="input form-control" [(ngModel)]="admin.prenom" formControlName="prenom"
                            type="text" id="prenom">
                        <div *ngIf="submitted && f['prenom'].errors" class="invalid-feedback">
                            <div *ngIf="f['prenom'].errors['required']">الإسم مطلوب</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="d-flex"> اللقب <div style="color: red;">*</div>
                    </div>
                    <div class="row pl-4">
                        <input class=" input form-control" formControlName="nom" type="text" name="nom" id="nom"
                            [(ngModel)]="admin.nom">
                        <div *ngIf="submitted && f['nom'].errors" class="invalid-feedback">
                            <div *ngIf="f['nom'].errors['required']">اللقب مطلوب</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="d-flex "> البريد الإلكتروني <div style="color: red;">*</div>
                    </div>
                    <div class="row pl-4">
                        <input class=" input form-control" formControlName="email" type="email" name="email" id="email"
                            [(ngModel)]="admin.email">
                        <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                            <div *ngIf="f['email'].errors['required']">البريد الإلكتروني مطلوب</div>
                            <div *ngIf="f['email'].errors['email']">يجب أن يكون البريد الإلكتروني صالحًا</div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-4">
                    <div class="d-flex"> رقم الهاتف <div style="color: red;">*</div>
                    </div>
                    <div class="row pl-4">
                        <input class=" input form-control" formControlName="telephone" type="number"
                            onKeyPress="if(this.value.length==13) return false;" onkeydown="javascript: return event.keyCode === 8 ||
            event.keyCode === 46 ? true : !isNaN(Number(event.key))" name="telephone" id="telephone"
                            [(ngModel)]="admin.telephone">
                    </div>
                    <div *ngIf="submitted && f['telephone'].errors" class="invalid-feedback">
                        <div *ngIf="f['telephone'].errors['required']">رقم الهاتف مطلوب</div>
                    </div>
                </div>

            </div>

            <br>
            <div class="row"><strong> الوظائف المتاحة </strong></div>
            <br>
            <div class="row">
                <div class="col-md-3">
                    <input #publicite id="publicite" type="checkbox" [checked]="admin.publicite"
                        (change)="onpublicite($event)"> &nbsp;
                    <label for="c3">الإعلانات</label>
                </div>
                <div class="col-md-3">
                    <input #actualite id="actualite" type="checkbox" [checked]="admin.actualite"
                        (change)="onactualite($event)"> &nbsp;
                    <label for="c2">الأخبار</label>
                </div>
                <div class="col-md-3">
                    <input #users id="users" type="checkbox" [checked]="admin.users" (change)="onusers($event)"> &nbsp;
                    <label for="c1">المستخدم</label>
                </div>

                <div class="col-md-3">
                    <input #counter id="counter" type="checkbox" [checked]="admin.counter" (change)="oncounter($event)">
                    &nbsp;
                    <label for="c1">عروض العداد</label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <input #conversion id="conversion" type="checkbox" [checked]="admin.conversion"
                        (change)="onconversion($event)">
                    &nbsp;
                    <label for="c4">طلبات التحويل</label>
                </div>
                <div class="col-md-3">
                    <input #tousconversion id="tousconversion" type="checkbox" [checked]="admin.tousconversion"
                        (change)="ontousconversion($event)"> &nbsp;
                    <label for="c4">سجل التحويلات</label>
                </div>
                <div class="col-md-3">
                    <input #jeux id="jeux" type="checkbox" [checked]="admin.jeux" (change)="onjeux($event)"> &nbsp;
                    <label for="c5">المسابقات</label>
                </div>
                <div class="col-md-3">
                    <input #mobile id="mobile" type="checkbox" [checked]="admin.mobile" (change)="onmobile($event)">
                    &nbsp;
                    <label for="c6">بطاقات الهاتف</label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <input #product id="product" type="checkbox" [checked]="admin.product" (change)="onproduct($event)">
                    &nbsp;
                    <label for="c6"> المنتجات </label>
                </div>
                <div class="col-md-3">
                    <input #event id="product" type="checkbox" [checked]="admin.event" (change)="onuserevent($event)">
                    &nbsp;
                    <label for="c6"> فعاليات </label>
                </div>
                <div class="col-md-3">
                    <input #pret id="product" type="checkbox" [checked]="admin.pret" (change)="onuserpret($event)">
                    &nbsp;
                    <label for="c6"> القروض </label>
                </div>
                <div class="col-md-3">
                    <input #store id="product" type="checkbox" [checked]="admin.store" (change)="onuserstore($event)">
                    &nbsp;
                    <label for="c6"> متجر
                        الايقونات  </label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <input #user_block id="user_block" type="checkbox" [checked]="admin.user_block"
                        (change)="onuserblock($event)">
                    &nbsp;
                    <label for="c6"> حظر (المستخدم) </label>
                </div>

                <div class="col-md-3">
                    <input #listdelegate id="listdelegate" type="checkbox" [checked]="admin.listdelegate"
                        (change)="onlistdelegate($event)"> &nbsp;
                    <label for="c6"> قائمة الوكلاء </label>
                </div>
                <div class="col-md-3">
                    <input #demanddelegate id="demanddelegate" type="checkbox" [checked]="admin.demanddelegate"
                        (change)="ondemanddelegate($event)"> &nbsp;
                    <label for="c6"> طلبات الوكالة </label>
                </div>
                <div class="col-md-3">
                    <input #block id="block" type="checkbox" [checked]="admin.block" (change)="onblock($event)"> &nbsp;
                    <label for="c6"> الحظر </label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <input #signal id="signal" type="checkbox" [checked]="admin.signal" (change)="onsignal($event)">
                    &nbsp;
                    <label for="c6"> الإبلاغات </label>
                </div>
                <div class="col-md-3">
                    <input #gift id="gift" type="checkbox" [checked]="admin.gift" (change)="ongift($event)"> &nbsp;
                    <label for="c6"> الهدايا </label>
                </div>
                <div class="col-md-3">
                    <input #theme id="theme" type="checkbox" [checked]="admin.theme" (change)="ontheme($event)"> &nbsp;
                    <label for="c6"> الخلفيات </label>
                </div>

                <div class="col-md-3">
                    <input #contact id="contact" type="checkbox" [checked]="admin.contact" (change)="oncontact($event)">
                    &nbsp;
                    <label for="c6"> الدعم </label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <input #user_point id="user_point" type="checkbox" [checked]="admin.user_point"
                        (change)="onuserpoint($event)">
                    &nbsp;
                    <label for="c6"> نقاط (المستخدم) </label>
                </div>
                <div class="col-md-3">
                    <input #user_diamond id="user_diamond" type="checkbox" [checked]="admin.user_diamond"
                        (change)="onuserdiamond($event)"> &nbsp;
                    <label for="c6"> مجوهرات (المستخدم) </label>
                </div>
                <div class="col-md-3">
                    <input #user_message id="user_message" type="checkbox" [checked]="admin.user_message"
                        (change)="onusermessage($event)"> &nbsp;
                    <label for="c6"> رسالة (المستخدم) </label>
                </div>

                <div class="col-md-3">
                    <input #user_delete id="user_delete" type="checkbox" [checked]="admin.user_delete"
                        (change)="onuserdelete($event)">
                    &nbsp;
                    <label for="c6"> حذف(المستخدم) </label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <input #codeparrainge id="user_block" type="checkbox" [checked]="admin.codeparrainage" (change)="oncodeparrainage($event)">
                    &nbsp;
                    <label for="c6">رموز الاحالة</label>
                </div>
            </div>
            <br>
            <div class="row ">
                <div class="col-md-5"></div>
                <div class="col-md-2"> <button (click)="modifier();" type="button" class="button form-control"> <strong>
                            تعديل </strong> </button> </div>
                <div class="col-md-5"></div>

            </div>
        </form>
    </div>
    <div class="container" *ngIf="user.role != 'globale' " style="padding-top: 10% !important">
        <div class="container" style=" width: 100%;height: 400px; max-height: 800px;background-size: contain;
        background-repeat: no-repeat;background-position: center center;display:block;margin-top:auto;
        margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
        </div>
    </div>
</body>