import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { first } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { Delegate } from 'src/app/modules/delegate';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DelegateService } from 'src/app/services/delegate.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CurrencyService } from 'src/app/services/currency.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-delegate',
  templateUrl: './delegate.component.html',
  styleUrls: ['./delegate.component.css']
})
export class DelegateComponent implements OnInit {
  user: any = {};
  itemsPerPage = 10;
  pageSize: number;
  curr: any = {};
  uri = environment.apiUrl;
  currentPage: number;
  closeResult = '';
  prix_delegate: string;
  registerForm: FormGroup;
  submitted = false;
  registerForm1: FormGroup;
  submitted1 = false;
  registerForm2: FormGroup;
  submitted2 = false;
  name: string;
  country: string;
  city: string;
  address: string;
  phoneNumber: string;
  remark: string;

  p: number = 1;


  delegates: Delegate[];
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  dtOptions: DataTables.Settings = {};
  zero=false;
  receivedData:boolean;
  constructor(private serviceauth: AuthentificationService, private http: HttpClient, private modalService: NgbModal,
    private formBuilder: FormBuilder,private route: ActivatedRoute,private sharedService: SharedService,
    private servicedelegate: DelegateService, private notifyService: NotificationService, private servicecurrency: CurrencyService) { }

  ngOnInit(): void {
    this.user = this.serviceauth.getCurrentUser();
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;
    var that = this;
    this.dtOptions = {
      pagingType: 'simple_numbers',
      language: {
        processing: "يرجى الإنتظار...",
        searchPlaceholder: 'البحث',search: "",
        lengthMenu: " _MENU_ ",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        paginate: {
          first: "",
          previous: "<i class='material-icons'>navigate_next</i> ",
          next: "<i class='material-icons'>navigate_before</i>",
          last: ""
        },
      },
      info: false,
      paging: true ,
      ordering: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.currentPage = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        const rowData = {
          no_of_records: dataTablesParameters.length,
          page: this.currentPage,
          group_id: 1
        };

        that.http.post<DataTablesResponse>(this.uri + 'indexDashboard', dataTablesParameters, { headers: this.headers }).subscribe(resp => {
          const details = JSON.parse(JSON.stringify(resp.original));
          that.delegates = details.data;
          callback({
            recordsTotal: details.recordsTotal,
            recordsFiltered: details.recordsFiltered,
            data: [],
          });
        });
      },
      columns: [
        { data: "created_at" },
        { data: "codeparrainage" },
        { data: "name" },
        { data: "country" },
        { data: "city" },
        { data: "address" },
        { data: "phoneNumber" },
        { data: "remark" }
      ],
    };

    this.servicecurrency.getbyid("1")
      .subscribe(data => {
        this.curr = data
        this.prix_delegate = this.curr.prix_delegate;
      });

    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
      country: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      remark: [""]
    });

    this.registerForm1 = this.formBuilder.group({
      remark: ['', Validators.required]
    });
    this.registerForm2 = this.formBuilder.group({
      prix_delegate: ['', Validators.required]
    });
  }

  get f() { return this.registerForm.controls; }
  get g() { return this.registerForm2.controls; }


  delete(id: string) {
    this.servicedelegate.delete(id).pipe(first()).subscribe(() => {
      this.modalService.dismissAll();
      $('#dtable').DataTable().draw(false);
      this.showToasterError();
    })
  }
  vide(remark:any){
    this.remark='';
    
  }

  updatedelegate() {
    this.submitted2 = true;
    if (this.registerForm2.invalid) {
      this.zero=false;
      return;
    }
    if (this.prix_delegate=='0') {
      this.zero=true;
      return;
    }
    if (  this.prix_delegate>'9999999999') {
      this.zero=true;
      return;
    }
  
    this.servicecurrency.updateprixdelegate(this.prix_delegate, "1").pipe(first()).subscribe(data => {
      this.prix_delegate = this.prix_delegate;
      this.showToasterSuccess();
        this.zero=false;
    })
  }

  updateInfoDelegate(name: string, phoneNumber: string, address: string, country: string, city: string, remark: string, id: string) {
   
    this.servicedelegate.updatedelegate(name, phoneNumber, address, country, city, remark, id)
      .pipe(first()).subscribe(() => {
        $('#dtable').DataTable().draw(false);
        this.showToasterSuccess2();
        this.modalService.dismissAll();
        this.submitted = false;
        this.name = "";
        this.city = "";
        this.address = "";
        this.country = "";
        this.phoneNumber = "";
        this.remark = "";
      })
  }

  addRemarkDelegate(id: string) {
    this.submitted1 = true;
    if (this.registerForm1.invalid) {
      return;
    }
    this.servicedelegate.addRemarkDelegate(this.remark, id)
      .pipe(first()).subscribe(() => {
        $('#dtable').DataTable().draw(false);
        this.showToasterSuccess2();
        this.modalService.dismissAll();
        this.submitted1 = false;
        this.remark = "";
      })
  }


  showToasterError() {
    this.notifyService.showSuccess("تم حذف الوكيل")
  }

  showToasterSuccess() {
    this.notifyService.showSuccess(" تم تعديل ثمن الوكالة")
  }

  showToasterSuccess2() {
    this.notifyService.showSuccess(" تم تعديل الوكيل")
  }

  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }
  openSm(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openSm1(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openSm2(content) {
    this.modalService.open(content, { size: 'lg', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    $('#dtable').DataTable().draw(false);
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
