import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadeauService } from 'src/app/services/cadeau.service';
import { first } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { Cadeau } from 'src/app/modules/cadeau';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyService } from 'src/app/services/currency.service';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-cadeau',
  templateUrl: './cadeau.component.html',
  styleUrls: ['./cadeau.component.css']
})
export class CadeauComponent implements OnInit {
  link1: string = "";
  link2: string = "";
  link3: string = "";
  prix1: string = "";
  prix2: string = "";
  prix3: string = "";
  prix4: string = "";
  img1: string;
  img2: string;
  img3: string;
  images1: File;
  images2: File;
  images3: File;
  registerForm1: FormGroup;
  registerForm2: FormGroup;
  registerForm3: FormGroup;
  registerForm4: FormGroup;
  submitted1 = false;
  submitted2 = false;
  submitted3 = false;
  submitted4 = false;
  cadeaux: Cadeau[] = [];
  cadeauxCher: Cadeau[] = [];
  cadeauxMoyen: Cadeau[] = [];
  cadeauxNonCher: Cadeau[] = [];
  currentPage = 1;
  currentPage1=1;
  currentPage2=1;
  currentPage3=1;
  itemsPerPage = 12;
  pageSize: number;
  pageSize1: number;
  pageSize2: number;
  pageSize3: number;
  
  p: number = 1;
  showCher = false;
  showMoyen = false;
  showNon = false;
  showT = true;
  closeResult = '';
  curr: any = {};
  percentage: number;
  user: any = {};
  receivedData: any;
  message: string;
  constructor(private formBuilder: FormBuilder, private modalService: NgbModal, private zone: NgZone, private serviceauth: AuthentificationService,
    private servicecadeau: CadeauService, private servicecurrency: CurrencyService,private route: ActivatedRoute,private sharedService: SharedService, 
    private notifyService: NotificationService) { }

  ngOnInit(): void {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;
    this.registerForm1 = this.formBuilder.group({
      prix1: ['', Validators.required],
      img1: ['', Validators.required],
    });

    this.registerForm2 = this.formBuilder.group({
      prix2: ['', Validators.required],
      img2: ['', Validators.required],
    });

    this.registerForm3 = this.formBuilder.group({
      prix3: ['', Validators.required],
      img3: ['', Validators.required],
    });

    this.registerForm4 = this.formBuilder.group({
      prix4: ['', Validators.required],
    });
    this.user = this.serviceauth.getCurrentUser();
    this.servicecurrency.getbyid("1")
      .subscribe(data => {
        this.curr = data
        this.percentage = this.curr.gift_percentage;

      })


    this.getCadeaux()
  }

  get f() { return this.registerForm1.controls; }
  get g() { return this.registerForm2.controls; }
  get h() { return this.registerForm3.controls; }
  get k() { return this.registerForm4.controls; }

  getCadeaux() {
    this.servicecadeau.getCadeaux()
      .pipe(first()).subscribe(data => { this.cadeaux = data })
  }

  getCadeauxCher() {
    this.servicecadeau.getCadeaux()
      .pipe(first()).subscribe(data => {
        this.cadeauxCher = data.filter(q => q.type == "cher")
      })
  }

  getCadeauxMoyen() {
    this.servicecadeau.getCadeaux()
      .pipe(first()).subscribe(data => {
        this.cadeauxMoyen = data.filter(q => q.type == "moyen")
      })
  }

  getCadeauxNonCher() {
    this.servicecadeau.getCadeaux()
      .pipe(first()).subscribe(data => {
        this.cadeauxNonCher = data.filter(q => q.type == "noncher")
      })
  }

  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }
  public onPageChange1(pageNum: number): void {
    this.pageSize1 = this.itemsPerPage * (pageNum - 1);
  }  public onPageChange2(pageNum: number): void {
    this.pageSize2 = this.itemsPerPage * (pageNum - 1);
  }  public onPageChange3(pageNum: number): void {
    this.pageSize3 = this.itemsPerPage * (pageNum - 1);
  }
  ajouter1() {
    this.submitted1 = true;
    if (this.registerForm1.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('image', this.images1);
    formData.append('prix', this.prix1);
    formData.append('type', "cher");
    this.servicecadeau.addCadeau(formData)
      .pipe(first()).subscribe(() => {
        this.prix1 = "";
        this.link1 = "";
        this.img1 = "";
        this.showToasterSuccess();
        setTimeout(() => {
          this.getCadeauxCher();
          this.getCadeaux();
        }, 2000)
        this.images1 = null;
        this.submitted1 = false;
      })
  }

  updatepourcentage() {
    if(!this.percentage){
      this.message='يجب ادخال النسبة'
      return
    }else{
    this.servicecurrency.updatepourcentage(this.percentage, "1").pipe(first()).subscribe(data => {
      this.percentage = this.percentage;
      this.showToasterSuccess3();
    })
    this.message='';
  }
  }

  ajouter2() {
    this.submitted2 = true;
    if (this.registerForm2.invalid) {
      return;
    }
    const formData2 = new FormData();
    formData2.append('image', this.images2);
    formData2.append('prix', this.prix2);
    formData2.append('type', "moyen");
    this.servicecadeau.addCadeau(formData2)
      .pipe(first()).subscribe(() => {
        this.prix2 = "";
        this.link2 = "";
        this.img2 = "";
        this.showToasterSuccess();
        setTimeout(() => {
          this.getCadeauxMoyen();
          this.getCadeaux();
        }, 2000)
        this.images2 = null;
        this.submitted2 = false;
      })

  }
  ajouter3() {
    this.submitted3 = true;
    if (this.registerForm3.invalid) {
      return;
    }
    const formData3 = new FormData();
    formData3.append('image', this.images3);
    formData3.append('prix', this.prix3);
    formData3.append('type', "noncher");
    this.servicecadeau.addCadeau(formData3)
      .pipe(first()).subscribe(() => {
        this.prix3 = "";
        this.link3 = "";
        this.img3 = "";
        setTimeout(() => {
          this.getCadeauxNonCher();
          this.getCadeaux();
        }, 2000)
        this.showToasterSuccess();
        this.images3 = null;
        this.submitted3 = false;
      })
  }


  updateCadeau(id: string, prix: number) {
    this.submitted4 = true;
    if (this.registerForm4.invalid) {
      return;
    }

    this.servicecadeau.updateCadeau(id, prix)
      .pipe(first()).subscribe(() => {
        this.prix4 = "";
        this.submitted4 = false;
        this.showToasterSuccess2();
        this.modalService.dismissAll();
      })
  }




  showToasterSuccess() {
    this.notifyService.showSuccess(" تم اضافة هدية")
  }
  showToasterSuccess2() {
    this.notifyService.showSuccess(" تم تعديل المبلغ")
  }

  showToasterSuccess3() {
    this.notifyService.showSuccess(" تم تعديل النسبة ")
  }
  selectImage1(event: any) {
    var reader = new FileReader();
    const file = event.target.files[0];
    this.images1 = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link1 = event.target.result
    }
  }
  selectImage2(event: any) {
    var reader = new FileReader();
    const file = event.target.files[0];
    this.images2 = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link2 = event.target.result
    }
  }

  selectImage3(event: any) {
    var reader = new FileReader();
    const file = event.target.files[0];
    this.images3 = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link3 = event.target.result
    }
  }
  isZeroAllowed = true;

  keyPressNumbers(event) {
      const key = event.key || String.fromCharCode(event.keyCode);

  if (key === '0' && this.isZeroAllowed && event.target.selectionStart === 0) {
    event.preventDefault();
  }

    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }

  }
inputEvent(event: any) {
  const value = event.target.value;
  this.isZeroAllowed = value === '';
}



  delete(id: string, showSection: string) {
    this.servicecadeau.deleteacadeau(id)
       .pipe(first()).subscribe(() => {
         this.zone.run(() => {
       
         this.showToasterError();
         this.modalService.dismissAll();
         this.cadeaux = this.cadeaux.filter(cadeau => cadeau.id !== id);
         if (this.cadeauxCher.length === 13) {
          // Adjust currentPage2 to the previous page if deleting the last gift on the current page
          if (this.currentPage1 > 1) {
            this.currentPage1--;
          }
        }
        if (this.cadeauxMoyen.length === 13) {
          // Adjust currentPage2 to the previous page if deleting the last gift on the current page
          if (this.currentPage2 > 1) {
            this.currentPage2= this.currentPage2-1;
          }
        }
        if (this.cadeauxNonCher.length === 13) {
          // Adjust currentPage2 to the previous page if deleting the last gift on the current page
          if (this.currentPage3 > 1) {
            this.currentPage3= this.currentPage3-1;
          }
        }
         if (showSection === 'showCher') {
           this.showCher = true;
           this.showMoyen = false;
           this.showNon = false;
           this.showT = false;
           this.getCadeauxCher();
         } else if (showSection === 'showMoyen') {
           this.showCher = false;
           this.showMoyen = true;
           this.showNon = false;
           this.showT = false;
           this.getCadeauxMoyen();
         } else if (showSection === 'showNon') {
           this.showCher = false;
           this.showMoyen = false;
           this.showNon = true;
           this.showT = false;
           this.getCadeauxNonCher();
         } else {
           // default to showT if no specific section is provided
           this.showCher = false;
           this.showMoyen = false;
           this.showNon = false;
           this.showT = true;
           this.getCadeaux();
         }
       });
     });
   }

  showToasterError() {
    this.notifyService.showSuccess("تم محو الهدية ")
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
