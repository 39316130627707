import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { Currency } from '../modules/currency';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  uri = environment.apiUrl;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });

  constructor(private http: HttpClient) { }
  getAllconversion(): Observable<Currency[]> {
    const url_get = this.uri + 'currencyy';
    return this.http.get<Currency[]>(url_get, { headers: this.headers });
  }
  updatemontant(montantparpoint: number, id: string) {
    const obj = {
      montantparpoint: montantparpoint
    };
    this
      .http
      .put(`${this.uri}montantparpoint/${id}`, obj, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'currencyy';
    return this.http.get<Currency[]>(url_get, { headers: this.headers });
  }


  updateprixroom(prix_room: number, id: string) {
    const obj = {
      prix_room: prix_room
    };
    this
      .http
      .put(`${this.uri}updatePrixRoom/${id}`, obj, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'currencyy';
    return this.http.get<Currency[]>(url_get, { headers: this.headers });
  }

  updateprixdelegate(prix_delegate, id) {
    const obj = {
      prix_delegate: prix_delegate
    };
    this
      .http
      .put(`${this.uri}updatePrixDelegate/${id}`, obj, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'currencyy';
    return this.http.get<Currency[]>(url_get, { headers: this.headers });
  }


  updateprixfavori(prix_favori: number, id: string) {
    const obj = {
      prix_favori: prix_favori
    };
    this
      .http
      .put(`${this.uri}updatePrixFavori/${id}`, obj, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'currencyy';
    return this.http.get<Currency[]>(url_get, { headers: this.headers });
  }

  updatepourcentage(pourcentage: number, id: string) {
    const obj = {
      pourcentage: pourcentage
    };
    this
      .http
      .put(`${this.uri}updatePourcentage/${id}`, obj, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'currencyy';
    return this.http.get<Currency[]>(url_get, { headers: this.headers });
  }




  updateMAXmontant(maxmontant: number, id: string) {
    const obj = {
      maxmontant: maxmontant
    };
    this
      .http
      .put(`${this.uri}maxmontant/${id}`, obj, { headers: this.headers })
      .subscribe();
    return this.http.get(`${this.uri}currency/${id}`, { headers: this.headers });
  }
  getbyid(id: string) {
    return this.http.get(`${this.uri}currency/${id}`, { headers: this.headers });
  }
}