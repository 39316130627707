import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { Admin } from '../modules/admin';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  uri = environment.apiUrl;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  constructor(private http: HttpClient) { }
  getAlladmin(): Observable<Admin[]> {
    const url_get = this.uri + 'admins';
    return this.http.get<Admin[]>(url_get, { headers: this.headers });
  }
  getbyid(id: string) : Observable<Admin>{
    return this
      .http
      .get<Admin>(`${this.uri}admins/${id}`, { headers: this.headers });
  }


  addadmin(nom: string, prenom: string, email: string, password: string, telephone: number, role: string,
    users: boolean, actualite: boolean, publicite: boolean, conversion: boolean, tousconversion: boolean,
    jeux: boolean, mobile: boolean, contact: boolean, counter: boolean, product: boolean, listdelegate: boolean,
    demanddelegate: boolean, block: boolean, signal: boolean, gift: boolean, theme: boolean
    , user_point: boolean, user_diamond: boolean, user_message: boolean, user_delete: boolean, user_block: boolean
    , event: boolean, pret: boolean, store: boolean, codeparrainage:boolean) {
    const obj = {
      nom: nom,
      prenom: prenom,
      email: email,
      password: password,
      telephone: telephone,
      role: role,
      users: users,
      actualite: actualite,
      publicite: publicite,
      conversion: conversion,
      tousconversion: tousconversion,
      jeux: jeux,
      mobile: mobile,
      contact: contact,
      counter: counter,
      product: product,
      listdelegate: listdelegate,
      demanddelegate: demanddelegate,
      block: block,
      signal: signal,
      gift: gift,
      theme: theme,
      user_point: user_point,
      user_diamond: user_diamond,
      user_message: user_message,
      user_delete: user_delete,
      user_block: user_block,
      event: event,
      pret: pret,
      store: store,
      codeparrainage: codeparrainage,
    };
    this.http.post(`${this.uri}registeradmin`, obj, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'admins';
    return this.http.get<Admin[]>(url_get, { headers: this.headers });
  }

  updatedadmin(id:string ,nom: string, prenom: string, email: string, telephone: number,
    users: boolean, actualite: boolean, publicite: boolean, conversion: boolean, tousconversion: boolean,
    jeux: boolean, mobile: boolean, contact: boolean, counter: boolean, product: boolean, listdelegate: boolean,
    demanddelegate: boolean, block: boolean, signal: boolean, gift: boolean, theme: boolean
    , user_point: boolean, user_diamond: boolean, user_message: boolean, user_delete: boolean, user_block: boolean
    , pret: boolean, event: boolean, store: boolean, codeparrainage:boolean) {
    const obj = {
      nom: nom,
      prenom: prenom,
      email: email,
      telephone: telephone,
      users: users,
      actualite: actualite,
      publicite: publicite,
      conversion: conversion,
      tousconversion: tousconversion,
      jeux: jeux,
      mobile: mobile,
      contact: contact,
      counter: counter,
      product: product,
      listdelegate: listdelegate,
      demanddelegate: demanddelegate,
      block: block,
      signal: signal,
      gift: gift,
      theme: theme,
      user_point: user_point,
      user_diamond: user_diamond,
      user_message: user_message,
      user_delete: user_delete,
      user_block: user_block,
      pret: pret,
      event: event,
      store: store,
      codeparrainage: codeparrainage,
    };
    
    this.http.put(`${this.uri}updateadmin/${id}`, obj, { headers: this.headers })
      .subscribe((data)=>{
      });
    const url_get = this.uri + 'admins';
    return this.http.get<Admin[]>(url_get, { headers: this.headers });
  }

  updateadmin(nom: string, prenom: string, email: string, telephone: number, role: string, id: string) {
    const obj = {
      nom: nom,
      prenom: prenom,
      email: email,
      telephone: telephone,
      role: role
    };
    this
      .http
      .put(`${this.uri}updateadmin/${id}`, obj, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'admin';
    return this.http.get(`${this.uri}admins/${id}`, { headers: this.headers });
  }
  deleteadmin(id: string) {
    return this.http.delete(`${this.uri}deleteadmin/` + id, { headers: this.headers });
  }

  validatepassword(password: string, id: string) {
    const obj = {
      password: password
    };
    return this
      .http
      .post(`${this.uri}verifierpassword/${id}`, obj, { headers: this.headers })

  }
  updatepassword(password: string, id: string) {
    const obj = {
      password: password
    };

    return this
      .http
      .put(`${this.uri}updatepassword/${id}`, obj, { headers: this.headers });
  }
}
