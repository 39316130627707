import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalDismissReasons, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { Admin } from 'src/app/modules/admin';
import { AdminService } from 'src/app/services/admin.service';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { NotificationService } from 'src/app/services/notification.service';
import { FiltreadminPipe } from './liste-admin-filter.pipe';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-liste-admin',
  templateUrl: './liste-admin.component.html',
  styleUrls: ['./liste-admin.component.css']
})
export class ListeAdminComponent implements OnInit {
  utilisateur: Admin[];
  filteredItems: Admin[] = [];
  dtOptions: DataTables.Settings = {};
  user: any = {};
  id: string;
  searchText: string;
  closeResult = '';
  nom: string;
  prenom: string;
  email: string;
  telephone: number;
  passwordac: string;
  password: string;
  repassword: string;
  registerForm: FormGroup;
  submitted = false;
  currentPage = 1;
  itemsPerPage = 10;
  pageSize: number;
  p: number = 1;
  public filtreutilisateur: FiltreadminPipe;
  receivedData: any;
  constructor(private serviceadmin: AdminService, private serviceauth: AuthentificationService, private route: ActivatedRoute,
    private modalService: NgbModal,private sharedService: SharedService, private notifyService: NotificationService, config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  ngOnInit() {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;

    this.user = this.serviceauth.getCurrentUser();
    this.route.data.subscribe((data: { admin: Admin[] }) => this.filteredItems = data.admin);
    this.utilisateur = this.filteredItems.filter(ad => ad.id !== this.user.id);
    this.filteredItems = this.utilisateur;
  }
  get f() { return this.registerForm.controls; }

  getadmin() {
    this.serviceadmin.getAlladmin()
      .pipe(first()).subscribe(data => {
        this.utilisateur = data.filter(ad => ad.id !== this.user.id);
        this.filteredItems = this.utilisateur
      })
  }

  reset() {
    this.filteredItems = this.utilisateur;
    this.searchText = "";
  }

  open(content) {
    this.submitted = false;
    this.modalService.open(content, { size: 'lg', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.nom = "";
      this.prenom = "";
      this.email = "";
      this.telephone = null;
      this.password = "";
      this.repassword = "";
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  showToasterSuccess() {
    this.notifyService.showSuccess("تمت اضافة مسؤول")
  }
  showToasterError() {
    this.notifyService.showSuccess("تم محو المسؤول")
  }

  delete(id) {
    this.serviceadmin.deleteadmin(id).pipe(first()).subscribe(() => {
      this.getadmin();
      this.showToasterError();
      this.modalService.dismissAll();
    })
  }

  openSm(content) {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }
}