import { Pipe, PipeTransform } from '@angular/core';
import { Utilisateur } from 'src/app/modules/utilisateur';



@Pipe({
  name: 'filtreutilisateur',
  pure:false
})
export class FiltreutilisateurPipe implements PipeTransform {

  transform(utilisateur: Utilisateur[], chercheTerme: string): Utilisateur[] {
      if(!utilisateur || !chercheTerme){
          return utilisateur;
      }
    return utilisateur.filter(utilisateur =>
        utilisateur.nomprenom.toLowerCase().indexOf(chercheTerme.toLowerCase()) !== -1);
  }

}