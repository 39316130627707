<app-menu></app-menu>

<body dir="rtl" [ngStyle]="{'width': receivedData ? '82%' : 'auto'}" style="padding: 62px;">
    <div class="container pt-5" *ngIf="user.role == 'globale' || user.product == 1" style="    margin-right: 22px;">
        <div class="d-flex" style="justify-content: space-between;">
            <h2>المنتجات </h2>
            <div>
                <button routerLink="/category" class=" lab"> قائمة  الأصناف</button> &nbsp; &nbsp;
            <button routerLink="/products-demands" class=" lab"> قائمة طلبات المنتجات</button>
            </div>
        </div>

        <br>

        <form [formGroup]="registerForm" enctype="multipart/form-data">
            <div class="row">
                <div class="col-lg-6">


                    <div class="card " style="border: 1px solid dashed #CBF1FF;;">


                        <label for="inputGroupFile01"
                            style="background-color: transparent;height: 87%;width: 78%;margin-right: 43px; margin: top -36px;"
                            class=" labelImg col-img d-flex flex-row justify-content-center align-items-center ">
                            <img class="img-fluid img" *ngIf="link ==''" src="assets/picupload.png" alt="">
                            <img class="img-fluid img" *ngIf="link !=''" [src]="link" height="200px" width="200px">
                        </label>
                        <input (change)="selectImage($event)" [(ngModel)]="img" accept="image/*" formControlName="img"
                            style="display:none" type="file" class="custom-file-input" id="inputGroupFile01">


                        <div *ngIf="submitted && f['img'].errors " class="invalid-feedback">
                            <div *ngIf="f['img'].errors['required']" style="
                        margin-top: -34px;
                        margin-right: 65px;
                    "> يجب إدخال المنتج </div>
                        </div>
                        <br>


                        <div *ngIf="imageArray.controls.length < 5"
                            class="d-flex flex-row justify-content-center align-items-center" >
                            <button #submitButton1 (click)="addImage()"
                                style="background-color: transparent;border: none;"><img
                                    src="../assets/addphotoprouct.png" alt="" height="50" width="" class="add"></button>
                        </div>
                        <div formArrayName="images">
                            <div class="images-container align-items-center justify-content-center">
                                <button class="button btn-pagination" *ngIf="imageArray.controls.length > 3"
                                    (click)="previousImage()" [disabled]="currentIndex === 0"> <img
                                        src="../assets/next.png" alt=""> </button>
                                <div *ngFor="let imageControl of imageArray.controls; let i = index" >
                                    <div class="form-group" *ngIf="i >= currentIndex && i < currentIndex + 3"class="arrImage">
                                        <label class="labelImg1  col-img d-flex flex-row  align-items-center"
                                            [for]="'image' + i">
                                            <img class="img-fluid" [src]="imagePreviews[i]" *ngIf="imagePreviews[i]">
                                            <img class="img-fluid" src="../assets/Group 10.png"
                                                *ngIf="!imagePreviews[i]">
                                        </label>
                                        <input type="file" [id]="'image' + i" (change)="onFileSelected($event, i)"
                                            accept="image/*" style="display:none">

                                    </div>
                                </div>

                                <button class="button btn-pagination" *ngIf="imageArray.controls.length > 3"
                                    (click)="nextImage()"
                                    [disabled]="currentIndex === (imageArray.controls.length - 1)"><img
                                        src="../assets/previous.png" alt=""></button>
                            </div>
                        </div>

                        <div *ngIf="imageArray.controls.length == 0">
                            <br><br><br><br>
                        </div>

                        <br><br>


                    </div>

                    <div class="bord" >

                        <div class="d-flex">
                            <p class="prix">الإسم</p>
                            <input type="text" [(ngModel)]="name" name="name" formControlName="name" maxlength="30"
                                class="inp form-control">
                        </div>
                        <div *ngIf=" submitted==true &&f['name'].errors" class="invalid-feedback">
                            <div *ngIf="f['name'].errors['required']"> يجب إدخال الإسم</div>
                        </div>
                        <br>

                        <div class="d-flex">
                            <p class="prix">الثمن</p>
                            <input type="text" onpaste="return false;" (keypress)="keyPressNumbers($event)"
                                maxlength="10" class="inp form-control" [(ngModel)]="prix" name="prix"
                                formControlName="prix">
                        </div>
                        <div *ngIf="submitted && f['prix'].errors" class="invalid-feedback">
                            <div *ngIf="f['prix'].errors['required']"> يجب إدخال الثمن</div>
                        </div>
                        <br>

                        <div class="d-flex">
                            <p class="prix">الوصف</p>
                            <textarea type="text" maxlength="250" style="max-height: 100px !important;margin-right: 3px;" cols="30"
                                rows="2" class="inp form-control" [(ngModel)]="description" name="description"
                                formControlName="description"></textarea>
                             
                        </div>
                        <div *ngIf="submitted && f['description'].errors" class="invalid-feedback">
                            <div *ngIf="f['description'].errors['required']"> يجب إدخال الوصف</div>
                        </div>
                        <br>
                        <div class="d-flex">
                            <p class="category">الصنف</p> &nbsp; &nbsp;
                            
                            <select style="margin-right: 22px;" [(ngModel)]="selectedCategory" name="category" class="inp form-control" formControlName="category" required>
                                <option value="undefined" disabled  >اختر الصنف</option>
                                <option *ngFor="let category of categories" [value]="category.id">{{ category.name }}</option>
                              </select>
                        </div>
                        <div *ngIf="submitted && f['category'].errors" class="invalid-feedback">
                            <div *ngIf="f['category'].errors['required']">يجب إدخال الصنف</div>
                          </div>
                        <br>

                        <div class="d-flex flex-row justify-content-center align-items-center">
                            <button type="submit" #submitButton1 (click)="ajouter(); " class="btn1">إضافة</button>
                        </div>
                    </div>
                </div>
                <br>
                <div class="col-lg-6">
                   
                
                    <div class="row bord"> 
                   <div class="d-flex" style="padding: 15px;justify-content: center;">
                    <button  (click)="getProducts(); " class=" lab">   الكل</button>&nbsp; &nbsp;
                    <select [(ngModel)]="selectedFilterCategory" formControlName="category1" (change)="filterProducts()" class="lab ">
                        <option  value="undefined" disabled>الأصناف  </option>
                        <option *ngFor="let category of categories" [value]="category.id">{{ category.name }}</option>
                    </select> 
                    
                </div>
                        <div class="col-md-6"
                            *ngFor="let product of products| slice: pageSize | slice: 0:itemsPerPage; let index = index;">
                            <div class="mb-3">
                                
                                <div class="col-md-6" style="width: 83%;border: 1px dashed #5bd5ff;border-radius: 9px; height:225px;margin-top:10px; text-align-last: center;">
                                    <br>
                                    <img class="img-fuild img1" src="{{product.img}}" alt=""
                                        style="height: 41%; width: 41%;">

                                </div>
                                <ng-template #content8 let-modal>
                                    <div class="modal-body" dir="rtl">
                                      <button type="button" class="close" aria-label="Close"
                                          (click)="modal.dismiss('Cross click')" >
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                      <div class="container-fluid">
                                          
                                        <div class="row" style="    justify-content: right;">
                                          
                                      <br>
                                          {{product.description}}
                                          <br>
                                      </div>
                                      <div class="row">
                                          <div class="col">
                                              <!-- Utilisez la variable selectedImage pour afficher l'image sélectionnée -->
                                              <img *ngIf="product.images.length=='0'" src="{{product.img}}"
                                                  alt="" width="253px" 
                                                  (click)="showImage(product.img)">
                                              <img *ngIf="product.images.length!='0'" src="{{selectedImage}}"
                                                  alt="" width="153px"
                                                  style="margin-right: 84px;margin-top: -1px;border: 1px dashed #47bdff;border-radius: 12px;"
                                                  (click)="showImage(product.img)">
                                          </div>
                                          <div class="col">
                                              <div *ngIf="product.images.length>'4'">
                                                  <mat-paginator [length]="product.images.length"
                                                      [hidePageSize]="true" [pageSize]="itemsPerPage"
                                                      (page)="pageChangedim($event)"
                                                      [showFirstLastButtons]="false">
                                                  </mat-paginator>
                                  
                                  
                                              </div>
                                              <div class="image-container"
                                                  style="display: flex; flex-wrap: wrap; margin-right: 36px;">
                                                  <div
                                                      *ngFor="let im of product.images | slice: (currentPage - 1) * itemsPerPage : currentPage * itemsPerPage; let i = index;">
                                                      <img src="{{im}}" alt="" height="80" width="80"
                                                          style="box-shadow:3px 2px #888888;margin-right:10px;margin-left: 2px;margin-bottom: 10px;border: 1px dashed #47bdff;border-radius: 12px;"
                                                          (click)="showImage(im)">
                                                  </div>
                                              </div>
                                  
                                  
                                          </div>
                                      </div>
                                  </div>
                                      </div>
                                   
                                  </ng-template>
                                  
                                <p class="prix"
                                    style="text-align: center;font-size: 14px;margin: -10px;margin-top: -46px;margin-left: 45px;">
                                    الاسم:{{product.name}} </p>
                                <p class="prix"
                                    style="text-align: center;font-size: 14px;margin: -10px;margin-top: -46px;margin-left: 45px;">
                                    الثمن :{{product.prix}} <img src="../assets/Coin.png" alt=""></p>

                                <div class="d-flex" style="margin-top: 37px;place-content: center;">

                                    <img class="delete" height="37px" width="37px" (click)="openSm2(content4)"
                                        src="assets/deleteic.png" alt="" style="margin-left:8px">
                                    <img src="../assets/edit.png" [routerLink]="['/editProduct/', product.id]" alt=""
                                        style=" width: 37px; height:37px ">
                                    <img class="delete" height="37px" width="37px" (click)="openSm2(content8)"
                                        (click)="showImage(product.img)" src="../assets/imagecarte.png" alt="">
                                </div>
                                <ng-template #content4 let-modal>

                                    <div class="modal-body" dir="rtl">
                                        <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                        <h5 id="h3"> هل تريد حذف هذا المنتج ؟</h5>
                                        <br>
                                        <div class="d-flex justify-content-center">
                                            <button id="btnP" (click)="delete(product.id)" type="button"
                                                class="btn btn-primary">&nbsp;
                                                &nbsp; تأكيد &nbsp; &nbsp;</button>
                                        </div>

                                    </div>

                                </ng-template>
                            </div>
                        </div>
                        <div style="    justify-content: center;" class="pag">
                        <ngb-pagination class="pagination-reversed" dir="rtl" [maxSize]="5" [rotate]="true" 
                            *ngIf="products.length > itemsPerPage" [collectionSize]="products.length" #numPages
                            [pageSize]="itemsPerPage" [(page)]="currentPage"
                            (pageChange)="onPageChange(currentPage); p = $event"><ng-template ngbPaginationPrevious>
                                <button class="btn btn-link custom-pagination-button">
                                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                </button>
                            </ng-template>
                            <ng-template ngbPaginationNext>
                                <button class="btn btn-link custom-pagination-button">
                                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                                </button>
                            </ng-template></ngb-pagination>
                        </div>
                    </div>
                </div>

            </div>
        </form>


        <br><br>


    </div>
    <div class="container" *ngIf="user.role != 'globale' && user.product == 0 " style="padding-top: 10% !important">
        <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
          background-repeat: no-repeat;background-position: center center;display:block; margin-top:auto;
          margin-bottom:auto; max-height: 800px; background-image: url('assets/droit.png')">
        </div>
      </div>
</body>