import { Component, OnInit, Renderer2 } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Recharge } from 'src/app/modules/recharge';
import { NotificationService } from 'src/app/services/notification.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { RechargeService } from 'src/app/services/recharge.service';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})

export class ProductComponent implements OnInit {
  selectedImage: string = '';
  prix: string;
  name: string
  prix2: string;
  selectedCategory:string;
  description: string;
  img: string;
  img2: string;
  submitted = false;
  submitted2 = false;
  images1: File;
  images2: File;
  registerForm: FormGroup;
  registerForm2: FormGroup;
  link: string = "";
  link2: string = "";
  closeResult = '';
  products: Recharge[] = [];
  filteredProducts: Recharge[] = [];
  selectedFilterCategory: string | undefined;
  currentPage = 1;
  itemsPerPage = 4;
  pageSize: number;
  p: number = 1;
  user: any = {};
  pagedProducts: any[] = [];
  imagePreviews: string[] = [];
  imagePreviews1: string[] = [];
  currentIndex = 0;
  isFirstDivVisible: boolean = true;
  im: any;
  receivedData: any;
  categories: Recharge[] = [];

  constructor(private servicerecharge: RechargeService,
    private formBuilder: FormBuilder,
    private notifyService: NotificationService,
    private modalService: NgbModal, private sharedService: SharedService,


    private route: ActivatedRoute, private serviceauth: AuthentificationService
  ) { }

  ngOnInit(): void {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;

    });
    this.receivedData = false;
    this.getCategories()
    this.registerForm = this.formBuilder.group({
      img: [null, Validators.required], // Update accordingly
      name: ['', Validators.required],
      prix: ['', Validators.required],
      description: ['', Validators.required],
      images: this.formBuilder.array([]),
      category:['', Validators.required],
      category1:[''],
    });

    this.user = this.serviceauth.getCurrentUser();
    this.registerForm2 = this.formBuilder.group({
      prix2: ['', Validators.required],
      new_images: this.formBuilder.array([])
    });
    for (let i = 0; i < 3; i++) {
      this.addImage(); // Call the function to add an image control
    }
    this.getProducts();
  }

  get f() { return this.registerForm.controls; }
  get g() { return this.registerForm2.controls; }
  showImage(imagePath: any): void {
    imagePath = imagePath.toString();
    this.selectedImage = imagePath;
  }
  getProducts() {
    this.servicerecharge.getProducts()
      .pipe(first()).subscribe(data => {
        this.products = data
        this.selectedFilterCategory = "undefined"
      })
  }

  getCategories() {
    this.servicerecharge.getCategories()
      .pipe(first()).subscribe(data => {
        this.categories = data
      })
  }

  filterProducts(): void {
    this.servicerecharge.getProducts()
    .pipe(first()).subscribe(data => {
 
      this.products = data.filter(product => product.category_id === this.selectedFilterCategory);
    })
  }


  pageChangedim(event: any): void {
    this.currentPage = event.pageIndex + 1;

  }

  get imageArray() {
    return this.registerForm.get('images') as FormArray;
  }
  onFileSelected(event: any, index: number) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.imageArray.setControl(index, this.formBuilder.control(files[i]));
      this.previewImage(files[i], index);
    }
  }
  addImage() {
    const control = this.formBuilder.control(null);
    this.imageArray.push(control);
    this.imagePreviews.push('');
  }
  previewImage(files: File, index: number) {
    if (files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreviews[index] = e.target.result;
      };
      reader.readAsDataURL(files);
    }
  }

  pageChanged(): void {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.pagedProducts = this.products.slice(startIndex, endIndex);
  }


  previousImage() {
    if (this.currentIndex > 0) {
      this.currentIndex -= 3;
    }
  }
  toggleDivs() {
    this.isFirstDivVisible = !this.isFirstDivVisible;
  }

  // Méthode pour passer à l'affichage suivant de trois images
  nextImage() {
    if (this.currentIndex < (this.imageArray.controls.length - 3)) {
      this.currentIndex += 3;
    }
  }

  get imageArray1() {
    return this.registerForm2.get('new_images') as FormArray;
  }
  onFileSelected1(event: any, index: number) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.imageArray1.setControl(index, this.formBuilder.control(files[i]));
      this.previewImage1(files[i], index);
    }
  }
  addImage1() {
    const control = this.formBuilder.control(null);
    this.imageArray1.push(control);
    this.imagePreviews1.push('');
  }
  previewImage1(files: File, index: number) {
    if (files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreviews1[index] = e.target.result;
      };
      reader.readAsDataURL(files);
    }
  }



  ajouter() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;

    }
    const formData = new FormData();
    formData.append('img', this.images1);
    formData.append('category_id', this.selectedCategory);
    formData.append('prix', this.prix);
    formData.append('name', this.name);
    formData.append('description', this.description);
    formData.append('type', "product");
    for (let i = 0; i < this.imageArray.length; i++) {
      if (this.imageArray.at(i).value != null) {
        formData.append('images[]', this.imageArray.at(i).value);
      }

    }
    this.servicerecharge.addProduct(formData)
      .pipe(first()).subscribe(() => {
        this.prix = "";
        this.link = "";
        this.name = "";
        this.description = "";
        this.img = "";
        this.imagePreviews = [];
        this.selectedCategory = undefined;  
        this.showToasterSuccess();
        setTimeout(() => {
          this.getProducts();
        }, 5000)
        this.images1 = null;
        this.submitted = false;
        this.imageArray.controls = []
        for (let i = 0; i < 3; i++) {
          this.addImage(); 
        }
      })

  }


  updateProduct(id: string, prix: string) {
    this.submitted2 = true;
    if (this.registerForm2.invalid) {
      return;
    }

    const formData2 = new FormData();
    formData2.append('img', this.images2);
    formData2.append('prix', prix);
    for (let i = 0; i < this.imageArray1.length; i++) {
      formData2.append('new_images[]', this.imageArray1.at(i).value);
    }

    this.servicerecharge.updaterecharge(id, formData2)
      .pipe(first()).subscribe(() => {
        this.prix2 = "";
        this.link2 = "";
        this.showToasterSuccess2();
        setTimeout(() => {
          this.getProducts();

        }, 2000)
        this.images2 = null;
        this.submitted2 = false;
        this.modalService.dismissAll();
      })
  }


  delete(id: string) {
    this.servicerecharge.deleterecharge(id)
      .pipe(first()).subscribe(() => {
        this.getProducts();
        this.showToasterError();
        this.modalService.dismissAll();
      })
  }

  showToasterSuccess() {
    this.notifyService.showSuccess(" تم اضافة منتج")
  }

  showToasterSuccess2() {
    this.notifyService.showSuccess(" تم تعديل المنتج")
  }

  showToasterError() {
    this.notifyService.showSuccess("تم محو المنتج ")
  }

  selectImage(event: any) {

    var reader = new FileReader();
    const file = event.target.files[0];
    this.images1 = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link = event.target.result
    }
  }

  selectImage2(event: any) {

    var reader = new FileReader();
    const file = event.target.files[0];
    this.images2 = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link2 = event.target.result
    }

  }

  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  open(content) {
    this.prix2 = "";
    this.link2 = "";
    this.modalService.open(content, { windowClass: "myCustomModalClass", centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;


    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });
  }


  isZeroAllowed = true;

  keyPressNumbers(event) {
    const key = event.key || String.fromCharCode(event.keyCode);

    if (key === '0' && this.isZeroAllowed && event.target.selectionStart === 0) {
      event.preventDefault();
    }

    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }

  }
  inputEvent(event: any) {
    const value = event.target.value;
    this.isZeroAllowed = value === '';
  }


  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }

}
