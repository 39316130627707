import { Component, OnInit, Renderer2 } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Recharge } from 'src/app/modules/recharge';
import { NotificationService } from 'src/app/services/notification.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { RechargeService } from 'src/app/services/recharge.service';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { ActualiteService } from 'src/app/services/actualite.service';

@Component({
  selector: 'app-editpost',
  templateUrl: './editpost.component.html',
  styleUrls: ['./editpost.component.css']
})
export class EditpostComponent implements OnInit {

  prix: string;
  name: string
  prix2: string;
  img: string;
  img2: string;
  submitted = false;
  submitted2 = false;
  images1: File;
  images2: File;
  registerForm: FormGroup;
  registerForm2: FormGroup;
  link: string = "";
  link2: string = "";
  closeResult = '';
  products: Recharge[] = [];
  currentPage = 1;
  itemsPerPage = 8;
  pageSize: number;
  p: number = 1;
  user: any = {};
  imagePreviews: string[] = [];
  imagePreviews1: string[] = [];
  currentIndex = 0;
  productId: number;
  product: Recharge;
  postId: string;
  texte: string;
  post:any= {};
  showAll: boolean = false;
  images = [];

  constructor(private servicerecharge: RechargeService, private serviceactualite: ActualiteService,
    private formBuilder: FormBuilder,
    private notifyService: NotificationService,
    private modalService: NgbModal,
    private route: ActivatedRoute, private serviceauth: AuthentificationService
  ) { }

  ngOnInit(): void {

    this.postId = this.route.snapshot.params['pubId'];

    this.getPostbyId(this.postId)



    this.registerForm = this.formBuilder.group({
      texte: ['', Validators.required],
      images: this.formBuilder.array([])
    });

    this.user = this.serviceauth.getCurrentUser();

    this.registerForm2 = this.formBuilder.group({
      images: this.formBuilder.array([])
    });


  }

  get f() { return this.registerForm.controls; }
  get g() { return this.registerForm2.controls; }



  getPostbyId(postId) {
    this.serviceactualite.getPostbyId(postId).pipe(first())
    .subscribe(data => {
      this.post = data;
      if(this.post.content[0].text){
        this.texte = this.post.content[0].text;
      }
      if(this.post.content[0].images){
        this.images = JSON.parse(this.post.content[0].images);

   
          for (let i = 0; i < this.images.length; i++) {
            const image = this.images[i];
            const control = this.formBuilder.control(image);
            this.imageArray.push(control);
          }
       
      }
    })
  }


  get imageArray() {
    return this.registerForm.get('images') as FormArray;
  }
  onFileSelected(event: any, index: number) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.imageArray.setControl(index, this.formBuilder.control(files[i]));
      this.previewImage(files[i], index);
    }
  }

  getSrc(index: number) {
    const control = this.imageArray.at(index);
    if (control.value instanceof File) {
      return this.imagePreviews[index];
    } else if (typeof control.value === 'string') {
      return control.value;
    } else {
      return null;
    }
  }
  addImage() {
    const control = this.formBuilder.control(null);
    this.imageArray.push(control);
    this.imagePreviews.push('');
  }
  previewImage(files: File, index: number) {
    if (files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreviews[index] = e.target.result;
      };
      reader.readAsDataURL(files);
    }
  }

  previousImage() {
    if (this.currentIndex > 0) {
      this.currentIndex -= 3;
    }
  }

  // Méthode pour passer à l'affichage suivant de trois images
  nextImage() {
    if (this.currentIndex < (this.imageArray.controls.length - 3)) {
      this.currentIndex += 3;
    }
  }

  updateImageText() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('texte', this.texte);
    for (let i = 0; i < this.imageArray.length; i++) {
      const item = this.imageArray.at(i).value;
      if (item instanceof File) {
        formData.append('images[]', item);
      } else if (typeof item === 'string') {
        formData.append('imageUrls[]', item);
      }
    }
    this.serviceactualite.updatePost(formData, this.postId).pipe(first()).subscribe(() => {
      this.link = "";
      this.img = "";
      this.imagePreviews = [];
      this.showToasterSuccess();
      this.getPostbyId(this.postId);
      this.images1 = undefined;
      this.submitted = false;
      this.imageArray.controls = []
    })
  }

  updateImage() {
    this.submitted2 = true;
    if (this.registerForm2.invalid) {
      return;
    }
    const formData = new FormData();

    for (let i = 0; i < this.imageArray.length; i++) {
      const item = this.imageArray.at(i).value;
      if (item instanceof File) {
        formData.append('images[]', item);
      } else if (typeof item === 'string') {
        formData.append('imageUrls[]', item);
      }
    }
    this.serviceactualite.updatePost(formData, this.postId).pipe(first()).subscribe(() => {
      this.link = "";
      this.img = "";
      this.imagePreviews = [];
      this.showToasterSuccess();
      this.getPostbyId(this.postId);
      this.images1 = undefined;
      this.submitted = false;
      this.imageArray.controls = []
    })
  }

  updateText() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('texte', this.texte);
    this.serviceactualite.updatePost(formData, this.postId).pipe(first()).subscribe(() => {
      this.link = "";
      this.img = "";
      this.imagePreviews = [];
      this.showToasterSuccess();
      this.getPostbyId(this.postId);
      this.images1 = undefined;
      this.submitted = false;
      this.imageArray.controls = []
    })
  }

  showToasterSuccess() {
    this.notifyService.showSuccess(" تم تعديل المنشور")
  }

  selectImage(event: any) {

    var reader = new FileReader();
    const file = event.target.files[0];
    this.images1 = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link = event.target.result
    }
  }






  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }

}
