<app-menu></app-menu>
<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<body>
  <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.actualite == 1">
    <br><br>
    <div class="card" style="padding-right: 40px;">
      <div class="card-body">
        <br>
        <h2 id="h4">  المنشورات </h2>

 <div *ngIf="posts.length==0">
            لا يوجد منشورات
          </div>
        <div *ngFor="let post of posts">
         
            <div class="container posts-content">
                <div class="row">
                  <div class="card mb-4">
                    <div class="card-body">
                      <div class="d-flex" style="justify-content: space-between;">
             
                        <div class="d-flex mb-3">
                          <img [src]="post.image" class="media-object" alt="">
                          <div class="media-body" style="margin-right: 15px;">
                            <h5>{{post.name}}  {{post.codeparrainage}} </h5>
                            <div class="text-muted small">{{ post.created_at | date: 'yyyy/MM/d H:mm' }}</div>
                          </div>
                        </div>

                        <div class="dropdown">
                          <img height="30px" src="../../../assets/crclemenu.png" alt="">
                          <div class="dropdown-content">
                            <a *ngIf="post.type != 'video'" [routerLink]="['/edit-post/', post._id]"><img src="../../../assets/editpublication.png" alt=""> تعديل المنشور</a>
                            <a (click)="openSm2(content4)"> <img src="../../../assets/deltepublication.png" alt=""> حذف المنشور</a>
                          </div>
                        </div>

                        <ng-template #content4 let-modal>
                          <div class="modal-body" dir="rtl">
                              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                  <span aria-hidden="true">&times;</span>
                              </button>
                              <h5 id="h3"> هل تريد حذف هذا المنشور ؟</h5>
                              <br>
                              <div class="d-flex justify-content-center">
                                <button (click)="delete(post._id)" class="custom-button custom-button-with-image">حذف</button>
                              </div>
                          </div>
                      </ng-template>
      
                        
                      </div>
                     
                      <div *ngIf="post.type == 'text' || post.type == 'imageText' || post.type == 'videoText'">
                        {{post.content[0].text}}
                      </div>
      
      
                      <div class="row" *ngIf="post.type == 'image' || post.type == 'imageText'">
                        <div *ngFor="let image of post.content[0].images?.slice(0, showAll ? post.content[0].images.length : 5); let i = index"
                          class="col-lg-4">
                          <div class="cardImage">
                            <img class="img-fluid imagePost" [src]="image" alt="Image {{ i + 1 }}" />
                          </div>
                        </div>
                        <div *ngIf="post.content[0].images?.length > 5" class="col-lg-4 d-flex align-items-center">
                          <div class="cardImage">
                            <button (click)="toggleView(post)" class="btn btn1">
                              {{ showAll ? 'اخفاء' : 'اعرضهم جميعا' }}
                            </button>
                          </div>
                        </div>
                      </div>
      
                      <div class="row video" *ngIf="post.type == 'video' || post.type == 'videoText'">
                        <video height="400" controls>
                          <source [src]="post.content[0].video" type="video/mp4">
                        </video>
                      </div>
      
                    </div>
                    <div class="card-footer">
                        <div class="d-flex" style="justify-content: space-between;">
                            <a href="javascript:void(0)" class="d-inline-block text-muted" style="margin-left: 10px;">
                                <small class="align-middle">
                                    <img src="../../../assets/react.png" alt=""> &nbsp;  <strong>{{post.reactCount}}</strong> </small>
                              </a>
                              <a href="javascript:void(0)" class="d-inline-block text-muted" (click)="toggleComments(post._id)">
                                <small class="align-middle">
                                  تعليقات <strong>{{post.commentCount}}</strong> </small>
                              </a>
                        </div>
                    </div>
                   <form [formGroup]="registerForm" enctype="multipart/form-data">
                    <div class=" d-flex">
                      <input type="text" formControlName="newComment" placeholder="تعليق..." class="form-control">
                      <button [disabled]="registerForm.invalid" type="submit"
                      #submitButton1 (click)="addComment(post._id); submitButton1.disabled = true"  class="lab btn"><i style="color: black;"
                        class="material-icons glyphicon">send</i> </button>
                    </div>
                   </form>
                    <br>
                    <section class="content-item" id="comments" *ngIf="post.showComments && post.comments && post.comments.length > 0">
                        <div class="container">   
                                           
                            <div class="media" *ngFor="let comment of post.comments.slice(0, visibleCommentsCount); let i = index">
                                <a class="pull-right" ><img class="media-object1" [src]="comment.image" alt=""></a>
                                <div class="media-body">
                                    <h6 class="media-heading"> {{comment.name}} {{comment.codeparrainage}} </h6>
                                   
                                    <div class="text-muted small"> {{ comment.created_at.$date.$numberLong | date: 'yyyy/MM/d H:mm'  }}</div>
                                    <p *ngIf="comment.type.text"> {{comment.type.text}} </p>
                                    <img *ngIf="comment.type.image" class="img-fluid imageComment" [src]=" comment.type.image">
                                    <p *ngIf="comment.type.gift">
                                      {{comment.type.gift.text}}
                                      <img [src]="comment.type.gift.image"  width="50" height="50">
                                      <img src="../../../assets/smallmultiplicate.png" width="50" height="50">
                                      {{comment.type.gift.giftCount}}
                                    </p>
                                    <br>
                                   
                                </div>
                                <div class="d-flex" style="justify-content: space-between;">
                                    <div  class="d-inline-block text-muted" style="margin-left: 10px;">
                      
                                      <small class="align-middle">
                                        <img src="../../../assets/react.png" style="max-width: 70px;height:20px;" alt=""> &nbsp; <strong>{{comment.reactCount}}</strong> </small>
                                        <small class="align-middle response">
                                          <a (click)="toggleCommentReplyInput(comment._id.$oid, comment.name)">إجابة</a>
                                        </small>
                                        <small *ngIf="comment.codeparrainage=='admin'" class="align-middle response">
                                          <a (click)="openSm3(content55)">تعديل </a>
                                        </small>
                                        <small class="align-middle response">
                                          <a (click)="openSm2(content5)">حذف</a>
                                        </small>
                                    </div> 
                                    <ng-template #content55 let-modal>
                                      <div class="modal-body" dir="rtl">
                                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                              <span aria-hidden="true">&times;</span>
                                          </button>
                                          <h5 id="h3">  تعديل التعليق </h5>
                                          <br>
                                          <form [formGroup]="registerForm4" enctype="multipart/form-data">
                                            <input type="text" class="form-control" [(ngModel)]="comment.type.text" formControlName="updateComment">
                                            <br>
                                            <div class="d-flex justify-content-center">
                                              <button (click)="updatedComment(post._id, comment._id.$oid, comment.type.text)" class="custom-button custom-button-with-image">تعديل</button>
                                            </div>
                                          </form>
                                        
                                      </div>
                                    </ng-template>

                                    <ng-template #content5 let-modal>
                                      <div class="modal-body" dir="rtl">
                                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                              <span aria-hidden="true">&times;</span>
                                          </button>
                                          <h5 id="h3"> هل تريد حذف هذا التعليق ؟</h5>
                                          <br>
                                          <div class="d-flex justify-content-center">
                                            <button (click)="deleteComment(post._id, comment._id.$oid)" class="custom-button custom-button-with-image">حذف</button>
                                          </div>
                                      </div>
                                    </ng-template>
                                    
                                    <a *ngIf="comment.commentCount != 0" href="javascript:void(0)" (click)="toggleReplies(comment._id.$oid, post)" class="d-inline-block text-muted ">
                                      <small class="align-middle">
                                        إجابات </small>
                                    </a>
                                </div>
              
                                <div class="reply " *ngIf="comment.replies && comment.replies.length > 0" >
                                  <br>
                                  <div *ngFor="let reply of comment.replies" class="media">
                                    <a class="pull-right"><img class="media-object1" [src]="reply.image" alt=""></a>
                                    <div class="media-body">
                                        <h6 class="media-heading"> {{reply.name}} {{reply.codeparrainage}} </h6>
                                        <div class="text-muted small"> {{ reply.created_at | date: 'yyyy/MM/d H:mm'  }}</div>
                                       
                                        <strong class="userReply" *ngIf="reply.userReply && !reply.type.text">{{reply.userReply}}</strong>
                                        <p *ngIf="reply.type.text"><strong class="userReply" *ngIf="reply.userReply">{{reply.userReply}}</strong> {{reply.type.text}} </p>
                                        <img *ngIf="reply.type.image" class="img-fluid imageComment" [src]=" reply.type.image" alt="">
                                        <p *ngIf="reply.type.gift">
                                          {{reply.type.gift.text}}
                                          <img [src]="reply.type.gift.image"  width="50" height="50">
                                          <img src="../../../assets/smallmultiplicate.png" width="50" height="50">
                                          {{reply.type.gift.giftCount}}
                                        </p>
                                        <br>
                                    
                                    </div>
                                    <div class="d-flex" style="justify-content: space-between;">
                                        <a href="javascript:void(0)" class="d-inline-block text-muted" style="margin-left: 10px;">
                                          <small class="align-middle">
                                            <img src="../../../assets/react.png" style="max-width: 70px;height:20px;" alt=""> &nbsp; <strong>{{reply.reactCount}}</strong> </small>
                                            <small class="align-middle response">
                                              <a (click)="toggleReplyInput(reply._id, reply.name)">إجابة</a>
                                            </small>
                                            <small *ngIf="reply.codeparrainage=='admin'" class="align-middle response">
                                              <a (click)="openSm3(content66)">تعديل </a>
                                            </small>
                                            <small class="align-middle response">
                                              <a (click)="openSm2(content6)">حذف</a>
                                            </small>
                                        </a> 
                                        <ng-template #content66 let-modal>
                                          <div class="modal-body" dir="rtl">
                                              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                                  <span aria-hidden="true">&times;</span>
                                              </button>
                                              <h5 id="h3">  تعديل الإجابة  </h5>
                                              <br>
                                              <form [formGroup]="registerForm5" enctype="multipart/form-data">
                                                <input type="text" class="form-control" [(ngModel)]="reply.type.text" formControlName="updateReply">
                                                <br>
                                                <div class="d-flex justify-content-center">
                                                  <button (click)="updatedReply(reply._id, reply.type.text)" class="custom-button custom-button-with-image">تعديل</button>
                                                </div>
                                              </form>
                                            
                                          </div>
                                        </ng-template>
                                        <ng-template #content6 let-modal>
                                            <div class="modal-body" dir="rtl">
                                                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h5 id="h3"> هل تريد حذف هذه الإجابة ؟</h5>
                                                <br>
                                                <div class="d-flex justify-content-center">
                                                  <button (click)="deleteReply(post._id, comment._id.$oid, reply._id)" class="custom-button custom-button-with-image">حذف</button>
                                                </div>
                                            </div>
                                        </ng-template>
                                       
                                    </div>
                                    <div *ngIf="showReplyInput[reply._id]">
                                      <form [formGroup]="registerForm3" enctype="multipart/form-data">
                                        <div class=" d-flex">
                                          <input type="text" [(ngModel)]="replyUserName1" class="form-control" formControlName="replyUserName1" placeholder=" إجابة على {{ reply.name }}" />
                                        <button [disabled]="registerForm3.invalid" type="submit" class="btn lab"
                                        #submitButton2 (click)="submitReply1(comment._id.$oid, replyUserName1, post._id, reply._id); submitButton2.disabled = true"><i style="color: #fff;"
                                        class="material-icons glyphicon">send</i></button>
                                        </div>
                                        
                                      </form>  
                                    </div>
                                  </div>
                                </div>
              
                                <br>
                                <div *ngIf="showCommentReplyInput[comment._id.$oid]">
                                  <form [formGroup]="registerForm2" enctype="multipart/form-data">
                                    <div class=" d-flex">
                                      <input type="text" [(ngModel)]="replyUserName" class="form-control" formControlName="replyUserName" placeholder=" إجابة على {{ comment.name }}" />
                                    <button [disabled]="registerForm2.invalid" type="submit" class="btn lab"
                                    #submitButton1 (click)="submitReply(comment._id.$oid, replyUserName, post._id); submitButton1.disabled = true"><i style="color: #fff;"
                                    class="material-icons glyphicon">send</i> </button>
                                    </div>
                                    
                                  </form>  
                                </div>
                          
                            </div>
                            <button class="btn btn1" *ngIf="post.comments.length > visibleCommentsCount" (click)="loadMoreComments()">
                              اقرأ المزيد
                            </button>
                        </div>
                    </section>
                  </div>
                </div>
             
              </div>
          </div>
          
          <ngb-pagination [maxSize]="5" 
          [(page)]="currentPage"
          [pageSize]="pageSize"
          [collectionSize]="totalItems"
          (pageChange)="pageChange($event)"
        ></ngb-pagination>


    
      

      </div>
    </div>
  </div>

  <div class="container" *ngIf="user.role != 'globale' && user.actualite == 0 " style="padding-top: 10% !important">
    <div class="container" style="width: 100%; height: 400px; max-height: 800px;background-size: contain;
      background-repeat: no-repeat;background-position: center center;display:block;margin-top:auto;
      margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
    </div>
  </div>
</body>