
<meta name="viewport" content="width=device-width, initial-scale=1.0">

<div id="menu"><app-menu></app-menu></div>

<div id="contenu"  [ngStyle]="{'width': receivedData ? '82%' : 'auto'}">
<body dir="rtl">
  <div class="container pt-5" *ngIf="user.role == 'globale' || user.counter == 1">

    <h2>إضافة عرض</h2>
    <br>
    <form [formGroup]="registerForm" style="background-color:#b3daffd9;  border-radius: 15px;">
      <div class="row">
        <div class="col-md-3">
          <input type="text" class="inpu form-control" formControlName="name" name="name" [(ngModel)]="name" maxlength="30"
            placeholder="إسم العرض">
          <div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
            <div class="error" *ngIf="f['name'].errors['required']"> إسم العرض مطلوب</div>
          </div>
        </div>
        <div class="col-md-2">
          <input type="text"maxlength="10"  class="inpu form-control" formControlName="point" onkeydown="javascript: return event.keyCode === 8 ||
                event.keyCode === 46 ? true : !isNaN(Number(event.key))" name="point" [(ngModel)]="point"(keypress)="keyPressNumbers($event)"
            placeholder="قيمة الربح  ">
          <div *ngIf="submitted && f['point'].errors" class="invalid-feedback">
            <div class="error" *ngIf="f['point'].errors['required']">قيمة الربح مطلوبة</div>
          </div>
        </div>
        <div class="col-md-2">
          <input type="text" maxlength="10"  class="inpu form-control" formControlName="price" onkeydown="javascript: return event.keyCode === 8 ||
                event.keyCode === 46 ? true : !isNaN(Number(event.key))" name="price" [(ngModel)]="price"(keypress)="keyPressNumbers($event)"
            placeholder="الثمن">
          <div *ngIf="submitted && f['price'].errors" class="invalid-feedback">
            <div class="error" *ngIf="f['price'].errors['required']"> الثمن مطلوب</div>
          </div>
        </div>
        <div class="col-md-2">
          <select class="form-select inpu" aria-label="Default select example" formControlName="type"
            [(ngModel)]="type">
            <option selected disabled [ngValue]="undefined">-- نوع العرض --</option>
            <option value="point">نقاط سنتات</option>
            <option value="diamond">مجوهرات</option>
          </select>
          <div *ngIf="submitted && f['type'].errors" class="invalid-feedback">
            <div class="error" *ngIf="f['type'].errors['required']">نوع العرض مطلوب</div>
          </div>
        </div>
        <div class="col-md-3" style="text-align: center;" id="bton">
          <button (click)="ajouter()" class="btn lab" id="bt">تسجيل</button>
        </div>
      </div>
    </form>
    <br>
    <h3> قائمة العروض</h3>
    <br>
    <div class="row" style="overflow-x:auto; overflow-y: auto; direction: rtl;">
      <table id="dtable" datatable [dtOptions]="dtOptions" class="row-border hover">
        <thead>
          <br>
          <tr>
            <th style="border-bottom-right-radius: 10px; border-top-right-radius:10px">#</th>
            <th>الإسم</th>
            <th>قيمة الربح </th>
            <th> الثمن</th>
            <th> النوع</th>
            <th style="border-bottom-left-radius: 10px; border-top-left-radius:10px"></th>
          </tr>
        </thead>
        <tbody *ngFor="let offer of offers ; let index = index;">
          <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;">
          <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{((currentPage * 10) -9)+index}}</td>
          <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{offer.name}} </td>
          <td  style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{offer.point}} </td>
          <td  style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{offer.price}} </td>
          <td  style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
            <div *ngIf="offer.type=='point'">نقاط سنتات</div>
            <div *ngIf="offer.type=='diamond'">مجوهرات</div>
          </td>
          <td style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
            <a (click)="open1(longContent1)"><i class="material-icons" style="color: #30287a;">edit</i></a> &nbsp;
            <a (click)="openSm2(content4)"><i class="material-icons" style="color: rgb(206, 6, 6);">delete</i></a>
          </td>
        </tr>
          <ng-template #longContent1 let-modal>
            <div class="modal-content">
              <div class="modal-body" id="mbody" dir="rtl">

                <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h5 id="h3"> تعديل العرض </h5>
                <form [formGroup]="registerForm1" enctype="multipart/form-data" >
                  <div class="row">
                    <div class="col-md-6">
                      <input maxlength="30" type="text" class="inp form-control" formControlName="name1" [(ngModel)]="offer.name">
                      <div *ngIf="submitted1 && g['name1'].errors" class="invalid-feedback">
                        <div class="error" *ngIf="g['name1'].errors['required']"> إسم العرض مطلوب</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <input type="text" maxlength="10" class="inp form-control"  formControlName="price1" onkeydown="javascript: return event.keyCode === 8 ||
                              event.keyCode === 46 ? true : !isNaN(Number(event.key))" [(ngModel)]="offer.price" (keypress)="keyPressNumbers($event)">
                      <div *ngIf="submitted1 && g['price1'].errors" class="invalid-feedback">
                        <div class="error" *ngIf="g['price1'].errors['required']"> الثمن مطلوب</div>
                      </div>
                    </div>
                  </div>
                  <br>
                  <div class="d-flex justify-content-center">
                    <button type="button" (click)="update(offer.id , offer.name, offer.price)" class="btn lab">&nbsp;
                      &nbsp; تعديل &nbsp; &nbsp;</button>
                  </div>
                </form>


              </div>
            </div>
          </ng-template>
          <ng-template #content4 let-modal>
            <div class="modal-body" dir="rtl">
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
              <h5 id="h3"> هل تريد حذف هذا العرض ؟</h5>
              <div class="d-flex justify-content-center">
                <button id="btnP" (click)="delete(offer.id)" type="button" class="btn btn-primary">&nbsp; &nbsp; تأكيد
                  &nbsp; &nbsp;</button>
              </div>
            </div>
          </ng-template>

        </tbody>
        <div style="margin-left: -196px;">
      
        </div>
      </table>
    </div>
  </div>

  <div class="container" *ngIf="user.role != 'globale' && user.counter == 0 " style="padding-top: 10% !important">
    <div class="container" style="width: 100%; height: 400px; max-height: 800px;background-size: contain;
      background-repeat: no-repeat;background-position: center center;display:block;margin-top:auto;
      margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
    </div>
  </div>

</body>
</div>










