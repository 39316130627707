import { Component, OnInit } from '@angular/core';
import { Actualite } from 'src/app/modules/actualite';
import { ActualiteService } from 'src/app/services/actualite.service';
import { first } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment.prod';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-actualite',
  templateUrl: './actualite.component.html',
  styleUrls: ['./actualite.component.css']
})
export class ActualiteComponent implements OnInit {
  actualite: Actualite[] = [];
  actu: string;
  actu1: string;
  date = new Date;
  registerForm: FormGroup;
  registerForm1: FormGroup;
  submitted = false;
  currentPage = 1;
  itemsPerPage = 10;
  pageSize: number;
  images: File;
  closeResult = '';
  url = environment.apiUrl;
  url1 = environment.urlStorage;
  link: string = "";
  link2: string = "";
  images2: File;
  user: any = {};
  p: number = 1;
  receivedData: any;
  constructor(private serviceactualite: ActualiteService,
    private serviceauth: AuthentificationService,
    private notifyService: NotificationService,
    private formBuilder: FormBuilder, private sharedService: SharedService,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;

    });
    this.receivedData = false;
    this.route.data.subscribe((data: { actualite: Actualite[] }) => this.actualite = data.actualite);
    this.user = this.serviceauth.getCurrentUser();
    this.getactualite();
    this.registerForm = this.formBuilder.group({
      actu: ['', Validators.required]
    });
    this.registerForm1 = this.formBuilder.group({
      actu1: ['', Validators.required]
    });
  }
  get f() { return this.registerForm.controls; }
  get h() { return this.registerForm1.controls; }

  getactualite() {
    this.serviceactualite.getAllactualite()
      .pipe(first()).subscribe(data => { this.actualite = data })
  }

  delete(id: string) {
    this.serviceactualite.deleteactualite(id).pipe(first()).subscribe(() => {
      this.getactualite();
      this.showToasterError();
      this.modalService.dismissAll();
    })
  }

  ajouter() {
    this.submitted = true;
    const formData = new FormData();
    formData.append('img', this.images2);
    formData.append('contenu', this.actu);
    this.serviceactualite.addactualite(formData).pipe(first()).subscribe(() => {

      this.showToasterSuccess();
      this.modalService.dismissAll();
      this.actu = 'undefined';
      this.images2 = null;
      this.link2 = "";
      this.submitted = false;
      setTimeout(() => {
        this.getactualite();
      }, 3000);
    })
  }

  modifierimage(id) {
    const formData = new FormData();
    formData.append('img', this.images);
    this.serviceactualite.updateimage(formData, id).pipe(first()).subscribe(() => {

      this.showToasterSuccess1();
      this.modalService.dismissAll();
      this.link = "",
        this.images = null;
      setTimeout(() => {
        this.getactualite();
      }, 3000);
    })
  }

  modifier(id: string, cont: string) {
    this.submitted = true;
    if (this.registerForm1.invalid) {
      return;
    }
    this.serviceactualite.updateactualite(cont, id).pipe(first()).subscribe(() => {

      this.actu1 = "";
      this.showToasterSuccess1();
      this.modalService.dismissAll();
      this.submitted = false;
      setTimeout(() => {
        this.getactualite();
      }, 3000);
    })
  }

  showToasterSuccess() {
    this.notifyService.showSuccess("تم اضافة الخبر ")
  }
  showToasterSuccess1() {
    this.notifyService.showSuccess("تم تعديل الخبر ")
  }
  showToasterError() {
    this.notifyService.showSuccess("تم محو الخبر ")
  }
  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }

  selectImage(event: any) {
    var reader = new FileReader();
    const file = event.target.files[0];
    this.images = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link = event.target.result;
    }
  }

  selectImage2(event: any) {
    var reader = new FileReader();
    const file = event.target.files[0];
    this.images2 = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link2 = event.target.result;
    }
  }

  open(content) {
    this.modalService.open(content, { centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.actu = '';
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.actu = '';
    });
  }
  open1(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.actu = '';
      this.link = "";
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.actu = '';
      this.link = "";
    });
  }

  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ferme() {
    this.link = "";
    this.link2 = "";
    this.images = null;
    this.images2 = null;
  }
}
