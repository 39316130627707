import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Recharge } from 'src/app/modules/recharge';
import { NotificationService } from 'src/app/services/notification.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { RechargeService } from 'src/app/services/recharge.service';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { ActualiteService } from 'src/app/services/actualite.service';
import { SharedService } from 'src/app/shared.service';
@Component({
  selector: 'app-addpost',
  templateUrl: './addpost.component.html',
  styleUrls: ['./addpost.component.css']
})
export class AddpostComponent implements OnInit {
  prix: string;
  name: string
  prix2: string;
  img: string;
  img2: string;
  submitted = false;
  submitted2 = false;
  images1: File;
  images2: File;
  registerForm: FormGroup;
  registerForm2: FormGroup;
  link: string = "";
  link2: string = "";
  closeResult = '';
  products: Recharge[] = [];
  currentPage = 1;
  itemsPerPage = 8;
  pageSize: number;
  p: number = 1;
  user: any = {};
  imagePreviews: string[] = [];
  imagePreviews1: string[] = [];
  currentIndex = 0;
  texte: string;
  texte2: string;
  video: string;
  receivedData: any;
  constructor(private serviceactualite: ActualiteService, private servicerecharge: RechargeService,
    private formBuilder: FormBuilder,
    private notifyService: NotificationService,
    private modalService: NgbModal, private sharedService: SharedService,
    private route: ActivatedRoute, private serviceauth: AuthentificationService
  ) { }

  ngOnInit(): void {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;

    });
    this.receivedData = false;
    const atLeastOneRequired: ValidatorFn = (group: FormGroup): { [key: string]: boolean } | null => {
      const texte = group.get('texte').value;
      const img = group.get('img').value;
      if (!texte && !img) {
        return { 'atLeastOneRequired': true };
      }
      return null;
    };
    this.registerForm = this.formBuilder.group({
      texte: [''],
      img: [''],
      images: this.formBuilder.array([])
    }, { validator: atLeastOneRequired });
    this.user = this.serviceauth.getCurrentUser();
    this.registerForm2 = this.formBuilder.group({
      texte2: [''],
      video: ['', Validators.required],
    });
  }

  get f() { return this.registerForm.controls; }
  get g() { return this.registerForm2.controls; }

  getProducts() {
    this.servicerecharge.getProducts()
      .pipe(first()).subscribe(data => {
        this.products = data
      })
  }


  get imageArray() {
    return this.registerForm.get('images') as FormArray;
  }
  onFileSelected(event: any, index: number) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.imageArray.setControl(index, this.formBuilder.control(files[i]));
      this.previewImage(files[i], index);
    }
  }

  addImage() {
    const control = this.formBuilder.control(null);
    this.imageArray.push(control);
    this.imagePreviews.push('');
  }

  previewImage(files: File, index: number) {
    if (files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreviews[index] = e.target.result;
      };
      reader.readAsDataURL(files);
    }
  }

  previousImage() {
    if (this.currentIndex > 0) {
      this.currentIndex -= 3;
    }
  }

  nextImage() {
    if (this.currentIndex < (this.imageArray.controls.length - 3)) {
      this.currentIndex += 3;
    }
  }

  ajouter() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    const formData = new FormData();
    if (this.images1 != undefined && this.texte != undefined && this.texte != "") {
      formData.append('oerpzutrfrb', "imageText");
    }
    if (this.images1 != undefined && (this.texte == undefined || this.texte == "")) {
      formData.append('oerpzutrfrb', "image");
    }
    if (this.images1 == undefined && this.texte != undefined && this.texte != "") {
      formData.append('oerpzutrfrb', "text");
    }
    formData.append('ajkasizhnkjd[]', this.images1);
    formData.append('qpoazizejid', this.texte);
    for (let i = 0; i < this.imageArray.length; i++) {
      formData.append('ajkasizhnkjd[]', this.imageArray.at(i).value);
    }
    this.serviceactualite.addPost(formData)
      .pipe(first()).subscribe(() => {
        this.texte = undefined;
        this.link = "";
        this.img = "";
        this.imagePreviews = [];
        this.showToasterSuccess();
        setTimeout(() => {
          this.getProducts();
        }, 5000)
        this.images1 = undefined;
        this.submitted = false;
        this.imageArray.controls = []
      })
  }

  ajouterVideo() {
    this.submitted2 = true;
    if (this.registerForm2.invalid) {
      return;
    }
    const formData = new FormData();
    if (this.images2 != undefined && this.texte2 != undefined && this.texte2 != "") {
      formData.append('oerpzutrfrb', "videoText");
    }
    if (this.images2 != undefined && (this.texte2 == undefined || this.texte2 == "")) {
      formData.append('oerpzutrfrb', "video");
    }
    formData.append('ajkasizhnkjd', this.images2);
    formData.append('qpoazizejid', this.texte2);
    this.serviceactualite.addVideo(formData)
      .pipe(first()).subscribe(() => {
        this.link2 = "";
        this.video = "";
        this.texte2 = undefined;
        this.showToasterSuccess();
        setTimeout(() => {
          this.getProducts();
        }, 5000)
        this.images2 = undefined;
        this.submitted2 = false;
      })
  }


  showToasterSuccess() {
    this.notifyService.showSuccess(" تم اضافة منشور")
  }


  selectImage(event: any) {
    var reader = new FileReader();
    const file = event.target.files[0];
    this.images1 = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link = event.target.result
    }
  }

  selectImage2(event: any) {
    var reader = new FileReader();
    const file = event.target.files[0];
    this.images2 = file;
    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      this.link2 = event.target.result
    }
  }


  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  open(content) {
    this.prix2 = "";
    this.link2 = "";
    this.modalService.open(content, { windowClass: "myCustomModalClass", centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }

}
