import { Injectable } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';
import { Theme } from 'src/app/modules/theme';

import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeResolver implements Resolve<Theme[]> {
  constructor(private themes: ThemeService) {}
  resolve(): Observable<Theme[]> {
    return this.themes.getTheme();
  }
}
