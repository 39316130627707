<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '82%' : 'auto'}">
    <div class="container">
        <br> <br>
        <div class="card">
            <div class="card-body">

                <div class="row">
                    <ul>
                        <li>{{ user.prenom}}<b>:الإسم </b></li>
                        <li>{{ user.nom}}<b>: اللقب</b></li>
                        <li>{{ user.email}}<b>:البريد الإلكتروني </b></li>
                        <li>{{ user.telephone}}<b>:رقم الهاتف</b></li>
                        <li *ngIf="user.role == 'globale'">{{ user.RIB}}<b>:رقم حساب جاري</b></li>
                        <li><a (click)="open(longContent)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-pencil-square" viewBox="0 0 16 16">
                                    <path
                                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path fill-rule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                </svg> </a> *********<b>:كلمة المرور</b></li>
                    </ul>
                </div>


                <div class="d-flex justify-content-center" *ngIf="collapse2 == false">
                    <button (click)="collapse2 =! collapse2" type="button" class="btn lab lab2">تعديل</button>
                </div>


                <div *ngIf="collapse2" id="div" dir="rtl">
                    <div class="row">





                        <div class="col-md-6">

                            <div class="row row1">الإسم </div>
                            <div class="row" id="inp" [formGroup]="registerForm">
                                <input type="text" formControlName="prenom" name="prenom" value="" class="form-control"
                                    placeholder="" [(ngModel)]="prenom">
                                <div *ngIf="submitted && f['prenom'].errors" class="invalid-feedback">
                                    <div *ngIf="f['prenom'].errors['required']">الإسم مطلوب</div>
                                </div>
                            </div>

                        </div>



                        <div class="col-md-6">

                            <div class="row row1">اللقب </div>
                            <div class="row" id="inp" [formGroup]="registerForm">
                                <input type="text" formControlName="nom" name="nom" value="" class="form-control"
                                    placeholder="" [(ngModel)]="nom">
                                <div *ngIf="submitted && f['nom'].errors" class="invalid-feedback">
                                    <div *ngIf="f['nom'].errors['required']">اللقب مطلوب</div>
                                </div>
                            </div>

                        </div>

                    </div>



                    <div class="row">
                        <div class="col-md-6">

                            <div class=" row row1">رقم الهاتف </div>
                            <div class="row" id="inp" [formGroup]="registerForm">
                                <input type="number" formControlName="telephone" name="telephone" value=""
                                    onKeyPress="if(this.value.length==13) return false;" onkeydown="javascript: return event.keyCode === 8 ||
                        event.keyCode === 46 ? true : !isNaN(Number(event.key))" class="form-control" placeholder=""
                                    [(ngModel)]="telephone">
                                <div *ngIf="submitted && f['telephone'].errors" class="invalid-feedback">
                                    <div *ngIf="f['telephone'].errors['required']">رقم الهاتف مطلوب</div>
                                </div>
                            </div>

                        </div>



                        <div class="col-md-6">

                            <div class="row row1"> البريد الإلكتروني </div>
                            <div class="row" id="inp" [formGroup]="registerForm">
                                <input type="text" formControlName="email" name="email" value="" class="form-control"
                                    placeholder="" [(ngModel)]="email">
                                <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                                    <div *ngIf="f['email'].errors['required']">البريد الإلكتروني مطلوب</div>
                                    <div *ngIf="f['email'].errors['email']">يجب أن يكون البريد الإلكتروني صالحًا</div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <!-- <div class="row" *ngIf="user.role == 'globale'">
                <div class="form-group">
                    <label class=" control-label">رقم حساب جاري </label>
                    <div class="col-md-4" id="inp" [formGroup]="registerForm">
                        <input id="inp2" formControlName="RIB" type="text" name="RIB" value="" class="form-control" [(ngModel)]="RIB">
                    </div>
                </div>
            </div> -->
                    <div class="row">
                        <div class="col-md-6">
                            <div class=" row row1"> كلمة المرور الحالية</div>
                            <div class="row" id="inp" [formGroup]="registerForm">
                                <input id="inp2" formControlName="passwordac" type="password" name="passwordac" value=""
                                    class="form-control" [(ngModel)]="passwordac">
                                <div *ngIf="submitted && f['passwordac'].errors" class="invalid-feedback">
                                    <div *ngIf="f['passwordac'].errors['required']">كلمة المرور الحالية مطلوبة</div>
                                </div>
                                <div *ngIf="validpasssword == false" class="invalid-feedback">كلمة السر غير مطابقة</div>

                            </div>

                        </div>
                    </div>


                    <div class="d-flex justify-content-center">
                        <button (click)="update();" type="button" class="btn lab lab2">تأكيد</button>
                    </div>




                </div>
            </div>
        </div>

    </div>


    <ng-template #longContent let-modal>
        <div class="modal-content">
            <div class="modal-body" id="mbody" dir="rtl">

                <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click'); reset()">
                    <span aria-hidden="true">&times;</span>
                </button>

                <h5 id="h3">تغيير كلمة المرور</h5>


                <form [formGroup]="registerForm">
                    <div class="row" id="inp" [formGroup]="registerForm1">
                        <input id="inp2" placeholder="كلمة المرور الحالية" formControlName="passwordac1" type="password"
                            name="passwordac" value="" class="form-control" [(ngModel)]="passwordac1">
                        <div *ngIf="submitted1 && f1['passwordac1'].errors" class="invalid-feedback">
                            <div *ngIf="f1['passwordac1'].errors['required']">كلمة المرور الحالية مطلوبة</div>
                        </div>
                        <div *ngIf="validpasssword1 == false" class="invalid-feedback">كلمة السر غير مطابقة</div>

                    </div>

                    <div class="row" id="inp" [formGroup]="registerForm1">
                        <input id="inp2" placeholder="كلمة المرور الجديدة" formControlName="password" type="password"
                            name="password" value="" class="form-control" [(ngModel)]="password">
                        <div *ngIf="submitted1 && f1['password'].errors" class="invalid-feedback">
                            <div *ngIf="f1['password'].errors['required']">كلمة المرور مطلوبة</div>
                        </div>
                    </div>

                    <div class="row" id="inp" [formGroup]="registerForm1">
                        <input id="inp2" placeholder="تأكيد كلمة المرور" formControlName="repassword" type="password"
                            name="repassword" value="" class="form-control" [(ngModel)]="repassword">
                        <div *ngIf="submitted1 && f1['repassword'].errors" class="invalid-feedback">
                            <div *ngIf="f1['repassword'].errors['required']">تأكيد كلمة المرور مطلوب</div>
                            <div *ngIf="f1['repassword'].errors['mustMatch']">كلمة السر غير مطابقة</div>
                        </div>

                    </div>




                    <div class="row" id="inp">
                        <div class="col-md-4">
                        </div>

                        <div class="col-md-4">
                            <button (click)="updatepassword();" type="button" class="form-control lab">تسجيل</button>
                        </div>
                        <div class="col-md-4"></div>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>
</body>