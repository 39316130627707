import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cadeau } from '../modules/cadeau';
import { Observable } from 'rxjs';
import { GiftActualite } from '../modules/giftactualite';
@Injectable({
  providedIn: 'root'
})
export class CadeauService {
  uri = environment.apiUrl;
  uriGift = environment.urlChatroom;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  constructor(private http: HttpClient) { }

  getCadeaux(): Observable<Cadeau[]> {
    const url_get = this.uriGift + 'cadeaux';
    return this.http.get<Cadeau[]>(url_get, { headers: this.headers });
  }

  getGiftActualite(): Observable<GiftActualite[]> {
    const url_get = this.uri + 'giftactualites';
    return this.http.get<GiftActualite[]>(url_get, { headers: this.headers });
  }

  addCadeau(data: FormData) {
    this
      .http
      .post(`${this.uriGift}cadeaux`, data, { headers: this.headers })
      .subscribe();
    const url_get = this.uriGift + 'cadeaux';
    return this.http.get<Cadeau[]>(url_get, { headers: this.headers });
  }

  addGiftActualite(data: FormData) {
    this
      .http
      .post(`${this.uri}giftactualites`, data, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'giftactualites';
    return this.http.get<GiftActualite[]>(url_get, { headers: this.headers });
  }

  updateCadeau(id: string, prix: number) {
    const obj = {
      prix: prix,

    };
    this
      .http
      .put(`${this.uriGift}cadeaux/${id}`, obj, { headers: this.headers })
      .subscribe();
    const url_get = this.uriGift + 'cadeaux';
    return this.http.get<Cadeau[]>(url_get, { headers: this.headers });
  }

  updateGiftActualite(id: string, price: number) {
    const obj = {
      price: price,

    };
    this
      .http
      .put(`${this.uri}giftactualites/${id}`, obj, { headers: this.headers })
      .subscribe();
    const url_get = this.uri + 'giftactualites';
    return this.http.get<GiftActualite[]>(url_get, { headers: this.headers });
  }


  deleteacadeau(id: string) {
    return this.http.delete(`${this.uriGift}cadeaux/` + id, { headers: this.headers });
  }

  deleteGiftActualite(id: string) {
    return this.http.delete(`${this.uri}giftactualites/` + id, { headers: this.headers });
  }

}
