import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { Question } from '../modules/question';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  uri = environment.urlGame;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  constructor(private http: HttpClient) { }
  getAll(): Observable<Question[]> {
    const obj = {};
    const url_get = this.uri + 'questions';
    return this.http.post<Question[]>(url_get, obj, { headers: this.headers });
  }
  addchoix(type, text, reponseChoix, choixA, choixB, choixC, choixD, niveau) {
    const obj = {
      type: type,
      text: text,
      reponseChoix: reponseChoix,
      choixA: choixA,
      choixB: choixB,
      choixC: choixC,
      choixD: choixD,
      niveau: niveau
    };
    return this.http.post(`${this.uri}addquestion`, obj, { headers: this.headers });
  }
  addVF(type, text, reponseVF, niveau) {
    const obj = {
      type: type,
      text: text,
      reponseVF: reponseVF,
      niveau: niveau
    };
    return this.http.post(`${this.uri}addquestion`, obj, { headers: this.headers });
  }

  updatechoix(text, reponseChoix, choixA, choixB, choixC, choixD, type, categorie, niveau, id) {
    const obj = {
      type: type,
      text: text,
      reponseChoix: reponseChoix,
      choixA: choixA,
      choixB: choixB,
      choixC: choixC,
      choixD: choixD,
      categorie: categorie,
      niveau: niveau
    };
    return this.http.put(`${this.uri}updateChoix/${id}`, obj, { headers: this.headers });
  }
  updateVF(text, reponseVF, type, categorie, niveau, id) {
    const obj = {
      type: type,
      text: text,
      reponseVF: reponseVF,
      categorie: categorie,
      niveau: niveau
    };
    return this.http.put(`${this.uri}updateVF/${id}`, obj, { headers: this.headers });
  }


  delete(id: string) {
    return this.http.delete(`${this.uri}deletequestion/` + id, { headers: this.headers });
  }
  getbyid(id: string) {
    return this
      .http
      .get(`${this.uri}questionss/${id}`, { headers: this.headers });
  }

  getFortune() {
    return this.http.get(`${this.uri}FDSCX4S58DQQZDXZEDFG`, { headers: this.headers });
  }

  updateFortune(obj) {
    return this.http.post(`${this.uri}TGF4RF52458RH7TADEZ`,obj, { headers: this.headers });
  }
}
