import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

import { Admin } from '../modules/admin';
import { JwtTokenModule } from '../modules/jwt-token-module';

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {
  uri = environment.apiUrl;
  headers: HttpHeaders | { [header: string]: string | string[]; };
  currentUser: any;
  res: any;
  token: string;
  public jwtToken: BehaviorSubject<JwtTokenModule> = new BehaviorSubject({
    isAuthenticated: null,
    token: null
  });
  subscription: Subscription;
  constructor(private http: HttpClient, private router: Router) {
    this.initToken();
    //this.subscription = this.initTimer();
    //this.router.navigate(['login']);
  }
  private initToken(): void {
    const token = localStorage.getItem('jwt');
    if (token) {
      this.jwtToken.next({
        isAuthenticated: true,
        token: token
      });
    } else {
      this.jwtToken.next({
        isAuthenticated: false,
        token: null
      });
    }
  }
  public signin(email: string, password: string): Observable<string> {
    return this.http.post<string>(this.uri + 'loginadmin',
      { email, password },
      { headers: this.headers }).pipe(
        tap((token: string) => {
          this.jwtToken.next({
            isAuthenticated: true,
            token: token
          });
          localStorage.setItem('jwt', token);
        })
      );
  }
  setUser(user: Admin, token: any): void {
    let user_string = JSON.stringify(user);
    let token_user = JSON.stringify(token);
    localStorage.setItem("currentUser", user_string);
    localStorage.setItem("token", token_user);
  }
  getCurrentUser(): Admin {

    let user_string = localStorage.getItem("currentUser");

    let user: Admin;

    user = JSON.parse(user_string);


    return user;

  }
  logout() {
    this.jwtToken.next({
      isAuthenticated: false,
      token: null
    });
    localStorage.removeItem('jwt');
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
  }

  requestReset(body): Observable<any> {
    return this.http.post(this.uri + `admin/req-reset-password`, body);
  }

  newPassword(password: string, id: string) {
    const obj = {
      password: password
    };
    return this
      .http
      .put(this.uri + `updatepassword/${id}`, obj).pipe(
        tap((token: string) => {
          this.jwtToken.next({
            isAuthenticated: true,
            token: token
          });
          localStorage.setItem('jwt', token);
        })
      );

  }
  isSignedIn() {
    return !!localStorage.getItem('jwt')
  }
}
