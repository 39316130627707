import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { NotificationService } from 'src/app/services/notification.service';
import { QuestionService } from 'src/app/services/question.service';
import { SharedService } from 'src/app/shared.service';

interface Interval {
  min: number;
  max: number;
  percentage: number;
}

@Component({
  selector: 'app-fortune',
  templateUrl: './fortune.component.html',
  styleUrls: ['./fortune.component.css']
})
export class FortuneComponent implements OnInit {
  user: any = {};
  fortune: any = {};
  intervals: Interval[];
  registerForm: FormGroup;
  receivedData: any;
  sum: number = 0;
  constructor(private serviceauth: AuthentificationService, private servicequestion: QuestionService, 
    private notifyService: NotificationService,private sharedService: SharedService) { }

  ngOnInit(): void {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
    });
    this.receivedData=false;
    this.user = this.serviceauth.getCurrentUser();
    this.servicequestion.getFortune().subscribe(data => {
      this.fortune = data
      this.intervals =  [
        { min: parseFloat(this.fortune.range_one_min), max: parseFloat(this.fortune.range_one_max), percentage: parseFloat(this.fortune.rate_one)},
        { min: parseFloat(this.fortune.range_two_min), max: parseFloat(this.fortune.range_two_max), percentage: parseFloat(this.fortune.rate_two )},
        { min: parseFloat(this.fortune.range_three_min), max: parseFloat(this.fortune.range_three_max), percentage: parseFloat(this.fortune.rate_three )},
        { min: parseFloat(this.fortune.range_for_min), max: parseFloat(this.fortune.range_for_max), percentage: parseFloat(this.fortune.rate_for) },
        { min: parseFloat(this.fortune.range_five_min), max: parseFloat(this.fortune.range_five_max), percentage: parseFloat(this.fortune.rate_five )},
        { min: parseFloat(this.fortune.range_six_min), max: parseFloat(this.fortune.range_six_max), percentage: parseFloat(this.fortune.rate_six) },
        { min: parseFloat(this.fortune.range_seven_min), max: parseFloat(this.fortune.range_seven_max), percentage: parseFloat(this.fortune.rate_seven )},
      ];
      this.calculateSum();
    });


  }

  
  calculateSum() {
    const sum = this.intervals.reduce((acc, curr) => acc + Number(curr.percentage), 0);
    this.sum = parseFloat(sum.toFixed(2)); 
  }

  submitForm() {
    const invalidIntervals = this.intervals.filter(interval =>  interval.min >= interval.max || interval.min>1000000|| interval.max>1000000
      || interval.min<0 || interval.max<0 || interval.percentage<0.0001);
    if (invalidIntervals.length > 0) {
      this.showToasterError1();
      return;
    }
    const totalPercentage = parseFloat( (this.intervals.reduce((acc, interval) => acc + interval.percentage, 0).toFixed(2)));
    if (totalPercentage !== 100) {
      this.showToasterError();
      return;
    }

  

    const obj ={
      range_one_min : this.intervals[0].min,
      range_one_max : this.intervals[0].max,
      rate_one : this.intervals[0].percentage,
      range_two_min : this.intervals[1].min,
      range_two_max : this.intervals[1].max,
      rate_two : this.intervals[1].percentage,
      range_three_min : this.intervals[2].min,
      range_three_max : this.intervals[2].max,
      rate_three : this.intervals[2].percentage,
      range_for_min : this.intervals[3].min,
      range_for_max : this.intervals[3].max,
      rate_for : this.intervals[3].percentage,
      range_five_min : this.intervals[4].min,
      range_five_max : this.intervals[4].max,
      rate_five : this.intervals[4].percentage,
      range_six_min : this.intervals[5].min,
      range_six_max : this.intervals[5].max,
      rate_six : this.intervals[5].percentage,
      range_seven_min : this.intervals[6].min,
      range_seven_max : this.intervals[6].max,
      rate_seven : this.intervals[6].percentage,
    }
    this.servicequestion.updateFortune(obj).subscribe(data => {
      this.showToasterSuccess()
    });
  }

  showToasterSuccess() {
    this.notifyService.showSuccess("تم تعديل عجلة الحظ")
  }

  showToasterError() {
    this.notifyService.showError("الرجاء التثبت من مجموع النسبة")
  }

  showToasterError1() {
    this.notifyService.showError("الرجاء التثبت من صحة المعلومات")
  }
}
