<meta name="viewport" content="width=device-width, initial-scale=1.0">
<app-menu></app-menu>

<body [ngStyle]="{'width': receivedData ? '82%' : 'auto'}">
    <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.codeparrainage == 1">
        <br> <br>

        <div class="card">
            <div class="card-body" >


                <h2 style="text-align: right;"> قائمة طلبات رموز الإحالة</h2>
            

                <div class="row" style="overflow-x: auto;direction: rtl;margin-top: 14px;">
                    <div class="dataTables_wrapper no-footer" id="dtable_wrapper" style="margin-top: 20px;">

                        <table id="dtable" datatable [dtOptions]="dtOptions" class="row-border hover">
                            <thead>
                                <tr>
                                    <th style="
                text-align: right;border-bottom-right-radius: 10px; border-top-right-radius:10px">#</th>
                                    <th style="text-align: right;">التاريخ</th>
                                    <th style="text-align: right;">المستخدم</th>
                                    <th style="text-align: right;">رمز الإحالة</th>
                                    <th style="text-align: right;">العداد</th>
                                    <th style="text-align: right;"> الرصيد</th>
                                    <th style="text-align:right"> رمز الإحالة المطلوب </th>
                                    <th
                                        style="text-align:left;border-bottom-left-radius: 10px; border-top-left-radius:10px;text-align:center">
                                        الإجابة على الطلب</th>
                                </tr>
                            </thead>
                            <tbody
                                *ngFor="let dem of demandesRechage| slice: pageSize | slice: 0:itemsPerPage; let index = index;">
                                <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;">
                                    <td style="border-left: none; border-top:none ;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"
                                        id="tdTlf"> {{((currentPage * 10) -9)+index}}</td>
                                    <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"
                                        id="tdTlf">{{ dem.created_at | date: 'yyyy-MM-dd H:mm' }}</td>

                                    <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"
                                        id="tdTlf">
                                        <div class="d-flex">{{ dem.name }}
                                            <img *ngIf="dem.badge" height="30" width="30" [src]="dem.badge" alt="">
                                        </div>
                                    </td>


                                    <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"
                                        id="tdTlf">{{ dem.codeparrainage }}</td>
                                    <td style=" border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> <a
                                            class="action" (click)="openSm3(contentOffer); getOfferUser(dem.user_id);getOfferUser2(dem.user_id)">
                                            <i class="material-icons" style="color: #30287a;">av_timer</i>
                                        </a>
                                    </td>

                                    <td id="tdTlf"
                                        style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{
                                        dem.montant }}</td>

                                    <td style="  border: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">

                                        {{dem.codeparrainageSpecial}}

                                    </td>
                                    <td
                                        style="text-align: center;  border-right: none; border-top:none ; border-bottom: 1px solid rgba(24, 156, 189, 0.596);">
                                        <span class="d-flex">
                                            <button *ngIf="dem.state == 'inOrder' " (click)="open(contentAccept)"
                                                id="submitB">
                                                <i class="material-icons"
                                                    style="color: green; margin-right:123px">check_circle</i>
                                            </button>

                                            <button *ngIf="dem.state == 'inOrder' " (click)="open(content)"
                                                id="submitB">
                                                <i class="material-icons" style="color: rgb(206, 6, 6);">cancel</i>
                                            </button>

                                        </span>

                                        <a *ngIf="dem.state == 'accepte' " style="color: green; cursor: default;">
                                            تمت عملية الشحن
                                        </a>

                                        <a *ngIf="dem.state == 'refuse' " style="color: red; cursor: default;">
                                            تم رفض طلب الشحن

                                        </a>
                                    </td>

                                    <ng-template #content let-modal>
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <button type="button" class="btn-close" aria-label="Close"
                                                    (click)="modal.dismiss('Cross click')"></button>
                                            </div>
                                            <div class="modal-body" dir="rtl">


                                                <h4 id="h3"> سيتم رفض طلب الشحن </h4>
                                                <br>
                                                <div class="justify-content-center d-flex">
                                                    <button
                                                        (click)="updatedemanderefuse2(dem.id); submitButton2.disabled = true"
                                                        #submitButton2 type="button" class="lab1 btn">رفض الطلب</button>
                                                </div>
                                                <br>
                                                <form [formGroup]="registerForm">
                                                    <div class="flex">
                                                        <button [disabled]="registerForm.invalid"
                                                            (click)="updatedemanderefuse(dem.id, reponse); submitButton1.disabled = true"
                                                            #submitButton1 type="button" class="lab btn"> <i
                                                                style="color: #fff;"
                                                                class="material-icons glyphicon">send</i>
                                                            &nbsp;</button>

                                                        <textarea placeholder="السبب" pattern=".*\S+.*" maxlength="250"
                                                            style="max-height: 100px !important;" name="reponse"
                                                            formControlName="reponse" id="" [(ngModel)]="reponse"
                                                            cols="30" rows="1" class="form-control"></textarea>

                                                    </div>
                                                    <div *ngIf="submitted && f['reponse'].errors"
                                                        class="invalid-feedback">
                                                        <div class="err" *ngIf="f['reponse'].errors['required']">يجب
                                                            ادخال السبب </div>
                                                    </div>
                                                    <br>
                                                </form>

                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template #contentOffer let-modal>
                                        <div class="modal-content" dir="rtl">
                                            <button type="button" class="form-control close" aria-label="Close"
                                                (click)="modal.dismiss('Cross click')">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            <div class="modal-body">
                                    
                                                <div class="table-responsive">
                                                  
                                                    <table class="table row-border hover">
                                                        <tbody>
                                                        
                                                          <th style="background-color: rgba(98, 180, 218, 0.479);text-align-last: center"> مجموع المجوهرات </th>
                                                          <th style="background-color: rgba(98, 180, 218, 0.479); border: 1px;text-align-last: center"> مجموع النقاط </th>
                                                        <tr>
                                                          <td style="text-align-last: center;">{{offers2.diamondAmount}} </td>
                                                                   
                                                          <td style="text-align-last: center;">{{offers2.pointAmount}}</td>
                                                        </tr>
                                                        </tbody>
                                                      </table>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                    
                                    <ng-template #contentAccept let-modal>
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <button type="button" class="btn-close" aria-label="Close"
                                                    (click)="modal.dismiss('Cross click')"></button>
                                            </div>
                                            <div class="modal-body" dir="rtl">


                                                <h4 id="h3"> تم الموافقة على طلب الشحن </h4>
                                                <br>
                                                <div class="justify-content-center d-flex">
                                                    <button
                                                        (click)="updatedemande(dem.id); submitButton3.disabled = true"
                                                        #submitButton3 type="button" class="lab1 btn">الموافقة على
                                                        الطلب</button>
                                                </div>
                                                <br>
                                                <form [formGroup]="registerForm2">
                                                    <div class="flex">
                                                        <button [disabled]="registerForm2.invalid"
                                                            (click)="updatedemande2(dem.id , reponseAccept); submitButton4.disabled = true"
                                                            #submitButton4 type="button" class="lab btn"> <i
                                                                style="color: #fff;"
                                                                class="material-icons glyphicon">send</i>
                                                            &nbsp;</button>

                                                        <textarea placeholder="السبب" pattern=".*\S+.*" maxlength="250"
                                                            style="max-height: 100px !important;" name="reponseAccept"
                                                            formControlName="reponseAccept" id=""
                                                            [(ngModel)]="reponseAccept" cols="30" rows="1"
                                                            class="form-control"></textarea>

                                                    </div>
                                                    <div *ngIf="submitted && g['reponseAccept'].errors"
                                                        class="invalid-feedback">
                                                        <div class="err" *ngIf="g['reponseAccept'].errors['required']">
                                                            يجب ادخال السبب </div>
                                                    </div>
                                                    <br>
                                                </form>

                                            </div>
                                        </div>
                                    </ng-template>

                                </tr>

                            </tbody>
                            <tr *ngIf="demandesRechage?.length == 0 ">
                                <td colspan="6" class="no-data-available" style="background-color: transparent;">لا توجد
                                    بيانات متوفرة</td>
                            </tr>

                        </table>

                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="container" *ngIf="user.role != 'globale' && user.codeparrainage == 0 " style="padding-top: 10% !important">

        <div class="container" style="
      width: 100%;
      height: 400px;
      max-height: 800px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      display:block;
      margin-top:auto;
      margin-bottom:auto;
      max-height: 800px;

      background-image: url('assets/droit.png')">



        </div>
    </div>

</body>