<meta name="viewport" content="width=device-width, initial-scale=1.0">

<app-menu></app-menu>

<body  [ngStyle]="{'width': receivedData ? '83%' : 'auto'}">
  <div class="container" dir="rtl" *ngIf="user.role == 'globale' || user.actualite == 1">
    <br><br>
    <div class="card">
      <div class="card-body">
   <br>

        <div class="row">
            <div class="col-md-2" style="margin-right: 45px;">
                <h2 id="h4" class="pt-4" style="margin-top: 9px;"> عدد الإعجابات </h2>
            </div>
            <div class="col-md-4">
                <div class="cardimg1 card-progress " style="height: 76px;">
                  <form [formGroup]="registerForm" enctype="multipart/form-data">
                    <div class="d-flex justify-content-center align-vertical">
                      <input type="number" oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" min="1" (keypress)="keyPressNumbers($event)"
                             class="inpu form-control" formControlName="nbrLike" name="nbrLike" [(ngModel)]="nbrLike">
                      <button (click)="updateNbrLike()" class="btn lab1">تسجيل</button>
                    </div>
                    <div *ngIf="submitted && f['nbrLike'].errors" class="invalid-feedback ">
                      <div style="margin-right:35px;" *ngIf="f['nbrLike'].errors['required'] || nbrLike == 0"> يجب إدخال عدد الإعجابات</div>
                    </div>
                  </form>
                  
                </div>
            </div>
            <div class="col-md-2">
              <img src="../assets/nbrlike.png" alt="" style="

              height: 90px;
              width: 90px;
          ">
          </div>
           
        </div>
<br>

        <h2 id="h4" class="titre">قائمة طلبات الترند </h2>

        <div class="row" style="overflow-x:auto;direction: rtl;" id="part2">
          <table id="dtable" datatable [dtOptions]="dtOptions" class="row-border hover" style="padding-top: 22px;">
            <thead>
              <tr>
                <th style="border-bottom-right-radius: 10px; border-top-right-radius:10px">#</th>
                <th>التاريخ</th>
                <th style="width: 191px;">المستخدم</th>
                <th style="width: 178px;">رمز الإحالة</th>
                <th style="width: 111px;">عدد الإعجابات</th>
                <th >عدد التعليقات</th>
                <th style="border-bottom-left-radius: 10px; border-top-left-radius:10px"></th>
              </tr>
            </thead>
            <tbody *ngFor="let trend of trenddemands ; let index = index">
              <tr style="border-bottom: 1px solid rgba(24, 156, 189, 0.596) ;">
                <td style="  border-left: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"> {{((currentPage * 10) -9)+index}}</td>
                <td  style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ trend.created_at | date: 'yyyy-MM-dd H:mm' }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" >{{ trend.name }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);">{{ trend.codeparrainage }}</td>

                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" >{{ trend.reactCount }}</td>
                <td style="  border:none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);" >{{ trend.commentCount }}</td>
                <td style="  border-right: none;border-bottom: 1px solid rgba(24, 156, 189, 0.596);"><span class="d-flex">
                    <button id="submitB" [routerLink]="['/publication-detail/', trend._id]" >
                        <i class="material-icons" style="color: #30287a;">info</i>
                    </button>

                    <button (click)="open(content)" #refuseButton id="submitB">
                      <i class="material-icons" style="color: rgb(206, 6, 6);">cancel</i>
                    </button> &nbsp; &nbsp;
                    <button (click)="accepttrend(trend._id) ;submitButton.disabled = true" #submitButton id="submitB">
                      <i class="material-icons" style="color: green;">check_circle</i>
                    </button> &nbsp; &nbsp;

                    <button (click)="openSm(content4)" #refuseButton id="submitB">
                        <i class="material-icons" style="color: red;">delete</i>
                      </button>
                  </span>
                </td>

                <ng-template #content4 let-modal>
                    <div class="modal-body" dir="rtl">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <br>
                        <h5 id="h3"> هل تريد حذف  هذا المنشور ؟</h5>
                        <div class="d-flex justify-content-center">
                            <button id="btnP" (click)="delete(trend._id)" type="button" class="btn btn-primary">&nbsp;
                                &nbsp; تأكيد &nbsp; &nbsp;</button>
                        </div>
                    </div>
                </ng-template>


                <ng-template #content let-modal>
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                    </div>
                    <div class="modal-body" dir="rtl">
                      <h4 id="h3"> سيتم رفض طلب الترند </h4><br>
                      <div class="justify-content-center d-flex">
                        <button (click)="refusetrend(trend._id); submitButton2.disabled = true" #submitButton2
                          type="button" class="lab btn">رفض الطلب</button>
                      </div><br>
                    </div>
                  </div>
                </ng-template>

              </tr>

            </tbody>
            <tr *ngIf="trenddemands?.length == 0 ">
              <td colspan="7" class="no-data-available">لا توجد بيانات متوفرة</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="user.role != 'globale' && user.actualite == 0 " style="padding-top: 10% !important">
    <div class="container" style="width: 100%; height: 400px; max-height: 800px;background-size: contain;
      background-repeat: no-repeat;background-position: center center;display:block;margin-top:auto;
      margin-bottom:auto;max-height: 800px;background-image: url('assets/droit.png')">
    </div>
  </div>
</body>


