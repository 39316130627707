import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { first } from 'rxjs/operators';
import { ActualiteService } from 'src/app/services/actualite.service';
import { Lightbox } from 'ngx-lightbox';
import { IAlbum } from 'ngx-lightbox';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-postslist',
  templateUrl: './postslist.component.html',
  styleUrls: ['./postslist.component.css']
})
export class PostslistComponent implements OnInit {
  posts: any[];
  currentPage = 1;
  pageSize = 10;
  totalItems: number;
  user: any = {};
  post: any = {};
  showAll: boolean = false;
  images: [] = [];
  visibleCommentsCount = 4;
  closeResult = '';
  newComment: string;
  updateComment: string;
  updateReply: string;
  registerForm: FormGroup;
  submitted = false;
  registerForm2: FormGroup;
  submitted2 = false;
  replyUserName: string = '';
  registerForm3: FormGroup;
  submitted3 = false;
  registerForm4: FormGroup;
  submitted4 = false;
  registerForm5: FormGroup;
  submitted5 = false;
  replyUserName1: string = '';
  showReplies: { [commentId: string]: boolean } = {};
  showCommentReplyInput: { [commentId: string]: boolean } = {};
  showReplyInput: { [replyId: string]: boolean } = {};

  constructor(private modalService: NgbModal, private formBuilder: FormBuilder,
    private lightbox: Lightbox, private serviceactualite: ActualiteService, private route: ActivatedRoute,
    private notifyService: NotificationService, private serviceauth: AuthentificationService) { }

  ngOnInit(): void {
    this.user = this.serviceauth.getCurrentUser();
    this.loadPosts();
    this.registerForm = this.formBuilder.group({
      newComment: ['', Validators.required]
    });
    this.registerForm2 = this.formBuilder.group({
      replyUserName: ['', Validators.required]
    });
    this.registerForm3 = this.formBuilder.group({
      replyUserName1: ['', Validators.required]
    });
    this.registerForm4 = this.formBuilder.group({
      updateComment: ['', Validators.required]
    });
    this.registerForm5 = this.formBuilder.group({
      updateReply: ['', Validators.required]
    });
  }

  loadPosts() {
    this.serviceactualite.getPosts(this.currentPage, this.pageSize).subscribe(
      (response) => {
        console.log(response.data)
        this.posts = response.data.map(post => {
          if (post.content[0].images) {
            post.content[0].images = JSON.parse(post.content[0].images);
          }
          return post;
        });
        this.totalItems = response.total;
      },
      (error) => {
        console.error('Error fetching posts', error);
      }
    );
  }



  pageChange(newPage: number) {
    this.currentPage = newPage;
    this.loadPosts();
  }

  toggleComments(postId: string) {
    const post = this.posts.find(p => p._id === postId);

    if (post) {
      post.showComments = !post.showComments;
    }
  }


  toggleReplies(commentId: string, post) {

    this.showReplies[commentId] = !this.showReplies[commentId];
    if (this.showReplies[commentId] && !post.comments.find(c => c._id === commentId)?.replies) {
      this.loadReplies(commentId, post);
    }
  }

  toggleCommentReplyInput(commentId: string, name: string): void {
    this.showCommentReplyInput[commentId] = !this.showCommentReplyInput[commentId];
    // this.replyUserName = `@${name} `;
    this.replyUserName ="";
  }

  toggleReplyInput(replyId: string, name: string): void {
      this.showReplyInput[replyId] = !this.showReplyInput[replyId];
      // this.replyUserName1 = `@${name} `;
      this.replyUserName1 = "";
  }


  submitReply(commentId: string, replyUserName: string, postId:string): void {
    this.submitted2 = true;
    if (this.registerForm2.invalid) {
      return;
    }
    this.serviceactualite.addReply(replyUserName, postId, commentId)
      .pipe(first()).subscribe(() => {
        this.showCommentReplyInput[commentId] =false
        this.replyUserName = '';
        this.loadPosts();
        this.showToasterSuccess();
        this.submitted = false;

      })
  }

  submitReply1(commentId: string, replyUserName: string, postId:string, replyId:string): void {
    this.submitted3 = true;
    if (this.registerForm3.invalid) {
      return;
    }
    this.serviceactualite.addReply1(replyUserName, postId, commentId, replyId)
      .pipe(first()).subscribe(() => {
        this.showReplyInput[replyId] =false;
        this.replyUserName1 = '';
        this.loadPosts();
        this.showToasterSuccess();
        this.submitted = false;
    })
  }

  loadReplies(commentId: string, post) {
    this.serviceactualite.getReplies(post._id, commentId).pipe(first())
      .subscribe(data => {

        const comment = post.comments.find(c => c._id.$oid === commentId);
        if (comment) {
          comment.replies = data;
        }
      });
  }

  addComment(postId) {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    const newComment = this.registerForm.value.newComment;
    this.serviceactualite.addComment(newComment, postId)
      .pipe(first()).subscribe(() => {
        this.newComment = "";
        this.loadPosts();
        this.showToasterSuccess();
        this.submitted = false;
        this.registerForm.reset();
      })
  }

  showToasterSuccess() {
    this.notifyService.showSuccess("تمت إضافة تعليق")
  }

  toggleView(post) {
    this.showAll = !this.showAll;
    if (this.showAll) {
      const imagesToDisplay: IAlbum[] = this.images
        .slice(5)
        .map(image => ({ src: image, caption: post.content[0].text, thumb: image }));
      this.lightbox.open(imagesToDisplay, 0);
    }
  }


  delete(postId) {
    this.serviceactualite.deletePost(postId)
      .pipe(first()).subscribe(() => {
        this.loadPosts();
        this.showToasterError();
        this.modalService.dismissAll();

      })
  }

  deleteComment(postId, commentId) {
    this.serviceactualite.deleteComment(postId, commentId)
      .pipe(first()).subscribe(() => {
        this.loadPosts();
        this.showToasterError2();
        this.modalService.dismissAll();
      })
  }

  updatedComment(postId, commentId, comment) {
    this.submitted4 = true;
    if (this.registerForm4.invalid) {
      return;
    }
    this.serviceactualite.updateComment(postId, commentId, comment)
      .pipe(first()).subscribe(() => {
        this.loadPosts();
        this.showToasterSuccess2();
        this.modalService.dismissAll();
      })
  }
  

  deleteReply(postId, commentId, replyId) {
    this.serviceactualite.deleteReply(postId, commentId, replyId)
      .pipe(first()).subscribe(() => {
        this.loadPosts();
        this.showToasterError3();
        this.modalService.dismissAll();
      })
  }

  updatedReply(replyId, reply) {
    this.submitted5 = true;
    if (this.registerForm5.invalid) {
      return;
    }
    this.serviceactualite.updateReply(replyId, reply)
      .pipe(first()).subscribe(() => {
        this.loadPosts();
        this.showToasterSuccess3();
        this.modalService.dismissAll();
      })
  }

  showToasterError() {
    this.notifyService.showSuccess("تم  حذف المنشور ")
  }
  showToasterError2() {
    this.notifyService.showSuccess("تم  حذف التعليق  ")
  }

  showToasterError3() {
    this.notifyService.showSuccess("تم  حذف الإجابة  ")
  }

  showToasterSuccess2() {
    this.notifyService.showSuccess("تم  تعديل التعليق  ")
  }

  showToasterSuccess3() {
    this.notifyService.showSuccess("تم  تعديل الإجابة  ")
  }

  loadMoreComments() {
    this.visibleCommentsCount += 4;
  }

  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openSm3(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
