import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ModalDismissReasons, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { DemandeRecharge } from 'src/app/modules/demanderecharge';
import { DemanderechargeService } from 'src/app/services/demanderecharge.service';
import { Recharge } from 'src/app/modules/recharge';
import { DataTablesResponse } from 'src/app/modules/datatables-response';
import { environment } from 'src/environments/environment.prod';
import { UtilisateurService } from 'src/app/services/utilisateur.service';
import { SharedService } from 'src/app/shared.service';
@Component({
  selector: 'app-liste-mobile',
  templateUrl: './liste-mobile.component.html',
  styleUrls: ['./liste-mobile.component.css']
})
export class ListeMobileComponent implements OnInit {
  //uri = environment.urlMarket;
  uri=environment.apiUrl;
  demandesRechage: DemandeRecharge[];
  recharges: Recharge[] = [];
  date = new Date;
  registerForm: FormGroup;
  registerForm1: FormGroup;
  registerForm2: FormGroup;
  submitted = false;
  submitted2 = false;

  closeResult = '';
  user: any = {};
  col: string;
  reponse: string;
  reponseAccept: string;
  searchTerm: string;
  offers: any;
  currentPage = 1;
  itemsPerPage = 10;
  pageSize: number;
  p: number = 1;
  tok = localStorage.getItem('token');
  token = JSON.parse(this.tok);
  headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
    'Accept': 'application/json'
  });
  dOptions: DataTables.Settings = {};
  dtOptions: DataTables.Settings = {};
  receivedData: any;
  offers2: any;
  constructor(private serviceutilisateur: UtilisateurService, private servicedemanderecharge: DemanderechargeService, private serviceauth: AuthentificationService, private notifyService: NotificationService,
    private formBuilder: FormBuilder,private sharedService: SharedService, private http: HttpClient, public datepipe: DatePipe, private modalService: NgbModal,
    config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.user = this.serviceauth.getCurrentUser();
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
     
    });
    this.receivedData=false;
    this.dtOptions = {
      pagingType: 'simple_numbers',
      searching: false,
      language: {
        processing: "يرجى الإنتظار...",
        searchPlaceholder: 'البحث',search: "",
        lengthMenu: " _MENU_ ",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        paginate: {
          first: "",
          previous: "<i class='material-icons'>navigate_next</i> ",
          next: "<i class='material-icons'>navigate_before</i>",
          last: ""
        },
      },
      info: false,
      paging: true,
      ordering: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.currentPage = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) + 1;
        const rowData = {
          no_of_records: dataTablesParameters.length,
          page: this.currentPage,
          group_id: 1
        };
        const obj = { searchTerm: this.searchTerm }
        this.http.post<DataTablesResponse>(this.uri + 'demandesRecharge', Object.assign(dataTablesParameters, obj), { headers: this.headers }).subscribe(resp => {
          const details = JSON.parse(JSON.stringify(resp.original));

          this.demandesRechage = details.data;
          callback({
            recordsTotal: details.recordsTotal,
            recordsFiltered: details.recordsFiltered,
            data: [],
          });
        });
      },
      columns: [
        { data: "created_at" },
        { data: "name" },
        { data: "badge" },
        { data: "numerotlf" },
        { data: "montant" },
        { data: "codeparrainage" },
      ],
    };

    this.dOptions = {
      pagingType: 'simple_numbers',
      searching: false,
      language: {
        processing: "يرجى الإنتظار...",
        searchPlaceholder: 'البحث',search: "",
        lengthMenu: " _MENU_ ",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        paginate: {
          first: "",
          previous: "<i class='material-icons'>navigate_next</i> ",
          next: "<i class='material-icons'>navigate_before</i>",
          last: ""
        },
      },
      info: false,
      paging: false,
      ordering: false,
      serverSide: true,
      processing: true }
    this.registerForm = this.formBuilder.group({
      reponse: ['', Validators.required],
    });
    this.registerForm2 = this.formBuilder.group({
      reponseAccept: ['', Validators.required],
    });
  }

  get f() { return this.registerForm.controls; }
  get g() { return this.registerForm2.controls; }

  updatedemande(id: string) {
    this.servicedemanderecharge.updatedemande(id).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.showToasterSuccess();
      this.modalService.dismissAll();
    })
  }
  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }
  updatedemande2(id: string, reponseAccept) {
    this.submitted2 = true;
    if (this.registerForm2.invalid) {
      return;
    }
    this.servicedemanderecharge.updatedemande2(id, reponseAccept).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.showToasterSuccess();
      this.reponseAccept = "";
      this.modalService.dismissAll();
    })
  }


  search() {
    $('#dtable').DataTable().ajax.reload();
  }

  updatedemanderefuse(id: string, reponse: string) {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.servicedemanderecharge.updatedemanderefuse(reponse, id, this.user.email).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.reponse = "";
      this.showToasterSuccess1();
      this.modalService.dismissAll();
      this.submitted = false;
    })
  }

  updatedemanderefuse2(id: string) {
    this.servicedemanderecharge.updatedemanderefuseC(id, this.user.email).pipe(first()).subscribe(() => {
      $('#dtable').DataTable().draw(false);
      this.reponse = "";
      this.showToasterSuccess1();
      this.modalService.dismissAll();
    })
  }

  getOfferUser(id) {
    this.serviceutilisateur.getOfferUser(id)
      .subscribe(data => {
        this.offers = data;
      })
  }
  getOfferUser2(id) {
    this.serviceutilisateur.getOfferUser2(id)
      .subscribe(data => {
        this.offers2 = data;
      })
      console.log(this.offers2);
  }


  showToasterSuccess() {
    this.notifyService.showSuccess("  تم قبول طلب الشحن  ")
  }

  showToasterSuccess1() {
    this.notifyService.showError("      تم رفض طلب الشحن  ")
  }

  open(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.reponse = "";
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.reponse = "";
    });
  }

  openSm3(content) {
    this.modalService.open(content, { size: 'lg', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
