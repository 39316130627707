<app-menu></app-menu>
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body dir="rtl" [ngStyle]="{'width': receivedData ? '100%' : 'auto'}">
  <div class="container pt-5" *ngIf="user.role == 'globale' || user.store == 1" style="
  padding-right: 65px !important;
">
    <h2 style="margin-right: 3px;">الايقونات</h2>
    <div class="row">
      <div class="col">
        <br>
        <div class="dash" style="margin-left: -195px;">
          <div class="row row1"
            style="border: 1px dashed rgb(77, 196, 226);border-radius: 10px;width: 54%;height:276px;">

            <div class="col-lg-7">
              <form [formGroup]="registerForm3" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
                <div class="bord" style="margin-top: -13px;">

                  <br>
                  <div class="">

                    <div class=" col-img d-flex flex-row justify-content-center align-items-center ">

                      <label class="btn2" for="inputGroupFile01" style="
                          width: 525px;
                          background: transparent;
                      ">

                        <img class="img-fluid img" *ngIf="link3 ==''" src="../assets/Group 10.png" alt="" style="
                         margin-right: 96px;margin-top: 83px;height: 107px;width: 202px;
                      ">
                        <img class="img-fluid img" *ngIf="link3 !=''" [src]="link3" height="200px" width="200px"
                          style="margin-right: 103px;margin-top: 83px;height: 105px;width: 167px;">
                      </label>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-center align-items-center">

                    <input (change)="selectImage($event)" [(ngModel)]="img" name="img" accept="image/*" required
                      formControlName="img" style="display:none" type="file" class="custom-file-input"
                      id="inputGroupFile01">
                  </div>

                  <br>
                  <div class="d-flex" style="margin-right: 108%;width: 221px;margin-top: -97px;">
                    <div class="card" id="card"
                      style="height: 83px;background-color: white;margin-right: 41px;margin-top: 26px;box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);width: 190px;">
                      <h4>
                        <p class="prix" style="text-align: center;font-size:19px;margin-top:9px">ثمن الايقونة
                        <p>
                      </h4>
                      <br>
                      <div style="display: flex; align-items: center;">

                        <input type="text" onpaste="return false;" (keypress)="keyPressNumbers($event)"
                          class="inp form-control" [(ngModel)]="prix3" name="prix3" formControlName="prix3"
                          maxlength="10"
                          style="outline: none;margin-inline: auto;border: none;border-bottom: 1px solid #c3c3c3;padding-bottom: 0px;width: 83%;box-shadow: none;border-radius: 0px;margin-top: -63px;">
                        <div class="d-flex"> <span style="margin-right: -42px;margin-top: -39px;">نقطة</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br>
                  <div class="d-flex flex-row justify-content-center align-items-center" style="margin-top: -23px;
                    height: 0px;              ">
                    <button (click)="ajouter()" type="submit" class="btn1" id="btnn1"
                      style="margin-right: 267%;margin-top: 44px;border-radius:6px">تسجيل</button>

                  </div>
                  <div class="invalid-feedback"><span>{{message}}</span></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <br>
      <br>
      <div class="col">
        <div class="row row2" *ngIf="store && store.length > 0"
          style="margin-top:21px; background-color: transparent; border:1px dashed rgb(77, 196, 226); border-radius: 10px;">
          <div class="col-md-6" id="item"
            *ngFor="let cadeau of store | slice: pageSize | slice: 0:itemsPerPage; let index = index;">
            <div class="mb-3">
              <div class="col-md-6" id="im">
                <img class="img-fuild img1" src="{{cadeau.urlicon}}" alt="err">
                <p class="prix" style="text-align: center;font-size: 15px;margin: -10px;margin-top: 4px;">ثمن الايقونة:
                  {{cadeau.prix}} <img src="../assets/Coin.png" alt=""></p>
                <br>
                <div class="d-flex"  id="allboutons" style="justify-content: center;margin-top: 0px;">

                  <img (click)="openSm2(content4)" class="delete" height="37px" width="37px" src="assets/deleteic.png"
                    alt="">
                  <a (click)="openSm2(content3)" class="updatebtn"> <img src="../assets/edit.png" alt="" style="
                          height: 37px;
                          width:37px;
                          margin-top: -5px;
                       "> </a>
                </div>





              </div>
              <ng-template #content4 let-modal>
                <div class="modal-body" dir="rtl">
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h5 id="h3"> هل تريد حذف هذه ايقونة ؟</h5>
                  <div class="d-flex justify-content-center">
                    <button id="btnP" (click)="supprimer(cadeau.id)" type="button" class="btn btn-primary">&nbsp;
                      &nbsp; تأكيد &nbsp; &nbsp;</button>
                  </div>
                </div>
              </ng-template>

            </div>

            <br>




            <br>

            <ng-template #content3 let-modal>
              <div class="modal-body" dir="rtl">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
                <form [formGroup]="registerForm4" (ngSubmit)="modifyStorePrice(cadeau.id, cadeau.prix)">
                  <div class="d-flex">
                    <p class="prix">المبلغ</p>
                    <input type="text" onpaste="return false;" formControlName="prix1" [(ngModel)]="cadeau.prix"
                      (keypress)="keyPressNumbers($event)" placeholder={{cadeau.prix}} class="inp form-control"
                      maxlength="10">
                  </div>
                  <div *ngIf="k['prix'].errors" class="invalid-feedback">
                    <div *ngIf="k['prix'].errors['required']"> يجب إدخال الثمن</div>
                  </div>
                  <div class="d-flex flex-row justify-content-center align-items-center" style="margin-top: 10px;">
                    <button type="submit" class="btn1" style="margin-right:18%">تعديل</button>
                  </div>
                </form>
              </div>
            </ng-template>
          </div>
          <br>
          <br>

        </div>
        <div class="col-md-6" *ngIf="store && store.length === 0">
          <img src="../assets/noproduct.png" alt="Image vide">
          <p>متجر فارغ</p>
        </div>
          <br>
        <ngb-pagination dir="rtl" [maxSize]="5" *ngIf="store.length > itemsPerPage"
          [collectionSize]="store.length" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage"
          (pageChange)="onPageChange(currentPage); p = $event"></ngb-pagination>

      </div>
    
     
    </div>
  </div>

  <div class="container" *ngIf="user.role != 'globale' && user.store == 0 " style="padding-top: 10% !important">
    <div class="container" style=" width: 100%;height: 400px;max-height: 800px;background-size: contain;
    background-repeat: no-repeat; background-position: center center;display:block;margin-top:auto;
    margin-bottom:auto; max-height: 800px;background-image: url('assets/droit.png')">
    </div>
  </div>
</body>