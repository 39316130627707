import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs';
import { Recharge } from 'src/app/modules/recharge';
import { AuthentificationService } from 'src/app/services/authentification.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RechargeService } from 'src/app/services/recharge.service';
import { SharedService } from 'src/app/shared.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  name: string
  name2: string
  user: any = {};
  receivedData: any;
  submitted = false;
  registerForm: FormGroup;
  submitted2 = false;
  registerForm2: FormGroup;
  prets: Recharge[] = [];
  itemsPerPage = 8;
  pageSize: number;
  closeResult = '';
  currentPage = 1;
  p: number = 1;
  constructor(private servicerecharge: RechargeService,private formBuilder: FormBuilder,private serviceauth: AuthentificationService,
    private notifyService: NotificationService,private sharedService: SharedService,private modalService: NgbModal) { }

  ngOnInit(): void {
    this.sharedService.data$.subscribe(data => {
      this.receivedData = data;
    });
    this.receivedData=false;
    this.getPrets()
    this.user = this.serviceauth.getCurrentUser();
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
    this.registerForm2 = this.formBuilder.group({
      name2: ['', Validators.required],
    });
  }

  get f() { return this.registerForm.controls; }
  get g() { return this.registerForm2.controls; }
  isZeroAllowed = true;
  keyPressNumbers(event) {
    const key = event.key || String.fromCharCode(event.keyCode);

    if (key === '0' && this.isZeroAllowed && event.target.selectionStart === 0) {
      event.preventDefault();
    }

    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }

  }

  getPrets() {
    this.servicerecharge.getCategories()
      .pipe(first()).subscribe(data => {
        this.prets = data
      })
  }

  ajouter() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.servicerecharge.addCategory(this.name)
    .pipe(first()).subscribe(() => {
      this.name = "";
      this.getPrets();
      this.submitted = false;
      this.showToasterSuccess();
    })
  }

  update(id:string, name : string) {
    this.submitted2 = true;
    if (this.registerForm2.invalid) {
      return;
    }
    this.servicerecharge.updateCategory(id,name )
    .pipe(first()).subscribe(() => {
      this.getPrets();
      this.submitted2 = false;
      this.showToasterSuccess2();
      this.modalService.dismissAll();
    })
  }

  open1(content) {
    this.modalService.open(content, { size: 'md', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });
  }

  openSm2(content) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  showToasterSuccess() {
    this.notifyService.showSuccess(" تم اضافة صنف")
  }
  showToasterSuccess2() {
    this.notifyService.showSuccess(" تم تعديل الصنف")
  }
  showToasterError() {
    this.notifyService.showSuccess("تم حذف الصنف ")
  }
  showToasterError2() {
    this.notifyService.showError("  لا يمكن حذف الصنف  ")
  }
  public onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }

  delete(id: string) {
    this.servicerecharge.deleteCategory(id)
      .pipe(first()).subscribe((data) => {
        if(data['success'] == "false"){
          this.showToasterError2();
        }else{
          this.showToasterError();
        }
        this.getPrets();
       
        this.modalService.dismissAll();
      })
  }

}
