import { Injectable } from '@angular/core';
import { Admin } from 'src/app/modules/admin';
import { AdminService } from 'src/app/services/admin.service';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminResolver implements Resolve<Admin[]> {
  constructor(private adminss: AdminService) {}
  resolve(): Observable<Admin[]> {
    return this.adminss.getAlladmin();
  }
}
